import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ModalContext from '../../contexts/ModalContext';
import { ButtonText, FormGroup } from '..';
import './styles.scss';

const Confirmation = ({
  children,
  confirmAction,
  confirmActionButtonProps,
  confirmLabel,
  message,
  rejectAction,
  rejectLabel
}) => {
  const { closeModal } = useContext(ModalContext);

  const handleConfirmClick = () => {
    confirmAction();
    closeModal();
  };

  const handleRejectClick = () => {
    rejectAction();
    closeModal();
  };

  return (
    <div className="confirmation">
      {message && (
        <p className="message" data-testid="confirmationMessage">
          {message}
        </p>
      )}
      {children}
      <FormGroup>
        <ButtonText
          onClick={handleConfirmClick}
          data-testid="confirmButton"
          {...confirmActionButtonProps}
        >
          {confirmLabel}
        </ButtonText>
        <ButtonText
          variant="outlined"
          onClick={handleRejectClick}
          data-testid="rejectButton"
        >
          {rejectLabel}
        </ButtonText>
      </FormGroup>
    </div>
  );
};

Confirmation.propTypes = {
  children: PropTypes.node,
  confirmAction: PropTypes.func,
  confirmActionButtonProps: PropTypes.object,
  confirmLabel: PropTypes.string,
  message: PropTypes.string,
  rejectAction: PropTypes.func,
  rejectLabel: PropTypes.string
};

Confirmation.defaultProps = {
  children: null,
  confirmAction: () => {},
  confirmActionButtonProps: {
    color: 'primary'
  },
  confirmLabel: 'Yes',
  message: '',
  rejectAction: () => {},
  rejectLabel: 'No'
};

export default Confirmation;
