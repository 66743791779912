import * as yup from 'yup';

const resetPasswordFormValidation = yup.object().shape({
  newPassword: yup
    .string()
    .matches(/(?=.{8})/, 'Password must be at least 8 characters long')
    .matches(
      /(?=^.{0,255}$)/,
      'Password must be no more than 255 characters long'
    )
    .matches(/(?=.*[a-z])/, 'Password must have at least 1 lower-cased letter')
    .matches(/(?=.*[A-Z])/, 'Password must have at least 1 capital letter')
    .matches(/(?=.*[0-9])/, 'Password must have at least 1 number')
    .required('Password is empty'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Passwords must match')
});

export default resetPasswordFormValidation;
