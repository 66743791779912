const getShortAddress = (address) => {
  if (typeof address !== 'string') return address;

  const subStrings = address.split(', ');
  const cityState = subStrings.slice(
    subStrings.length - 3,
    subStrings.length - 1
  );

  return cityState.join(', ');
};

export default getShortAddress;
