import { API, Auth } from 'aws-amplify';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Zendesk from 'react-zendesk';
import { v4 as uuidv4 } from 'uuid';
import './App.scss';
import Routes from './Routes';
import './assets/styles/_main.scss';
import {
  Footer,
  Loader,
  NavbarBottom,
  NavbarTop,
  ToastNotification
} from './components/index';
import AuthContext from './contexts/AuthContext';
import ModalContext from './contexts/ModalContext';
import UserContext from './contexts/UserContext';
import {
  createUser as createUserMutation,
  createStripeCustomer as createStripeCustomerMutation
} from './graphql/customMutations';
import {
  getUserData as getUserDataQuery,
  checkForPaymentMethods as checkForPaymentMethodsQuery
} from './graphql/customQueries';
import { onSendMessage } from './graphql/customSubscriptions';
import { getStripeAccount as getStripeAccountQuery } from './graphql/queries';
import { useLazyQuery, useMutation, useWindowDimensions } from './hooks';
import { getLocation, getPublicId, initializePendo } from './utils';
import initializeAmplify from './utils/initializeAmplify';
import { APP_URL_SCHEMES, URL_HELPERS } from './utils/url';

initializeAmplify();

function App() {
  const {
    state: { loading: authLoading, accessToken },
    getAuthTokens
  } = useContext(AuthContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    state: { id }
  } = useContext(UserContext);
  const { addToast } = useToasts();
  const [getUserData] = useLazyQuery(getUserDataQuery);
  const [checkForPaymentMethods] = useLazyQuery(checkForPaymentMethodsQuery);
  const [getStripeAccount] = useLazyQuery(getStripeAccountQuery);
  const [createStripeCustomer] = useMutation(createStripeCustomerMutation);
  const [createUser] = useMutation(createUserMutation);
  const {
    setUserLocation,
    setUserData,
    state: { location, unreadConversations }
  } = useContext(UserContext);

  const { windowWidth } = useWindowDimensions();

  const {
    state: { visible }
  } = useContext(ModalContext);
  const newMessageSubscriptionRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAuthTokens();
    let pendoVisitorID = localStorage.getItem('pendoVisitorID');

    if (!pendoVisitorID) {
      localStorage.setItem('pendoVisitorID', uuidv4());
    }

    if (accessToken) {
      Auth.currentUserPoolUser({ bypassCache: true })
        .then(async (currentUser) => {
          try {
            const {
              getUserData: { items }
            } = await getUserData({
              variables: {
                email: currentUser.attributes.email
              }
            });
            let user;
            // console.log(items);
            if (items?.length === 0) {
              const firstName = currentUser.attributes.given_name;
              const lastName = currentUser.attributes.family_name;
              const createUserResponse = await createUser({
                variables: {
                  input: {
                    email: currentUser.attributes.email,
                    firstName: currentUser.attributes.given_name,
                    lastName: currentUser.attributes.family_name,
                    sub: currentUser.attributes.sub,
                    publicID: getPublicId(`${firstName} ${lastName}`)
                  }
                },
                authMode: 'API_KEY'
              });
              user = createUserResponse.createUser;
            } else {
              user = items[0];
            }
            const { renterConversations, ownerConversations } = user;
            let conversations = [];
            if (renterConversations && ownerConversations) {
              conversations = [
                ...renterConversations?.items,
                ...ownerConversations?.items
              ];
            }
            let hasPaymentMethods = false;
            let customerID;

            if (user.customerID) {
              const paymentMethodsResponse = await checkForPaymentMethods({
                variables: {
                  customerID: user.customerID
                }
              });
              hasPaymentMethods =
                !!paymentMethodsResponse.getPaymentMethods.items?.length;
              customerID = user.customerID;
            } else {
              const createCustomerResponse = await createStripeCustomer({
                variables: {
                  input: {
                    userID: user.id,
                    fullName: user.fullName,
                    email: user.email,
                    publicID: user.publicID,
                    _version: 0
                  }
                },
                authMode: 'API_KEY'
              });
              customerID =
                createCustomerResponse.createStripeCustomer.customerID;
            }

            const getStripeAccountData = await getStripeAccount({
              variables: {
                id: user.id
              }
            });

            setUserData({
              email: currentUser.attributes.email,
              sub: currentUser.attributes.sub,
              publicID: user.publicID,
              id: user.id || '',
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName
                ? user.fullName
                : `${user.firstName} ${user.lastName}`,
              avatar: user.avatar,
              location: {
                lat: user.location?.lat || null,
                lon: user.location?.lon || null,
                address: user.address
              },
              stripeAccountID:
                getStripeAccountData.getStripeAccount?.stripeAccountID,
              stripeDetailsSubmitted:
                getStripeAccountData.getStripeAccount?.detailsSubmitted || null,
              unreadConversations: conversations.filter((conversation) => {
                return (
                  !conversation.hasRead &&
                  conversation.lastMessageReceived?.recipientID === user.id
                );
              }),
              customerID,
              hasPaymentMethods
            });
            initializePendo({
              id: user.id,
              email: user.email,
              full_name: user.fullName
                ? user.fullName
                : `${user.firstName} ${user.lastName}`
            });
            setLoading(false);
          } catch (error) {
            console.error('getUserData', error);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error('App', error);
        });
    } else {
      initializePendo({
        id: pendoVisitorID
      });
      setLoading(false);
    }

    if (!location.lat || !location.lon) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          getLocation(latitude, longitude).then(({ address }) => {
            setUserLocation({
              lat: latitude,
              lon: longitude,
              address
            });
          });
        }
      );
    }
  }, [accessToken]);

  // *NOTE: Stops background scrolling when a modal is open
  useEffect(() => {
    const urls = [
      /^\/profile\/accounts$/,
      /^\/profile\/login-information$/,
      /^\/profile\/payment-information$/,
      /^\/profile\/remittance$/,
      /^\/messages\/([A-Z0-9]{11})$/,
      /^\/transactions\/([A-Z0-9]{11})$/
    ];

    const url = urls.find((url) => url.test(pathname));

    const isModalOpen =
      visible || (url && windowWidth >= 365 && windowWidth <= 1024);

    document.body.style.overflow = isModalOpen ? 'hidden' : 'unset';
  }, [visible, pathname, windowWidth]);

  const profileSubpageURLs = [
    APP_URL_SCHEMES.accounts,
    APP_URL_SCHEMES.itemsAndReviews,
    APP_URL_SCHEMES.loginInformation,
    APP_URL_SCHEMES.paymentInformation,
    APP_URL_SCHEMES.remittance
  ];

  const [previousURL, setPrevURL] = useState(URL_HELPERS.index);

  // *NOTE: Scrolls to the top of the page when redirecting to a subpage
  useEffect(() => {
    setPrevURL(pathname);
    const currentURL = pathname;

    const isFromPageToSubpage =
      previousURL === URL_HELPERS.profile &&
      profileSubpageURLs.includes(currentURL);

    const isFromSubpageToPage =
      profileSubpageURLs.includes(previousURL) &&
      currentURL === URL_HELPERS.profile;

    const isFromSubpageToSubpage =
      profileSubpageURLs.includes(previousURL) &&
      profileSubpageURLs.includes(currentURL);

    if (
      !(
        (isFromPageToSubpage ||
          isFromSubpageToPage ||
          isFromSubpageToSubpage) &&
        windowWidth < 1201
      )
    ) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    const subscribeToNotifications = async () => {
      newMessageSubscriptionRef.current = await API.graphql({
        query: onSendMessage,
        variables: {
          recipientID: id
        }
      }).subscribe({
        next: ({
          value: {
            data: { onSendMessage }
          }
        }) => {
          if (!/\/messages/.test(history.location.pathname)) {
            if (onSendMessage.sender) {
              addToast(
                <ToastNotification
                  message={`New message from ${onSendMessage.sender.firstName}`}
                  toastId="new-message"
                />,
                {
                  id: 'new-message'
                }
              );
            }
          }
          setUserData({
            unreadConversations: unreadConversations.concat(
              onSendMessage.conversation
            )
          });
        },
        error: (error) => console.error('subscription provider', error)
      });
    };

    if (id) {
      subscribeToNotifications();
    } else {
      newMessageSubscriptionRef?.current?.unsubscribe();
    }

    return () => newMessageSubscriptionRef?.current?.unsubscribe();
  }, [id]);

  const renderRoutes = () => {
    const appLoading = authLoading || loading;

    return (
      <div className="page-body">{appLoading ? <Loader /> : <Routes />}</div>
    );
  };

  const renderFooter = () => {
    if (pathname.startsWith('/items/') || pathname.startsWith('/my-items/')) {
      return null;
    }

    return (
      <>
        <Footer />
        {windowWidth <= 768 &&
          (pathname === URL_HELPERS.index ||
            pathname === URL_HELPERS.myItems ||
            pathname === URL_HELPERS.dashboard ||
            pathname === URL_HELPERS.messages ||
            pathname === URL_HELPERS.profile ||
            pathname === URL_HELPERS.searchResults) && (
            <NavbarBottom isLoggedIn={accessToken && !loading && true} />
          )}
      </>
    );
  };

  return (
    <>
      {windowWidth >= 769 && (
        <NavbarTop isLoggedIn={accessToken && !loading && true} />
      )}
      {renderRoutes()}
      {renderFooter()}
      {windowWidth >= 481 && (
        <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY} />
      )}
    </>
  );
}

export default App;
