import PropTypes from 'prop-types';
import React from 'react';

const Toggle = ({ children }) => {
  return <div className="toggle">{children}</div>;
};

Toggle.propTypes = {
  children: PropTypes.node
};

Toggle.defaultProps = {
  children: null
};

export default Toggle;
