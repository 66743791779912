import React, { Fragment } from 'react';
import Proptypes from 'prop-types';
import { ModalProvider } from '../contexts/ModalContext';
import { AuthProvider } from '../contexts/AuthContext';
import { UserProvider } from '../contexts/UserContext';
import { ToastProvider } from 'react-toast-notifications';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastNotificationContainer } from '../components';

const providers = [ModalProvider, AuthProvider, UserProvider];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const Compose = ({ components, children }) => (
  <Fragment>
    <Elements stripe={stripePromise}>
      <ToastProvider
        autoDismiss={true}
        autoDismissTimeout={3000}
        components={{ Toast: ToastNotificationContainer }}
      >
        {components.reduce((acc, curr) => {
          const [Provider, props] = Array.isArray(curr)
            ? [curr[0], curr[1]]
            : [curr, {}];
          return <Provider {...props}>{acc}</Provider>;
        }, children)}
      </ToastProvider>
    </Elements>
  </Fragment>
);

export const ComposeProvider = ({ children }) => (
  <Compose components={providers}>{children}</Compose>
);

ComposeProvider.propTypes = {
  children: Proptypes.node.isRequired
};

Compose.propTypes = {
  components: Proptypes.node.isRequired,
  children: Proptypes.node.isRequired
};

export default ComposeProvider;
