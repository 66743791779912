import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactAvatar from 'react-avatar';
import defaultAvatar from '../../assets/images/user-01.png';
import { useLazyS3Read } from '../../hooks';
import './styles.scss';

const Avatar = ({ avatar, children, name, size, ...props }) => {
  const [getAvatar, { loading: avatarLoading, data: avatarData }] =
    useLazyS3Read();

  useEffect(() => {
    const getAvatarUrl = async () => {
      await getAvatar(avatar);
    };

    getAvatarUrl();
  }, [avatar?.key]);

  const classNames = clsx(
    'avatar-container',
    (!avatar || !avatarData) && '-default'
  );

  const styles = {
    width: `calc(${size}px + 8px)`,
    height: `calc(${size}px + 8px)`
  };

  return avatarLoading ? null : (
    <div className={classNames} style={styles}>
      {avatar && avatarData ? (
        <ReactAvatar
          className="avatar"
          color="#eaecf0"
          fgColor="#00acc1"
          name={name}
          round
          size={size}
          src={avatarData}
          {...props}
        />
      ) : (
        <img src={defaultAvatar} className="default-avatar" />
      )}
      {children}
    </div>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.object,
  children: PropTypes.node,
  name: PropTypes.string,
  size: PropTypes.string
};

Avatar.defaultProps = {
  avatar: null,
  children: null,
  name: '',
  size: ''
};

export default Avatar;
