import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import amazonLogo from '../../assets/logos/amazon.svg';
import facebookLogo from '../../assets/logos/facebook.svg';
import googleLogo from '../../assets/logos/google.svg';
import './styles.scss';

const ButtonSocMed = ({ label, socialMedia, ...props }) => {
  const SOCIALS = {
    amazon: {
      logo: amazonLogo,
      provider: 'LoginWithAmazon'
    },
    facebook: {
      logo: facebookLogo,
      provider: 'Facebook'
    },
    google: {
      logo: googleLogo,
      provider: 'Google'
    }
  };

  const classNames = clsx('button-soc-med', `-${socialMedia}`);

  const handleClick = () =>
    Auth.federatedSignIn({ provider: SOCIALS[socialMedia].provider });

  return (
    <button {...props} className={classNames} onClick={handleClick}>
      <img src={SOCIALS[socialMedia].logo} className="logo" />
      {label}
    </button>
  );
};

ButtonSocMed.propTypes = {
  label: PropTypes.string,
  socialMedia: PropTypes.string
};

ButtonSocMed.defaultProps = {
  label: '',
  socialMedia: ''
};

export default ButtonSocMed;
