import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Dropdown, FormTitle } from '../../../../components';
import './styles.scss';

const Advanced = () => {
  const { control, getValues } = useFormContext();

  return (
    <div className="advanced">
      <FormTitle title="Advanced" />
      <Dropdown
        control={control}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.key}
        keywordTags={() => getValues('requiredDocuments')}
        label="Documents required for rental"
        name="requiredDocuments"
        options={[
          {
            key: 'IDENTIFICATION',
            name: 'Identification (license, ID, passport, etc)'
          },
          {
            key: 'OPERATORS_LICENSE',
            name: "Operator's license"
          },
          {
            key: 'INSURANCE',
            name: 'Proof of Insurance'
          },
          {
            key: 'OTHER',
            name: 'Other'
          }
        ]}
        shouldEnterKeywords
      />
    </div>
  );
};

export default Advanced;
