import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { getUserThings as getUserThingsQuery } from '../../graphql/queries';
import { useQuery } from '../../hooks';
import { URL_HELPERS } from '../../utils/url';

const useMyItems = () => {
  const history = useHistory();

  const { state } = useContext(UserContext);

  const { loading: getUserThingsLoading, data: getUserThingsData } = useQuery(
    getUserThingsQuery,
    {
      variables: {
        userID: state.id
      }
    }
  );

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!getUserThingsLoading && getUserThingsData) {
      setItems(getUserThingsData.getUserThings.items);
    }
  }, [getUserThingsLoading, getUserThingsData]);

  const handleChange = (event) => {
    const keyword = new RegExp(event.target.value, 'i');
    const filteredItems = getUserThingsData.getUserThings.items.filter((item) =>
      keyword.test(item.name)
    );

    setItems(filteredItems);
  };

  const handleClick = () => {
    history.push({
      pathname: URL_HELPERS.add,
      state: {
        thing: {
          name: ''
        }
      }
    });
  };

  return {
    isUserItemsLoading: getUserThingsLoading,
    userItemsData: getUserThingsData?.getUserThings.items || [],
    searchedItems: items,
    onChange: handleChange,
    onClick: handleClick
  };
};

export default useMyItems;
