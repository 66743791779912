import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import ReviewCard from './ReviewCard';

const ReviewCardList = ({ reviews }) => {
  return (
    <div className="review-card-list">
      {reviews.map((review, index) => (
        <ReviewCard
          avatar={review.reviewer.avatar}
          date={review.createdAt}
          name={review.reviewer.fullName}
          review={review.content}
          key={index}
        />
      ))}
    </div>
  );
};

ReviewCardList.propTypes = {
  reviews: PropTypes.array
};

ReviewCardList.defaultProps = {
  reviews: []
};

export default ReviewCardList;
