import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Header = ({ children, className }) => {
  const classNames = clsx('header', className);

  return <header className={classNames}>{children}</header>;
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Header.defaultProps = {
  children: null,
  className: ''
};

export default Header;
