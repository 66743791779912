import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '../../../../components';
import './styles.scss';

const SystemMessage = ({ children, date, message, name, status, time }) => {
  return (
    <div className="system-message">
      <span className="date">{date}</span>
      <div className="details">
        <div className="name-time-container">
          <span className="app-name">Ukuu</span>
          <span className="time">{time}</span>
        </div>
        <div className="message-container">
          <div className="icon-container">
            <Icon icon="task_alt" />
          </div>
          <div className="content">
            <div className="item-name-status-container">
              <span className="item-name">{name}</span>
              <span className="status">{status}</span>
            </div>
            <p className="message">{message}</p>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

SystemMessage.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  time: PropTypes.string
};

SystemMessage.defaultProps = {
  children: null,
  date: '',
  message: '',
  name: '',
  status: '',
  time: ''
};

export default SystemMessage;
