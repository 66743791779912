import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

const ToggleSwitch = ({ checked, label, onChange }) => {
  const [checkedValue, setCheckedValue] = useState(checked);
  const handleCheck = () => {
    setCheckedValue(!checkedValue);
    onChange(!checkedValue);
  };

  return (
    <label className="toggle-switch" data-testid="checkbox">
      <input
        className="toggle"
        checked={checkedValue}
        onChange={(event) => handleCheck(event.target.checked)}
        type="checkbox"
        data-testid={`checkbox${label}`}
      />
      <span className="slider round" />
      <span className="label">{label}</span>
    </label>
  );
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func
};

ToggleSwitch.defaultProps = {
  checked: false,
  label: '',
  onChange: () => {}
};

export default ToggleSwitch;
