import PropTypes from 'prop-types';
import React from 'react';
import { PeriodType } from '../../constants/enumTypes';
import './styles.scss';

const Price = ({ periodType, pricePerPeriod }) => {
  return (
    <div className="price">
      <span className="price-per-period">{`$${pricePerPeriod}`}</span>
      <span className="period-type">{PeriodType[periodType]}</span>
    </div>
  );
};

Price.propTypes = {
  periodType: PropTypes.string,
  pricePerPeriod: PropTypes.string
};

Price.defaultProps = {
  periodType: '',
  pricePerPeriod: ''
};

export default Price;
