import React, { useContext } from 'react';
import RNModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import {
  ButtonIcon,
  ButtonText,
  EmptyListPlaceholder,
  FormTitle,
  Header,
  Icon,
  Loader,
  PaymentMethodCard
} from '../../../../components';
import ModalContext from '../../../../contexts/ModalContext';
import UserContext from '../../../../contexts/UserContext';
import { useWindowDimensions } from '../../../../hooks';
import { URL_HELPERS } from '../../../../utils/url';
import usePaymentInformation from './usePaymentInformation';
import './styles.scss';
import { PayoutAccountCard } from './components';

const PaymentInformation = () => {
  const history = useHistory();

  const { state } = useContext(UserContext);
  const { AddPaymentMethodModal, EditPaymentMethodModal } =
    useContext(ModalContext);

  const { windowWidth } = useWindowDimensions();

  const {
    isStripeAccountLoading,
    stripeAccountData,
    isPaymentMethodsLoading,
    paymentMethodsData,
    refetchPaymentMethods,
    createStripeAccountLink,
    isCreateStripeAccountLinkLoading,
    setDefaultPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    defaultPaymentMethodID,
    setDefaultPaymentMethodID
  } = usePaymentInformation();

  const hasPaymentMethod = paymentMethodsData.length > 0;

  const handleBackClick = () => history.push(URL_HELPERS.profile);

  const handleConnectClick = async () => {
    const {
      createStripeAccountLink: { url }
    } = await createStripeAccountLink({
      variables: {
        input: {
          userID: state.id
        }
      }
    });

    window.location.replace(url);
  };

  const handleAddClick = () => AddPaymentMethodModal(refetchPaymentMethods);

  const handleChange = async (data) => {
    await setDefaultPaymentMethod({
      variables: {
        input: {
          paymentMethodID: data,
          customerID: state.customerID
        }
      }
    });

    setDefaultPaymentMethodID(data);
  };

  const handlePaymentMethodCardClick = (paymentMethod, checked) => {
    return (event) => {
      if (event.target !== event.currentTarget) return;

      EditPaymentMethodModal({
        paymentMethod,
        updatePaymentMethod,
        onDelete: handleDeleteClick,
        checked
      });
    };
  };

  const handleDeleteClick = async (paymentMethodID) => {
    try {
      await deletePaymentMethod({
        variables: {
          input: {
            paymentMethodID
          }
        }
      });
    } catch (error) {
      console.error('error', error);
    }
  };

  const renderPaymentInformation = () => {
    return isStripeAccountLoading || isPaymentMethodsLoading ? (
      <Loader />
    ) : (
      <div className="payment-information-subpage">
        <Header>
          <Header.Left>
            <ButtonIcon icon="arrow_back" onClick={handleBackClick} />
          </Header.Left>
          <Header.Center>Payment Information</Header.Center>
          <Header.Right />
        </Header>
        <div className="body">
          <FormTitle title="Payout Account" />
          <PayoutAccountCard
            connected={stripeAccountData.detailsSubmitted}
            loading={isCreateStripeAccountLinkLoading}
            onClick={handleConnectClick}
          />
          <FormTitle title="Payment Methods" />
          <div className="payment-methods">
            {hasPaymentMethod ? (
              paymentMethodsData.map((paymentMethod) => (
                <PaymentMethodCard
                  brand={paymentMethod.brand}
                  lastFourDigits={paymentMethod.last4}
                  checked={paymentMethod.id === defaultPaymentMethodID}
                  value={paymentMethod.id}
                  onChange={handleChange}
                  onClick={handlePaymentMethodCardClick(
                    paymentMethod,
                    paymentMethod.id === defaultPaymentMethodID
                  )}
                  key={paymentMethod.id}
                />
              ))
            ) : (
              <EmptyListPlaceholder
                type="payment method"
                icon="add_circle_outline"
                text="You have not added yet."
              />
            )}
          </div>
          <ButtonText color="primary" onClick={handleAddClick}>
            <Icon icon="add" />
            Add New
          </ButtonText>
        </div>
      </div>
    );
  };

  return windowWidth <= 1024 ? (
    <RNModal overlayClassName="overlay" className="modal-content" isOpen>
      {renderPaymentInformation()}
    </RNModal>
  ) : (
    renderPaymentInformation()
  );
};

export default PaymentInformation;
