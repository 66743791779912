import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { CognitoErrorCode } from '../../constants/enumTypes';
import AuthContext from '../../contexts/AuthContext';
import ModalContext from '../../contexts/ModalContext';
import { URL_HELPERS } from '../../utils/url';
import { signinValidation } from '../../validators';
import { Badge, ButtonSocMed, ButtonText, RequestItem, SignUpForm } from '..';
import { FormGroup, FormInput } from '../Forms';
import './styles.scss';

const SignInForm = ({
  event,
  history,
  returnUrl,
  statusMessage,
  statusType
}) => {
  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
    trigger
  } = useForm({ resolver: yupResolver(signinValidation) });

  const redirectHistory = useHistory();

  const { getAuthTokens } = useContext(AuthContext);
  const { changeModalTitle, closeModal } = useContext(ModalContext);

  const [content, setContent] = useState('SIGN_IN_FORM');
  const [status, setStatus] = useState(statusType || 'error');
  const [message, setMessage] = useState(statusMessage || '');

  const handleMessage = (error) => {
    //replace error.message for custom messages
    setStatus('error');
    switch (error.code) {
      case CognitoErrorCode.USER_NOT_FOUND_EXCEPTION:
        setMessage(error.message);
        break;
      case CognitoErrorCode.USER_NOT_CONFIRMED_EXCEPTION:
        setMessage(error.message);
        break;
      case CognitoErrorCode.NOT_AUTHORIZED_EXCEPTION:
        setMessage('Invalid email or password');
        break;
      case CognitoErrorCode.LIMIT_EXCEEDED_EXCEPTION:
        setMessage(
          "You've reached the maximum reset password request attempts. Please try after some time"
        );
        break;
      default:
        setMessage('There was an error to your request');
        break;
    }
  };

  const handleForgotPasswordClick = async (event) => {
    event.preventDefault();
    const { email } = getValues();

    try {
      if (await trigger('email')) {
        await Auth.forgotPassword(email);

        setStatus('success');
        setMessage('Forgot password steps sent.');
      }
    } catch (error) {
      if (error.code) {
        handleMessage(error);
      }
      console.error('Forgot Password:', error);
    }
  };

  const handleSignInClick = async (data) => {
    try {
      const { email, password } = data;
      const user = await Auth.signIn(email, password);
      await getAuthTokens();

      if (event?.target.innerText.includes('Request')) {
        changeModalTitle('Request Item');
        setContent('REQUEST_ITEM');
        return;
      } else {
        closeModal();
      }

      if (returnUrl && user) {
        history.push(returnUrl);
        return user;
      }

      if (redirectHistory.location.pathname === URL_HELPERS.index) {
        redirectHistory.push(URL_HELPERS.dashboard);
      }
    } catch (error) {
      if (error.code) {
        handleMessage(error);
      }
      console.error('Sign In:', error);
    }
  };

  const handleSignUpClick = () => {
    changeModalTitle('Sign Up');
    setContent('SIGN_UP_FORM');
  };

  const renderContent = () => {
    switch (content) {
      case 'SIGN_IN_FORM':
        return (
          <div className="sign-in-form" data-testid="signInContent">
            <FormGroup>
              <ButtonSocMed
                socialMedia="facebook"
                label="Sign In with Facebook"
              />
              <ButtonSocMed socialMedia="google" label="Sign In with Google" />
              <ButtonSocMed socialMedia="amazon" label="Sign In with Amazon" />
            </FormGroup>
            <p>or sign in with email</p>
            <form
              onSubmit={handleSubmit(handleSignInClick)}
              data-testid="signInForm"
            >
              <FormGroup>
                {message && (
                  <Badge
                    variant="contained"
                    type={status}
                    label={message}
                    className="message"
                    data-testid="message"
                  />
                )}
                <FormInput
                  label="Email Address"
                  type="text"
                  name="email"
                  control={control}
                  error={errors.email}
                  data-testid="email"
                />
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  control={control}
                  error={errors.password}
                  data-testid="password"
                />
                <ButtonText
                  variant="text"
                  type="button"
                  onClick={handleForgotPasswordClick}
                >
                  Forgot Password?
                </ButtonText>
                <ButtonText
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                  data-testid="signInBtn"
                >
                  Sign In
                </ButtonText>
                <ButtonText
                  color="primary"
                  variant="outlined"
                  type="button"
                  onClick={handleSignUpClick}
                  data-testid="signUpBtn"
                >
                  Sign Up
                </ButtonText>
              </FormGroup>
            </form>
          </div>
        );
      case 'SIGN_UP_FORM':
        return <SignUpForm />;
      case 'REQUEST_ITEM':
        return <RequestItem />;
    }
  };

  return renderContent();
};

SignInForm.propTypes = {
  event: PropTypes.object,
  history: PropTypes.object,
  returnUrl: PropTypes.string,
  statusMessage: PropTypes.string,
  statusType: PropTypes.oneOf(['error', 'success'])
};

SignInForm.defaultProps = {
  event: null,
  history: null,
  returnUrl: '',
  statusMessage: '',
  statusType: 'error'
};

export default SignInForm;
