import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

const useS3Read = (s3Files, args = {}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const getImageUrl = async () => {
      try {
        if (s3Files) {
          if (s3Files?.length) {
            let s3Data = [];
            for (let counter = 0; counter < s3Files.length; counter++) {
              const result = await Storage.get(s3Files[counter].key, {
                ...args
              });
              s3Data.push(result);
            }

            setData(s3Data);
          } else {
            if (s3Files.key) {
              const result = await Storage.get(s3Files.key, { ...args });
              setData(result);
            } else {
              setData([]);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('read image', error);
        setErrors(error);
      }
    };
    getImageUrl();
  }, []);

  return { data, loading, errors };
};

export default useS3Read;
