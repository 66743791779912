import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Badge = ({ alert, className, label, type, variant, ...props }) => {
  const badgeClassName = clsx('badge', `-${variant}`, `-${type}`, className);

  return (
    <div className={badgeClassName} {...props}>
      {alert && <span className="alert" data-testid="alert" />}
      <span className="label">{label}</span>
    </div>
  );
};

Badge.propTypes = {
  alert: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'success',
    'error',
    'renter',
    'owner',
    'requested',
    'accepted',
    'declined',
    'inrent',
    'completed',
    'cancelled',
    'document'
  ]),
  variant: PropTypes.oneOf(['contained', 'text'])
};

Badge.defaultProps = {
  alert: false,
  className: '',
  label: '',
  type: 'success',
  variant: 'contained'
};

export default Badge;
