import Header from './Header';
import Left from './Left';
import Center from './Center';
import Right from './Right';

Header.Left = Left;
Header.Center = Center;
Header.Right = Right;

export default Header;
