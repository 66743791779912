import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader, NavigationTabs } from '../../components';
import './styles.scss';

const Transactions = ({
  activeTransactions,
  handlePagination,
  handleTabNavigation,
  nextToken,
  renderTransactionDetails,
  renderTransactionList,
  transactionsData
}) => {
  return (
    <div className="transactions-page">
      <div className="container">
        <div className="sidebar-container">
          <div className="tabs-container">
            <NavigationTabs onTabClick={handleTabNavigation} />
            {activeTransactions.length}
          </div>
          <div className="subnav-container" id="subnav">
            <InfiniteScroll
              dataLength={transactionsData.length}
              next={handlePagination}
              scrollThreshold={0.9}
              hasMore={!!nextToken}
              loader={<Loader />}
              scrollableTarget="subnav"
            >
              {renderTransactionList()}
            </InfiniteScroll>
          </div>
        </div>
        {renderTransactionDetails()}
      </div>
    </div>
  );
};

Transactions.propTypes = {
  activeTransactions: PropTypes.array,
  handlePagination: PropTypes.func.isRequired,
  handleTabNavigation: PropTypes.func.isRequired,
  nextToken: PropTypes.string,
  renderTransactionDetails: PropTypes.func.isRequired,
  renderTransactionList: PropTypes.func.isRequired,
  transactionsData: PropTypes.array.isRequired
};

Transactions.defaultProps = {
  activeTransactions: [],
  nextToken: ''
};

export default Transactions;
