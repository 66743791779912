import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import { isEmpty } from '../../utils';
import { reviewFormValidation } from '../../validators';
import {
  ButtonText,
  Divider,
  Image,
  StarRating,
  ToastNotification,
  TransactionDates,
  UserCard
} from '..';
import { FormInputError, FormTextArea } from '../Forms';
import './styles.scss';

const ReviewForm = ({ postReview, transaction, user }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register
  } = useForm({
    resolver: yupResolver(reviewFormValidation)
  });
  const { addToast } = useToasts();

  const { state } = useContext(UserContext);
  const { closeModal } = useContext(ModalContext);

  const [loading, setLoading] = useState(false);

  const isItemReview = isEmpty(user);
  const isUserOwnerReview = !isEmpty(user) && transaction.owner.id === user.id;

  const onSubmit = async (values) => {
    setLoading(true);
    const toReview = !isEmpty(user)
      ? { userID: user.id }
      : { thingID: transaction.Thing.id };
    await postReview({
      variables: {
        input: {
          reviewerID: state.id,
          content: values.content,
          rating: values.rating,
          ...toReview
        }
      }
    });
    setLoading(false);
    addToast(
      <ToastNotification
        message={`Your ${
          isItemReview ? 'item' : 'user'
        } review was submitted successfully`}
        toastId="review"
        variant="success"
      />,
      { id: 'review' }
    );
    closeModal();
  };

  const renderItemSection = () => {
    return (
      <div className="item">
        <Image image={transaction?.Thing?.images?.[0]} />
        <span className="name">{transaction?.Thing?.name}</span>
      </div>
    );
  };

  return (
    <form className="review-form" onSubmit={handleSubmit(onSubmit)}>
      {isItemReview ? (
        <>
          {renderItemSection()}
          <Divider />
          <UserCard
            avatar={transaction.owner.avatar}
            name={transaction.owner.fullName}
            className="owner"
          >
            <div className="info">
              <span className="name">{transaction.owner.fullName}</span>
            </div>
          </UserCard>
          <Divider />
          <TransactionDates
            startDateTime={transaction?.startDateTime}
            endDateTime={transaction?.endDateTime}
            periodType={transaction?.Thing.periodType}
          />
        </>
      ) : (
        <>
          <UserCard avatar={user.avatar} size="70" name={user.fullName}>
            <div className="info">
              <span className="name">{user.fullName}</span>
            </div>
          </UserCard>
          <Divider />
          {renderItemSection()}
          <TransactionDates
            startDateTime={transaction?.startDateTime}
            endDateTime={transaction?.endDateTime}
            periodType={transaction?.Thing.periodType}
          />
          <Divider />
        </>
      )}
      <div className="overall-rating">
        <span>Overall Rating</span>
        <Controller
          name="rating"
          control={control}
          render={({ field }) => (
            <StarRating
              className="star-rate"
              readOnly={false}
              rating={field.value}
              {...field}
            />
          )}
        />
        {errors.rating && <FormInputError error={errors.rating} />}
      </div>
      <FormTextArea
        name="content"
        register={register}
        label={
          isItemReview
            ? 'What can you say about the item'
            : isUserOwnerReview
            ? 'What can you say about the owner'
            : 'What can you say about the renter'
        }
        placeholder={
          isItemReview
            ? 'What do you think of the quality and appearance?'
            : isUserOwnerReview
            ? 'Tell us what do you think about the owner.'
            : 'Tell us what do you think about the renter.'
        }
      />
      <ButtonText color="primary" loading={loading}>
        Submit
      </ButtonText>
      <ButtonText variant="outlined" onClick={closeModal}>
        Cancel
      </ButtonText>
    </form>
  );
};

ReviewForm.propTypes = {
  postReview: PropTypes.func,
  transaction: PropTypes.object,
  user: PropTypes.object
};

ReviewForm.defaultProps = {
  postReview: () => {},
  transaction: null,
  user: null
};

export default ReviewForm;
