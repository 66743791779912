import PropTypes from 'prop-types';
import React from 'react';
import { FormInput, Icon } from '../../../../components';
import './styles.scss';

const CategorySearch = ({ categories, icons, onChange, onClick }) => {
  return (
    <div className="category-search">
      <FormInput
        icon="search"
        noController
        onChange={onChange}
        placeholder="Search categories"
      />
      <div className="list">
        {categories.map((category) => (
          <div
            className="category"
            key={category.key}
            onClick={onClick(category)}
          >
            <Icon icon={icons[category.key]} />
            <span className="name">{category.name}</span>
            <Icon icon="expand_more" />
          </div>
        ))}
      </div>
    </div>
  );
};

CategorySearch.propTypes = {
  categories: PropTypes.array,
  icons: PropTypes.array,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

CategorySearch.defaultProps = {
  categories: [],
  icons: [],
  onChange: () => {},
  onClick: () => {}
};

export default CategorySearch;
