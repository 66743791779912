import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { FormInputError, FormLabel } from '..';
import './styles.scss';

const FormTextArea = ({
  className,
  error,
  label,
  name,
  register,
  required,
  ...props
}) => {
  const formTextareaClassName = clsx('form-textarea', className);
  const textareaClassName = clsx('textarea', error && 'error');

  return (
    <div className={formTextareaClassName}>
      {label && <FormLabel text={label} required={required} />}
      <textarea className={textareaClassName} {...register(name)} {...props} />
      {error && <FormInputError error={error} data-testid={`${name}Error`} />}
    </div>
  );
};

FormTextArea.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  required: PropTypes.bool
};

FormTextArea.defaultProps = {
  className: '',
  error: null,
  label: '',
  register: () => {},
  required: false
};

export default FormTextArea;
