import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import { sendMessage as sendMessageMutation } from '../../graphql/customMutations';
import { logTransaction as logTransactionMutation } from '../../graphql/mutations';
import { getPaymentMethods as getPaymentMethodsQuery } from '../../graphql/queries';
import { useMutation, useQuery } from '../../hooks';
import { PaymentStatus, SystemMessage, TransactionStatus } from '../../models';
import {
  getLocalTime,
  getMinRentDate,
  getPeriodConversion,
  getPublicId
} from '../../utils';
import { requestItemValidation } from '../../validators';
import {
  ButtonText,
  Divider,
  DocumentList,
  Dropdown,
  FormInputError,
  Loader,
  PricingInfo,
  ToastNotification,
  TransactionDates,
  TransactionItemCard
} from '..';
import './styles.scss';
import RentalDatePicker from './RentalDatePicker';

const RequestItem = ({ requestThing, thing }) => {
  const { state } = useContext(UserContext);
  const { closeModal } = useContext(ModalContext);

  const { loading: getPaymentMethodsLoading, data: getPaymentMethodsData } =
    useQuery(getPaymentMethodsQuery, {
      variables: {
        customerID: state.customerID
      }
    });

  const [logTransaction, { loading: logTransactionLoading }] = useMutation(
    logTransactionMutation
  );

  const [sendMessage, { loading: sendMessageLoading }] =
    useMutation(sendMessageMutation);

  const [startDate, setStartDate] = useState(
    getLocalTime(thing.timezone, new Date())
  );

  const [endDate, setEndDate] = useState(
    getLocalTime(
      thing.timezone,
      getMinRentDate(thing.periodType, thing.minRentPeriod)
    )
  );

  const [periods, setPeriods] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);

  const defaultValues = {
    datePicker: {
      from: new Date(),
      to: new Date()
    },
    rentalPrice: 0,
    deposit: thing.deposit,
    paymentMethod: {
      label: `**** **** **** ${getPaymentMethodsData?.getPaymentMethods?.items[0]?.last4}`,
      value: getPaymentMethodsData?.getPaymentMethods?.items[0]?.id
    }
  };

  const rentalPrice = +(periods * thing.pricePerPeriod).toFixed(2);

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [getPaymentMethodsData]),
    context: {
      minRentPeriod: thing.minRentPeriod,
      periodType: thing.periodType
    },
    resolver: yupResolver(requestItemValidation)
  });

  const { addToast } = useToasts();

  useEffect(() => {
    reset(defaultValues);
  }, [getPaymentMethodsData]);

  useEffect(() => {
    const periodConversion = getPeriodConversion(
      thing.periodType,
      startDate,
      endDate
    );

    setPeriods(periodConversion);
  }, [startDate, endDate]);

  const handleEditClick = () => {
    setShowCalendar((showCalendar) => !showCalendar);
  };

  const handleDateRange = (date, type) => {
    if (type === 'fromDate') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (thing.userID === state.id) {
        throw "You can't request your own item";
      }

      const { datePicker, paymentMethod, deposit } = data;
      const input = {
        ownerID: thing.userID,
        thingID: thing.id,
        renterID: state.id,
        startDateTime: datePicker.from,
        endDateTime: datePicker.to,
        publicID: getPublicId(),
        rentalPrice,
        paymentMethodID: paymentMethod.value,
        customerID: state.customerID,
        deposit
      };

      const userIDs = [thing.userID, state.id];

      const response = await requestThing(input);

      await logTransaction({
        variables: {
          input: {
            transactionID: response.requestThing.id,
            transactionStatus: TransactionStatus.REQUESTED,
            paymentStatus: PaymentStatus.PENDING
          },
          recipientEmail: response.requestThing.owner.email
        }
      });

      // :: Send system message to each user
      userIDs.forEach(async (userID) => {
        await sendMessage({
          variables: {
            input: {
              conversationID: response.requestThing.conversationID,
              conversationPublicID: response.requestThing.conversation.publicID,
              systemMessage: SystemMessage.ITEM_REQUEST,
              recipientID: userID
            }
          }
        });
      });
    } catch (error) {
      addToast(
        <ToastNotification
          message={error}
          toastId="confirm-request-error"
          variant="error"
        />,
        { id: 'confirm-request-error' }
      );

      console.error('error', error);
    } finally {
      closeModal();
    }
  };

  const onError = (errors) => {
    if (errors) {
      addToast(
        <ToastNotification
          message="Something went wrong please try again"
          toastId="request-item-error"
          variant="error"
        />,
        { id: 'request-item-error' }
      );
    }
  };

  return (
    <form
      className="request-item"
      onSubmit={handleSubmit(onSubmit, onError)}
      data-testid="requestItemForm"
    >
      <TransactionItemCard item={thing} />
      <Divider />
      <div className="rental-dates">
        <div className="heading">
          <span className="title">Request details</span>
          <span className="right" onClick={handleEditClick}>
            {showCalendar ? 'Save' : 'Edit'}
          </span>
        </div>
        <div className="date-container">
          {showCalendar ? (
            <RentalDatePicker
              onDateChange={handleDateRange}
              format="dateTime"
              name="datePicker"
              startDate={startDate}
              endDate={endDate}
              control={control}
              timezone={thing.timezone || 'Asia/Manila'}
              minRentPeriod={thing.minRentPeriod}
              periodType={thing.periodType}
            />
          ) : (
            <>
              <TransactionDates
                startDateTime={startDate}
                endDateTime={endDate}
                periodType={thing.periodType}
              />
              {errors?.general && <FormInputError error={errors.general} />}
            </>
          )}
        </div>
      </div>
      <Divider />
      <DocumentList documents={thing.requiredDocuments} />
      <PricingInfo
        deposit={thing.deposit}
        unitPrice={thing.pricePerPeriod}
        periods={periods}
        periodType={thing.periodType}
        rentalPrice={rentalPrice}
      />
      <Divider />
      {getPaymentMethodsLoading ? (
        <Loader />
      ) : (
        <Dropdown
          label="Pay with"
          placeholder="Select a payment method"
          options={
            getPaymentMethodsData &&
            getPaymentMethodsData.getPaymentMethods.items?.map(
              (paymentMethod) => {
                return {
                  label: `**** **** **** ${paymentMethod.last4}`,
                  value: paymentMethod.id
                };
              }
            )
          }
          name="paymentMethod"
          control={control}
          defaultValue={
            getPaymentMethodsData && {
              value: getPaymentMethodsData.getPaymentMethods.items.find(
                (paymentMethod) => paymentMethod.default
              ),
              label: getPaymentMethodsData.getPaymentMethods.items.find(
                (paymentMethod) => paymentMethod.default
              )
            }
          }
        />
      )}
      <Divider />
      {/* <AgreementLink link={RENTER_AGREEMENT_LINK} /> */}
      <div className="confirmation-text">
        <span className="title">Agreement Information</span>
        <p>
          By selecting the button below, you agree to the{' '}
          <span className="link">Renter’s Rules</span> and the{' '}
          <span className="link">Renter Refund Policy</span>.
        </p>
        <p>
          Your payment method will be charged when the rental is confirmed and
          ready to pick up.
        </p>
      </div>
      <ButtonText
        color="primary"
        loading={isSubmitting || logTransactionLoading || sendMessageLoading}
        data-testid="confirmBtn"
      >
        Confirm Request
      </ButtonText>
      <ButtonText variant="outlined" onClick={closeModal}>
        Cancel
      </ButtonText>
    </form>
  );
};

RequestItem.propTypes = {
  requestThing: PropTypes.func,
  thing: PropTypes.object
};

RequestItem.defaultProps = {
  requestThing: () => {},
  thing: null
};

export default RequestItem;
