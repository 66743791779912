import React, { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Map, RequestItem, ToastNotification } from '../../components';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import { requestThing as requestThingMutation } from '../../graphql/customMutations';
import { getUserThing as getUserThingQuery } from '../../graphql/customQueries';
import { useMutation, useQuery } from '../../hooks';

const useItemDetails = (itemId) => {
  const { addToast } = useToasts();

  const { loading: getUserThingLoading, data: getUserThingData } = useQuery(
    getUserThingQuery,
    {
      variables: {
        publicID: itemId
      }
    }
  );

  const [requestThing] = useMutation(requestThingMutation);

  const { state } = useContext(UserContext);
  const { paymentServiceModal, showModal } = useContext(ModalContext);

  const handleRequestClick = () => {
    if (getUserThingData.getUserThing.items[0].listed) {
      paymentServiceModal({
        title: 'Confirm Request',
        hasPaymentMethods: state.hasPaymentMethods,
        content: () => (
          <RequestItem
            thing={getUserThingData.getUserThing.items[0]}
            requestThing={async (input) => {
              const response = await requestThing({
                variables: {
                  input
                }
              });
              return response;
            }}
          />
        )
      });
    } else {
      addToast(
        <ToastNotification
          message="This item is not available at this time"
          toastId="request-error"
          variant="error"
        />,
        { id: 'request-error' }
      );
    }
  };

  const handleAddressClick = () => {
    showModal({
      title: 'Location',
      content: () =>
        Map({
          lat: getUserThingData.getUserThing.items[0].location.lat || 0,
          lon: getUserThingData.getUserThing.items[0].location.lon || 0,
          marker: 'circle'
        })
    });
  };

  return {
    isUserItemLoading: getUserThingLoading,
    userItemData: getUserThingData?.getUserThing.items[0] || null,
    onAddressClick: handleAddressClick,
    onRequestClick: handleRequestClick
  };
};

export default useItemDetails;
