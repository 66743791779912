import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { us_states } from '../../assets/json';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import {
  createSetupIntent as createSetupIntentMutation,
  setDefaultPaymentMethod as setDefaultPaymentMethodMutation
} from '../../graphql/mutations';
import { useMutation } from '../../hooks';
import { createStripeError, isStripeError } from '../../utils';
import { addPaymentMethodValidation } from '../../validators';
import {
  ButtonText,
  Dropdown,
  FormInput,
  FormInputError,
  FormLabel,
  FormTitle
} from '..';
import './styles.scss';

const AddPaymentMethodForm = ({ callback, modalContent }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(addPaymentMethodValidation)
  });

  const [createSetupIntent, { loading: createSetupIntentLoading }] =
    useMutation(createSetupIntentMutation);

  const [setDefaultPaymentMethod] = useMutation(
    setDefaultPaymentMethodMutation
  );

  const { state, setUserData } = useContext(UserContext);
  const { updateModal, closeModal } = useContext(ModalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const style = {
    style: {
      base: {
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: '400',
        color: '#101828',

        '::placeholder': {
          color: '#667085'
        }
      }
    }
  };

  const handleChange = (event) => {
    setError(event.error ? createStripeError(event.error) : null);
  };

  const onSubmit = async (data) => {
    if (elements == null) return;
    if (!state.customerID) return;

    setLoading(true);
    setError(null);

    try {
      const response = await createSetupIntent({
        variables: {
          input: {
            customerID: state.customerID,
            userID: state.id,
            fullName: data.fullName
          }
        }
      });

      const { setupIntent, error } = await stripe.confirmCardSetup(
        response.createSetupIntent.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: data.fullName,
              address: {
                country: 'US',
                state: data.state.value,
                city: data.city,
                line1: data.address
              }
            }
          }
        }
      );

      if (error) throw error;

      if (response.status === 'succeeded' || !state.hasPaymentMethods) {
        await setDefaultPaymentMethod({
          variables: {
            input: {
              paymentMethodID: setupIntent.payment_method,
              customerID: state.customerID
            }
          }
        });

        setUserData({ hasPaymentMethods: true });
      }

      if (modalContent) {
        return updateModal({
          title: modalContent.title,
          content: () => modalContent.content()
        });
      } else {
        callback();
        return closeModal();
      }
    } catch (error) {
      setError(
        isStripeError(error) ? createStripeError(error) : 'There was an error.'
      );

      console.error('add payment method', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="add-payment-method-form" onSubmit={handleSubmit(onSubmit)}>
      <FormTitle title="Personal Information" />
      <FormInput
        label="Full Name"
        control={control}
        name="fullName"
        error={errors.fullName}
        required
      />
      <Dropdown
        label="State"
        options={[...us_states]}
        name="state"
        control={control}
        error={errors.state}
        required
      />
      <FormInput
        label="City"
        control={control}
        name="city"
        error={errors.city}
        required
      />
      <FormInput
        label="Address"
        control={control}
        name="address"
        error={errors.address}
        required
      />
      <FormTitle title="Card Details" />
      <div className="input-container">
        <FormLabel text="Card Number" required />
        <CardElement
          options={style}
          className="input"
          onChange={handleChange}
        />
        {error && <FormInputError error={error} />}
      </div>
      <ButtonText
        color="primary"
        loading={!stripe || !elements || loading || createSetupIntentLoading}
        disabled={error?.type === 'validation_error'}
      >
        Add
      </ButtonText>
      <ButtonText variant="outlined" type="button" onClick={closeModal}>
        Cancel
      </ButtonText>
    </form>
  );
};

AddPaymentMethodForm.propTypes = {
  callback: PropTypes.func,
  modalContent: PropTypes.object
};

AddPaymentMethodForm.defaultProps = {
  callback: () => {},
  modalContent: null
};

export default AddPaymentMethodForm;
