import React, { useContext, useEffect, useState } from 'react';
import RNModal from 'react-modal';
import { useHistory, useParams } from 'react-router-dom';
import { EmptyListPlaceholder, Loader, RequestItem } from '../../components';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import {
  requestThing as requestThingMutation,
  sendMessage as sendMessageMutation
} from '../../graphql/customMutations';
import { getUserTransaction as getUserTransactionQuery } from '../../graphql/customQueries';
import {
  generateExchangeCode as generateExchangeCodeMutation,
  updateTransactionStatus as updateTransactionStatusMutation
} from '../../graphql/mutations';
import { useLazyQuery, useMutation, useWindowDimensions } from '../../hooks';
import { TransactionDetails } from './components';

const useTransaction = () => {
  const { convoId, transactionId } = useParams();
  const history = useHistory();

  const { state } = useContext(UserContext);
  const { paymentServiceModal } = useContext(ModalContext);

  const [
    getUserTransaction,
    { loading: isUserTransactionLoading, data: getUserTransactionData }
  ] = useLazyQuery(getUserTransactionQuery);

  const [requestThing, { data: requestThingData }] =
    useMutation(requestThingMutation);
  const [generateExchangeCode, { loading: generateExchangeCodeLoading }] =
    useMutation(generateExchangeCodeMutation);
  const [
    updateTransactionStatus,
    {
      loading: updateTransactionStatusLoading,
      data: updateTransactionStatusData
    }
  ] = useMutation(updateTransactionStatusMutation);
  const [sendMessage] = useMutation(sendMessageMutation);

  const { windowWidth } = useWindowDimensions();

  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    if (transactionId) {
      const getTransaction = async () => {
        await getUserTransaction({
          variables: {
            publicID: transactionId,
            userID: state.id
          }
        });
      };

      getTransaction();
    } else {
      setTransaction(null);
    }
  }, [transactionId]);

  useEffect(() => {
    if (getUserTransactionData) {
      setTransaction(getUserTransactionData.getUserTransaction.items[0]);
    }
  }, [getUserTransactionData]);

  useEffect(() => {
    if (requestThingData?.requestThing) {
      history.push(
        `/messages/${requestThingData.requestThing.publicID}/transaction/${requestThingData.requestThing.publicID}`
      );
    }
  }, [requestThingData]);

  useEffect(() => {
    if (updateTransactionStatusData) {
      setTransaction(updateTransactionStatusData.updateTransactionStatus);
    }
  }, [updateTransactionStatusData]);

  const requestAgain = () => {
    if (transaction.Thing.listed) {
      paymentServiceModal({
        hasPaymentMethods: state.hasPaymentMethods,
        title: 'Request item',
        content: () => (
          <RequestItem
            thing={transaction.Thing}
            requestThing={async (input) => {
              const response = await requestThing({
                variables: {
                  input
                }
              });
              return response;
            }}
          />
        )
      });
    }
  };

  const handleUpdateStatus = async (transactionStatus) => {
    try {
      await updateTransactionStatus({
        variables: {
          input: {
            id: transaction.id,
            _version: transaction._version,
            hasRead: false,
            transactionStatus
          }
        }
      });
    } catch (error) {
      console.error('updateTransactionStatus', error);
      throw error;
    }
  };

  const sendSystemMessage = async (systemMessage, recipientID) => {
    if (recipientID) {
      // :: Send system message to each user
      await sendMessage({
        variables: {
          input: {
            conversationID: transaction.conversation.id,
            conversationPublicID: transaction.conversation.publicID,
            // fix systemmessage
            systemMessage,
            recipientID
          }
        }
      });
    } else {
      const userIDs = [transaction.owner.id, transaction.renter.id];
      // :: Send system message to each user
      userIDs.forEach(async (userID) => {
        await sendMessage({
          variables: {
            input: {
              conversationID: transaction.conversation.id,
              conversationPublicID: transaction.conversation.publicID,
              // fix systemmessage
              systemMessage,
              recipientID: userID
            }
          }
        });
      });
    }
  };

  const renderTransaction = () => {
    if (!transaction) {
      return windowWidth <= 768 ? null : (
        <div className="transaction-details-column-container">
          <EmptyListPlaceholder type="transaction" />
        </div>
      );
    }

    const closeModal = () => {
      history.push(`/messages/${convoId}`);
      setTransaction(null);
    };

    const renderTransactionDetails = () => {
      return isUserTransactionLoading ? (
        <Loader />
      ) : (
        <TransactionDetails
          closeModal={closeModal}
          generateExchangeCode={generateExchangeCode}
          handleUpdateStatus={handleUpdateStatus}
          loading={
            updateTransactionStatusLoading || generateExchangeCodeLoading
          }
          requestAgain={requestAgain}
          sendSystemMessage={sendSystemMessage}
          transaction={transaction}
        />
      );
    };

    return windowWidth <= 1024 ? (
      <RNModal overlayClassName="overlay" className="modal-content" isOpen>
        {renderTransactionDetails()}
      </RNModal>
    ) : (
      renderTransactionDetails()
    );
  };

  return {
    isUserTransactionLoading,
    userTransactionData: transaction,
    renderTransaction
  };
};

export default useTransaction;
