import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { getShortAddress } from '../../utils';
import './styles.scss';

const Address = ({ address, className }) => {
  const classNames = clsx('address', className);

  return <address className={classNames}>{getShortAddress(address)}</address>;
};

Address.propTypes = {
  address: PropTypes.string,
  className: PropTypes.string
};

Address.defaultProps = {
  address: '',
  className: ''
};

export default Address;
