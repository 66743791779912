import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const ModalImageCarousel = ({ data, loading, onChange, selectedItem }) => {
  return (
    <div className="modal-image-carousel">
      <Carousel
        autoPlay={false}
        infiniteLoop
        interval={60000}
        onChange={onChange}
        selectedItem={selectedItem}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
      >
        {!loading &&
          data &&
          data.map((image, index) => (
            <img src={image} className="photo" key={index} />
          ))}
      </Carousel>
    </div>
  );
};

ModalImageCarousel.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  selectedItem: PropTypes.number
};

ModalImageCarousel.defaultProps = {
  data: null,
  loading: true,
  onChange: () => {},
  selectedItem: 0
};

export default ModalImageCarousel;
