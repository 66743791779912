const isStripeError = (error) => {
  const errorKeys = Object.keys(error);
  console.log(errorKeys);

  const stripeErrorKeys = errorKeys.filter(
    (errorKey) => errorKey === 'type' || errorKey === 'code'
  );

  return stripeErrorKeys.length === 2;
};

export default isStripeError;
