import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import KeywordTag from './KeywordTag';

const KeywordTagList = ({ field, keywords, onRemove, readOnly }) => {
  const handleRemove = (index, field) => {
    return () => {
      onRemove(index, field);
    };
  };

  return keywords.length ? (
    <div className="keyword-tag-list">
      {keywords.map((label, index) => (
        <KeywordTag
          label={label}
          readOnly={readOnly}
          onRemove={handleRemove(index, field)}
          index={index}
          key={label}
        />
      ))}
    </div>
  ) : null;
};

KeywordTagList.propTypes = {
  field: PropTypes.object,
  keywords: PropTypes.array,
  onRemove: PropTypes.func,
  readOnly: PropTypes.bool
};

KeywordTagList.defaultProps = {
  field: {},
  keywords: [],
  onRemove: () => {},
  readOnly: false
};

export default KeywordTagList;
