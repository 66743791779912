export const getPaymentMethods = /* GraphQL */ `
  query GetPaymentMethods($customerID: ID!) {
    getPaymentMethods(customerID: $customerID) {
      items {
        id
        brand
        last4
        billing_details {
          email
          name
          phone
          address {
            line1
            city
            country
            line2
            postal_code
            state
          }
        }
        exp_month
        exp_year
        default
      }
    }
  }
`;
export const findThings = /* GraphQL */ `
  query FindThings(
    $filter: SearchableThingFilterInput
    $limit: Int
    $sort: [SearchableThingSortInput]
    $nextToken: String
    $from: Int
  ) {
    findThings(
      filter: $filter
      limit: $limit
      sort: $sort
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        rating
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        images {
          bucket
          key
          region
        }
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        User {
          id
          firstName
          lastName
          email
          bio
          sub
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const findMaximumPrice = /* GraphQL */ `
  query FindThings(
    $filter: SearchableThingFilterInput
    $limit: Int
    $sort: [SearchableThingSortInput]
    $nextToken: String
    $from: Int
  ) {
    findThings(
      filter: $filter
      limit: $limit
      sort: $sort
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        rating
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        images {
          bucket
          key
          region
        }
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        User {
          id
          firstName
          lastName
          email
          bio
          sub
          address
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        renterID
        ownerID
        Thing {
          id
          name
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          email
          bio
          sub
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          email
          bio
          sub
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceived {
            id
            senderID
            recipientID
            sender {
              id
              firstName
              lastName
              email
              bio
              sub
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            recipient {
              id
              firstName
              lastName
              email
              bio
              sub
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            body {
              text
            }
            dateSent
            conversationPublicID
            conversation {
              id
              publicID
              transactionID
              lastMessageReceivedID
              hasRead
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            conversationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          hasRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getUserConversation = /* GraphQL */ `
  query GetUserConversation($publicID: ID!, $userID: ID!) {
    getUserConversation(publicID: $publicID, userID: $userID) {
      items {
        id
        publicID
        transactionID
        lastMessageReceivedID
        lastMessageReceivedAt
        lastMessageReceived {
          id
          senderID
          recipientID
          sender {
            id
            fullName
            firstName
            lastName
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          recipient {
            id
            firstName
            fullName
            lastName
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          body {
            text
          }
          dateSent
          conversationPublicID
          conversation {
            id
            publicID
            transactionID
            lastMessageReceivedID
            lastMessageReceivedAt
            hasRead
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        hasRead
        transaction {
          id
          exchangeCode
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          Thing {
            id
            name
            periodType
            pricePerPeriod
            minRentPeriod
            userID
            listed
            tags
            description
            deposit
            itemValue
            images {
              bucket
              key
              region
            }
            maxRentPeriod
            requiredDocuments
            archived
            archivedAt
            publicID
            daysAvailable
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          renter {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            fullName
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          owner {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            fullName
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          transactionStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getUserConversations = /* GraphQL */ `
  query GetUserConversations(
    $userID: ID!
    $renterNextToken: String
    $ownerNextToken: String
  ) {
    getUserConversations(
      userID: $userID
      renterNextToken: $renterNextToken
      ownerNextToken: $ownerNextToken
    ) {
      items {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          renter {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          owner {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          hasRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          Thing {
            id
            name
            periodType
            pricePerPeriod
            minRentPeriod
            userID
            listed
            tags
            description
            deposit
            itemValue
            maxRentPeriod
            requiredDocuments
            archived
            archivedAt
            publicID
            daysAvailable
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renterNextToken
      ownerNextToken
      startedAt
    }
  }
`;

export const getUserData = /* GraphQL */ `
  query GetUserData($sub: String, $publicID: String, $email: String) {
    getUserData(sub: $sub, publicID: $publicID, email: $email) {
      items {
        id
        firstName
        lastName
        fullName
        stripeAccountID
        customerID
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          items {
            id
            publicID
            transactionID
            lastMessageReceivedID
            lastMessageReceived {
              id
              senderID
              recipientID
              sender {
                id
                firstName
                lastName
                email
                bio
                sub
                address
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              recipient {
                id
                firstName
                lastName
                email
                bio
                sub
                address
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              body {
                text
              }
              dateSent
              conversationPublicID
              conversation {
                id
                publicID
                transactionID
                lastMessageReceivedID
                hasRead
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              conversationID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            hasRead
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startedAt
        }
        ownerConversations {
          nextToken
          items {
            id
            publicID
            transactionID
            lastMessageReceivedID
            lastMessageReceived {
              id
              senderID
              recipientID
              sender {
                id
                firstName
                lastName
                email
                bio
                sub
                address
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              recipient {
                id
                firstName
                lastName
                email
                bio
                sub
                address
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              body {
                text
              }
              dateSent
              conversationPublicID
              conversation {
                id
                publicID
                transactionID
                lastMessageReceivedID
                hasRead
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              conversationID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            hasRead
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const checkForPaymentMethods = /* GraphQL */ `
  query GetPaymentMethods($customerID: ID!) {
    getPaymentMethods(customerID: $customerID) {
      items {
        id
      }
    }
  }
`;
export const getCustomerID = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      customerID
    }
  }
`;

export const getUserMessages = /* GraphQL */ `
  query GetUserMessages(
    $conversationPublicID: ID!
    $dateSent: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserMessages(
      conversationPublicID: $conversationPublicID
      dateSent: $dateSent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getUserTransaction = /* GraphQL */ `
  query GetUserTransaction($publicID: ID!, $userID: ID!) {
    getUserTransaction(publicID: $publicID, userID: $userID) {
      items {
        id
        renterID
        ownerID
        Thing {
          id
          name
          images {
            bucket
            key
            region
          }
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          avatar {
            bucket
            key
            region
          }
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          avatar {
            bucket
            key
            region
          }
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getUserTransactions = /* GraphQL */ `
  query GetUserTransactions(
    $userID: ID!
    $limit: Int
    $nextToken: String
    $type: TransactionType
  ) {
    getUserTransactions(
      userID: $userID
      limit: $limit
      nextToken: $nextToken
      type: $type
    ) {
      items {
        id
        renterID
        ownerID
        paymentStatus
        Thing {
          id
          name
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        transactionStatus
        hasRead
        rentalPrice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      renterID
      ownerID
      exchangeCode
      paymentStatus
      paymentIntentID
      deposit
      Thing {
        id
        name
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          hasRead
          rentalPrice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      transactionStatus
      hasRead
      rentalPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getUserProfile = /* GraphQL */ `
  query GetUserProfile(
    $publicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $thingNextToken: String
  ) {
    getUserProfile(
      publicID: $publicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        fullName
        stripeAccountID
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things(nextToken: $thingNextToken) {
          items {
            id
            name
            timezone
            periodType
            pricePerPeriod
            minRentPeriod
            userID
            listed
            tags
            description
            deposit
            itemValue
            maxRentPeriod
            requiredDocuments
            archived
            archivedAt
            publicID
            daysAvailable
            address
            numberOfRatings
            rating
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        customerID
        rating
        numberOfRatings
        reviews {
          items {
            reviewerID
            reviewer {
              firstName
              lastName
              fullName
              avatar {
                bucket
                key
                region
              }
            }
            content
            rating
            createdAt
            thingID
            userID
            id
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserThing = /* GraphQL */ `
  query GetUserThing(
    $publicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelThingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserThing(
      publicID: $publicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        periodType
        pricePerPeriod
        timezone
        minRentPeriod
        userID
        listed
        tags
        description
        numberOfRatings
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        reviews {
          items {
            reviewerID
            reviewer {
              id
              avatar {
                bucket
                key
                region
              }
              firstName
              lastName
              fullName
              email
              bio
              sub
              address
              publicID
              customerID
              rating
              numberOfRatings
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            content
            rating
            createdAt
            thingID
            userID
            id
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          avatar {
            bucket
            key
            region
          }
        }
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getOwnerConversations = /* GraphQL */ `
  query GetOwnerConversations(
    $ownerID: ID!
    $lastMessageReceivedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOwnerConversations(
      ownerID: $ownerID
      lastMessageReceivedAt: $lastMessageReceivedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicID
        transactionID
        lastMessageReceivedID
        lastMessageReceivedAt
        lastMessageReceived {
          id
          senderID
          recipientID
          sender {
            id
            fullName
            firstName
            lastName
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          recipient {
            id
            firstName
            fullName
            lastName
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          body {
            text
          }
          dateSent
          conversationPublicID
          conversation {
            id
            publicID
            transactionID
            lastMessageReceivedID
            lastMessageReceivedAt
            hasRead
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        hasRead
        transaction {
          id
          exchangeCode
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          Thing {
            id
            name
            periodType
            pricePerPeriod
            minRentPeriod
            userID
            listed
            tags
            description
            deposit
            itemValue
            images {
              bucket
              key
              region
            }
            maxRentPeriod
            requiredDocuments
            archived
            archivedAt
            publicID
            daysAvailable
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          renter {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            fullName
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          owner {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            fullName
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          transactionStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRenterConversations = /* GraphQL */ `
  query GetRenterConversations(
    $renterID: ID!
    $lastMessageReceivedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRenterConversations(
      renterID: $renterID
      lastMessageReceivedAt: $lastMessageReceivedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicID
        transactionID
        lastMessageReceivedID
        lastMessageReceivedAt
        lastMessageReceived {
          id
          senderID
          recipientID
          sender {
            id
            fullName
            firstName
            lastName
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          recipient {
            id
            firstName
            fullName
            lastName
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          body {
            text
          }
          dateSent
          conversationPublicID
          conversation {
            id
            publicID
            transactionID
            lastMessageReceivedID
            lastMessageReceivedAt
            hasRead
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        hasRead
        transaction {
          id
          exchangeCode
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          Thing {
            id
            name
            periodType
            pricePerPeriod
            minRentPeriod
            userID
            listed
            tags
            description
            deposit
            itemValue
            images {
              bucket
              key
              region
            }
            maxRentPeriod
            requiredDocuments
            archived
            archivedAt
            publicID
            daysAvailable
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          renter {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            fullName
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          owner {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            fullName
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          transactionStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
