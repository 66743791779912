import * as yup from 'yup';

const signupValidation = yup.object().shape({
  firstName: yup.string().required('First name is empty'),
  lastName: yup.string().required('Last name is empty'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is empty'),
  password: yup
    .string()
    .matches(/(?=.{8})/, 'Password must be at least 8 characters long')
    .matches(
      /(?=^.{0,255}$)/,
      'Password must be no more than 255 characters long'
    )
    .matches(/(?=.*[a-z])/, 'Password must have at least 1 lower-cased letter')
    .matches(/(?=.*[A-Z])/, 'Password must have at least 1 capital letter')
    .matches(/(?=.*[0-9])/, 'Password must have at least 1 number')
    .required('Password is empty')
});

export default signupValidation;
