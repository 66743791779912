import clsx from 'clsx';
import React, { createContext, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import heartHandIcon from '../../assets/icons/heart-hand.svg';
import listIcon from '../../assets/icons/listIcon.svg';
import filterIcon from '../../assets/icons/filterIcon.svg';
import { DropdownMenu, Header, Icon, Loader } from '../../components';
import useMessages from './useMessages';
import useTransaction from './useTransaction';
import './styles.scss';
import { ConversationCard } from './components';

export const MessagesContext = createContext();

const Messages = () => {
  const {
    tab,
    isUserConversationsLoading,
    userConversationsData,
    hasMoreConversations,
    handleTabNavigation,
    handlePagination,
    renderConversation
  } = useMessages();

  const { renderTransaction } = useTransaction();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuOptions = [
    { label: 'Most Recent', onClick: () => {} },
    { label: 'Unread', onClick: () => {} },
    { label: 'Status', onClick: () => {} }
  ];

  const tabOptions = ['ALL', 'SUPPLYING', 'RENTING'];

  const tabText = {
    ALL: 'All',
    SUPPLYING: 'Lending',
    RENTING: 'Renting'
  };

  const classNames = clsx(
    'icon',
    tab === 'SUPPLYING' && '-lending',
    tab === 'RENTING' && '-renting'
  );

  const handleLeftIconClick = () => {
    const currentIndex = tabOptions.indexOf(tab);
    const nextIndex = (currentIndex + 1) % tabOptions.length;
    handleTabNavigation(tabOptions[nextIndex]);
  };

  const handleRightIconClick = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  const handleOutsideClick = () => setIsMenuOpen(false);

  return (
    <MessagesContext.Provider value={{ tab }}>
      <div className="messages-page">
        <div className="message-list">
          <Header>
            <Header.Left>
              <img
                src={tab === 'ALL' ? listIcon : heartHandIcon}
                className={classNames}
                onClick={handleLeftIconClick}
              />
              <span className="label">{tabText[tab]}</span>
            </Header.Left>
            <Header.Center>Inbox</Header.Center>
            <Header.Right>
              <DropdownMenu onOutsideClick={handleOutsideClick}>
                <DropdownMenu.Toggle>
                  <img
                    src={filterIcon}
                    className={classNames}
                    onClick={handleRightIconClick}
                  />
                </DropdownMenu.Toggle>
                {isMenuOpen && (
                  <DropdownMenu.Content>
                    <div className="title">
                      <span className="label">Sort by:</span>
                      <Icon icon="expand_less" />
                    </div>
                    {menuOptions.map(({ label, onClick }) => (
                      <div className="option" onClick={onClick} key={label}>
                        <span className="label">{label}</span>
                        <span className="count">(0)</span>
                      </div>
                    ))}
                  </DropdownMenu.Content>
                )}
              </DropdownMenu>
            </Header.Right>
          </Header>
          <div className="body">
            <InfiniteScroll
              dataLength={userConversationsData.length}
              next={handlePagination}
              hasMore={hasMoreConversations}
              loader={<Loader />}
              endMessage={
                !isUserConversationsLoading && (
                  <span className="end-message">All conversations loaded.</span>
                )
              }
              className="message-list"
            >
              {isUserConversationsLoading && !userConversationsData.length ? (
                <Loader />
              ) : (
                userConversationsData.map((conversation) => (
                  <ConversationCard
                    conversation={conversation}
                    key={conversation.id}
                  />
                ))
              )}
            </InfiniteScroll>
          </div>
        </div>
        {renderConversation()}
        {renderTransaction()}
      </div>
    </MessagesContext.Provider>
  );
};

export default Messages;
