import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '../../../../components';
import './styles.scss';

const AddItemButton = ({ onClick }) => {
  return (
    <div className="new-item-button" data-testid="newButton" onClick={onClick}>
      <Icon icon="library_add" />
      <p className="label">Add item</p>
    </div>
  );
};

AddItemButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AddItemButton;
