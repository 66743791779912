import PropTypes from 'prop-types';
import React from 'react';
import amazonLogo from '../../../../../../assets/logos/amazon.svg';
import facebookLogo from '../../../../../../assets/logos/facebook-reverse.svg';
import googleLogo from '../../../../../../assets/logos/google.svg';
import { Avatar, ButtonText } from '../../../../../../components';
import './styles.scss';

const AccountCard = ({ avatar, hasLogo, name, onClick, type }) => {
  const LOGOS = {
    amazon: amazonLogo,
    facebook: facebookLogo,
    google: googleLogo
  };

  return (
    <div className="account-card">
      <Avatar avatar={avatar} size="46" name={name}>
        {hasLogo && (
          <div className="logo-container">
            <img src={LOGOS[type.toLocaleLowerCase()]} className="logo" />
          </div>
        )}
      </Avatar>
      <span className="name">{name}</span>
      <span className="type">{`${type} Account`}</span>
      <ButtonText variant="outlined" className="sign-out" onClick={onClick}>
        Sign Out
      </ButtonText>
    </div>
  );
};

AccountCard.propTypes = {
  avatar: PropTypes.object,
  hasLogo: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string
};

AccountCard.defaultProps = {
  avatar: null,
  hasLogo: false,
  name: '',
  onClick: () => {},
  type: ''
};

export default AccountCard;
