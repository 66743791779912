import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import Tab from './Tab';

const NavigationTabs = ({
  activeTab,
  leftTabLabel,
  onClick,
  rightTabLabel
}) => {
  const handleClick = (tab) => onClick(tab);

  return (
    <div className="navigation-tabs">
      <Tab
        label={leftTabLabel}
        isActive={activeTab === leftTabLabel.toUpperCase()}
        onClick={handleClick}
      />
      <Tab
        label={rightTabLabel}
        isActive={activeTab === rightTabLabel.toUpperCase()}
        onClick={handleClick}
      />
    </div>
  );
};

NavigationTabs.propTypes = {
  activeTab: PropTypes.string,
  leftTabLabel: PropTypes.string,
  onClick: PropTypes.func,
  rightTabLabel: PropTypes.string
};

NavigationTabs.defaultProps = {
  activeTab: 'ITEMS',
  leftTabLabel: 'Items',
  onClick: () => {},
  rightTabLabel: 'Reviews'
};

export default NavigationTabs;
