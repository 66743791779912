import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RDatepicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { FormLabel } from '../Forms';
import './styles.scss';

const DatePicker = ({
  control,
  disabled,
  endDate,
  format,
  handleChange,
  label,
  name,
  noController,
  readOnly,
  startDate
}) => {
  const [fromDate, setFromDate] = useState(
    startDate ? new Date(startDate) : new Date()
  );
  const [toDate, setToDate] = useState(
    endDate ? new Date(endDate) : new Date()
  );
  const [showStartTime, setShowStartTime] = useState(false);
  const [showEndTime, setShowEndTime] = useState(false);

  const handleDate = (date, type, onChange) => {
    if (type === 'fromDate') {
      setFromDate(date);
      handleChange(date, 'fromDate');

      if (!noController) {
        setShowStartTime(true);
        onChange({ from: date, to: toDate });
      }
    } else {
      setToDate(date);
      handleChange(date, 'endDate');

      if (!noController) {
        setShowEndTime(true);
        onChange({ from: fromDate, to: date });
      }
    }
  };

  let fromDateFormat;
  let toDateFormat;
  if (format === 'dateTime') {
    if (readOnly) {
      fromDateFormat = 'MMMM d, yyyy h:mm aa';
    } else if (showStartTime) {
      fromDateFormat = 'MMMM d, yyyy h:mm aa';
    } else {
      fromDateFormat = 'MMMM d, yyyy';
    }
  } else {
    fromDateFormat = 'MMMM d, yyyy';
  }

  if (format === 'dateTime') {
    if (readOnly) {
      toDateFormat = 'MMMM d, yyyy h:mm aa';
    } else if (showEndTime) {
      toDateFormat = 'MMMM d, yyyy h:mm aa';
    } else {
      toDateFormat = 'MMMM d, yyyy';
    }
  } else {
    toDateFormat = 'MMMM d, yyyy';
  }

  const dateOptions = {
    fromDate: fromDate,
    toDate: toDate,
    showTimeSelect: format === 'dateTime' ? true : false,
    onChangeRaw: (event) => event.preventDefault(),
    disabled: disabled
  };

  return (
    <div className="date-picker">
      {label && <FormLabel text={label} />}
      <div className="date-inputs">
        <div className="date-input">
          {noController ? (
            <RDatepicker
              wrapperClassName="date-wrapper"
              className="date"
              selectsStart
              selected={fromDate}
              onChange={(date) => handleDate(date, 'fromDate')}
              dateFormat={fromDateFormat}
              {...dateOptions}
            />
          ) : (
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <RDatepicker
                  wrapperClassName="date-wrapper"
                  onChangeRaw={(event) => event.preventDefault()}
                  className="date"
                  selected={fromDate}
                  onChange={(date) => handleDate(date, 'fromDate', onChange)}
                  dateFormat={fromDateFormat}
                  selectsStart
                  {...dateOptions}
                />
              )}
            />
          )}
        </div>
        <span>-</span>
        <div className="date-input">
          {noController ? (
            <RDatepicker
              className="date"
              selected={toDate}
              onChange={(date) => handleDate(date, 'toDate')}
              selectsEnd
              minDate={fromDate}
              dateFormat={toDateFormat}
              {...dateOptions}
            />
          ) : (
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <RDatepicker
                  className="date"
                  selected={toDate}
                  onChange={(date) => handleDate(date, 'toDate', onChange)}
                  selectsEnd
                  minDate={fromDate}
                  dateFormat={toDateFormat}
                  {...dateOptions}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  endDate: PropTypes.string,
  format: PropTypes.oneOf(['date', 'dateTime']),
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  noController: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  startDate: PropTypes.string
};

DatePicker.defaultProps = {
  control: {},
  disabled: false,
  endDate: '',
  format: 'date',
  handleChange: () => {},
  label: '',
  name: '',
  readOnly: false,
  startDate: ''
};

export default DatePicker;
