import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const FormTitle = ({ title }) => {
  return <p className="form-title">{title}</p>;
};

FormTitle.propTypes = {
  title: PropTypes.string
};

FormTitle.defaultProps = {
  title: ''
};

export default FormTitle;
