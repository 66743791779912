import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DropdownMenu from '../../DropdownMenu';
import Icon from '../../Icon';
import './styles.scss';

const FormLabel = ({ children, required, text, ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const handleOutsideClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="form-label">
      <label className="label" {...props}>
        {text}
      </label>
      {required && <Icon icon="emergency" />}
      {children && (
        <DropdownMenu onOutsideClick={handleOutsideClick}>
          <DropdownMenu.Toggle>
            <Icon icon="info" onClick={handleClick} />
          </DropdownMenu.Toggle>
          {isMenuOpen && (
            <DropdownMenu.Content>{children}</DropdownMenu.Content>
          )}
        </DropdownMenu>
      )}
    </div>
  );
};

FormLabel.propTypes = {
  children: PropTypes.node,
  required: PropTypes.bool,
  text: PropTypes.string.isRequired
};

FormLabel.defaultProps = {
  children: null,
  required: false
};

export default FormLabel;
