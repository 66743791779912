import PropTypes from 'prop-types';
import React from 'react';

const Right = ({ children }) => {
  return <section className="right">{children}</section>;
};

Right.propTypes = {
  children: PropTypes.node
};

Right.defaultProps = {
  children: null
};

export default Right;
