import PropTypes from 'prop-types';
import React from 'react';
import { Address, Price, Rating } from '../../../../components';
import './styles.scss';

const GeneralInformation = ({ item }) => {
  return (
    <div className="general-information">
      <div className="top">
        <span className="name">{item.name}</span>
        <Rating
          rating={item?.rating || 0}
          numberOfRatings={item.numberOfRatings || 0}
        />
      </div>
      <div className="bottom">
        <Price
          pricePerPeriod={item.pricePerPeriod}
          periodType={item.periodType}
        />
        <Address address={item.address} />
      </div>
    </div>
  );
};

GeneralInformation.propTypes = {
  item: PropTypes.object
};

GeneralInformation.defaultProps = {
  item: null
};

export default GeneralInformation;
