import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthenticatedRoute, GuestRoute, Route } from './components/Routes';
import { Custom as CustomLayout, Main as MainLayout } from './layouts';
import {
  Confirm,
  Dashboard,
  Home,
  ItemDetails,
  ItemForm,
  Messages,
  MyItemDetails,
  MyItems,
  Profile,
  ResetPassword,
  SearchResults,
  Signin,
  Transactions,
  UserProfile
} from './pages';
import { APP_URL_SCHEMES, URL_HELPERS } from './utils/url';

const Routes = () => {
  return (
    <Switch>
      <Route
        component={Home}
        layout={CustomLayout}
        path={URL_HELPERS.index}
        exact
      />
      <Route
        component={ItemDetails}
        layout={MainLayout}
        path={APP_URL_SCHEMES.itemDetails}
        exact
      />
      <Route
        component={SearchResults}
        layout={MainLayout}
        path={URL_HELPERS.searchResults}
      />
      <Route
        component={UserProfile}
        layout={CustomLayout}
        path={APP_URL_SCHEMES.userProfile}
        exact
      />
      <GuestRoute
        component={Signin}
        layout={MainLayout}
        path={URL_HELPERS.signin}
      />
      <GuestRoute
        component={ResetPassword}
        layout={MainLayout}
        path={URL_HELPERS.resetPassword}
      />
      <GuestRoute
        component={Confirm}
        layout={MainLayout}
        path={URL_HELPERS.confirm}
      />
      <AuthenticatedRoute
        component={ItemForm}
        layout={MainLayout}
        path={APP_URL_SCHEMES.editItem}
        exact
      />
      <AuthenticatedRoute
        component={ItemForm}
        layout={MainLayout}
        path={URL_HELPERS.add}
        exact
      />
      <AuthenticatedRoute
        component={Profile}
        layout={CustomLayout}
        path={URL_HELPERS.profile}
      />
      <AuthenticatedRoute
        component={MyItems}
        layout={CustomLayout}
        path={URL_HELPERS.myItems}
        exact
      />
      <AuthenticatedRoute
        component={MyItemDetails}
        layout={MainLayout}
        path={APP_URL_SCHEMES.myItemDetails}
      />
      <AuthenticatedRoute
        component={Transactions}
        layout={CustomLayout}
        path={URL_HELPERS.transactions}
        exact
      />
      <AuthenticatedRoute
        component={Transactions}
        layout={CustomLayout}
        path={APP_URL_SCHEMES.transactionDetails}
      />
      <AuthenticatedRoute
        component={Messages}
        layout={MainLayout}
        path={URL_HELPERS.messages}
        exact
      />
      <AuthenticatedRoute
        component={Messages}
        layout={MainLayout}
        path={APP_URL_SCHEMES.convoDetails}
        exact
      />
      <AuthenticatedRoute
        component={Messages}
        layout={MainLayout}
        path={APP_URL_SCHEMES.convoTransactionDetails}
        exact
      />
      <AuthenticatedRoute
        component={Signin}
        layout={MainLayout}
        path={URL_HELPERS.signout}
      />
      <AuthenticatedRoute
        component={Dashboard}
        layout={MainLayout}
        path={URL_HELPERS.dashboard}
        exact
      />
      <AuthenticatedRoute
        component={Dashboard}
        layout={MainLayout}
        path={APP_URL_SCHEMES.dashboardTransaction}
        exact
      />
    </Switch>
  );
};

export default Routes;
