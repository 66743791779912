import React, { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  Confirmation,
  EmptyListPlaceholder,
  Icon,
  ToastNotification
} from '../../../../components';
import ModalContext from '../../../../contexts/ModalContext';
import { useS3Read } from '../../../../hooks';
import { validateImages } from '../../../../utils';
import Thumbnail from '../Thumbnail';
import UploadButton from '../UploadButton';
import './styles.scss';

const Media = () => {
  const mapPreviews = (image) => {
    try {
      return URL.createObjectURL(image);
    } catch {
      return image;
    }
  };

  const { control, getValues } = useFormContext();
  const { data } = useS3Read(getValues('images'));
  const [images, setImages] = useState([]);
  const [imageValues, setImageValues] = useState(getValues('images') || []);
  const { addToast } = useToasts();
  const { showModal } = useContext(ModalContext);

  const revokeImageUrls = () => {
    images.forEach((image) => {
      setTimeout(() => {
        URL.revokeObjectURL(image);
      }, 1000);
    });
  };

  useEffect(() => {
    if (images?.length) {
      revokeImageUrls();
    }

    setImages(data);
  }, [data]);

  const updateImages = (onChange, acceptedFiles) => {
    onChange([...imageValues, ...acceptedFiles]);
    setImageValues([...imageValues, ...acceptedFiles]);
    setImages([...images, ...acceptedFiles].map(mapPreviews));
    revokeImageUrls();
  };

  const handleDropzoneChange = (acceptedFiles, onChange) => {
    if (acceptedFiles || acceptedFiles.length) {
      updateImages(onChange, acceptedFiles);
    }
  };

  const handleChange = async (event, onChange) => {
    if (event.target.files || event.target.files.length) {
      try {
        const acceptedFiles = await validateImages(event.target.files);
        updateImages(onChange, acceptedFiles);
      } catch (error) {
        addToast(
          <ToastNotification
            message={error}
            toastId="media-change-error"
            variant="error"
          />,
          { id: 'media-change-error' }
        );
      }
    }
  };

  const handleSelect = (index, onChange) => {
    const updatedImageValues = imageValues.toSpliced(0, 0, imageValues[index]);
    updatedImageValues.splice(index + 1, 1);
    const updatedImages = images.toSpliced(0, 0, images[index]);
    updatedImages.splice(index + 1, 1);

    setImageValues(updatedImageValues);
    setImages(updatedImages.map(mapPreviews));
    onChange(updatedImageValues);
  };

  const handleConfirmDeleteClick = (index, onChange) => {
    const filteredImageValues = imageValues.filter((_, i) => index !== i);
    const filteredImages = images.filter((_, i) => index !== i);
    setImages(filteredImages.map(mapPreviews));
    setImageValues(filteredImageValues);
    onChange(filteredImageValues);
    revokeImageUrls();
  };

  const handleDeleteClick = (index, onChange) => {
    showModal({
      title: '',
      content: () => (
        <Confirmation
          confirmLabel="Delete"
          confirmAction={() => handleConfirmDeleteClick(index, onChange)}
          confirmActionButtonProps={{ color: 'error' }}
          rejectLabel="Cancel"
        >
          <EmptyListPlaceholder
            type="image"
            icon="delete_outline"
            text="Are you sure you want to delete this photo?"
          />
        </Confirmation>
      )
    });
  };

  return (
    <Controller
      name="images"
      control={control}
      render={({ field: { onChange } }) => (
        <Dropzone
          onDrop={(acceptedFiles) => {
            handleDropzoneChange(acceptedFiles, onChange);
          }}
          maxSize={10 * 1000000}
          accept={['image/jpeg', 'image/jpg', 'image/png']}
          onDropRejected={(rejectedFiles) => {
            const errors = rejectedFiles.map((file) => file.errors[0]);
            let errorMessage;
            switch (errors[0].code) {
              case 'file-invalid-type':
                errorMessage = 'Unsupported file format. Must be jpg or png';
                break;
              case 'file-too-large':
                errorMessage = 'File must not be greater than 10 MB';
                break;
              default:
                errorMessage = 'Unsupported file format. Must be jpg or png';
                break;
            }
            addToast(
              <ToastNotification
                message={errorMessage}
                toastId="media-drop-error"
                variant="error"
              />,
              { id: 'media-drop-error' }
            );
          }}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div className="media" data-testid="media" {...getRootProps()}>
              <div className="featured">
                {images?.length ? (
                  <div className="default-image-container">
                    <img
                      className="default-image"
                      data-testid="mainThumbnail"
                      src={images[0]}
                    />
                    <button
                      className="button"
                      onClick={() => handleDeleteClick(0, onChange)}
                    >
                      <Icon icon="delete_outline" />
                    </button>
                  </div>
                ) : isDragActive ? (
                  <p data-testid="dragActive">Drag and drop some files here</p>
                ) : (
                  <EmptyListPlaceholder type="image" icon="file_upload" />
                )}
              </div>
              <div className="thumbnails">
                <UploadButton
                  handleChange={(event) => handleChange(event, onChange)}
                  getInputProps={getInputProps}
                />
                {images?.length > 1 &&
                  images
                    .slice(1)
                    .map((image, index) => (
                      <Thumbnail
                        image={image}
                        index={index}
                        onDelete={() => handleDeleteClick(index + 1, onChange)}
                        onSelect={() => handleSelect(index + 1, onChange)}
                        key={image}
                      />
                    ))}
              </div>
            </div>
          )}
        </Dropzone>
      )}
    />
  );
};

export default Media;
