import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ButtonText, FormInput } from '../../../../components';
import ModalContext from '../../../../contexts/ModalContext';
import './styles.scss';

const ExchangeItem = ({ exchangeCode, transaction }) => {
  const { closeModal } = useContext(ModalContext);

  const exchangeCodeArray = exchangeCode.split('');

  return (
    <div className="exchange-item">
      <p className="message">
        Provide this code to {transaction.renter.firstName} to verify this item
        exchange
      </p>
      <div className="exchange-code">
        {exchangeCodeArray.map((digit, index) => (
          <FormInput
            value={digit}
            disabled
            noController
            key={`exchange-code-${index}`}
          />
        ))}
      </div>
      <p className="expiration-timer">This code will expire in 00:60 seconds</p>
      <ButtonText variant="outlined" type="button" onClick={closeModal}>
        Close
      </ButtonText>
    </div>
  );
};

ExchangeItem.propTypes = {
  exchangeCode: PropTypes.string,
  transaction: PropTypes.object
};

ExchangeItem.defaultProps = {
  exchangeCode: '',
  transaction: null
};

export default ExchangeItem;
