import clsx from 'clsx';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { UserCard } from '../..';
import './styles.scss';

const ReviewCard = ({ avatar, date, name, review }) => {
  const [expanded, setExpanded] = useState(false);

  const reviewClassName = clsx('review', expanded && '-expanded');

  const handleClick = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <div className="review-card" onClick={handleClick}>
      <p className={reviewClassName}>{review}</p>
      <UserCard avatar={avatar} name={name} size="40">
        <div className="name-date-container">
          <span className="name">{name}</span>
          <p className="date">{format(new Date(date), 'MMM dd, yyyy')}</p>
        </div>
      </UserCard>
    </div>
  );
};

ReviewCard.propTypes = {
  avatar: PropTypes.object,
  date: PropTypes.string,
  name: PropTypes.string,
  review: PropTypes.string
};

ReviewCard.defaultProps = {
  avatar: null,
  date: '',
  name: '',
  review: ''
};

export default ReviewCard;
