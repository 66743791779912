import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  EmptyListPlaceholder,
  ItemCardList,
  NavigationTabs,
  ReviewCardList
} from '../../../../components';
import './styles.scss';

const ItemsAndReviews = ({ history, items, reviews }) => {
  const [tab, setTab] = useState('ITEMS');

  const isItemsEmpty = items.length === 0;
  const isReviewsEmpty = reviews.length === 0;

  const classNames = clsx(
    'items-and-reviews-subpage',
    (tab === 'ITEMS' && isItemsEmpty && '-empty') ||
      (tab === 'REVIEWS' && isReviewsEmpty && '-empty')
  );

  const handleClick = (tab) => setTab(tab.toUpperCase());

  return (
    <div className={classNames}>
      <div className="subpage-title">
        <h4>Profile</h4>
      </div>
      <NavigationTabs
        activeTab={tab}
        leftTabLabel="Items"
        rightTabLabel="Reviews"
        onClick={handleClick}
      />
      {tab === 'ITEMS' ? (
        isItemsEmpty ? (
          <EmptyListPlaceholder
            type="item"
            icon="find_in_page"
            text="No listings found"
            supportingText="You haven't added any items yet."
          />
        ) : (
          <ItemCardList history={history} things={items} />
        )
      ) : isReviewsEmpty ? (
        <EmptyListPlaceholder
          type="review"
          icon="find_in_page"
          text="No reviews found"
          supportingText="You haven't received any reviews yet."
        />
      ) : (
        <ReviewCardList reviews={reviews} />
      )}
    </div>
  );
};

ItemsAndReviews.propTypes = {
  history: PropTypes.object,
  items: PropTypes.array,
  reviews: PropTypes.array
};

ItemsAndReviews.defaultProps = {
  history: null,
  items: [],
  reviews: []
};

export default ItemsAndReviews;
