import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Description = ({ description }) => {
  return (
    <div className="description">
      <span className="title">Description</span>
      <p className="description">{description}</p>
    </div>
  );
};

Description.propTypes = {
  description: PropTypes.string
};

Description.defaultProps = {
  description: ''
};

export default Description;
