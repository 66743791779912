import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Main = ({ children }) => {
  return <main className="container">{children}</main>;
};

Main.propTypes = {
  children: PropTypes.node.isRequired
};

export default Main;
