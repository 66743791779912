import clsx from 'clsx';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Badge, ButtonIcon } from '../../../../components';
import { TransactionStatusUI } from '../../../../constants/enumTypes';
import UserContext from '../../../../contexts/UserContext';
import './styles.scss';

const TransactionCard = ({
  history,
  transaction,
  type,
  handleDesktopClick
}) => {
  const { conversation, Thing, transactionStatus, updatedAt } = transaction;

  const { state } = useContext(UserContext);

  const handleClick = () => {
    type === 'desktop'
      ? handleDesktopClick()
      : history.push(`/dashboard/transaction/${conversation.publicID}`);
  };

  let isOwner;
  if (
    transaction.Thing.userID === state.id ||
    transaction.ownerID === state.id
  ) {
    isOwner = true;
  }

  const typeClassNames = clsx('type', isOwner ? '-lending' : '-renting');

  return (
    <div className="transaction-card">
      <div className="top">
        <span className="name">{Thing.name}</span>
        <span className={typeClassNames}>
          {state.id === transaction.renterID
            ? `Renting from  ${transaction.owner.firstName}`
            : state.id === transaction.ownerID
            ? `Lending to ${transaction.renter.firstName}`
            : ''}
        </span>
      </div>
      <span className="date">
        {format(new Date(updatedAt), 'MMM d, yyyy h:mmaa')}
      </span>
      <div className="bottom">
        <Badge
          alert
          label={TransactionStatusUI[transactionStatus].label}
          type={transactionStatus.toLowerCase()}
          className="transaction-status"
        />
        <ButtonIcon
          icon="chevron_right"
          variant="outlined"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

TransactionCard.propTypes = {
  history: PropTypes.object,
  transaction: PropTypes.object,
  type: PropTypes.string,
  handleDesktopClick: PropTypes.function
};

TransactionCard.defaultProps = {
  history: null,
  transaction: null,
  type: '',
  handleDesktopClick: () => {}
};

export default TransactionCard;
