import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { ResetPasswordForm } from '../../components';
import ModalContext from '../../contexts/ModalContext';

const ResetPassword = ({ history }) => {
  const { showModal, closeModal } = useContext(ModalContext);

  useEffect(() => {
    const codeParam = history.location.search
      .split('&')
      .find((param) => /code/g.test(param));
    const dataParam = history.location.search
      .split('&')
      .find((param) => /data/g.test(param));

    const code = codeParam?.replace(/\?code=/, '');
    const data = dataParam?.replace(/data=/, '');
    if (code && data) {
      showModal({
        title: 'Reset Password',
        content: () => ResetPasswordForm({ code, data, history, closeModal })
      });
    } else {
      history.push('/');
    }
  }, []);

  return null;
};

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired
};

export default ResetPassword;
