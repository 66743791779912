import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const FormInputError = ({ error, ...props }) => {
  const renderFormInputError = () => {
    if (!error) {
      return null;
    }

    // :: For input fields that contain multiple error messages
    return error.types?.matches?.length ? (
      <p data-testid="error" className="form-input-error" {...props}>
        {Array.isArray(error.types.matches)
          ? error.types.matches
              .map((message, index) => {
                if (index === error.types.matches.length - 1) {
                  return message.replace(/(\w+\s){2}/, 'and ');
                }
                if (index >= 1) {
                  return message.replace(/(\w+\s){2}/, '');
                }
                return message;
              })
              .join(', ')
          : error.types.matches}
      </p>
    ) : (
      <p data-testid="error" className="form-input-error" {...props}>
        {error.message}
      </p>
    );
  };

  return renderFormInputError();
};

FormInputError.propTypes = {
  error: PropTypes.object
};

FormInputError.defaultProps = {
  error: {}
};

export default FormInputError;
