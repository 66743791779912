import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import ButtonIcon from '../ButtonIcon';
import Icon from '../Icon';
import './styles.scss';

/**
 * *NOTE:
 *   Usage: For custom messages, add the message className
 */

const ToastNotification = ({ children, message, toastId, variant }) => {
  const { removeToast } = useToasts();

  const ICONS = {
    error: 'error_outline',
    success: 'check',
    warning: 'warning_amber'
  };

  const classNames = clsx('toast-notification', `-${variant}`);

  const handleClick = () => {
    removeToast(toastId);
  };

  return (
    <div className={classNames}>
      <Icon icon={ICONS[variant]} />
      {children || <p className="message">{message}</p>}
      <ButtonIcon icon="close" onClick={handleClick} />
    </div>
  );
};

ToastNotification.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  toastId: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'success', 'warning'])
};

ToastNotification.defaultProps = {
  children: null,
  message: '',
  toastId: '',
  variant: 'success'
};

export default ToastNotification;
