import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import listedIcon from '../../assets/icons/clipboard-check.svg';
import unListedIcon from '../../assets/icons/clipboard-x.svg';
import {
  ButtonIcon,
  ButtonText,
  Description,
  Divider,
  Footer,
  Header,
  Icon,
  ImageCarousel,
  ItemReviews,
  Loader,
  Rating,
  ToastNotification,
  UserCard
} from '../../components';
import UserContext from '../../contexts/UserContext';
import { URL_HELPERS } from '../../utils/url';
import useMyItemDetails from './useMyItemDetails';
import './styles.scss';
import { GeneralInformation } from './components';

const MyItemDetails = ({ history }) => {
  const { itemId } = useParams();
  const { addToast, removeToast } = useToasts();

  const {
    isUserItemLoading,
    userItemData,
    updateItem,
    isUpdateItemLoading,
    updateItemData
  } = useMyItemDetails(itemId);

  const { state } = useContext(UserContext);

  const randomId = (length) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';

    for (let counter = 0; counter < length; counter++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  const listed = updateItemData?.listed ?? userItemData?.listed;

  const handleListToggle = async () => {
    const { description, pricePerPeriod, deposit, minRentPeriod } =
      userItemData;

    if (
      description !== '' &&
      pricePerPeriod > 1 &&
      pricePerPeriod * minRentPeriod > 5 &&
      deposit >= pricePerPeriod * 0.5
    ) {
      if (state.stripeAccountID && state.stripeDetailsSubmitted) {
        const response = await updateItem({
          variables: {
            input: {
              id: userItemData.id,
              listed: !updateItemData?.listed ?? !userItemData?.listed,
              _version: userItemData._version
            }
          }
        });
        const id = randomId(10);

        addToast(
          <ToastNotification
            message={
              response.updateThing.listed
                ? 'Renters can now request your item'
                : 'Renters cannot request your item anymore'
            }
            toastId={`list-success-${id}`}
            variant="success"
          />,
          {
            id: `list-success-${id}`
          }
        );
      } else {
        addToast(
          <ToastNotification toastId="list-no-payout-info" variant="error">
            <p className="message">
              We don&apos;t have a way to pay you for rental items yet! To list
              this item, please{' '}
              <Link
                to={APP_URL_SCHEMES.paymentInformation}
                onClick={() => removeToast('list-no-payout-info')}
              >
                click here
              </Link>{' '}
              and provide your payout information
            </p>
          </ToastNotification>,
          {
            id: 'list-no-payout-info',
            autoDismiss: false
          }
        );
      }
    } else {
      addToast(
        <ToastNotification toastId="list-incomplete" variant="error">
          <p className="message">
            Some fields needed for listing are missing.{' '}
            <Link
              to={{
                pathname: `/my-items/edit/${userItemData.publicID}`,
                state: { thing: userItemData }
              }}
              onClick={() => removeToast('list-incomplete')}
            >
              Click here
            </Link>{' '}
            to edit the item
          </p>
        </ToastNotification>,
        {
          id: 'list-incomplete',
          autoDismiss: false
        }
      );
    }
  };

  const handleEditClick = () => {
    history.push({
      pathname: `/my-items/edit/${userItemData.publicID}`,
      state: {
        thing: userItemData,
        isEditItem: true
      }
    });
  };

  if (isUserItemLoading) {
    return <Loader />;
  }

  return (
    <div className="my-item-details-page">
      <Header>
        <Header.Left>
          <ButtonIcon icon="arrow_back" onClick={history.goBack} />
        </Header.Left>
        <Header.Right>
          {!isUpdateItemLoading && (
            <span className="label">{listed ? 'Listed' : 'Unlisted'}</span>
          )}
          <ButtonIcon onClick={handleListToggle} loading={isUpdateItemLoading}>
            <img src={listed ? listedIcon : unListedIcon} />
          </ButtonIcon>
        </Header.Right>
      </Header>
      <div className="body">
        <ImageCarousel images={userItemData.images} />
        <GeneralInformation item={userItemData} />
        <Divider />
        <UserCard
          avatar={userItemData.User.avatar}
          name={userItemData.User.fullName}
          size="40"
        >
          <div className="info">
            <Link to={URL_HELPERS.profile} className="name">
              {userItemData.User.fullName}
            </Link>
            <Rating
              rating={userItemData.User?.rating || 0}
              numberOfRatings={userItemData.numberOfRatings || 0}
            />
          </div>
        </UserCard>
        <Divider />
        <Description description={userItemData.description} />
        <ItemReviews item={userItemData} />
      </div>
      <Footer />
      <footer className="page-footer">
        <ButtonText
          color="primary"
          onClick={handleEditClick}
          className="edit-btn"
        >
          <Icon icon="edit" />
          Edit
        </ButtonText>
      </footer>
    </div>
  );
};

MyItemDetails.propTypes = {
  history: PropTypes.object
};

MyItemDetails.defaultProps = {
  history: null
};

export default MyItemDetails;
