import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';
import './styles.scss';

const KeywordTag = ({ index, label, onRemove, readOnly }) => {
  return (
    <div className="keyword-tag">
      <span className="label" data-testid={`keywordLabel${index}`}>
        {label}
      </span>
      {!readOnly && (
        <Icon icon="close" onClick={onRemove} data-testid={`close${index}`} />
      )}
    </div>
  );
};

KeywordTag.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  readOnly: PropTypes.bool
};

KeywordTag.defaultProps = {
  index: 0,
  label: '',
  onRemove: () => {},
  readOnly: false
};

export default KeywordTag;
