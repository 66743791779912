import PropTypes from 'prop-types';
import HedgehogInactive from '../../assets/icons/hedgehog-gull-gray.png';
import React, { useContext, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import logo from '../../assets/icons/ukuu-logo.png';
import AuthContext from '../../contexts/AuthContext';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import { URL_HELPERS } from '../../utils/url';
import {
  Avatar,
  Confirmation,
  Divider,
  DropdownMenu,
  EmptyListPlaceholder,
  Header,
  Icon,
  Search,
  SignInForm,
  SignUpForm
} from '..';
import './styles.scss';

const NavbarTop = ({ isLoggedIn }) => {
  const history = useHistory();

  const { state, removeUserData } = useContext(UserContext);
  const { showModal } = useContext(ModalContext);
  const { signout } = useContext(AuthContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  const handleOutsideClick = () => setIsMenuOpen(false);
  const handleOptionClick = () => setIsMenuOpen(false);

  const handleSignUpClick = () => {
    showModal({
      title: 'Sign Up',
      content: () => SignUpForm()
    });
  };

  const handleSignInClick = (event) => {
    showModal({
      title: 'Sign In',
      content: () => SignInForm({ event })
    });
  };

  const handleConfirmSignOutClick = () => {
    removeUserData();
    signout();
    history.push(URL_HELPERS.index);
  };

  const handleSignOutClick = () => {
    showModal({
      content: () => (
        <Confirmation
          confirmLabel="Yes"
          confirmAction={handleConfirmSignOutClick}
          rejectLabel="Cancel"
        >
          <EmptyListPlaceholder
            type="app"
            icon="logout"
            text="Are you sure you want to logout?"
          />
        </Confirmation>
      )
    });
  };

  const renderAboutAndHelpLinks = () => {
    return (
      <>
        <Divider />
        <a
          href="http://info.ukuu.io"
          target="_blank"
          rel="noreferrer"
          className="option"
          onClick={handleOptionClick}
        >
          <img
            src={HedgehogInactive}
            className="icon hedgehog"
            height="20px"
            width="20px"
          />
          <span className="label">About</span>
        </a>
        <a
          href="http://help.ukuu.io"
          target="_blank"
          rel="noreferrer"
          className="option"
          onClick={handleOptionClick}
        >
          <Icon icon="help_outline" />
          <span className="label">Help</span>
        </a>
      </>
    );
  };

  return (
    <Header className="navbar-top">
      <Header.Left>
        <NavLink to={isLoggedIn ? URL_HELPERS.dashboard : URL_HELPERS.index}>
          <img src={logo} />
        </NavLink>
      </Header.Left>
      <Header.Center>
        <Search history={history} />
      </Header.Center>
      <Header.Right>
        <DropdownMenu onOutsideClick={handleOutsideClick}>
          <DropdownMenu.Toggle>
            <div className="menu-toggle" onClick={handleMenuClick}>
              <Icon icon="menu" />
              <Avatar avatar={state.avatar} name={state.fullName} size={32} />
            </div>
          </DropdownMenu.Toggle>
          {isMenuOpen && (
            <DropdownMenu.Content>
              {isLoggedIn ? (
                <>
                  <NavLink
                    to={URL_HELPERS.index}
                    exact
                    className="option"
                    onClick={handleOptionClick}
                  >
                    <Icon icon="search" />
                    <span className="label">Find</span>
                  </NavLink>
                  <NavLink
                    to={URL_HELPERS.myItems}
                    className="option"
                    onClick={handleOptionClick}
                  >
                    <Icon icon="format_list_bulleted" />
                    <span className="label">Listings</span>
                  </NavLink>
                  <NavLink
                    to={URL_HELPERS.messages}
                    className="option inbox"
                    onClick={handleOptionClick}
                  >
                    <Icon icon="chat_bubble_outline" />
                    <span className="label">Inbox</span>
                    {!!state.unreadConversations.length && (
                      <span className="alert" data-testid="alert" />
                    )}
                  </NavLink>
                  <NavLink
                    to={URL_HELPERS.profile}
                    className="option"
                    onClick={handleOptionClick}
                  >
                    <Icon icon="person_outline" />
                    <span className="label">Account</span>
                  </NavLink>
                  {renderAboutAndHelpLinks()}
                  <div className="option" onClick={handleSignOutClick}>
                    <Icon icon="logout" />
                    <span className="label">Logout</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="option" onClick={handleSignUpClick}>
                    <Icon icon="edit" />
                    <span className="label">Sign Up</span>
                  </div>
                  <div className="option" onClick={handleSignInClick}>
                    <Icon icon="login" />
                    <span className="label">Log In</span>
                  </div>
                  {renderAboutAndHelpLinks()}
                </>
              )}
            </DropdownMenu.Content>
          )}
        </DropdownMenu>
      </Header.Right>
    </Header>
  );
};

NavbarTop.propTypes = {
  isLoggedIn: PropTypes.bool
};

NavbarTop.defaultProps = {
  isLoggedIn: false
};

export default NavbarTop;
