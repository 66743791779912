import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ButtonIcon,
  EmptyListPlaceholder,
  Header,
  ItemCardList,
  Loader,
  Search,
  SortContainer
} from '../../components';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import {
  findMaximumPrice as findMaximumPriceQuery,
  findThings as findThingsQuery
} from '../../graphql/customQueries';
import { createSearch as createSearchMutation } from '../../graphql/mutations';
import { listThings as listThingsQuery } from '../../graphql/queries';
import {
  useDebounce,
  useLazyQuery,
  useMutation,
  useWindowDimensions
} from '../../hooks';
import { getLocation, isEmpty, parseDate } from '../../utils';
import './styles.scss';

const SearchResults = ({ history }) => {
  const { state } = useContext(UserContext);
  const { showModal, closeModal } = useContext(ModalContext);

  const [thingsData, setThingsData] = useState([]);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [nextToken, setNextToken] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [totalThings, setTotalThings] = useState(0);
  const [filters, setFilters] = useState(
    history.location.search
      .split('&')
      .slice(1) // :: Exclude the keyword param (?keyword=)
      .filter((param) => !/sort/g.test(param))
  );
  const [currentVariables, setCurrentVariables] = useState({});
  const [sortParam, setSortParam] = useState(
    history.location.search.split('&').find((param) => /sort/g.test(param))
  );
  const [keyword, setKeyword] = useState('');
  const [urlParams, setUrlParams] = useState(
    history.location.search.split('&')
  );

  const { windowWidth } = useWindowDimensions();

  const [createSearch] = useMutation(createSearchMutation);
  const [findThings, { loading }] = useLazyQuery(findThingsQuery);
  const [findMaximumPrice] = useLazyQuery(findMaximumPriceQuery);
  const [listThings, { loading: listThingsLoading, data: listThingsData }] =
    useLazyQuery(listThingsQuery);

  const MAX_SUGGESTIONS = 10;

  const constructFilters = () => {
    const filterObject = filters
      .map((filter) => {
        if (/tags/g.test(filter)) {
          const tags = filter.replace('tags=', '').split(',');
          return tags.map((tag) => ({
            tags: {
              match: tag
            }
          }));
        }
        if (/price/g.test(filter)) {
          const prices = filter.replace('prices=', '').split(':');
          return {
            pricePerPeriod: { gt: Number(prices[0]), lte: Number(prices[1]) }
          };
        }
      })
      .filter((filter) => filter);

    const locationFilters = filters.filter((filter) => {
      return /distance/g.test(filter) || /latlng/g.test(filter);
    });

    let locationFilterObject;

    if (locationFilters.length > 1) {
      const distance = locationFilters
        .find((filter) => /distance/g.test(filter))
        .replace('distance=', '');
      const [filteredLat, filteredLon] = decodeURIComponent(
        decodeURIComponent(
          locationFilters
            .find((filter) => /latlng/g.test(filter))
            .replace('latlng=', '')
        )
      ).split(',');

      locationFilterObject = {
        location: {
          lat: parseFloat(filteredLat),
          lon: parseFloat(filteredLon),
          distance: parseFloat(distance)
        }
      };
    } else if (locationFilters.length === 1) {
      locationFilterObject = locationFilters.map((filter) => {
        if (/distance/g.test(filter)) {
          const distance = filter.replace('distance=', '');
          const { lat, lon } = state.currentLocation;
          return {
            location: { lat, lon, distance: parseFloat(distance) }
          };
        }
        if (/latlng/g.test(filter)) {
          const latlng = filter.replace('latlng=', '').split(',');
          return {
            location: {
              lat: parseFloat(latlng[0]),
              lon: parseFloat(latlng[1])
            }
          };
        }
      });
    } else if (!isEmpty(state.currentLocation)) {
      const { lat, lon } = state.currentLocation;
      return {
        location: {
          lat,
          lon
        }
      };
    } else {
      return;
    }

    return [filterObject.flat(), locationFilterObject].flat();
  };

  const hasDateRangeFilter = () => {
    const filterParam = filters.find((filter) => {
      return /date_range/g.test(filter);
    });

    if (filterParam) {
      const decodedDateRange = filterParam;

      return decodedDateRange;
    }
    return null;
  };

  const decodeDateRangeFilter = (dateRange) => {
    const dates = dateRange.replace('date_range=', '');
    const [startDate, endDate] = decodeURIComponent(
      decodeURIComponent(dates)
    ).split(':');

    return [parseDate(startDate), parseDate(endDate)];
  };

  const constructDateRangeFilter = () => {
    const DAYS = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    const dateRangeFilter = hasDateRangeFilter();

    if (dateRangeFilter) {
      const [parsedStartDate, parsedEndDate] =
        decodeDateRangeFilter(dateRangeFilter);
      const updatedDays = Array.from(
        new Set([...DAYS.slice(parsedStartDate.getDay()), ...DAYS])
      );
      const dateRange = updatedDays.slice(
        0,
        updatedDays.indexOf(DAYS[parsedEndDate.getDay()]) + 1 - DAYS.length
      );
      return dateRange.map((dayAvailable) => ({
        daysAvailable: {
          eq: dayAvailable
        }
      }));
    }
  };

  const getSortString = () => sortParam?.replace('sort=', '');

  const constructSort = () => {
    const sort = getSortString();

    switch (sort) {
      case 'price_low':
        return {
          field: 'pricePerPeriod',
          direction: 'asc'
        };
      case 'price_high':
        return {
          field: 'pricePerPeriod',
          direction: 'desc'
        };
      default: {
        if (isEmpty(state.currentLocation)) {
          return {
            field: 'pricePerPeriod',
            direction: 'asc'
          };
        }
        return {
          field: 'location',
          direction: 'asc'
        };
      }
    }
  };

  const defaultSortValue = () => {
    const sort = getSortString();
    switch (sort) {
      case 'distance':
        return {
          label: 'Distance',
          value: 'dist'
        };
      default: {
        if (isEmpty(state.currentLocation)) {
          return {
            label: 'Price low to high',
            value: 'price_low'
          };
        }
        return {
          label: 'Distance',
          value: 'dist'
        };
      }
    }
  };

  const defaultLocationValue = () => {
    const locationFilter = filters.find((filter) => /latlng/g.test(filter));

    if (locationFilter) {
      const latlng = decodeURIComponent(
        decodeURIComponent(locationFilter.replace('latlng=', ''))
      ).split(',');
      return {
        lat: +latlng[0],
        lon: +latlng[1]
      };
    } else if (!isEmpty(state.currentLocation)) {
      return {
        lat: state.currentLocation.lat,
        lon: state.currentLocation.lon
      };
    }

    return {
      lat: null,
      lon: null
    };
  };

  const defaultDateValues = () => {
    const dateRangeFilter = hasDateRangeFilter();
    if (dateRangeFilter) {
      const [parsedStartDate, parsedEndDate] =
        decodeDateRangeFilter(dateRangeFilter);

      return [parsedStartDate, parsedEndDate];
    }

    return [new Date(), new Date()];
  };

  const debounceSearchThings = useDebounce(filters, 1000);

  const handleUrlParams = (params) => {
    if (params.length >= 1) {
      setFilters(params.slice(1).filter((param) => !/sort/g.test(param)));
      setSortParam(params.find((param) => /sort/g.test(param)));
    }
    setUrlParams(params);

    history.replace(params.join('&'));
  };

  const handlePagination = async () => {
    if (nextToken) {
      if (totalThings !== thingsData.length) {
        const variables = {
          ...currentVariables,
          nextToken
        };
        const response = await findThings({ variables });
        const updatedThings = [...thingsData, ...response.findThings.items];
        setThingsData(updatedThings);

        if (updatedThings.length === totalThings) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setNextToken(response.findThings.nextToken);
      } else {
        if (!loading) {
          return (
            <div className="empty-results">
              <h3>No results found</h3>
            </div>
          );
        }
      }
    }
  };

  const handleFilterButton = () => {
    showModal({
      title: keyword,
      content: () =>
        SortContainer({
          urlParams,
          handleUrlParams,
          history,
          maximumPrice,
          defaultSortValue: defaultSortValue(),
          defaultLocationValue: defaultLocationValue(),
          defaultDateValues: defaultDateValues()
        })
    });
  };

  useEffect(() => {
    setThingsData([]);
    const searchKeyword = new URLSearchParams(history.location.search).get(
      'keyword'
    );
    setKeyword(searchKeyword);
    setUrlParams(history.location.search.split('&'));
    const search = async () => {
      try {
        let response, variables, maxPriceResponse;
        const limit = 10;
        let name, location;
        if (searchKeyword) {
          name = {
            fuzzy: searchKeyword
          };
        }
        const maximumPriceSort = {
          field: 'pricePerPeriod',
          direction: 'desc'
        };
        if (filters.length) {
          variables = {
            limit,
            filter: {
              archived: {
                eq: false
              },
              listed: {
                eq: true
              },
              name,
              and: constructFilters(),
              or: constructDateRangeFilter()
            },
            sort: constructSort()
          };
        } else {
          if (!isEmpty(state.currentLocation)) {
            variables = {
              limit,
              filter: {
                archived: {
                  eq: false
                },
                listed: {
                  eq: true
                },
                name,
                location: {
                  lat: state.currentLocation.lat,
                  lon: state.currentLocation.lon
                }
              },
              sort: constructSort()
            };
          } else {
            variables = {
              limit,
              filter: {
                archived: {
                  eq: false
                },
                listed: {
                  eq: true
                },
                name
              },
              sort: constructSort()
            };
          }
        }
        response = await findThings({ variables });

        maxPriceResponse = await findMaximumPrice({
          variables: {
            ...variables,
            filter: { ...variables.filter, and: [] },
            sort: maximumPriceSort
          }
        });

        setNextToken(response.findThings.nextToken);
        setCurrentVariables(variables);
        setThingsData(response.findThings.items);
        setMaximumPrice(maxPriceResponse.findThings?.items[0]?.pricePerPeriod);
        setHasMore(
          response.findThings.total !== response.findThings.items.length
        );
        const locationFilter = filters.find((filter) => {
          return /latlng/g.test(filter);
        });

        if (locationFilter) {
          const latlng = decodeURIComponent(
            decodeURIComponent(locationFilter.replace('latlng=', ''))
          ).split(',');
          location = await getLocation(latlng[0], latlng[1]);
        }

        await createSearch({
          variables: {
            input: {
              keyword: searchKeyword,
              location: location?.address || ''
            }
          }
        });
        setTotalThings(response.findThings.total);
      } catch (error) {
        console.error(error);
      }
    };

    if (debounceSearchThings) {
      search();
    }
  }, [
    debounceSearchThings,
    new URLSearchParams(history.location.search).get('keyword')
  ]);

  useEffect(() => {
    if (thingsData.length === 0) {
      const getSuggestedItems = async () => {
        const variables = {
          limit: 300,
          filter: {
            archived: {
              eq: false
            },
            listed: {
              eq: true
            }
          }
        };

        await listThings({ variables });
      };

      getSuggestedItems();
    }
  }, [thingsData]);

  useEffect(() => {
    if (windowWidth > 480) {
      closeModal();
    }
  }, [windowWidth]);

  return (
    <div className="search-results-page">
      <Header>
        <Header.Center>
          <Search history={history} />
        </Header.Center>
      </Header>
      <div className="body">
        {windowWidth > 480 && (
          <SortContainer
            urlParams={urlParams}
            handleUrlParams={handleUrlParams}
            history={history}
            maximumPrice={maximumPrice}
            defaultSortValue={defaultSortValue()}
            defaultLocationValue={defaultLocationValue()}
            defaultDateValues={defaultDateValues()}
          />
        )}
        <InfiniteScroll
          dataLength={thingsData.length}
          next={handlePagination}
          hasMore={hasMore}
          loader={<Loader />}
          hasChildren
        >
          {loading ? (
            <Loader />
          ) : thingsData.length > 0 ? (
            <ItemCardList
              firstCoords={defaultLocationValue()}
              history={history}
              headerNode={
                <div className="header">
                  <span className="label">Search results</span>
                  <ButtonIcon
                    icon="settings2"
                    onClick={handleFilterButton}
                    variant="outlined"
                  />
                </div>
              }
              things={thingsData}
            />
          ) : listThingsLoading ? (
            <Loader />
          ) : (
            <>
              <EmptyListPlaceholder
                type="item"
                icon="search"
                text="No results found"
                supportingText="Your search did not match any listings."
              />
              <ItemCardList
                firstCoords={defaultLocationValue()}
                history={history}
                label="You might be interested in"
                things={listThingsData?.listThings.items.slice(
                  0,
                  MAX_SUGGESTIONS
                )}
              />
            </>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  history: PropTypes.object
};

SearchResults.defaultProps = {
  history: null
};

export default SearchResults;
