import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Icon = ({ className, icon, ...props }) => {
  const classNames = clsx('material-icons', 'icon', className);

  return (
    <span className={classNames} {...props}>
      {icon}
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string
};

Icon.defaultProps = {
  className: '',
  icon: ''
};

export default Icon;
