import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import { Confirmation, ToastNotification } from '../../components';
import { Documents, PeriodTypeSelection } from '../../constants/enumTypes';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import {
  archiveThing as archiveThingMutation,
  createThing as createThingMutation,
  updateThing as updateThingMutation
} from '../../graphql/mutations';
import { useMutation, useS3Upload } from '../../hooks';
import { PeriodType, RequiredDocument } from '../../models';
import { daysAvailableHandler, getLocation, getPublicId } from '../../utils';
import { APP_URL_SCHEMES, URL_HELPERS } from '../../utils/url';
import { itemFormValidation } from '../../validators';
import ItemForm from './ItemForm';
import './styles.scss';
import { SaveAndList } from './components';

const ItemFormContainer = ({ history }) => {
  const { state } = useContext(UserContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { addToast, removeToast } = useToasts();
  const [createThing, { loading: createLoading }] =
    useMutation(createThingMutation);
  const [updateThing, { loading: updateLoading }] =
    useMutation(updateThingMutation);
  const [archiveThing] = useMutation(archiveThingMutation);
  const { isEditItem, thing } = history.location.state;
  const [isDraft, setDraft] = useState(false);
  const [uploadFiles, { loading: uploadLoading }] = useS3Upload();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    getValues,
    trigger
  } = useForm({
    resolver: yupResolver(itemFormValidation),
    defaultValues: {
      location: isEditItem
        ? {
            lat: thing.location.lat,
            lon: thing.location.lon,
            address: thing.address
          }
        : {
            lat: state.location.lat || state.currentLocation.lat,
            lon: state.location.lon || state.currentLocation.lon,
            address: state.location.address || state.currentLocation.address
          },
      name: thing.name || '',
      pricePerPeriod: thing.pricePerPeriod || 0,
      images: thing.images || [],
      periodType: thing.periodType,
      deposit: thing.deposit || 0,
      itemValue: thing.itemValue || 0,
      description: thing.description,
      minRentPeriod: thing.minRentPeriod || 1,
      maxRentPeriod: thing.maxRentPeriod || null,
      tags: thing.tags || [],
      category: thing.category || [],
      // Needed for validation when adding an item
      listed: isEditItem ? thing.listed : true,
      periodType: {
        value: thing.periodType || PeriodType.DAYS,
        label: PeriodTypeSelection[thing.periodType] || PeriodTypeSelection.DAYS
      },
      requiredDocuments: isEditItem
        ? thing.requiredDocuments?.length
          ? [
              ...thing.requiredDocuments?.map((document) => ({
                name: Documents[document],
                key: document
              }))
            ]
          : []
        : [
            {
              name: Documents[RequiredDocument.IDENTIFICATION],
              key: RequiredDocument.IDENTIFICATION
            }
          ],
      daysAvailable: isEditItem
        ? daysAvailableHandler(thing.daysAvailable)
        : [
            { label: 'Monday', checked: true },
            { label: 'Tuesday', checked: true },
            { label: 'Wednesday', checked: true },
            { label: 'Thursday', checked: true },
            { label: 'Friday', checked: true },
            { label: 'Saturday', checked: true },
            { label: 'Sunday', checked: true }
          ]
    }
  });

  const getImageValues = async (imageValues) => {
    const filteredS3Images = imageValues.filter((image) => !image.key);
    if (filteredS3Images.length) {
      return [
        ...imageValues.filter((image) => image.key),
        ...(await uploadFiles(filteredS3Images))
      ];
    }
    return imageValues;
  };

  const displayToast = () => {
    addToast(
      <ToastNotification toastId="item-form-no-payout-info" variant="error">
        <p className="message">
          We don&apos;t have a way to pay you for rental items yet! To list this
          item, please{' '}
          <Link
            to={APP_URL_SCHEMES.paymentInformation}
            onClick={() => removeToast('item-form-no-payout-info')}
          >
            click here
          </Link>{' '}
          and provide your payout information
        </p>
      </ToastNotification>,
      {
        id: 'item-form-no-payout-info',
        autoDismiss: false
      }
    );
  };

  const onSubmit = async (values) => {
    try {
      const {
        periodType,
        daysAvailable,
        tags = [],
        category = [],
        location,
        images,
        requiredDocuments,
        ...rest
      } = values;
      const s3Images = await getImageValues(images);
      const { timezone } = await getLocation(location.lat, location.lon);

      const publicID = getPublicId(values.name);
      const { isEditItem, thing } = history.location.state;
      const data = {
        location: {
          lat: parseFloat(location.lat),
          lon: parseFloat(location.lon)
        },
        address: location.address,
        daysAvailable: daysAvailable
          .filter((day) => day.checked)
          .map((day) => day.label),
        tags: [...tags],
        category: [...category],
        periodType: periodType.value,
        timezone,
        requiredDocuments: [
          ...requiredDocuments.map((document) => document.key)
        ]
      };
      if (isEditItem) {
        await updateThing({
          variables: {
            input: {
              ...data,
              ...rest,
              images: s3Images,
              id: thing.id,
              _version: thing._version
            }
          }
        });
      } else {
        await createThing({
          variables: {
            input: {
              ...data,
              ...rest,
              publicID,
              images: s3Images,
              listed: true,
              userID: state?.id || '',
              archived: false
            }
          }
        });
      }

      history.push(URL_HELPERS.myItems);
      addToast(
        <ToastNotification
          message="Saved Successfully"
          toastId="item-form-save-success"
          variant="success"
        />,
        { id: 'item-form-save-success' }
      );
    } catch (error) {
      console.error('submit', error);
      addToast(
        <ToastNotification
          message="An error occured while processing your request"
          toastId="item-form-save-error"
          variant="error"
        />,
        { id: 'item-form-save-error' }
      );
    }
  };

  const handleDraft = async () => {
    const noValidationErrors = await trigger('name');
    try {
      if (noValidationErrors) {
        setDraft(true);
        const {
          periodType,
          daysAvailable,
          location,
          images,
          requiredDocuments,
          name,
          ...rest
        } = getValues();
        const publicID = getPublicId(name);
        const s3Images = await getImageValues(images);
        const { timezone } = getLocation(location.lat, location.lon);

        const data = {
          location: {
            lat: location.lat,
            lon: location.lon
          },
          timezone,
          address: location.address,
          daysAvailable: daysAvailable
            .filter((day) => day.checked)
            .map((day) => day.label),
          periodType: periodType.value,
          requiredDocuments: [
            ...requiredDocuments.map((document) => document.key)
          ],
          publicID,
          name
        };

        await createThing({
          variables: {
            input: {
              ...data,
              ...rest,
              images: s3Images,
              listed: false,
              userID: state?.id,
              archived: false
            }
          }
        });
        addToast(
          <ToastNotification
            message="Item successfully save as draft"
            toastId="item-form-draft-success"
            variant="success"
          />,
          { id: 'item-form-draft-success' }
        );
        setDraft(false);
        history.push(URL_HELPERS.myItems);
      } else {
        addToast(
          <ToastNotification
            message="Please fill up atleast the item name to save as draft"
            toastId="item-form-draft-empty"
            variant="error"
          />,
          { id: 'item-form-draft-empty' }
        );
      }
    } catch (error) {
      console.error('Draft: ', error);
      addToast(
        <ToastNotification
          message="An error occured while saving your item as draft"
          toastId="item-form-draft-error"
          variant="error"
        />,
        { id: 'item-form-draft-error' }
      );
    }
  };

  const handleCheck = async () => {
    const noValidationErrors = await trigger();
    const { listed } = getValues();

    if (noValidationErrors && !(listed === false)) {
      if (!state.stripeAccountID) {
        return displayToast();
      }
      if (!state.stripeDetailsSubmitted) {
        return displayToast();
      }

      return showModal({
        content: () => (
          <SaveAndList
            handleCheck={() => {
              closeModal();
              handleSubmit(onSubmit, onError)();
            }}
            loading={
              updateLoading || uploadLoading || (createLoading && !isDraft)
            }
          />
        ),
        title: 'Save and List'
      });
    } else {
      // :: Without this, the errors won't remove until you call handleCheck again
      return handleSubmit(onSubmit, onError)();
    }
  };

  const onError = (errors) => {
    if (errors) {
      addToast(
        <ToastNotification
          message="Please fill up all the required fields"
          toastId="item-form-error"
          variant="error"
        />,
        { id: 'item-form-error' }
      );
    }
  };

  const confirmDelete = () => {
    showModal({
      content: () =>
        Confirmation({
          message:
            'Deleting this item will remove it from your account, cancel any pending or upcoming rentals, and remove it from Ukuu. If you would like to keep this item on Ukuu but prevent requests temporarily, consider Unlisting instead. \n\nPlease confirm if you would really like to delete this item.',
          rejectAction: () => closeModal(),
          confirmAction: async () => {
            const { _version, id } = thing;
            await archiveThing({
              variables: {
                input: {
                  id,
                  _version,
                  archived: true
                }
              }
            });
            closeModal();
            addToast(
              <ToastNotification
                message="Item successfully deleted"
                toastId="item-form-delete"
                variant="success"
              />,
              { id: 'item-form-delete' }
            );
            history.push(URL_HELPERS.myItems);
          },
          rejectLabel: 'Cancel',
          confirmLabel: 'Confirm'
        }),
      title: 'Are you sure?'
    });
  };

  const confirmCancel = () => {
    showModal({
      content: () =>
        Confirmation({
          message:
            'The information you have given us about this item has not yet been saved. Consider saving this item as a draft and coming back to complete the listing later. \n\nPlease confirm if you would really like to cancel discard this information',
          rejectAction: () => closeModal(),
          confirmAction: () => {
            closeModal();
            addToast(
              <ToastNotification
                message="Item successfully canceled"
                toastId="item-form-cancel"
                variant="success"
              />,
              { id: 'item-form-cancel' }
            );
            history.push(URL_HELPERS.myItems);
          },
          confirmLabel: 'Yes',
          rejectLabel: 'No'
        }),
      title: 'Are you sure?'
    });
  };

  return (
    <FormProvider
      control={control}
      errors={errors}
      getValues={getValues}
      register={register}
    >
      <ItemForm
        confirmCancel={confirmCancel}
        confirmDelete={confirmDelete}
        draftLoading={createLoading && isDraft}
        handleCheck={handleCheck}
        handleDraft={handleDraft}
        history={history}
        saveLoading={
          updateLoading || uploadLoading || (createLoading && !isDraft)
        }
      />
    </FormProvider>
  );
};

ItemFormContainer.propTypes = {
  history: PropTypes.object.isRequired
};

export default ItemFormContainer;
