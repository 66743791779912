import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ButtonText, FormGroup } from '../../../../components';
import ModalContext from '../../../../contexts/ModalContext';
import './styles.scss';

const SaveAndList = ({ handleCheck, loading }) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <div className="save-and-list">
      <p>
        Listing this item means renters will be able to request this item
        starting immediately. Please confirm you are ready to supply this item
        to renters.
      </p>
      <FormGroup>
        <ButtonText color="primary" loading={loading} onClick={handleCheck}>
          Confirm
        </ButtonText>
        <ButtonText variant="outlined" onClick={closeModal}>
          Cancel
        </ButtonText>
      </FormGroup>
    </div>
  );
};

SaveAndList.propTypes = {
  handleCheck: PropTypes.func,
  loading: PropTypes.bool
};

SaveAndList.defaultProps = {
  handleCheck: () => {},
  loading: false
};

export default SaveAndList;
