import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useWindowDimensions } from '../../../hooks';
import { Distance, Image, Price, Rating } from '../..';
import './styles.scss';

const ItemCard = ({
  distance,
  name,
  numberOfRatings,
  onClick,
  periodType,
  pricePerPeriod,
  rating,
  s3Image
}) => {
  const { windowWidth } = useWindowDimensions();

  const classNames = clsx('item-card', windowWidth >= 769 && '-large');

  const renderLaptopView = () => {
    return (
      <>
        <div className="price-rating-container">
          <Price periodType={periodType} pricePerPeriod={pricePerPeriod} />
          <Rating rating={rating} numberOfRatings={numberOfRatings} />
        </div>
        <p className="name">{name}</p>
        <Distance distance={distance} />
      </>
    );
  };

  return (
    <div className={classNames} onClick={onClick} data-testid="card">
      <Image image={s3Image} className="item-image" />
      <div className="item-info">
        {windowWidth >= 769 ? (
          renderLaptopView()
        ) : (
          <>
            <p className="name">{name}</p>
            <Price periodType={periodType} pricePerPeriod={pricePerPeriod} />
            <div className="distance-rating-container">
              <Distance distance={distance} />
              <Rating rating={rating} numberOfRatings={numberOfRatings} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ItemCard.propTypes = {
  distance: PropTypes.string,
  name: PropTypes.string,
  numberOfRatings: PropTypes.number,
  onClick: PropTypes.func,
  periodType: PropTypes.oneOf(['DAYS', 'HOURS', 'MONTHS', 'WEEKS']),
  pricePerPeriod: PropTypes.number,
  rating: PropTypes.number,
  s3Image: PropTypes.object
};

ItemCard.defaultProps = {
  distance: '',
  name: '',
  numberOfRatings: 0,
  onClick: () => {},
  periodType: 'DAYS',
  pricePerPeriod: 0,
  rating: 0,
  s3Image: {}
};

export default ItemCard;
