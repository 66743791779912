import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Divider, DropdownMenu, Icon } from '../../../../components';
import './styles.scss';

const Thumbnail = ({ image, index, onDelete, onSelect }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const handleOutsideClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="thumbnail-container">
      <img
        src={image}
        className="thumbnail"
        data-testid={`thumbnail${index}`}
      />
      <DropdownMenu onOutsideClick={handleOutsideClick}>
        <DropdownMenu.Toggle>
          <button className="button" onClick={handleClick}>
            <Icon icon="more_vert" />
          </button>
        </DropdownMenu.Toggle>
        {isMenuOpen && (
          <DropdownMenu.Content>
            <span className="option" onClick={onSelect}>
              Make Default Photo
            </span>
            <Divider />
            <span className="option" onClick={onDelete}>
              Delete
            </span>
          </DropdownMenu.Content>
        )}
      </DropdownMenu>
    </div>
  );
};

Thumbnail.propTypes = {
  image: PropTypes.string,
  index: PropTypes.number,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func
};

Thumbnail.defaultProps = {
  image: '',
  index: 0,
  onDelete: () => {},
  onSelect: () => {}
};

export default Thumbnail;
