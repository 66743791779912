import { format } from 'date-fns';
import { PeriodType } from '../models';

const getIntervalDate = (
  start = new Date(),
  end = new Date(),
  periodType = 'DAYS'
) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const isSameYear = startDate.getFullYear() === endDate.getFullYear();
  const isSameMonth = startDate.getMonth() === endDate.getMonth();
  const isSameDate = startDate.getDate() === endDate.getDate();

  switch (periodType) {
    case PeriodType.MONTHS:
      if (!isSameYear) {
        return `${format(startDate, 'MMM d, yyyy')} - ${format(
          endDate,
          'MMM d, yyyy'
        )}`;
      }

      return `${format(startDate, 'MMM d')} - ${format(
        endDate,
        'MMM d, yyyy'
      )}`;
    case PeriodType.WEEKS:
    case PeriodType.DAYS:
      if (!isSameYear) {
        return `${format(startDate, 'MMM d, yyyy')} - ${format(
          endDate,
          'MMM d, yyyy'
        )}`;
      }

      if (!isSameMonth) {
        return `${format(startDate, 'MMM d')} - ${format(
          endDate,
          'MMM d, yyyy'
        )}`;
      }

      return `${format(startDate, 'MMM d')} - ${format(endDate, 'd, yyyy')}`;
    case PeriodType.HOURS:
      if (!isSameYear) {
        return `${format(startDate, 'h:mmaaa MMM d, yyyy')} - ${format(
          endDate,
          'h:mmaaa MMM d, yyyy'
        )}`;
      }

      if (!isSameMonth) {
        return `${format(startDate, 'h:mmaaa MMM d')} - ${format(
          endDate,
          'h:mmaaa MMM d, yyyy'
        )}`;
      }

      if (!isSameDate) {
        return `${format(startDate, 'h:mmaaa MMM d')} - ${format(
          endDate,
          'h:mmaaa MMM d, yyyy'
        )}`;
      }

      return `${format(startDate, 'h:mmaaa')} - ${format(
        endDate,
        'h:mmaaa MMM d, yyyy'
      )}`;
    default:
      return '';
  }
};

export default getIntervalDate;
