export const RENTER_AGREEMENT_LINK =
  'http://info.ukuu.io/resources/renter-agreement/';
export const OWNER_AGREEMENT_LINK =
  'http://info.ukuu.io/resources/owner-agreement/';
export const TERMS_OF_USE_LINK = 'http://info.ukuu.io/resources/terms-of-use/';
export const PRIVACY_POLICY_LINK =
  'http://info.ukuu.io/resources/privacy-policy/';
export const ISSUES_WITH_UKUU_LINK =
  'http://info.ukuu.io/inform-ukuu/issues-with-ukuu/';
export const ISSUES_WITH_USERS_ITEMS_LINK =
  'http://info.ukuu.io/inform-ukuu/issues-with-users-items/';
export const PROVIDE_FEEDBACK_LINK =
  'http://info.ukuu.io/inform-ukuu/provide-feedback/';
