import PropTypes from 'prop-types';
import React from 'react';

const Left = ({ children }) => {
  return <section className="left">{children}</section>;
};

Left.propTypes = {
  children: PropTypes.node
};

Left.defaultProps = {
  children: null
};

export default Left;
