import { StripeErrorCode } from '../constants/enumTypes';

/**
 * @param {import('@stripe/stripe-js').StripeError} error
 */
const createStripeError = (error) => {
  let errorMessage;
  switch (error.code) {
    case StripeErrorCode.SETUP_INTENT_AUTH_FAILED:
      errorMessage = ' We are unable to authenticate your payment method.';
      break;

    case StripeErrorCode.INCOMPLETE_CVC:
      errorMessage = 'Your CVC is incomplete.';
      break;

    case StripeErrorCode.CARD_DECLINED:
      errorMessage = 'Your card has been declined.';
      break;

    case StripeErrorCode.INCOMPLETE_ZIP:
      errorMessage = 'Incomplete zip code.';
      break;

    case StripeErrorCode.INVALID_EXPIRY_YEAR_PAST:
      errorMessage = "Your card's expiration year is in the past.";
      break;

    case StripeErrorCode.INCOMPLETE_NUMBER:
      errorMessage = "Your card's number is incomplete.";
      break;

    default:
      errorMessage = 'There was an error.';
      break;
  }

  return {
    type: error.type,
    message: errorMessage
  };
};

export default createStripeError;
