import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getLocation, isEmpty } from '../../utils';
import {
  DatePicker,
  Dropdown,
  FormGroup,
  FormKeywordInput,
  LocationInput,
  RangeSlider
} from '../';
import './styles.scss';

const SortContainer = ({
  defaultDateValues,
  defaultLocationValue,
  defaultSortValue,
  handleUrlParams,
  history,
  maximumPrice,
  urlParams
}) => {
  const [tagsParams, setTagsParams] = useState(
    new URLSearchParams(history.location.search).get('tags')?.split(',') || []
  );

  const [location, setLocation] = useState(null);
  const [sortValue, setSortValue] = useState(defaultSortValue);
  const [startDate, setStartDate] = useState(defaultDateValues[0]);
  const [endDate, setEndDate] = useState(defaultDateValues[1]);

  const [sortParam, setSortParam] = useState(
    new URLSearchParams(history.location.search).get('sort')
  );

  useEffect(() => {
    const fetchLocation = async () => {
      if (!isEmpty(defaultLocationValue)) {
        const { address } = await getLocation(
          defaultLocationValue.lat,
          defaultLocationValue.lon
        );
        setLocation(address);
      }
    };

    fetchLocation();
  }, []);

  const mapParams = (urlParam, value) => {
    return history.location.search.split('&').map((param) => {
      const regex = new RegExp(urlParam, 'g');
      if (regex.test(param)) {
        return `${urlParam}=${value}`;
      }
      return param;
    });
  };

  const filterParams = (urlParam) => {
    return urlParams.filter((param) => {
      const regex = new RegExp(urlParam, 'g');
      return !regex.test(param);
    });
  };

  const handleLocation = (coordinates = null) => {
    const locationParam = history.location.search
      .split('&')
      .find((param) => /latlng/g.test(param));
    if (coordinates) {
      if (locationParam) {
        handleUrlParams(
          mapParams(
            'latlng',
            `${coordinates.latitude},${coordinates.longitude}`
          )
        );
      } else {
        handleUrlParams([
          ...history.location.search.split('&'),
          `latlng=${coordinates.latitude},${coordinates.longitude}`
        ]);
      }
    }
  };

  const handleTags = (tags) => {
    setTagsParams(tags);

    const tagParam = urlParams.find((param) => /tags/g.test(param));

    if (tagParam) {
      if (tags.length) {
        handleUrlParams(mapParams('tags', tags.join(',')));
      } else {
        handleUrlParams(filterParams('tags'));
      }
    } else {
      handleUrlParams([...urlParams, `tags=${tags.join(',')}`]);
    }
  };

  const handleSort = (option) => {
    setSortValue(option);
    if (sortParam) {
      handleUrlParams(mapParams('sort', option.value));
      setSortParam(option.value);
    } else {
      handleUrlParams([...urlParams, `sort=${option.value}`]);
      setSortParam(option.value);
    }
  };

  const handlePriceValues = (min, max) => {
    const priceParam = urlParams.find((param) => /prices/g.test(param));
    if (priceParam) {
      handleUrlParams(mapParams('prices', `${min}:${max}`));
    } else {
      handleUrlParams([...urlParams, `prices=${min}:${max}`]);
    }
  };

  const handleDistanceValues = (min) => {
    const distanceParam = urlParams.find((param) => /distance/g.test(param));
    if (distanceParam) {
      handleUrlParams(mapParams('distance', `${min}`));
    } else {
      handleUrlParams([...urlParams, `distance=${min}`]);
    }
  };

  const handleDateRange = (date, type) => {
    const dateRangeParam = urlParams.find((param) => /date_range/g.test(param));
    if (type === 'fromDate') {
      setStartDate(date);
    } else {
      const dateFormatUrl = `${format(startDate, 'dd/MM/yyyy')}:${format(
        date,
        'dd/MM/yyyy'
      )}`;
      setEndDate(date);

      if (dateRangeParam) {
        handleUrlParams(mapParams('date_range', `${dateFormatUrl}`));
      } else {
        handleUrlParams([...urlParams, `date_range=${dateFormatUrl}`]);
      }
    }
  };

  return (
    <div className="sort-container">
      <div className="heading">
        <h3 className="title">Filters</h3>
      </div>
      <FormGroup>
        <Dropdown
          defaultValue={sortValue}
          handleChange={handleSort}
          label="Sort"
          name="sort"
          noController
          options={[
            {
              label: 'Distance',
              value: 'dist'
            },
            {
              label: 'Price Low to High',
              value: 'price_low'
            },
            {
              label: 'Price High to Low',
              value: 'price_high'
            }
          ]}
          placeholder="Select an option"
          value={sortValue}
        />
        <RangeSlider
          className="slider"
          handleSlider={handlePriceValues}
          label="Price"
          max={maximumPrice}
          min={0}
          multiSlider
        />
        <LocationInput
          defaultValue={location}
          handleChange={handleLocation}
          label="Location"
          name={'location'}
          noController
          saveLatLng
        />
        <RangeSlider
          className="slider"
          handleSlider={handleDistanceValues}
          label="Distance"
          max={500}
          min={1}
        />
        <DatePicker
          endDate={endDate}
          handleChange={handleDateRange}
          label="Dates"
          noController
          startDate={startDate}
        />
        <FormKeywordInput
          handleChange={handleTags}
          keywordTags={tagsParams}
          label="Tags"
          name="tags"
          noController
        />
      </FormGroup>
    </div>
  );
};

SortContainer.propTypes = {
  defaultDateValues: PropTypes.array,
  defaultLocationValue: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired
  }),
  defaultSortValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  handleUrlParams: PropTypes.func,
  history: PropTypes.object,
  maximumPrice: PropTypes.number,
  urlParams: PropTypes.array
};

SortContainer.defaultProps = {
  defaultDateValues: [],
  defaultLocationValue: null,
  defaultSortValue: null,
  handleUrlParams: () => {},
  history: null,
  maximumPrice: 50,
  urlParams: []
};

export default SortContainer;
