import { useEffect, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import AuthContext from '../../contexts/AuthContext';

/**
 * @param {import('@aws-amplify/api-graphql').GraphQLOptions} args
 */
const useQuery = (query, args) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);
  const {
    state: { accessToken }
  } = useContext(AuthContext);

  const loadData = async () => {
    try {
      const response = await API.graphql({
        query,
        authMode: accessToken ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY',
        ...args
      });
      setData(response.data);
    } catch (error) {
      console.error('query: ', error);
      setErrors(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return { loading, data, errors, refetchQueries: loadData };
};

export default useQuery;
