import * as yup from 'yup';
import { getMinRentDate } from '../utils';

const requestItemValidation = yup
  .object()
  .shape({
    paymentMethod: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string()
      })
      .test('hasPaymentMethod', null, function (obj) {
        if (obj.value === '') {
          return this.createError({ message: 'Payment method is required' });
        }
        return true;
      }),
    datePicker: yup
      .object()
      .shape({
        from: yup.date(),
        to: yup.date()
      })
      .test('greaterThanEndDate', null, function (obj) {
        if (obj.from > obj.to) {
          return this.createError({
            message: 'Start date must not be greater than end date',
            path: 'general'
          });
        }
        return true;
      })
  })
  .test('minimumRentalDate', null, function (obj, { options }) {
    const minRentPeriod = options.context.minRentPeriod;
    const periodType = options.context.periodType;
    const minRentDate = getMinRentDate(
      periodType,
      minRentPeriod,
      new Date(obj.datePicker.from)
    );

    if (minRentDate > new Date(obj.datePicker.to)) {
      return this.createError({
        message:
          'Your rental period must be at least the minimum rental period',
        path: 'general'
      });
    }
    return true;
  });

export default requestItemValidation;
