export const sendMessage = /* GraphQL */ `
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          exchangeCode
          ownerID
          renter {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          owner {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          Thing {
            id
            name
            periodType
            pricePerPeriod
            minRentPeriod
            userID
            listed
            tags
            description
            deposit
            itemValue
            maxRentPeriod
            requiredDocuments
            archived
            archivedAt
            publicID
            daysAvailable
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          hasRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          systemMessage
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      systemMessage
      conversationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      sub
      location {
        lat
        lon
      }
      address
      publicID
      customerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createStripeCustomer = /* GraphQL */ `
  mutation CreateStripeCustomer($input: CreateStripeCustomerInput!) {
    createStripeCustomer(input: $input) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      sub
      location {
        lat
        lon
      }
      address
      publicID
      customerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const requestThing = /* GraphQL */ `
  mutation RequestThing(
    $input: RequestThingInput!
    $condition: RequestThingConditionInput
  ) {
    requestThing(input: $input, condition: $condition) {
      id
      renterID
      ownerID
      paymentStatus
      Thing {
        id
        name
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          hasRead
          rentalPrice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      transactionStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      rentalPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      transactionStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent($input: CreatePaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      transactionStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const setDefaultPaymentMethod = /* GraphQL */ `
  mutation SetDefaultPaymentMethod($input: SetDefaultPaymentMethodInput!) {
    setDefaultPaymentMethod(input: $input) {
      items {
        id
        brand
        last4
        billing_details {
          email
          name
          phone
          address {
            line1
            city
            country
            line2
            postal_code
            state
          }
        }
        exp_month
        exp_year
        default
      }
    }
  }
`;
