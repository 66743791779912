import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import hedgehogInactive from '../../assets/icons/hedgehog-gull-gray.png';
import hedgehogActive from '../../assets/icons/hedgehog-pacific-blue.png';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import { URL_HELPERS } from '../../utils/url';
import { Icon, SignInForm } from '..';
import './styles.scss';

const NavbarBottom = ({ isLoggedIn }) => {
  const { pathname } = useLocation();

  const isInDashboardPage = pathname === URL_HELPERS.dashboard;

  const { state } = useContext(UserContext);
  const { showModal } = useContext(ModalContext);

  const [icon, setIcon] = useState(hedgehogInactive);

  useEffect(() => {
    setIcon(isInDashboardPage ? hedgehogActive : hedgehogInactive);
  }, [isInDashboardPage]);

  const handleMouseOver = () => {
    if (!isInDashboardPage) {
      setIcon(hedgehogActive);
    }
  };

  const handleMouseOut = () => {
    if (!isInDashboardPage) {
      setIcon(hedgehogInactive);
    }
  };

  const handleClick = (event) => {
    showModal({
      title: 'Sign In',
      content: () => SignInForm({ event })
    });
  };

  return (
    <nav className="navbar-bottom">
      <NavLink to={URL_HELPERS.index} exact className="link">
        <Icon icon="search" />
        <span className="label">Find</span>
      </NavLink>
      {isLoggedIn ? (
        <>
          <NavLink to={URL_HELPERS.myItems} className="link">
            <Icon icon="format_list_bulleted" />
            <span className="label">Listings</span>
          </NavLink>
          <NavLink
            to={URL_HELPERS.dashboard}
            className="link dashboard"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <img src={icon} className="icon hedgehog" />
            <span className="label">Ukuu</span>
          </NavLink>
          <NavLink to={URL_HELPERS.messages} className="link inbox">
            <Icon icon="chat_bubble_outline" />
            <span className="label">Inbox</span>
            {!!state.unreadConversations.length && (
              <span className="alert" data-testid="alert" />
            )}
          </NavLink>
          <NavLink to={URL_HELPERS.profile} className="link">
            <Icon icon="person_outline" />
            <span className="label">Account</span>
          </NavLink>
        </>
      ) : (
        <>
          <button
            type="button"
            className="link button"
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <Icon icon="login" />
            <span className="label">Log In</span>
          </button>
          <a
            href="http://help.ukuu.io"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <Icon icon="help_outline" />
            <span className="label">Help</span>
          </a>
        </>
      )}
    </nav>
  );
};

NavbarBottom.propTypes = {
  isLoggedIn: PropTypes.bool
};

NavbarBottom.defaultProps = {
  isLoggedIn: false
};

export default NavbarBottom;
