import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  ButtonIcon,
  DropdownMenu,
  Header,
  UserCard
} from '../../../../components';
import { useWindowDimensions } from '../../../../hooks';
import './styles.scss';

const ConversationHeader = ({
  avatar,
  itemName,
  onClick,
  to,
  transactionStatusBadgeProps,
  userName
}) => {
  const { windowWidth } = useWindowDimensions();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleClick = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  const handleOptionClick = () => setIsMenuOpen(false);
  const handleOutsideClick = () => setIsMenuOpen(false);

  return (
    <Header className="conversation-header">
      {windowWidth <= 768 && (
        <Header.Left>
          <ButtonIcon icon="arrow_back" onClick={onClick} />
        </Header.Left>
      )}
      <Header.Center>
        <UserCard avatar={avatar} size="48" name={userName}>
          <div className="names-status-container">
            <div className="user-name-status-container">
              <span className="user-name">{userName}</span>
              <Badge {...transactionStatusBadgeProps} />
            </div>
            <span className="item-name">{itemName}</span>
          </div>
        </UserCard>
      </Header.Center>
      {windowWidth <= 1024 && (
        <Header.Right>
          <DropdownMenu onOutsideClick={handleOutsideClick}>
            <DropdownMenu.Toggle>
              <ButtonIcon icon="info" onClick={handleToggleClick} />
            </DropdownMenu.Toggle>
            {isMenuOpen && (
              <DropdownMenu.Content>
                <Link
                  to={to}
                  className="option link"
                  onClick={handleOptionClick}
                >
                  Transaction Details
                </Link>
                <span className="option" onClick={() => {}}>
                  Report User
                </span>
              </DropdownMenu.Content>
            )}
          </DropdownMenu>
        </Header.Right>
      )}
    </Header>
  );
};

ConversationHeader.propTypes = {
  avatar: PropTypes.string,
  itemName: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  transactionStatusBadgeProps: PropTypes.object,
  userName: PropTypes.string
};

ConversationHeader.defaultProps = {
  avatar: '',
  itemName: '',
  onClick: () => {},
  to: '',
  transactionStatusBadgeProps: {},
  userName: ''
};

export default ConversationHeader;
