import PropTypes from 'prop-types';
import React from 'react';
import { ButtonText, FormInput } from '../../../../../../components';
import './styles.scss';

const EmailAddressForm = ({ form, isLoading, onSubmit }) => {
  return (
    <form className="email-address-form" onSubmit={onSubmit}>
      <FormInput
        label="Email"
        name="email"
        required
        control={form.control}
        error={form.formState.errors.email}
        data-testid="email"
      />
      <ButtonText
        color="primary"
        loading={isLoading}
        data-testid="emailSubmitBtn"
      >
        Save
      </ButtonText>
    </form>
  );
};

EmailAddressForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

EmailAddressForm.defaultProps = {
  form: null,
  isLoading: false,
  onSubmit: () => {}
};

export default EmailAddressForm;
