/**
 * @param {String} dateString
 * @param {String} separator
 */
const parseDate = (dateString, separator = '/') => {
  const [day, month, year] = dateString.split(separator);
  return new Date(Date.UTC(year, month - 1, day));
};

export default parseDate;
