import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const Confirm = (props) => {
  console.log(props);

  useEffect(() => {}, []);

  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          hasConfirmed: true
        }
      }}
    />
  );
};

export default Confirm;
