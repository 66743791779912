import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';
import './styles.scss';

const ItemSuggestion = ({ name, onClick }) => {
  return (
    <div className="item-suggestion" onClick={() => onClick(name)}>
      <Icon icon="search" />
      <span className="name">{name}</span>
    </div>
  );
};

ItemSuggestion.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

ItemSuggestion.defaultProps = {
  onClick: () => {}
};

export default ItemSuggestion;
