import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import stripeImage from '../../../../../../assets/images/stripe.png';
import {
  ButtonText,
  EmptyListPlaceholder,
  Icon,
  Loader
} from '../../../../../../components';
import './styles.scss';

const PayoutAccountCard = ({ connected, loading, onClick }) => {
  const classNames = clsx('payout-account-card', connected && '-connected');

  return (
    <div className={classNames}>
      {connected ? (
        <>
          <EmptyListPlaceholder type="payment method" icon="link">
            <img src={stripeImage} className="stripe-image" />
          </EmptyListPlaceholder>
          <div className="info">
            <span className="name">Stripe Account</span>
            <span className="type">(For payouts)</span>
            <span className="status">Connected</span>
            <ButtonText variant="outlined">
              <Icon icon="link_off" /> Disconnect
            </ButtonText>
          </div>
        </>
      ) : (
        <>
          <img src={stripeImage} className="image" />
          <div className="info">
            <span className="type">(For payouts)</span>
            <p className="text">
              Neque porro quisquam est qui dolorem ipsum. This is a long text
              placeholder max of 2 lines.
            </p>
            <ButtonText color="primary" onClick={onClick}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Icon icon="link" /> Connect
                </>
              )}
            </ButtonText>
          </div>
        </>
      )}
    </div>
  );
};

PayoutAccountCard.propTypes = {
  connected: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func
};

PayoutAccountCard.defaultProps = {
  connected: false,
  loading: false,
  onClick: () => {}
};

export default PayoutAccountCard;
