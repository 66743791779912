import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Loader } from '../../components';
import { AuthenticatedRoute } from '../../components/Routes';
import UserContext from '../../contexts/UserContext';
import { Custom as CustomLayout } from '../../layouts';
import { APP_URL_SCHEMES, URL_HELPERS } from '../../utils/url';
import useProfile from './useProfile';
import './styles.scss';
import { ProfileSidebar } from './components';
import {
  Accounts,
  ItemsAndReviews,
  PaymentInformation,
  SignInInformation
} from './subpages';

const Profile = ({ history }) => {
  const { setUserData } = useContext(UserContext);
  const {
    isUserProfileLoading,
    userProfileData: userData,
    isUserItemsLoading,
    items,
    updateUser
  } = useProfile();
  const [userProfileData, setUserProfileData] = useState(userData);

  useEffect(() => {
    if (userData) {
      setUserProfileData(userData);
    }
  }, [userData]);

  if (isUserProfileLoading || isUserItemsLoading) {
    return <Loader />;
  }

  return (
    <div className="profile-page">
      <main className="body">
        <ProfileSidebar
          userProfileData={userProfileData}
          setUserProfileData={setUserProfileData}
          setUserData={setUserData}
          updateUser={updateUser}
        >
          <Switch>
            <AuthenticatedRoute
              component={ItemsAndReviews}
              componentProps={{
                items,
                history,
                reviews: userProfileData.reviews
                  ? userProfileData.reviews.items
                  : []
              }}
              exact
              layout={CustomLayout}
              path={URL_HELPERS.profile}
            />
            <AuthenticatedRoute
              component={Accounts}
              exact
              layout={CustomLayout}
              path={APP_URL_SCHEMES.accounts}
            />
            <AuthenticatedRoute
              component={SignInInformation}
              componentProps={{ user: userProfileData }}
              exact
              layout={CustomLayout}
              path={APP_URL_SCHEMES.loginInformation}
            />
            <AuthenticatedRoute
              component={PaymentInformation}
              exact
              layout={CustomLayout}
              path={APP_URL_SCHEMES.paymentInformation}
            />
          </Switch>
        </ProfileSidebar>
      </main>
    </div>
  );
};

Profile.propTypes = {
  history: PropTypes.object
};

Profile.defaultProps = {
  history: null
};

export default Profile;
