import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { KeywordTagList } from '../..';
import { FormInputError, FormLabel } from '..';

const FormKeywordInput = ({
  className,
  control,
  error,
  handleChange,
  keywordTags,
  label,
  name,
  noController,
  required,
  type,
  ...props
}) => {
  const [keywords, setKeywords] = useState(keywordTags);

  const removeKeywordTag = (index, field) => {
    setKeywords(keywords.filter((_, i) => index !== i));
    if (noController) {
      handleChange(keywords.filter((_, i) => index !== i));
    } else {
      field.onChange(keywords.filter((_, i) => index !== i));
    }
  };

  const handleKeyPress = (key, target, field) => {
    if (key === 'Enter' && target.value) {
      const duplicate = keywords.find((keyword) => target.value === keyword);
      if (!duplicate) {
        if (target.value.includes(',') || target.value.includes(';')) {
          target.value
            .split(/[,;]+/g)
            .filter(Boolean)
            .forEach((word) => {
              if (!keywords.find((keyword) => word === keyword)) {
                setKeywords((prevKeywords) => [word, ...prevKeywords]);
              }
            });
        } else {
          setKeywords([target.value, ...keywords]);
        }
        if (field) {
          field.onChange([target.value, ...keywords]);
        } else {
          handleChange([target.value, ...keywords]);
        }
      }
      target.value = '';
    }
  };

  return (
    <div className={`form-input-container ${className} `}>
      {label && <FormLabel text={label} required={required} />}
      {noController ? (
        <>
          <input
            className={['input', error ? 'error' : ''].join(' ')}
            type={type}
            data-testid="textInput"
            name={name}
            onKeyPress={({ key, target }) => {
              handleKeyPress(key, target);
            }}
            {...props}
          />
          <KeywordTagList onRemove={removeKeywordTag} keywords={keywords} />
        </>
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <input
                className={['input', error ? 'error' : ''].join(' ')}
                type={type}
                data-testid="textInput"
                onKeyPress={({ key, target }) => {
                  handleKeyPress(key, target, field);
                }}
                {...props}
              />
              <KeywordTagList
                onRemove={removeKeywordTag}
                keywords={keywords}
                field={field}
              />
            </>
          )}
        />
      )}
      {error && <FormInputError error={error} data-testid={`${name}Error`} />}
    </div>
  );
};

FormKeywordInput.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object,
  error: PropTypes.object,
  handleChange: PropTypes.func,
  keywordTags: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noController: PropTypes.bool,
  register: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string
};

FormKeywordInput.defaultProps = {
  className: '',
  control: {},
  error: null,
  handleChange: () => {},
  keywordTags: [],
  label: '',
  noController: false,
  register: () => {},
  required: false,
  type: 'text'
};

export default FormKeywordInput;
