import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckboxGroup, FormInput, FormTitle } from '../../../../components';
import './styles.scss';

const Availability = () => {
  const { control, errors, getValues } = useFormContext();

  return (
    <div className="availability">
      <FormTitle title="Availability" />
      <FormInput
        control={control}
        data-testid="minRentPeriod"
        error={errors.minRentPeriod}
        label="Minimum rental"
        name="minRentPeriod"
        type="number"
      />
      <FormInput
        control={control}
        data-testid="maxRentPeriod"
        error={errors.maxRentPeriod}
        label="Maximum rental"
        name="maxRentPeriod"
        placeholder="Enter max rental"
        type="number"
      />
      <CheckboxGroup
        checkboxes={getValues('daysAvailable')}
        control={control}
        error={errors.daysAvailable}
        label="Days available"
        name="daysAvailable"
        required
      />
    </div>
  );
};

export default Availability;
