import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import RDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ButtonIcon from '../../../ButtonIcon';
import ButtonText from '../../../ButtonText';
import { FormGroup } from '../../../Forms';
import SearchField from '../../SearchField';

const DatePicker = ({
  endDate,
  endDateValue,
  onBackClick,
  onCancelClick,
  onChange,
  onCloseClick,
  onOKClick,
  startDate,
  startDateValue,
  ...props
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current || endDate) {
      //:: Focus <input /> element to enable enter to next step
      inputRef.current.focus();
    }
  }, [inputRef.current, endDate]);

  return (
    <>
      <div className="top">
        <div className="navigation">
          <ButtonIcon icon="arrow_back" onClick={onBackClick} />
          <span className="text">Select rental dates</span>
          <ButtonIcon icon="close" onClick={onCloseClick} />
        </div>
        <div className="dates">
          <SearchField
            innerRef={inputRef}
            placeholder="Star Date"
            readOnly
            {...props}
            value={startDateValue}
          />
          <span className="dash">-</span>
          <SearchField
            innerRef={inputRef}
            placeholder="End Date"
            readOnly
            {...props}
            value={endDateValue}
          />
        </div>
        <RDatePicker
          calendarStartDay={1}
          className="date-container"
          selected={startDate}
          onChange={onChange}
          minDate={new Date()}
          monthsShown={1}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
        />
      </div>
      <div className="bottom">
        <FormGroup>
          <ButtonText color="primary" onClick={onOKClick}>
            Add Dates
          </ButtonText>
          {/* <button onClick={() => onClick(inputRef)} className="ok"> */}
          <ButtonText variant="outlined" onClick={onCancelClick}>
            Reset
          </ButtonText>
        </FormGroup>
      </div>
    </>
  );
};

DatePicker.propTypes = {
  endDate: PropTypes.string.isRequired,
  endDateValue: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onOKClick: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  startDateValue: PropTypes.string.isRequired
};

export default DatePicker;
