import React from 'react';
import PropTypes from 'prop-types';

import { ModalProvider } from '../contexts/ModalContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import AuthContext from '../contexts/AuthContext';
import UserContext from '../contexts/UserContext';
import { Modal } from '../components';

import { ToastProvider } from 'react-toast-notifications';
import { MemoryRouter } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_sample');

const MockProvider = ({ children, routerProps, authProps, userProps }) => {
  return (
    <Elements stripe={stripePromise}>
      <MemoryRouter {...routerProps}>
        <AuthContext.Provider value={{ ...authProps }}>
          <ModalProvider>
            <UserContext.Provider value={{ ...userProps }}>
              <ToastProvider>
                <Modal />
                {children}
              </ToastProvider>
            </UserContext.Provider>
          </ModalProvider>
        </AuthContext.Provider>
      </MemoryRouter>
    </Elements>
  );
};

MockProvider.propTypes = {
  children: PropTypes.node.isRequired,
  modalProps: PropTypes.object,
  authProps: PropTypes.object,
  userProps: PropTypes.object,
  routerProps: PropTypes.object
};

MockProvider.defaultProps = {
  modalProps: {},
  authProps: {
    state: {
      accessToken: ''
    }
  },
  userProps: {},
  routerProps: {}
};

export default MockProvider;
