import PropTypes from 'prop-types';
import React from 'react';

const ToastNotificationContainer = ({ children }) => {
  return <div className="toast-notification-container">{children}</div>;
};

ToastNotificationContainer.propTypes = {
  children: PropTypes.node
};

ToastNotificationContainer.defaultProps = {
  children: null
};

export default ToastNotificationContainer;
