import { useEffect } from 'react';
import { getUserProfile as getUserProfileQuery } from '../../graphql/customQueries';
import { getUserThings as getUserThingsQuery } from '../../graphql/queries';
import { useLazyQuery, useQuery } from '../../hooks/api';

const useUserProfile = (publicId) => {
  // *NOTE: for querying user data and reviews
  const { loading: getUserProfileLoading, data: getUserProfileData } = useQuery(
    getUserProfileQuery,
    {
      variables: {
        publicID: publicId
      }
    }
  );

  // *NOTE: for querying user things/items
  const [
    getUserThings,
    { loading: getUserThingsLoading, data: getUserThingsData }
  ] = useLazyQuery(getUserThingsQuery);

  useEffect(() => {
    if (getUserProfileData?.getUserProfile) {
      const getThings = async () => {
        await getUserThings({
          variables: {
            userID: getUserProfileData.getUserProfile.items[0].id
          }
        });
      };
      getThings();
    }
  }, [getUserProfileData]);

  return {
    isUserProfileLoading: getUserProfileLoading,
    userProfileData: getUserProfileData?.getUserProfile.items[0] || null,
    isUserItemsLoading: getUserThingsLoading,
    userItemsData: getUserThingsData?.getUserThings.items || []
  };
};

export default useUserProfile;
