import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const Content = ({ children, className }) => {
  const classNames = clsx('content', className);

  return <div className={classNames}>{children}</div>;
};

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Content.defaultProps = {
  children: null,
  className: ''
};

export default Content;
