import { Auth, Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { createUser as createUserMutation } from '../../graphql/mutations';
import { getUserData as getUserDataQuery } from '../../graphql/queries';
import { useLazyQuery, useMutation } from '../../hooks';

const Signin = ({ history }) => {
  const [getUserData] = useLazyQuery(getUserDataQuery);
  const [createUser] = useMutation(createUserMutation);

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'cognitoHostedUI':
          const currentUser = await Auth.currentUserPoolUser();
          const {
            getUserData: { items }
          } = await getUserData({
            variables: {
              sub: currentUser.attributes.sub
            }
          });
          console.log(items);

          if (!items?.length) {
            await createUser({
              variables: {
                input: {
                  email: currentUser.attributes.email,
                  firstName: currentUser.attributes.given_name,
                  lastName: currentUser.attributes.family_name,
                  sub: currentUser.attributes.sub
                }
              }
            });
          }
      }
    });

    if (/\?error_description/.test(history.location.search)) {
      history.push({
        pathname: '/',
        state: {
          authError: true
        }
      });
    }
  }, []);

  return null;
};

Signin.propTypes = {
  history: PropTypes.object.isRequired
};

export default Signin;
