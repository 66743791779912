/**
 * @param {Date} startDate
 * @param {("HOURS"|"DAYS"|"WEEKS"|"MONTHS")} periodType
 * @param {Number} minRentPeriod
 */
const getMinRentDate = (periodType, minRentPeriod, startDate) => {
  let toSeconds;

  switch (periodType) {
    case 'HOURS':
      toSeconds = 3600;
      break;
    case 'DAYS':
      toSeconds = 86400;
      break;
    case 'WEEKS':
      toSeconds = 604800;
      break;
    case 'MONTHS':
      toSeconds = 2629800;
      break;
    default:
      toSeconds = 86400;
      break;
  }

  let minimumRentalDate;
  if (startDate) {
    minimumRentalDate = new Date(
      startDate.getTime() + 1000 * toSeconds * minRentPeriod
    );
  } else {
    minimumRentalDate = new Date(
      new Date().getTime() + 1000 * toSeconds * minRentPeriod
    );
  }
  return minimumRentalDate;
};

export default getMinRentDate;
