// :: ModalContext
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CHANGE_MODAL_TITLE = 'CHANGE_MODAL_TITLE';
export const UPDATE_MODAL = 'UPDATE_MODAL';

// :: AuthContext
export const GET_AUTH_TOKENS = 'GET_AUTH_TOKENS';
export const SIGN_OUT = 'SIGN_OUT';

// :: UserContext
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
