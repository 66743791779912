import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../Icon';
import './styles.scss';

const NearbyLocation = ({ label, onClick }) => {
  return (
    <div className="nearby-location" onClick={onClick}>
      <Icon icon="place" className="place" />
      <span className="location">{label}</span>
    </div>
  );
};

NearbyLocation.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default NearbyLocation;
