import { useState, useEffect } from 'react';

const useWindowDimensions = () => {
  const hasWindow = typeof window !== 'undefined';
  const [windowHeight, setWindowHeight] = useState(
    hasWindow ? window.innerHeight : null
  );
  const [windowWidth, setWindowWidth] = useState(
    hasWindow ? window.innerWidth : null
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const handleResize = () => {
    if (hasWindow) {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    }
  };

  return { windowHeight, windowWidth };
};

export default useWindowDimensions;
