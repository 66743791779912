export const PeriodType = {
  HOURS: '/ hour',
  DAYS: '/ day',
  WEEKS: '/ week',
  MONTHS: '/ month'
};

export const UnitPeriod = {
  HOURS: {
    SINGULAR: 'hour',
    PLURAL: 'hours'
  },
  DAYS: {
    SINGULAR: 'day',
    PLURAL: 'days'
  },
  WEEKS: {
    SINGULAR: 'week',
    PLURAL: 'weeks'
  },
  MONTHS: {
    SINGULAR: 'month',
    PLURAL: 'months'
  }
};

export const PeriodTypeSelection = {
  HOURS: 'Hourly',
  DAYS: 'Daily',
  WEEKS: 'Weekly',
  MONTHS: 'Monthly'
};

export const Documents = {
  IDENTIFICATION: 'Identification (license, ID, passport, etc)',
  OPERATORS_LICENSE: "Operator's License",
  INSURANCE: 'Proof of Insurance',
  OTHER: 'Other'
};

export const TransactionStatusUI = {
  REQUESTED: {
    label: 'Requested',
    badgeOptions: {
      icon: 'assignment_returned',
      color: 'primary'
    }
  },
  ACCEPTED: {
    label: 'Accepted',
    badgeOptions: {
      icon: 'thumb_up',
      color: 'success'
    }
  },
  DECLINED: {
    label: 'Declined',
    badgeOptions: {
      icon: 'thumb_down',
      color: 'danger'
    }
  },
  INRENT: {
    label: 'In-rent',
    badgeOptions: {
      icon: 'add_shopping_cart',
      color: 'secondary'
    }
  },
  COMPLETED: {
    label: 'Completed',
    badgeOptions: {
      icon: 'done',
      color: 'complete'
    }
  },
  CANCELLED: {
    label: 'Cancelled',
    badgeOptions: {
      icon: 'event_busy',
      color: 'cancelled'
    }
  }
};

export const SystemMessageUI = {
  ITEM_REQUEST: {
    label: 'Item Requested',
    ownerMessage: 'Your item has been requested.',
    renterMessage: 'You requested an item.'
  },
  ACCEPT_REQUEST: {
    label: 'Request Accepted',
    ownerMessage: 'You accepted a request.',
    renterMessage: 'Your request has been accepted.'
  },
  DECLINE_REQUEST: {
    label: 'Request Declined',
    ownerMessage: 'You declined a request.',
    renterMessage: 'Your request has been declined.'
  },
  GENERATE_START_EXCHANGE_CODE: {
    label: 'Start exchange code generated',
    ownerMessage: '',
    renterMessage: 'The owner would like to start the booking.'
  },
  IN_RENT: {
    label: 'Item in rent',
    ownerMessage: 'Your item is currently in rent.',
    renterMessage: 'Your currently renting an item.'
  },
  LATE_DROPOFF_REMINDER: {
    label: 'Late dropoff reminder',
    ownerMessage: 'Booking is going to expire!',
    renterMessage: 'Booking is going to expire!'
  },
  GENERATE_END_EXCHANGE_CODE: {
    label: 'End exchange code generated',
    ownerMessage: '',
    renterMessage: 'The owner would like to complete the booking.'
  },
  COMPLETE_RENT: {
    label: 'Rent completed',
    ownerMessage: 'The rent for your item has completed.',
    renterMessage: 'The rent for the item you requested has completed.'
  },
  LATE_DROPOFF_WARNING: {
    label: 'Late dropoff warning',
    ownerMessage:
      'Return is going to be late! Late returns are charged at 1.5x the normal rental rate.',
    renterMessage:
      'Return is going to be late! Late returns are charged at 1.5x the normal rental rate.'
  },
  CANCEL_RENT: {
    label: 'Rent cancelled',
    ownerMessage: 'The rent for your item has been cancelled.',
    renterMessage: 'The rent for the item you requested has been cancelled.'
  },
  ITEM_REVIEW: {
    label: 'Item review',
    ownerMessage: '',
    renterMessage: 'Leave an item review.'
  },
  USER_REVIEW: {
    label: 'User review',
    ownerMessage: 'Leave a user review.',
    renterMessage: 'Leave a user review.'
  }
};

export const StripeErrorCode = {
  SETUP_INTENT_AUTH_FAILED: 'setup_intent_authentication_failure',
  INVALID_EXPIRY_YEAR_PAST: 'invalid_expiry_year_past',
  INCOMPLETE_ZIP: 'incomplete_zip',
  INCOMPLETE_CVC: 'incomplete_cvc',
  CARD_DECLINED: 'card_declined',
  INCOMPLETE_NUMBER: 'incomplete_number'
};

export const CognitoErrorCode = {
  USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  LIMIT_EXCEEDED_EXCEPTION: 'LimitExceededException',
  CODE_MISMATCH_EXCEPTION: 'CodeMismatchException'
};
