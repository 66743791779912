import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import {
  EmptyListPlaceholder,
  Header,
  ItemCardList,
  Loader,
  Search,
  SignInForm
} from '../../components';
import AuthContext from '../../contexts/AuthContext';
import ModalContext from '../../contexts/ModalContext';
import useHome from './useHome';
import './styles.scss';
import { TopCategories, WrapperComponent } from './components';
import { useWindowDimensions } from '../../hooks';
import { URL_HELPERS } from '../../utils/url';

const Home = ({ history }) => {
  const {
    categoryIcons,
    isItemsLoading,
    isCategoryLoading,
    category,
    categoryItems,
    onCategoryClick,
    onShowAllClick,
    categoryList
  } = useHome();

  const {
    state: { accessToken }
  } = useContext(AuthContext);

  const { showModal } = useContext(ModalContext);

  const { windowWidth } = useWindowDimensions();
  useEffect(() => {
    if (history.location.state?.hasConfirmed) {
      history.replace();
      showModal({
        title: 'Sign In',
        content: () =>
          SignInForm({
            location: history.location,
            statusMessage:
              'You have confirmed your email address. You can now sign in.',
            statusType: 'success'
          })
      });
    } else if (history.location.state?.hasResetPassword) {
      history.replace();
      showModal({
        title: 'Sign In',
        content: () =>
          SignInForm({
            location: history.location,
            statusMessage:
              'You have updated your password. You can now sign in.',
            statusType: 'success'
          })
      });
    } else if (history.location.state?.notLoggedIn) {
      showModal({
        title: 'Sign In',
        content: () =>
          SignInForm({
            returnUrl: history.location.state?.returnUrl,
            history
          })
      });
    } else if (history.location.state?.authError) {
      showModal({
        title: 'Sign In',
        content: () =>
          SignInForm({
            statusMessage:
              'There was an error signing in to your account. Please try after some time.',
            statusType: 'error'
          })
      });
    }
  }, []);

  const classNames = clsx('items', categoryItems.length === 0 && '-empty');

  const handleClick = (event) => {
    if (accessToken) {
      history.push({
        pathname: URL_HELPERS.add,
        state: {
          thing: {
            name: ''
          }
        }
      });
    } else {
      showModal({
        title: 'Sign In',
        content: () => SignInForm({ event })
      });
    }
  };

  if (isItemsLoading) {
    return <Loader />;
  }

  return (
    <div className="home-page" data-testid="homePage">
      <Header>
        {windowWidth < 768 ? (
          <Header.Center>
            <Search history={history} />
            <TopCategories
              categories={categoryList}
              icons={categoryIcons}
              onCategoryClick={onCategoryClick}
              onShowAllClick={onShowAllClick}
              selectedCategory={category}
            />
            {/* <LendYours /> */}
            {/* <TopCategories */}
            {/*   categories={categoryList} */}
            {/*   icons={categoryIcons} */}
            {/*   onCategoryClick={onCategoryClick} */}
            {/*   onShowAllClick={onShowAllClick} */}
            {/*   selectedCategory={category} */}
            {/* /> */}
          </Header.Center>
        ) : (
          <Header.Center>
            <WrapperComponent handleClick={handleClick} />
            <TopCategories
              categories={categoryList}
              icons={categoryIcons}
              onCategoryClick={onCategoryClick}
              onShowAllClick={onShowAllClick}
              selectedCategory={category}
            />
          </Header.Center>
        )}
      </Header>
      <div className="body">
        {/* {!accessToken && ( */}
        {/*   <div className="guest-user"> */}
        {/*     <div className="text-container"> */}
        {/*       <h2>Lend yours!</h2> */}
        {/*       <p> */}
        {/*         By listing and lending your own items, you can earn on Ukuu! */}
        {/*       </p> */}
        {/*     </div> */}
        {/*     <ButtonText color="primary" onClick={handleClick}> */}
        {/*       Lend my own items */}
        {/*     </ButtonText> */}
        {/*     <p>or browse these hot items</p> */}
        {/*   </div> */}
        {/* )} */}

        <div className={classNames} data-testid="itemsContainer">
          {isCategoryLoading ? (
            <Loader />
          ) : categoryItems.length > 0 ? (
            <ItemCardList history={history} things={categoryItems} />
          ) : (
            <EmptyListPlaceholder
              type="item"
              icon="search"
              text="No listings found"
              supportingText="This category does not have any listings."
            />
          )}
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.object
};

Home.defaultProps = {
  history: null
};

export default Home;
