import * as yup from 'yup';

const editPaymentMethodValidation = yup
  .object()
  .shape({
    fullName: yup.string().required('Full name is required'),
    expMonth: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.number()
      })
      .test('state', null, function (obj) {
        if (!obj.value || !obj.label) {
          return this.createError({
            message: 'Expiration month is required'
          });
        }

        return true;
      })
      .required('Expiration month is required'),
    expYear: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.number()
      })
      .test('state', null, function (object) {
        if (!object.value || !object.label) {
          return this.createError({ message: 'Expiration year is required' });
        }
        return true;
      })
      .required('Expiration year is required'),
    state: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string()
      })
      .test('state', null, function (obj) {
        if (!obj.value || !obj.label) {
          return this.createError({
            message: 'State is required'
          });
        }
        return true;
      })
      .required('State is required'),
    city: yup.string().required('City is required'),
    address: yup.string().required('Address is required'),
    postalCode: yup
      .string()
      .max(8, 'Postal code must not be greater than eight digits')
      .required('Postal code is required')
  })
  .test('haxExpired', null, function (obj) {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    if (
      obj.expYear.value <= currentYear &&
      obj.expMonth.value <= currentMonth
    ) {
      return this.createError({
        message: 'This card has expired',
        path: 'expirationDate'
      });
    }
    return true;
  });

export default editPaymentMethodValidation;
