import { intervalToDuration } from 'date-fns';

const getElapsedDate = (start = new Date(), end = new Date()) => {
  const duration = intervalToDuration({
    start: new Date(start),
    end: new Date(end)
  });
  const weeks = Math.trunc(duration.days / 7);

  if (duration.years > 0) return `${duration.years}y`;
  if (weeks > 0) return `${weeks}w`;
  if (duration.days > 0) return `${duration.days}d`;
  if (duration.hours > 0) return `${duration.hours}h`;
  return `${duration.minutes}m`;
};

export default getElapsedDate;
