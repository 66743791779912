import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '../../components';
import './styles.scss';

const PaymentMethodCard = ({
  brand,
  checked,
  lastFourDigits,
  onChange,
  onClick,
  value
}) => {
  const classNames = clsx('payment-method-card', checked && '-default');

  const handleChange = (event) => {
    event.persist();
    onChange(event.target.value);
  };

  return (
    <div className={classNames} onClick={onClick}>
      <div className="icon-container">
        <Icon icon="credit_card" className="credit-card" />
      </div>
      <div className="info">
        <span className="brand">{brand}</span>
        <span className="last-four-digits">
          Card number ending in {lastFourDigits}
        </span>
      </div>
      {value && (
        <input
          type="radio"
          name="radioGroup"
          className="radio-button"
          checked={checked}
          value={value}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

PaymentMethodCard.propTypes = {
  brand: PropTypes.string,
  checked: PropTypes.bool,
  lastFourDigits: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string
};

PaymentMethodCard.defaultProps = {
  brand: '',
  checked: false,
  lastFourDigits: '',
  onChange: () => {},
  onClick: () => {},
  value: ''
};

export default PaymentMethodCard;
