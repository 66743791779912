import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Bio = ({ bio }) => {
  return <p className="bio">{bio}</p>;
};

Bio.propTypes = {
  bio: PropTypes.string
};

Bio.defaultProps = {
  bio: ''
};

export default Bio;
