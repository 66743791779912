import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '../Avatar';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import { URL_HELPERS } from '../../utils/url';

const ProfileHeader = ({ avatar, children, name }) => {
  return (
    <div className="profile-header">
      <NavLink to={URL_HELPERS.profile}>
        <Avatar avatar={avatar} size="96" name={name} />
      </NavLink>

      <span className="name">{name}</span>
      {children}
    </div>
  );
};

ProfileHeader.propTypes = {
  avatar: PropTypes.object,
  children: PropTypes.node,
  name: PropTypes.string
};

ProfileHeader.defaultProps = {
  avatar: {},
  children: null,
  name: ''
};

export default ProfileHeader;
