import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const UserMessage = ({ date, message, name, time, variant }) => {
  const classNames = clsx('user-message', `-${variant}`);
  return (
    <div className={classNames}>
      {date && <span className="date">{date}</span>}
      <div className="details">
        <div className="name-time-container">
          {name && <span className="name">{name}</span>}
          <span className="time">{time}</span>
        </div>
        <p className="message">{message}</p>
      </div>
    </div>
  );
};

UserMessage.propTypes = {
  date: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  time: PropTypes.string,
  variant: PropTypes.oneOf(['receiver', 'sender'])
};

UserMessage.defaultProps = {
  date: '',
  message: '',
  name: '',
  time: '',
  variant: 'sender'
};

export default UserMessage;
