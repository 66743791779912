import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import {
  AcceptExchange,
  ButtonText,
  Confirmation,
  Loader,
  ReviewForm
} from '../../../../components';
import { SystemMessageUI } from '../../../../constants/enumTypes';
import ModalContext from '../../../../contexts/ModalContext';
import UserContext from '../../../../contexts/UserContext';
import {
  postThingReview as postThingReviewMutation,
  postUserReview as postUserReviewMutation,
  updateTransactionStatus as updateTransactionStatusMutation
} from '../../../../graphql/mutations';
import { useMutation } from '../../../../hooks';
import {
  PeriodType,
  SystemMessage,
  TransactionStatus
} from '../../../../models';
import EarlyDropOff from '../../../Transactions/components/EarlyDropOff';
import SysMessage from '../SystemMessage';
import UserMessage from '../UserMessage';

const MessageDetails = ({
  handleUpdateTransaction,
  hasReviewed,
  message,
  targetUser,
  thing,
  transaction,
  showDate
}) => {
  const { state } = useContext(UserContext);
  const { showModal } = useContext(ModalContext);

  const [
    updateTransactionStatus,
    { data: updateTransactionStatusData, loading, errors }
  ] = useMutation(updateTransactionStatusMutation);

  const [postThingReview, { data: postThingReviewData }] = useMutation(
    postThingReviewMutation
  );

  const [postUserReview, { data: postUserReviewData }] = useMutation(
    postUserReviewMutation
  );

  const isRenter = thing.userID !== state.id;

  const handleUpdate = async (transactionStatus) => {
    try {
      await updateTransactionStatus({
        variables: {
          input: {
            id: transaction.id,
            _version: transaction._version,
            hasRead: false,
            transactionStatus
          }
        }
      });
    } catch (error) {
      console.error('updateTransactionStatus error: ', error);
      console.error('updateTransactionStatus errors: ', errors);
    }
  };

  useEffect(() => {
    if (updateTransactionStatusData) {
      handleUpdateTransaction(
        updateTransactionStatusData.updateTransactionStatus
      );
    }
  }, [updateTransactionStatusData]);

  const completeRentalEarlyModal = () => {
    showModal({
      title: 'Complete Rental Early?',
      content: () => EarlyDropOff({ transaction })
    });
  };

  const acceptExchangeModal = () => {
    showModal({
      title: 'Verify Exchange Code',
      content: () => AcceptExchange({ transaction })
    });
  };

  const handleAcceptItemRequest = () => {
    handleUpdate(TransactionStatus.ACCEPTED);
  };

  const handleDeclineItemRequest = () => {
    showModal({
      title: 'Decline Request?',
      content: () =>
        Confirmation({
          message: 'Are you sure?',
          confirmAction: () => {
            handleUpdate(TransactionStatus.DECLINED);
          }
        })
    });
  };

  const handleItemReview = () => {
    showModal({
      title: 'Review your experience',
      content: () =>
        ReviewForm({
          transaction,
          postReview: postThingReview
        })
    });
  };

  const handleUserReview = () => {
    showModal({
      title: 'Review your experience',
      content: () =>
        ReviewForm({
          user: targetUser,
          postReview: postUserReview
        })
    });
  };

  const systemMessageContent = () => {
    return isRenter
      ? SystemMessageUI[message.systemMessage].renterMessage
      : SystemMessageUI[message.systemMessage].ownerMessage;
  };

  const renderButtons = () => {
    switch (message.systemMessage) {
      case SystemMessage.ITEM_REQUEST:
        if (isRenter) return null;

        if (transaction.transactionStatus !== TransactionStatus.REQUESTED)
          return null;

        return loading ? (
          <Loader />
        ) : (
          <>
            <ButtonText color="primary" onClick={handleAcceptItemRequest}>
              Accept
            </ButtonText>
            <ButtonText
              color="error"
              variant="outlined"
              onClick={handleDeclineItemRequest}
            >
              Decline
            </ButtonText>
          </>
        );
      case SystemMessage.GENERATE_START_EXCHANGE_CODE:
        if (!isRenter) return null;

        if (transaction.transactionStatus !== TransactionStatus.ACCEPTED)
          return null;

        return loading ? (
          <Loader />
        ) : (
          <ButtonText color="primary" onClick={acceptExchangeModal}>
            Accept
          </ButtonText>
        );
      case SystemMessage.GENERATE_END_EXCHANGE_CODE:
        if (!isRenter) return null;

        if (transaction.transactionStatus !== TransactionStatus.INRENT)
          return null;

        return loading ? (
          <Loader />
        ) : (
          <ButtonText
            color="primary"
            onClick={
              transaction.transactionStatus === TransactionStatus.INRENT &&
              ((transaction.Thing.periodType === PeriodType.HOURS &&
                (Date.parse(transaction.endDateTime) - Date.now()) / 1000 / 60 >
                  45) ||
                ((transaction.Thing.periodType === PeriodType.DAYS ||
                  transaction.Thing.periodType === PeriodType.WEEKS ||
                  transaction.Thing.periodType === PeriodType.MONTHS) &&
                  new Date(transaction.endDateTime).getDate() !==
                    new Date().getDate()))
                ? completeRentalEarlyModal
                : acceptExchangeModal
            }
          >
            Accept
          </ButtonText>
        );
      case SystemMessage.LATE_DROPOFF_REMINDER:
        if (!isRenter) return null;

        if (transaction.transactionStatus !== TransactionStatus.INRENT)
          return null;

        return loading ? (
          <Loader />
        ) : (
          <ButtonText onClick={acceptExchangeModal}>Return</ButtonText>
        );
      case SystemMessage.ITEM_REVIEW:
        return (
          !hasReviewed.thing &&
          !postThingReviewData && (
            <ButtonText variant="outlined" onClick={handleItemReview}>
              Leave Review
            </ButtonText>
          )
        );
      case SystemMessage.USER_REVIEW:
        return (
          !hasReviewed.user &&
          !postUserReviewData && (
            <ButtonText variant="outlined" onClick={handleUserReview}>
              Leave Review
            </ButtonText>
          )
        );
      default:
        return null;
    }
  };

  return message.systemMessage ? (
    <SysMessage
      date={
        !!message.dateSent && format(new Date(message.dateSent), 'MMM dd, yyyy')
      }
      message={systemMessageContent()}
      name={thing.name}
      time={
        !!message.dateSent && format(new Date(message.dateSent), 'hh:mmaaa')
      }
      status={SystemMessageUI[message.systemMessage].label}
    >
      {renderButtons()}
    </SysMessage>
  ) : (
    <UserMessage
      date={
        showDate &&
        !!message.dateSent &&
        format(new Date(message.dateSent), 'MMM dd, yyyy')
      }
      message={message.body.text}
      name={targetUser.id === message.senderID && message.sender.fullName}
      time={
        !!message.dateSent && format(new Date(message.dateSent), 'hh:mmaaa')
      }
      variant={targetUser.id === message.senderID ? 'receiver' : 'sender'}
    />
  );
};

MessageDetails.propTypes = {
  handleUpdateTransaction: PropTypes.func,
  hasReviewed: PropTypes.bool,
  message: PropTypes.object,
  targetUser: PropTypes.object,
  thing: PropTypes.object,
  transaction: PropTypes.object,
  showDate: PropTypes.bool
};

MessageDetails.defaultProps = {
  handleUpdateTransaction: () => {},
  hasReviewed: false,
  message: null,
  targetUser: null,
  thing: null,
  transaction: null,
  showDate: true
};

export default MessageDetails;
