import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import RModal from 'react-modal';
import ButtonIcon from '../../ButtonIcon';
import ButtonText from '../../ButtonText';
import { FormGroup } from '../../Forms';
import SearchField from '../SearchField';
import './styles.scss';
import DatePicker from './DatePicker';
import Location from './Location';
import TextQuery from './TextQuery';

const MobileSearch = ({
  address,
  date,
  endDate,
  endDateValue,
  initializeMap,
  isOpen,
  keyword,
  lat,
  locationRef,
  lon,
  onClearAllClick,
  onCloseClick,
  onDateCancelClick,
  onDateChange,
  onKeywordChange,
  onLocationCancelClick,
  onLocationChange,
  onQueryCancelClick,
  onSubmit,
  removeSuggestions,
  startDate,
  startDateValue,
  thingSuggestions
}) => {
  const [step, setStep] = useState('SUMMARY');

  const handleDateChange = (dates) => {
    onDateChange(dates);
  };

  const handleKeyPress = ({ key, target }) => {
    if (key === 'Enter' && target.value) {
      removeSuggestions();
      setStep('SUMMARY');
    }
  };

  const handleClick = (ref) => {
    if (ref.current.value) {
      removeSuggestions();
      setStep('SUMMARY');
    }
  };

  // add toast notif if clicked with no value
  const handleSearch = () => {
    if (keyword.length >= 2) {
      onSubmit();
    }
  };

  const handleQueryOKClick = () => {
    if (keyword) {
      setStep('SUMMARY');
    }
  };

  // *
  const handleDateOKClick = () => {
    if (date) {
      setStep('SUMMARY');
    }
  };

  const handleLocationOKClick = () => {
    if (address) {
      setStep('SUMMARY');
    }
  };

  const handleBackClick = () => {
    setStep('SUMMARY');
  };

  const handleCloseClick = () => {
    setStep('SUMMARY');
    onCloseClick();
  };

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef?.current || endDate) {
      //:: Focus <input /> element to enable enter to next step
      inputRef?.current?.focus();
    }
  }, [inputRef?.current, endDate]);

  const renderStep = () => {
    switch (step) {
      case 'TEXT_QUERY':
        return (
          <TextQuery
            value={keyword}
            suggestions={thingSuggestions}
            onChange={onKeywordChange}
            onKeyPress={handleKeyPress}
            onSuggestionClick={(thingName) => {
              removeSuggestions();
              onKeywordChange(
                {
                  target: {
                    value: thingName
                  }
                },
                true
              );
            }}
            onOKClick={handleQueryOKClick}
            onCancelClick={onQueryCancelClick}
            onBackClick={handleBackClick}
            onCloseClick={handleCloseClick}
          />
        );
      case 'DATE_PICKER':
        return (
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            value={date}
            onChange={handleDateChange}
            onKeyPress={handleKeyPress}
            onClick={handleClick}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            onOKClick={handleDateOKClick}
            onCancelClick={onDateCancelClick}
            onBackClick={handleBackClick}
            onCloseClick={handleCloseClick}
          />
        );
      case 'LOCATION':
        return (
          <Location
            value={address}
            onChange={onLocationChange}
            onKeyPress={handleKeyPress}
            onSuggestionClick={({ lat, lon, address }) => {
              onLocationChange({ lat, lon, address });
            }}
            locationRef={locationRef}
            initializeMap={initializeMap}
            lat={lat}
            lon={lon}
            onOKClick={handleLocationOKClick}
            onCancelClick={onLocationCancelClick}
            onBackClick={handleBackClick}
            onCloseClick={handleCloseClick}
          />
        );
      default:
        // TODO: refactor
        return (
          <>
            <div className="top">
              <div className="navigation">
                <div className="spacer" />
                <span className="text">Search</span>
                <ButtonIcon icon="close" onClick={onCloseClick} />
              </div>
              <SearchField
                icon="search"
                placeholder="What are you looking for?"
                value={keyword}
                name="ukuusearch"
                readOnly
                onClick={() => setStep('TEXT_QUERY')}
                // onChange={onKeywordChange}
                // onKeyPress={({ key, target }) => {
                //   if (key === 'Enter' && target.value.length >= 2) {
                //     onSubmit();
                //   }
                // }}
              />
              <SearchField
                icon="calendar_today"
                placeholder="When do you need it?"
                text="Add dates"
                value={date}
                name="ukuusearch"
                readOnly
                onClick={() => setStep('DATE_PICKER')}
              />
              <SearchField
                icon="place"
                placeholder="Where would you like to rent?"
                text="Set location"
                innerRef={inputRef}
                value={address}
                name="ukuusearch"
                readOnly
                onClick={() => setStep('LOCATION')}
                // onChange={handleLocationChange}
              />
            </div>
            <div className="bottom">
              <FormGroup>
                <ButtonText color="primary" onClick={handleSearch}>
                  Search
                </ButtonText>
                <ButtonText variant="outlined" onClick={onClearAllClick}>
                  Reset
                </ButtonText>
              </FormGroup>
            </div>
          </>
        );
    }
  };

  return (
    <RModal
      overlayClassName="overlay"
      className="modal-content"
      isOpen={isOpen}
    >
      <div className="mobile-search">{renderStep()}</div>
    </RModal>
  );
};

MobileSearch.propTypes = {
  address: PropTypes.string,
  date: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  endDateValue: PropTypes.string.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  initializeMap: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  keyword: PropTypes.string,
  lat: PropTypes.object.isRequired,
  locationRef: PropTypes.object.isRequired,
  lon: PropTypes.object.isRequired,
  onClearAllClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onDateCancelClick: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onKeywordChange: PropTypes.func.isRequired,
  onLocationCancelClick: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  onQueryCancelClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  removeSuggestions: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  startDateValue: PropTypes.string.isRequired,
  thingSuggestions: PropTypes.array
};

MobileSearch.defaultProps = {
  address: '',
  isOpen: false,
  keyword: '',
  thingSuggestions: []
};

export default MobileSearch;
