import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import { Component } from 'react';
import Slider from 'react-slick';
import arrowRight from '../../../../assets/icons/arrowRight.svg';
import arrowLeft from '../../../../assets/icons/arrowLeft.svg';
import { DynamicSVGIcon } from '../../components';

const SampleArrow = (props) => {
  const { className, style, onClick, imageSrc } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 36,
        width: 36
      }}
      onClick={onClick}
    >
      <img src={imageSrc} />
    </div>
  );
};

export default class Responsive extends Component {
  render() {
    const getClassNames = (category) => {
      return clsx(
        'category',
        category?.name === selectedCategory?.name && '-selected'
      );
    };
    const { categories, onCategoryClick, selectedCategory } = this.props;
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 11,
      slidesToScroll: 4,
      initialSlide: 0,
      nextArrow: <SampleArrow imageSrc={arrowRight} />,
      prevArrow: <SampleArrow imageSrc={arrowLeft} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            nextArrow: null,
            prevArrow: null
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            nextArrow: null,
            prevArrow: null
          }
        }
      ]
    };
    return (
      <div className="container">
        <div className="top-categories">
          <Slider {...settings}>
            {categories.map((category) => (
              <div
                className={getClassNames(category)}
                onClick={onCategoryClick(category)}
                key={category.key}
              >
                {/* <img src={images[category.key]} className="image" /> */}
                {/* <img src={appliances} /> */}
                <div className="category-container">
                  <DynamicSVGIcon
                    path={category.path}
                    pathD={category?.pathD}
                    pathD1={category?.pathD1}
                    pathD2={category?.pathD2}
                    pathD3={category?.pathD3}
                    pathD4={category?.pathD4}
                    multiple={category?.multiple}
                    extraOptions={category?.extraOptions}
                    width={24}
                    height={24}
                    fill={
                      category.name === selectedCategory?.name
                        ? '#00acc1'
                        : '#98a2b3'
                    }
                  />
                  <span className="name">{category.name}</span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

Responsive.propTypes = {
  categories: PropTypes.array,
  icons: PropTypes.array,
  images: PropTypes.array,
  onCategoryClick: PropTypes.func,
  onShowAllClick: PropTypes.func,
  selectedCategory: PropTypes.object
};

Responsive.defaultProps = {
  categories: [],
  icons: [],
  images: [],
  onCategoryClick: () => {},
  onShowAllClick: () => {},
  selectedCategory: null
};

SampleArrow.propTypes = {
  className: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
  imageSrc: PropTypes.object
};

SampleArrow.defaultProps = {
  className: {},
  style: {},
  onClick: () => {},
  imageSrc: {}
};
