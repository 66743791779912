import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import './styles.scss';

const Rating = ({ className, hasIcon, numberOfRatings, rating }) => {
  const ratingClassNames = clsx('rating', className);

  return (
    <div className={ratingClassNames}>
      {hasIcon && <Icon icon="star_outline" />}
      <span className="avg-rating">{(rating || 0).toFixed(1)}</span>
      <span className="num-of-ratings">({numberOfRatings})</span>
    </div>
  );
};

Rating.propTypes = {
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
  numberOfRatings: PropTypes.number,
  rating: PropTypes.number
};

Rating.defaultProps = {
  className: '',
  hasIcon: true,
  numberOfRatings: 0,
  rating: 0
};

export default Rating;
