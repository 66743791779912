import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const DynamicSVGIcon = ({
  path,
  pathD,
  pathD1,
  pathD2,
  pathD3,
  pathD4,
  multiple,
  extraOptions,
  width = 24,
  height = 24,
  fill = '#98A2B3'
}) => {
  useEffect(() => {
    console.log(path);
  }, []);

  return (
    <>
      {path && pathD ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill={'none'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" clipRule="evenodd" d={path} fill={fill} />
          <path d={pathD} fill={fill} />
        </svg>
      ) : pathD ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill={'none'}
          xmlns="http://www.w3.org/2000/svg"
        >
          {extraOptions ? (
            <path d={pathD} fill={fill} />
          ) : (
            <path
              d={pathD}
              stroke={fill}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )}
        </svg>
      ) : multiple ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 26 23"
          fill={'none'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={pathD1}
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d={pathD2}
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d={pathD3}
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d={pathD4}
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill={'none'}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" clipRule="evenodd" d={path} fill={fill} />
        </svg>
      )}
    </>
  );
};

export default DynamicSVGIcon;

DynamicSVGIcon.propTypes = {
  path: PropTypes.string,
  pathD: PropTypes.string,
  pathD1: PropTypes.string,
  pathD2: PropTypes.string,
  pathD3: PropTypes.string,
  pathD4: PropTypes.string,
  multiple: PropTypes.string,
  extraOptions: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

DynamicSVGIcon.defaultProps = {
  path: '',
  pathD: '',
  pathD1: '',
  pathD2: '',
  pathD3: '',
  pathD4: '',
  multiple: '',
  extraOptions: '',
  width: 0,
  height: 0,
  fill: ''
};
