import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { PeriodType } from '../../../models';
import { getLocalTime, getMinRentDate } from '../../../utils';
import { FormLabel } from '../../Forms';
import './styles.scss';

const RentalDatePicker = ({
  control,
  disabled,
  endDate,
  format,
  minRentPeriod,
  name,
  onDateChange,
  periodType,
  readOnly,
  startDate,
  timezone
}) => {
  const [fromDate, setFromDate] = useState(
    startDate ? new Date(startDate) : getLocalTime(timezone, new Date())
  );
  const [toDate, setToDate] = useState(
    endDate ? new Date(endDate) : new Date()
  );
  const [showStartTime, setShowStartTime] = useState(
    periodType === PeriodType.HOURS
  );
  const [showEndTime, setShowEndTime] = useState(
    periodType === PeriodType.HOURS
  );

  const isSelectedDateInFuture =
    +startDate > +getLocalTime(timezone, new Date());
  const date = getLocalTime(timezone, new Date());
  let currentMins = date.getMinutes();
  let currentHour = date.getHours();

  if (isSelectedDateInFuture) {
    currentHour = 0;
    currentMins = 0;
  }

  let fromDateFormat;
  let toDateFormat;
  if (format === 'dateTime') {
    if (readOnly) {
      fromDateFormat = 'MMMM d, yyyy h:mm aa';
    } else if (showStartTime) {
      fromDateFormat = 'MMMM d, yyyy h:mm aa';
    } else {
      fromDateFormat = 'MMMM d, yyyy';
    }
  } else {
    fromDateFormat = 'MMMM d, yyyy';
  }

  if (format === 'dateTime') {
    if (readOnly) {
      toDateFormat = 'MMMM d, yyyy h:mm aa';
    } else if (showEndTime) {
      toDateFormat = 'MMMM d, yyyy h:mm aa';
    } else {
      toDateFormat = 'MMMM d, yyyy';
    }
  } else {
    toDateFormat = 'MMMM d, yyyy';
  }

  const dateOptions = {
    startDate: fromDate,
    endDate: toDate,
    showTimeInput: format === 'dateTime' ? true : false,
    disabled: disabled
  };

  const handleDate = (date, type, onChange) => {
    if (type === 'fromDate') {
      setShowStartTime(true);
      setFromDate(date);
      onDateChange(date, 'fromDate');
      onChange({ from: date, to: toDate });
    } else {
      setShowEndTime(true);
      setToDate(date);
      onDateChange(date, 'endDate');
      onChange({ from: fromDate, to: date });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <div className="date-picker">
          <FormLabel text="From" />
          <RDatepicker
            selected={fromDate}
            onChange={(date) => handleDate(date, 'fromDate', onChange)}
            minDate={getLocalTime(timezone, new Date())}
            dateFormat={fromDateFormat}
            minTime={
              new Date(new Date().setHours(currentHour, currentMins, 0, 0))
            }
            maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
            {...dateOptions}
          />
          <FormLabel text="To" />
          <RDatepicker
            selected={toDate}
            onChange={(date) => handleDate(date, 'toDate', onChange)}
            minDate={getLocalTime(
              timezone,
              getMinRentDate(periodType, minRentPeriod, fromDate)
            )}
            dateFormat={toDateFormat}
            {...dateOptions}
          />
        </div>
      )}
    />
  );
};

RentalDatePicker.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  endDate: PropTypes.string,
  format: PropTypes.oneOf(['date', 'dateTime']),
  minRentPeriod: PropTypes.number.isRequired,
  name: PropTypes.string,
  onDateChange: PropTypes.func,
  periodType: PropTypes.oneOf(['HOURS', 'DAYS', 'WEEKS', 'MONTHS']).isRequired,
  readOnly: PropTypes.bool,
  startDate: PropTypes.string,
  timezone: PropTypes.string.isRequired
};

RentalDatePicker.defaultProps = {
  control: {},
  disabled: false,
  endDate: '',
  format: 'date',
  name: '',
  onDateChange: () => {},
  readOnly: false,
  startDate: ''
};

export default RentalDatePicker;
