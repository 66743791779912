import Amplify from 'aws-amplify';
import awsExports from '../aws-exports';

const initializeAmplify = () => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  const [localRedirectSignIn, productionRedirectSignIn] =
    awsExports.oauth.redirectSignIn.split(',');

  const [localRedirectSignOut, productionRedirectSignOut] =
    awsExports.oauth.redirectSignOut.split(',');

  const updatedAwsConfig = {
    ...awsExports,
    oauth: {
      ...awsExports.oauth,
      redirectSignIn: isLocalhost
        ? localRedirectSignIn
        : productionRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productionRedirectSignOut
    }
  };

  Amplify.configure(updatedAwsConfig);
};

export default initializeAmplify;
