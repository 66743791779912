import PropTypes from 'prop-types';
import React from 'react';
import RSelect from 'react-select';

const Select = ({ className, field, name, options, placeholder, ...props }) => {
  return (
    <RSelect
      {...field}
      {...props}
      inputId={name}
      className={`dropdown-button ${className}`}
      classNamePrefix="select"
      options={options}
      placeholder={placeholder}
    />
  );
};

Select.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  field: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  shouldEnterKeywords: PropTypes.bool
};

Select.defaultProps = {
  className: '',
  error: '',
  field: {},
  placeholder: '',
  shouldEnterKeywords: false
};

export default Select;
