import * as yup from 'yup';

const signinValidation = yup.object().shape({
  rating: yup
    .number()
    .min(1, 'Rating must be greater than or equal to 1')
    .max(5, 'Rating must be less than or equal to 5')
    .required('Rating is required')
});

export default signinValidation;
