import axios from 'axios';

/**
 * @param {Number} latitude
 * @param {Number} longitude
 */
const getLocation = async (latitude, longitude) => {
  try {
    const getTimezone = axios.get(
      'https://maps.googleapis.com/maps/api/timezone/json',
      {
        params: {
          location: `${latitude},${longitude}`,
          timestamp: new Date().getTime() / 1000,
          key: process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY
        }
      }
    );

    const getAddress = axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json',
      {
        params: {
          latlng: `${latitude},${longitude}`,
          key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY
        }
      }
    );

    const response = await Promise.all([getTimezone, getAddress]);

    if (response.length) {
      console.log(response);
      return {
        timezone: response[0].data.timeZoneId,
        address: response[1].data.results[0].formatted_address
      };
    }
    return null;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export default getLocation;
