import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import { getDistance } from '../../utils';
import Distance from '../Distance';
import Divider from '../Divider';
import EmptyListPlaceholder from '../EmptyListPlaceholder';
import Image from '../Image';
import ReviewCardList from '../ReviewCardList';
import StarRating from '../StarRating';
import './styles.scss';

const ItemReviews = ({ item }) => {
  const { state } = useContext(UserContext);
  const { showModal } = useContext(ModalContext);

  const reviewsShown = item.reviews?.items?.slice(0, 2) || [];
  // TODO: Refactor
  const allReviews = item.reviews?.items || [];

  const handleClick = () => {
    showModal({
      title: 'Reviews',
      content: () => (
        <div className="item-reviews">
          <div className="item-info">
            <Image className="item-image" image={item.images[0]} />
            <div className="right">
              <span className="name">{item.name}</span>
              <Distance
                distance={getDistance(state.currentLocation, {
                  lat: item.location.lat,
                  lon: item.location.lon
                })}
              />
            </div>
          </div>
          <div className="overall-rating">
            <span className="label">Overall Rating</span>
            <StarRating rating={item.rating} />
          </div>
          <Divider />
          <ReviewCardList reviews={allReviews} />
        </div>
      )
    });
  };

  return (
    <div className="item-reviews">
      <div className="title-container">
        <span className="title">Reviews</span>
        {allReviews.length > 2 && (
          <span className="show-all" onClick={handleClick}>
            Show all
          </span>
        )}
      </div>
      {allReviews.length > 0 ? (
        <ReviewCardList reviews={reviewsShown} />
      ) : (
        <EmptyListPlaceholder
          type="review"
          icon="article"
          text="No Reviews Yet"
        />
      )}
    </div>
  );
};

ItemReviews.propTypes = {
  item: PropTypes.object
};

ItemReviews.defaultProps = {
  item: null
};

export default ItemReviews;
