import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import { Confirmation, ToastNotification } from '../../../../components';
import { CognitoErrorCode } from '../../../../constants/enumTypes';
import ModalContext from '../../../../contexts/ModalContext';
import UserContext from '../../../../contexts/UserContext';
import { updateUser as updateUserMutation } from '../../../../graphql/mutations';
import { useMutation } from '../../../../hooks/api';
import {
  emailVerificationFormValidation,
  passwordSecurityFormValidation,
  updateEmailFormValidation
} from '../../../../validators';
import { CodeForm } from './components';

const useSignInInformation = (user) => {
  const codeForm = useForm({
    resolver: yupResolver(emailVerificationFormValidation)
  });
  const emailAddressForm = useForm({
    defaultValues: {
      email: user?.email
    },
    resolver: yupResolver(updateEmailFormValidation)
  });
  const passwordForm = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    resolver: yupResolver(passwordSecurityFormValidation)
  });
  const { addToast } = useToasts();

  const [updateUser, { loading: updateUserLoading }] =
    useMutation(updateUserMutation);

  const { setUserData } = useContext(UserContext);
  const { showModal, closeModal } = useContext(ModalContext);

  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  const [updateEmailAddressLoading, setUpdateEmailAddressLoading] =
    useState(false);
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);

  const handleVerifyCodeSubmit = (updatedEmail) => {
    return async (data) => {
      try {
        setVerifyCodeLoading(true);

        await Auth.verifyCurrentUserAttributeSubmit(
          'email',
          data.verificationCode
        );
        await updateUser({
          variables: {
            input: {
              _version: user._version,
              id: user.id,
              email: updatedEmail
            }
          }
        });
        setUserData({
          email: updatedEmail
        });

        addToast(
          <ToastNotification
            message="Update email successful"
            toastId="code-verify-success"
            variant="success"
          />,
          { id: 'code-verify-success' }
        );
        closeModal();
        // :: Refresh session
        await Auth.currentUserPoolUser({ bypassCache: true });
      } catch (error) {
        if (error.code) {
          switch (error.code) {
            case CognitoErrorCode.CODE_MISMATCH_EXCEPTION:
              codeForm.setError('verificationCode', {
                type: 'focus',
                message: 'Invalid verification code'
              });
              break;
            default:
              codeForm.setError('verificationCode', {
                type: 'focus',
                message: 'There was an error'
              });
              break;
          }
        } else {
          addToast(
            <ToastNotification
              message="There was an error with updating your email"
              toastId="code-verify-error"
              variant="error"
            />,
            { id: 'code-verify-error' }
          );
          closeModal();
        }

        console.error(error);
      } finally {
        setVerifyCodeLoading(false);
      }
    };
  };

  const handleUpdateEmailAddressSubmit = async (data) => {
    if (data.email === user.email) return;

    try {
      setUpdateEmailAddressLoading(true);

      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(currentUser, {
        email: data.email
      });

      showModal({
        title: 'Email Verification',
        content: () =>
          CodeForm({
            form: codeForm,
            isLoading: verifyCodeLoading || updateUserLoading,
            onSubmit: codeForm.handleSubmit(handleVerifyCodeSubmit(data.email))
          })
      });
    } catch (error) {
      let message;

      if (error.code) {
        switch (error.code) {
          case CognitoErrorCode.LIMIT_EXCEEDED_EXCEPTION:
            message =
              "You've reached the maximum number of email update attempts. Please try after some time";
            break;
          default:
            message = 'There was an error. Please try after some time';
            break;
        }
      } else {
        message = 'There was an error. Please try after some time';
      }

      addToast(
        <ToastNotification
          message={message}
          toastId="email-update-error"
          variant="error"
        />,
        { id: 'email-update-error' }
      );

      console.error(error);
    } finally {
      setUpdateEmailAddressLoading(false);
    }
  };

  const handleUpdatePasswordSubmit = (data) => {
    showModal({
      title: 'Confirm Password',
      content: () => (
        <Confirmation
          message="Are you sure you want to update your password?"
          confirmLabel="Confirm"
          rejectLabel="Cancel"
          confirmAction={async () => {
            try {
              setUpdatePasswordLoading(true);

              const currentUser = await Auth.currentAuthenticatedUser();
              await Auth.changePassword(
                currentUser,
                data.currentPassword,
                data.newPassword
              );

              addToast(
                <ToastNotification
                  message="Password updated"
                  toastId="password-update-success"
                  variant="success"
                />,
                { id: 'password-update-success' }
              );
              passwordForm.reset();
            } catch (error) {
              let message;

              if (error.code) {
                switch (error.code) {
                  case CognitoErrorCode.NOT_AUTHORIZED_EXCEPTION:
                    message = 'Incorrect current password';
                    break;
                  case CognitoErrorCode.LIMIT_EXCEEDED_EXCEPTION:
                    message =
                      "You've reached the maximum number of password update attempts. Please try after some time";
                    break;
                  default:
                    message = 'There was an error';
                    break;
                }
              } else {
                message = 'There was an error';
              }

              addToast(
                <ToastNotification
                  message={message}
                  toastId="password-update-error"
                  variant="error"
                />,
                { id: 'password-update-error' }
              );

              console.error(error);
            } finally {
              setUpdatePasswordLoading(false);
            }
          }}
        />
      )
    });
  };

  return {
    emailAddressForm,
    passwordForm,
    updateEmailAddressLoading,
    updatePasswordLoading,
    onEmailAddressSubmit: emailAddressForm.handleSubmit(
      handleUpdateEmailAddressSubmit
    ),
    onPasswordSubmit: passwordForm.handleSubmit(handleUpdatePasswordSubmit)
  };
};

export default useSignInInformation;
