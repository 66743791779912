import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthContext from '../../contexts/AuthContext';

const AuthenticatedRoute = ({
  layout: Layout,
  component: Component,
  componentProps,
  ...rest
}) => {
  const { state } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        state.accessToken ? (
          <Layout {...matchProps}>
            <Component {...componentProps} {...matchProps} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                returnUrl: rest.location.pathname,
                notLoggedIn: true
              }
            }}
          />
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  componentProps: PropTypes.object
};

AuthenticatedRoute.defaultProps = {
  componentProps: {}
};

export default AuthenticatedRoute;
