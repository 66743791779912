import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormInputError, FormLabel } from '../Forms';
import './styles.scss';
import ToggleSwitch from './ToggleSwitch';

const CheckboxGroup = ({
  checkboxes,
  control,
  error,
  label,
  name,
  noController,
  required
}) => {
  const [values, setValues] = useState(checkboxes);

  const handleChange = (checked, onChange, label) => {
    setValues(
      values.map((value) => {
        if (value.label === label) {
          return {
            label,
            checked
          };
        }
        return value;
      })
    );
    const checkedValues = values.map((value) => {
      if (value.label === label) {
        return {
          label,
          checked
        };
      }
      return value;
    });
    onChange([...checkedValues]);
  };

  return (
    <div className="checkbox-group">
      {label && <FormLabel text={label} required={required} />}
      <div className="checkboxes">
        {noController ? (
          checkboxes.map(({ label, checked }, index) => (
            <ToggleSwitch key={index} label={label} checked={checked} />
          ))
        ) : (
          <Controller
            name={name}
            control={control}
            render={({ field }) =>
              checkboxes.map(({ label, checked }, index) => (
                <ToggleSwitch
                  key={index}
                  onChange={(value) =>
                    handleChange(value, field.onChange, label)
                  }
                  label={label}
                  checked={checked}
                />
              ))
            }
          />
        )}
      </div>
      {error && <FormInputError error={error} data-testid={`${name}Error`} />}
    </div>
  );
};

CheckboxGroup.propTypes = {
  checkboxes: PropTypes.array,
  control: PropTypes.object,
  error: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noController: PropTypes.bool,
  required: PropTypes.bool
};

CheckboxGroup.defaultProps = {
  checkboxes: [],
  control: {},
  error: null,
  label: '',
  noController: false,
  required: false
};

export default CheckboxGroup;
