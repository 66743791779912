import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Dropdown,
  FormInput,
  FormTextArea,
  FormTitle,
  LocationInput
} from '../../../../components';
import Media from '../Media';
import './styles.scss';

const GeneralInformation = () => {
  const { register, control, errors } = useFormContext();

  return (
    <div className="general-information">
      <FormTitle title="Upload photos" />
      <Media />
      <FormTitle title="Item details" />
      <FormInput
        className="name-field"
        control={control}
        data-testid="itemName"
        error={errors.name}
        label="Item name"
        name="name"
        placeholder="Name me!"
        required
        type="text"
      />
      <FormTextArea
        data-testid="description"
        error={errors.description}
        label="Item description"
        name="description"
        placeholder="Tell the world about your item"
        register={register}
        required
        type="text"
      />
      <FormInput
        className="price-field"
        control={control}
        data-testid="itemValue"
        label="Item value"
        name="itemValue"
        type="number"
      />
      <LocationInput
        className={errors.location ? 'error' : ''}
        control={control}
        data-testid="location"
        error={errors.location}
        label="Item location"
        name="location"
        required
        saveLatLng
      />
      <FormTitle title="Pricing" />
      <FormInput
        className="price-field"
        control={control}
        data-testid="pricePerPeriod"
        error={errors.pricePerPeriod}
        label="Price"
        name="pricePerPeriod"
        required
        type="number"
      />
      <Dropdown
        className="dropdown"
        control={control}
        data-testid="periodType"
        error={errors.periodType}
        label="Period"
        name="periodType"
        options={[
          { value: 'HOURS', label: 'Hourly' },
          { value: 'DAYS', label: 'Daily' },
          { value: 'WEEKS', label: 'Weekly' }
        ]}
        placeholder="Options"
        required
      >
        <p>
          The time are renters paying for. Prices can be set per hour, day, or
          week.
        </p>
      </Dropdown>
      <FormInput
        className="price-field"
        control={control}
        data-testid="deposit"
        error={errors.deposit}
        label="Deposit"
        name="deposit"
        required
        type="number"
      />
    </div>
  );
};

export default GeneralInformation;
