import PropTypes from 'prop-types';
import React from 'react';
import { Documents } from '../../constants/enumTypes';
import Badge from '../Badge';
import Icon from '../Icon';
import './styles.scss';

const DocumentList = ({ documents }) => {
  const hasDocuments = documents.length > 0;

  return hasDocuments ? (
    <div className="document-list">
      <div className="top">
        <Icon icon="description" />
        <div className="text-container">
          <span className="title">Documents Required</span>
          <span className="subtitle">
            When renting this item, be ready to provide:
          </span>
        </div>
      </div>
      <div className="bottom">
        {documents.map((document) => (
          <Badge
            alert
            label={Documents[document]}
            type="document"
            key={document}
          />
        ))}
      </div>
    </div>
  ) : null;
};

DocumentList.propTypes = {
  documents: PropTypes.array
};

DocumentList.defaultProps = {
  documents: []
};

export default DocumentList;
