import { v4 as uuidv4 } from 'uuid';

const getPublicId = (name) => {
  const uuid = uuidv4().split('-');
  const id = uuid[uuid.length - 1];
  if (name) {
    const firstChar = name.match(/\b(\w)/g).join('');
    const publicID = `${firstChar}${id}`;
    return publicID.slice(0, 11).toUpperCase();
  }

  return id.slice(0, 11).toUpperCase();
};

export default getPublicId;
