import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Icon } from '../../../../components';
import './styles.scss';

const UploadButton = ({ getInputProps, handleChange }) => {
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="upload-button">
      <input
        {...getInputProps()}
        ref={inputRef}
        type="file"
        data-testid="upload"
        accept="image/*"
        onChange={handleChange}
        className="input"
        style={{ display: 'none' }}
      />
      <button onClick={handleClick}>
        <div className="icon-container">
          <Icon icon="add_circle_outline" />
        </div>
      </button>
    </div>
  );
};

UploadButton.propTypes = {
  getInputProps: PropTypes.func,
  handleChange: PropTypes.func
};

UploadButton.defaultProps = {
  getInputProps: () => {},
  handleChange: () => {}
};

export default UploadButton;
