import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, UserCard } from '../../../../components';
import { TransactionStatusUI } from '../../../../constants/enumTypes';
import UserContext from '../../../../contexts/UserContext';
import { useWindowDimensions } from '../../../../hooks';
import { getElapsedDate } from '../../../../utils';
import './styles.scss';

const ConversationCard = ({ conversation }) => {
  const history = useHistory();

  const { state } = useContext(UserContext);

  const { windowWidth } = useWindowDimensions();

  const hasUnreadMessage =
    !conversation.hasRead &&
    conversation.lastMessageReceived?.recipientID === state.id;

  const classNames = clsx('conversation-card', hasUnreadMessage && '-alert');

  const handleClick = () => {
    const url =
      windowWidth <= 1024
        ? `/messages/${conversation.publicID}`
        : `/messages/${conversation.publicID}/transaction/${conversation.publicID}`;

    history.push(url);
  };

  return (
    <div className={classNames}>
      {hasUnreadMessage && (
        <div className="alert-container">
          <span className="alert" />
        </div>
      )}
      <UserCard
        avatar={conversation.user.avatar}
        size="32"
        name={conversation.user.fullName}
        onClick={handleClick}
      >
        <div className="details">
          <div className="top">
            <span className="user-name" data-testid="userName">
              {conversation.user.fullName}
            </span>
            <Badge
              label={
                TransactionStatusUI[conversation.transaction.transactionStatus]
                  .label
              }
              type={conversation.transaction.transactionStatus.toLowerCase()}
              className="transaction-status"
            />
          </div>
          <span className="item-name">
            {conversation.transaction.Thing.name}
          </span>
          <span className="last-message-received-at">
            {getElapsedDate(conversation.lastMessageReceivedAt)}
          </span>
        </div>
      </UserCard>
    </div>
  );
};

ConversationCard.propTypes = {
  conversation: PropTypes.object
};

ConversationCard.defaultProps = {
  conversation: null
};

export default ConversationCard;
