import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import PropTypes from 'prop-types';

const Route = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <ReactRoute
      {...rest}
      render={(matchProps) => (
        <Layout {...matchProps}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

Route.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired
};

export default Route;
