import clsx from 'clsx';
import React, { useContext } from 'react';
import RNModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ButtonIcon, ButtonSocMed, Header } from '../../../../components';
import UserContext from '../../../../contexts/UserContext';
import { useWindowDimensions } from '../../../../hooks';
import { URL_HELPERS } from '../../../../utils/url';
import './styles.scss';
import { AccountCard } from './components';

const Accounts = () => {
  const history = useHistory();

  const { state } = useContext(UserContext);

  const { windowWidth } = useWindowDimensions();

  const accounts = [
    {
      avatar: state.avatar,
      name: state.fullName,
      type: 'Facebook'
    },
    {
      avatar: state.avatar,
      name: state.fullName,
      type: 'Google'
    },
    {
      avatar: state.avatar,
      name: state.fullName,
      type: 'Amazon'
    }
  ];

  const classNames = clsx(
    'account-card-list',
    accounts.length === 0 && '-no-connected-accounts'
  );

  const handleClick = () => history.push(URL_HELPERS.profile);

  const renderAccounts = () => {
    return (
      <div className="accounts-subpage">
        <Header>
          <Header.Left>
            <ButtonIcon icon="arrow_back" onClick={handleClick} />
          </Header.Left>
          <Header.Center>Connected Accounts</Header.Center>
          <Header.Right />
        </Header>
        <div className="body">
          <div className={classNames}>
            <AccountCard
              avatar={state.avatar}
              name={state.fullName}
              type="Email"
            />
            {accounts.length > 0 &&
              accounts.map((account) => (
                <AccountCard
                  avatar={account.avatar}
                  hasLogo
                  name={account.name}
                  onClick={() => {}}
                  type={account.type}
                  key={account.type}
                />
              ))}
          </div>
          <div className="buttons-container">
            <span>Link account with</span>
            <ButtonSocMed
              socialMedia="facebook"
              label="Sign in with Facebook"
            />
            <ButtonSocMed socialMedia="google" label="Sign in with Google" />
            <ButtonSocMed socialMedia="amazon" label="Sign in with Amazon" />
          </div>
        </div>
      </div>
    );
  };

  return windowWidth <= 1024 ? (
    <RNModal overlayClassName="overlay" className="modal-content" isOpen>
      {renderAccounts()}
    </RNModal>
  ) : (
    renderAccounts()
  );
};

export default Accounts;
