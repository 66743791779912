import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Icon } from '../../../../components';
import './styles.scss';

const ButtonAvatarUpload = ({ getInputProps, onChange }) => {
  const inputRef = useRef(null);

  const handleClick = () => inputRef.current.click();

  return (
    <div className="button-avatar-upload">
      <input
        {...getInputProps()}
        ref={inputRef}
        type="file"
        data-testid="upload"
        accept="image/*"
        onChange={onChange}
        className="input"
        style={{ display: 'none' }}
      />
      <button className="button" onClick={handleClick}>
        <Icon icon="photo_camera" />
      </button>
    </div>
  );
};

ButtonAvatarUpload.propTypes = {
  getInputProps: PropTypes.func,
  onChange: PropTypes.func
};

ButtonAvatarUpload.defaultProps = {
  getInputProps: () => {},
  onChange: () => {}
};

export default ButtonAvatarUpload;
