import PropTypes from 'prop-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  ButtonIcon,
  ButtonText,
  Description,
  Divider,
  Footer,
  Header,
  ImageCarousel,
  ItemReviews,
  Loader,
  Price,
  Rating,
  UserCard
} from '../../components';
import useItemDetails from './useItemDetails';
import './styles.scss';
import { GeneralInformation } from './components';

const ItemDetails = ({ history }) => {
  const { itemId } = useParams();

  const { isUserItemLoading, userItemData, onRequestClick } =
    useItemDetails(itemId);

  if (isUserItemLoading) {
    return <Loader />;
  }

  return (
    <div className="item-details-page">
      <Header>
        <Header.Left>
          <ButtonIcon icon="arrow_back" onClick={history.goBack} />
        </Header.Left>
      </Header>
      <div className="body">
        <ImageCarousel images={userItemData.images} />
        <GeneralInformation item={userItemData} />
        <Divider />
        <UserCard
          avatar={userItemData.User.avatar}
          name={userItemData.User.fullName}
          size="40"
        >
          <div className="info">
            <Link to={`/user/${userItemData.User.publicID}`} className="name">
              {userItemData.User.fullName}
            </Link>
            <Rating
              rating={userItemData.User.rating || 0}
              numberOfRatings={userItemData.numberOfRatings || 0}
            />
          </div>
        </UserCard>
        <Divider />
        <Description description={userItemData.description} />
        <ItemReviews item={userItemData} />
      </div>
      <Footer />
      <footer className="page-footer">
        <Price
          periodType={userItemData.periodType}
          pricePerPeriod={userItemData.pricePerPeriod}
        />
        <ButtonText
          color="primary"
          onClick={onRequestClick}
          data-testid="requestButton"
          className="request-btn"
        >
          Request
        </ButtonText>
      </footer>
    </div>
  );
};

ItemDetails.propTypes = {
  history: PropTypes.object
};

ItemDetails.defaultProps = {
  history: null
};

export default ItemDetails;
