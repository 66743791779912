import PropTypes from 'prop-types';
import React from 'react';
import { ButtonIcon, ButtonText, Header } from '../../components';
import {
  Advanced,
  Availability,
  GeneralInformation,
  SearchTerms
} from './components';

const ItemForm = ({
  confirmCancel,
  confirmDelete,
  draftLoading,
  handleCheck,
  handleDraft,
  history,
  saveLoading
}) => {
  return (
    <div className="item-form-page">
      <Header>
        <Header.Left>
          <ButtonIcon icon="arrow_back" onClick={history.goBack} />
        </Header.Left>
        <Header.Center>
          {history.location.state?.isEditItem ? 'Edit Item' : 'Add Item'}
        </Header.Center>
        <Header.Right />
      </Header>
      <div className="body">
        <GeneralInformation />
        <Availability />
        <SearchTerms />
        <Advanced />
        <ButtonText
          color="primary"
          loading={saveLoading}
          onClick={handleCheck}
          data-testid="submitButton"
        >
          {history.location.state.isEditItem ? 'Save' : 'Save and List'}
        </ButtonText>
        <ButtonText
          variant="outlined"
          onClick={confirmCancel}
          data-testid="cancelButton"
        >
          Cancel
        </ButtonText>
        {history.location.state.isEditItem ? (
          <ButtonText
            color="error"
            variant="outlined"
            onClick={confirmDelete}
            data-testid="deleteButton"
          >
            Delete
          </ButtonText>
        ) : (
          <ButtonText
            color="primary"
            variant="outlined"
            loading={draftLoading}
            onClick={handleDraft}
            data-testid="draftButton"
          >
            Save as Drafts
          </ButtonText>
        )}
      </div>
    </div>
  );
};

ItemForm.propTypes = {
  confirmCancel: PropTypes.func,
  confirmDelete: PropTypes.func,
  draftLoading: PropTypes.bool,
  handleCheck: PropTypes.func,
  handleDraft: PropTypes.func,
  history: PropTypes.object,
  saveLoading: PropTypes.bool
};

ItemForm.defaultProps = {
  confirmCancel: () => {},
  confirmDelete: () => {},
  draftLoading: false,
  handleCheck: () => {},
  handleDraft: () => {},
  history: null,
  saveLoading: false
};

export default ItemForm;
