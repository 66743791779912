const handleCheckBoxValues = (daysAvailable) => {
  const initialValues = [
    { label: 'Monday', checked: false },
    { label: 'Tuesday', checked: false },
    { label: 'Wednesday', checked: false },
    { label: 'Thursday', checked: false },
    { label: 'Friday', checked: false },
    { label: 'Saturday', checked: false },
    { label: 'Sunday', checked: false }
  ];

  return initialValues.map(({ label }) => {
    const checkedDay = daysAvailable.find((day) => label === day);
    if (checkedDay) {
      return {
        label,
        checked: true
      };
    }
    return {
      label,
      checked: false
    };
  });
};

export default handleCheckBoxValues;
