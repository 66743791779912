import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../Loader';
import './styles.scss';

const ButtonText = ({
  children,
  className,
  color,
  disabled,
  loading,
  variant,
  ...props
}) => {
  const classNames = clsx('button-text', `-${color}`, `-${variant}`, className);

  return (
    <button className={classNames} disabled={disabled || loading} {...props}>
      {loading ? <Loader /> : children}
    </button>
  );
};

ButtonText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['error', 'gray', 'primary']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text'])
};

ButtonText.defaultProps = {
  children: 'Button',
  className: '',
  color: 'gray',
  disabled: false,
  loading: false,
  variant: 'contained'
};

export default ButtonText;
