import PropTypes from 'prop-types';
import React from 'react';

const Center = ({ children, ...props }) => {
  return (
    <section className="center" {...props}>
      {children}
    </section>
  );
};

Center.propTypes = {
  children: PropTypes.node
};

Center.defaultProps = {
  children: null
};

export default Center;
