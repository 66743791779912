import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../contexts/UserContext';
import { setDefaultPaymentMethod as setDefaultPaymentMethodMutation } from '../../../../graphql/customMutations';
import { getPaymentMethods as getPaymentMethodsQuery } from '../../../../graphql/customQueries';
import {
  createStripeAccountLink as createStripeAccountLinkMutation,
  deletePaymentMethod as deletePaymentMethodMutation,
  updatePaymentMethod as updatePaymentMethodMutation
} from '../../../../graphql/mutations';
import { getStripeAccount as getStripeAccountQuery } from '../../../../graphql/queries';
import { useMutation, useQuery } from '../../../../hooks/api';

const usePaymentInformation = () => {
  const { state, setUserData } = useContext(UserContext);

  const { loading: getStripeAccountLoading, data: getStripeAccountData } =
    useQuery(getStripeAccountQuery, {
      variables: {
        id: state.id
      }
    });

  const {
    loading: getPaymentMethodsLoading,
    data: getPaymentMethodsData,
    refetchQueries: refetchPaymentMethods
  } = useQuery(getPaymentMethodsQuery, {
    variables: {
      customerID: state.customerID
    }
  });

  const [createStripeAccountLink, { loading: createStripeAccountLinkLoading }] =
    useMutation(createStripeAccountLinkMutation);

  const [setDefaultPaymentMethod, { data: setDefaultPaymentMethodData }] =
    useMutation(setDefaultPaymentMethodMutation);

  const [updatePaymentMethod, { data: updatePaymentMethodData }] = useMutation(
    updatePaymentMethodMutation
  );

  const [deletePaymentMethod, { data: deletePaymentMethodData }] = useMutation(
    deletePaymentMethodMutation
  );

  const [defaultPaymentMethodID, setDefaultPaymentMethodID] = useState('');

  useEffect(() => {
    if (getPaymentMethodsData) {
      const defaultPaymentMethodID =
        getPaymentMethodsData.getPaymentMethods.items.find(
          (paymentMethod) => paymentMethod.default
        )?.id;

      if (defaultPaymentMethodID) {
        setDefaultPaymentMethodID(defaultPaymentMethodID);
      } else {
        if (getPaymentMethodsData.getPaymentMethods.items.length > 0) {
          const setDefault = async () => {
            await setDefaultPaymentMethod({
              variables: {
                input: {
                  customerID: state.customerID,
                  paymentMethodID:
                    getPaymentMethodsData.getPaymentMethods.items[0].id
                }
              }
            });
          };
          setDefault();
        }
      }
    }
  }, [getPaymentMethodsData]);

  useEffect(() => {
    refetchPaymentMethods();
  }, [setDefaultPaymentMethodData, updatePaymentMethodData]);

  useEffect(() => {
    if (getPaymentMethodsData?.getPaymentMethods.items.length === 1) {
      setUserData({ hasPaymentMethods: false });
    }

    refetchPaymentMethods();
  }, [deletePaymentMethodData]);

  return {
    isStripeAccountLoading: getStripeAccountLoading,
    stripeAccountData: getStripeAccountData?.getStripeAccount || null,
    isPaymentMethodsLoading: getPaymentMethodsLoading,
    paymentMethodsData: getPaymentMethodsData?.getPaymentMethods.items || [],
    refetchPaymentMethods,
    createStripeAccountLink,
    isCreateStripeAccountLinkLoading: createStripeAccountLinkLoading,
    setDefaultPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    defaultPaymentMethodID,
    setDefaultPaymentMethodID
  };
};

export default usePaymentInformation;
