const initializePendo = (pendoAttributes, onScriptLoaded = () => {}) => {
  const canUseDOM = () => {
    if (
      typeof window === 'undefined' ||
      !window.document ||
      !window.document.createElement
    ) {
      return false;
    }
    return true;
  };
  if (canUseDOM()) {
    const pendo = document.getElementById('pendo');
    const script = document.createElement('script');
    script.id = 'pendo';

    if (pendo) {
      pendo.parentNode.removeChild(pendo);
      script.innerHTML = `
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
          })(v[w]);
          y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
          z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
          pendo.identify({
          visitor: {
            id: '${pendoAttributes.id}',
            email: '${pendoAttributes.email}',
            full_name: '${pendoAttributes.full_name}'
          }
        });
      })('${process.env.REACT_APP_PENDO_API_KEY}');
      `;
    } else {
      script.innerHTML = `
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
          })(v[w]);
          y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
          z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
          pendo.initialize({
          visitor: {
            id: '${pendoAttributes.id}',
            email: '${pendoAttributes.email}',
            full_name: '${pendoAttributes.full_name}'
          }
        });
      })('${process.env.REACT_APP_PENDO_API_KEY}');
      `;
    }
    script.addEventListener('load', onScriptLoaded);
    document.body.appendChild(script);
  }
};

export default initializePendo;
