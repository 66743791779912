import { useEffect } from 'react';
import { getUserThing as getUserThingQuery } from '../../graphql/customQueries';
import { updateThing as updateThingMutation } from '../../graphql/mutations';
import { useMutation, useQuery } from '../../hooks';

const useMyItemDetails = (itemId) => {
  const {
    loading: getUserThingLoading,
    data: getUserThingData,
    refetchQueries: refetchUserThing
  } = useQuery(getUserThingQuery, {
    variables: {
      publicID: itemId
    }
  });

  const [updateThing, { loading: updateThingLoading, data: updateThingData }] =
    useMutation(updateThingMutation);

  useEffect(() => {
    if (!updateThingLoading && updateThingData?.updateThing) {
      refetchUserThing();
    }
  }, [updateThingLoading, updateThingData]);

  return {
    isUserItemLoading: getUserThingLoading,
    userItemData: getUserThingData?.getUserThing.items[0] || null,
    updateItem: updateThing,
    isUpdateItemLoading: updateThingLoading,
    updateItemData: updateThingData?.updateThing || null
  };
};

export default useMyItemDetails;
