const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const MAX_FILE_SIZE = 10 * 1000000;

/**
 * @param {File[]} files
 * @returns {Promise<File[]>}
 */
const validateImages = (files) =>
  new Promise((resolve, reject) => {
    const acceptedFiles = Array.from(files).map((file) => {
      console.log(file);
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        reject('Unsupported file format. Must be jpg or png');
      }

      if (file.size > MAX_FILE_SIZE) {
        reject('File must not be greater than 10 MB');
      }

      return file;
    });

    resolve(acceptedFiles);
  });

export default validateImages;
