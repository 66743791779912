import PropTypes from 'prop-types';
import React from 'react';
import { ButtonText, FormInput } from '../../../../../../components';
import './styles.scss';

const PasswordForm = ({ form, isLoading, onSubmit }) => {
  return (
    <form className="password-form" onSubmit={onSubmit}>
      <FormInput
        label="Current"
        type="password"
        name="currentPassword"
        required
        control={form.control}
        error={form.formState.errors.currentPassword}
        data-testid="currentPassword"
      />
      <FormInput
        label="New"
        type="password"
        name="newPassword"
        required
        control={form.control}
        error={form.formState.errors.newPassword}
        data-testid="newPassword"
      />
      <FormInput
        label="Confirm New"
        type="password"
        name="confirmPassword"
        required
        control={form.control}
        error={form.formState.errors.confirmPassword}
        data-testid="confirmPassword"
      />
      <ButtonText
        color="primary"
        loading={isLoading}
        data-testid="passwordSubmitBtn"
      >
        Save
      </ButtonText>
    </form>
  );
};

PasswordForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

PasswordForm.defaultProps = {
  form: null,
  isLoading: false,
  onSubmit: () => {}
};

export default PasswordForm;
