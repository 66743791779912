import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  AcceptExchange,
  ButtonIcon,
  ButtonText,
  Confirmation,
  Divider,
  DocumentList,
  Header,
  Icon,
  PricingInfo,
  ToastNotification,
  TransactionDates,
  TransactionItemCard
} from '../../../../components';
import ModalContext from '../../../../contexts/ModalContext';
import UserContext from '../../../../contexts/UserContext';
import { getPaymentMethods as getPaymentMethodsQuery } from '../../../../graphql/customQueries';
import { useQuery, useWindowDimensions } from '../../../../hooks';
import {
  PaymentStatus,
  PeriodType,
  SystemMessage,
  TransactionStatus,
  TransactionType
} from '../../../../models';
import { getPeriodConversion } from '../../../../utils';
import EarlyDropOff from '../../../Transactions/components/EarlyDropOff';
import ExchangeItem from '../../../Transactions/components/ExchangeItem';
import { MessagesContext } from '../..';
import './styles.scss';

const TransactionDetails = ({
  closeModal,
  generateExchangeCode,
  handleUpdateStatus,
  loading,
  requestAgain,
  sendSystemMessage,
  transaction
}) => {
  const { addToast } = useToasts();

  const { state } = useContext(UserContext);
  const { showModal } = useContext(ModalContext);
  const { tab } = useContext(MessagesContext);

  const { windowWidth } = useWindowDimensions();

  const [focused, setFocused] = useState(null);

  const { data: getPaymentMethodsData } = useQuery(getPaymentMethodsQuery, {
    variables: {
      customerID: state.customerID
    }
  });

  const exchangeItemModal = () => {
    if (state.id === transaction.owner.id) {
      generateExchangeCode({
        variables: {
          input: {
            transactionID: transaction.id
          }
        }
      }).then((response) => {
        showModal({
          title: 'Exchange Code',
          content: () =>
            ExchangeItem({
              exchangeCode: response.generateExchangeCode.exchangeCode,
              transaction
            })
        });
      });
      if (transaction.transactionStatus === TransactionStatus.ACCEPTED) {
        sendSystemMessage(
          SystemMessage.GENERATE_START_EXCHANGE_CODE,
          transaction.renter.id
        );
      } else if (transaction.transactionStatus === TransactionStatus.INRENT) {
        sendSystemMessage(
          SystemMessage.GENERATE_END_EXCHANGE_CODE,
          transaction.renter.id
        );
      }
    } else {
      if (
        transaction.transactionStatus === TransactionStatus.INRENT &&
        ((transaction.Thing.periodType === PeriodType.HOURS &&
          (Date.parse(transaction.endDateTime) - Date.now()) / 1000 / 60 >
            45) ||
          ((transaction.Thing.periodType === PeriodType.DAYS ||
            transaction.Thing.periodType === PeriodType.WEEKS ||
            transaction.Thing.periodType === PeriodType.MONTHS) &&
            new Date(transaction.endDateTime).getDate() !==
              new Date().getDate()))
      ) {
        showModal({
          title: 'Complete Rental Early?',
          content: () => EarlyDropOff({ transaction })
        });
      } else {
        showModal({
          title: 'Verify Exchange Code',
          content: () => AcceptExchange({ transaction })
        });
      }
    }
  };

  const renderPaymentMethod = () => {
    if (state.id === transaction.owner.id) {
      return null;
    }

    const paymentMethod = getPaymentMethodsData?.getPaymentMethods?.items.find(
      (paymentMethod) => paymentMethod.id === transaction.paymentMethodID
    );

    return (
      <div className="pay-with">
        <p className="title">Pay with</p>
        <div className="bottom">
          <Icon icon="credit_card" />
          <span>•••• {paymentMethod?.last4}</span>
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    const renderSameStateButtons = (status) => {
      switch (status) {
        case TransactionStatus.ACCEPTED:
          return (
            <>
              {transaction.transactionStatus === TransactionStatus.ACCEPTED &&
                transaction.paymentStatus === PaymentStatus.CAPTURED && (
                  <ButtonText
                    color="primary"
                    loading={focused === 1 && loading}
                    onClick={() => {
                      setFocused(1);
                      exchangeItemModal();
                    }}
                    data-testid="acceptBtn"
                  >
                    Start Rent
                  </ButtonText>
                )}
              <ButtonText
                onClick={() => {
                  setFocused(0);

                  showModal({
                    title: 'Decline Request?',
                    content: () =>
                      Confirmation({
                        message: 'Are you sure?',
                        confirmAction: () => {
                          handleUpdateStatus(TransactionStatus.CANCELLED);
                        }
                      })
                  });
                }}
                loading={focused === 0 && loading}
              >
                Cancel Booking
              </ButtonText>
            </>
          );
        default:
          return (
            <ButtonText
              color="primary"
              loading={focused === 1 && loading}
              onClick={() => {
                setFocused(1);
                exchangeItemModal();
              }}
            >
              End Rent
            </ButtonText>
          );
      }
    };
    if (tab === TransactionType.SUPPLYING) {
      switch (transaction.transactionStatus) {
        case TransactionStatus.REQUESTED:
          return (
            <>
              <ButtonText
                color="primary"
                loading={focused === 1 && loading}
                onClick={() => {
                  setFocused(1);
                  handleUpdateStatus(TransactionStatus.ACCEPTED)
                    .then(() => {
                      addToast(
                        <ToastNotification
                          message="Rent request accepted"
                          toastId="transaction-status-accepted"
                          variant="success"
                        />,
                        { id: 'transaction-status-accepted' }
                      );
                    })
                    .catch((error) => {
                      if (
                        error.transactionStatus === TransactionStatus.CANCELLED
                      ) {
                        addToast(
                          <ToastNotification
                            message="Item Request has been cancelled"
                            toastId="transaction-status-cancelled"
                            variant="error"
                          />,
                          { id: 'transaction-status-cancelled' }
                        );
                      }
                    });
                }}
              >
                Accept
              </ButtonText>
              <ButtonText
                color="error"
                variant="outlined"
                onClick={() => {
                  setFocused(0);

                  showModal({
                    title: 'Decline Request?',
                    content: () =>
                      Confirmation({
                        message: 'Are you sure?',
                        confirmAction: () => {
                          handleUpdateStatus(TransactionStatus.DECLINED).then(
                            () =>
                              addToast(
                                <ToastNotification
                                  message="Rent request denied"
                                  toastId="transaction-status-declined"
                                  variant="success"
                                />,
                                { id: 'transaction-status-declined' }
                              )
                          );
                        }
                      })
                  });
                }}
                loading={focused === 0 && loading}
              >
                Decline
              </ButtonText>
            </>
          );
        case TransactionStatus.ACCEPTED:
          return renderSameStateButtons(TransactionStatus.ACCEPTED);
        case TransactionStatus.INRENT:
          return renderSameStateButtons(TransactionStatus.INRENT);
        default:
          return <></>;
      }
    } else {
      switch (transaction.transactionStatus) {
        case TransactionStatus.REQUESTED:
          return (
            <>
              <ButtonText
                variant="outlined"
                loading={focused === 0 && loading}
                onClick={() => {
                  setFocused(0);
                  handleUpdateStatus(TransactionStatus.CANCELLED);
                }}
              >
                Cancel
              </ButtonText>
              <ButtonText color="primary">Modify</ButtonText>
            </>
          );
        case TransactionStatus.ACCEPTED:
          return renderSameStateButtons(TransactionStatus.ACCEPTED);
        case TransactionStatus.INRENT:
          return renderSameStateButtons(TransactionStatus.INRENT);
        default:
          return (
            <ButtonText color="primary" onClick={requestAgain}>
              Request Again
            </ButtonText>
          );
      }
    }
  };

  return (
    <div className="transaction-details">
      <Header>
        {windowWidth <= 1024 && (
          <Header.Left>
            <ButtonIcon icon="arrow_back" onClick={closeModal} />
          </Header.Left>
        )}
        <Header.Center>Transaction Details</Header.Center>
        {windowWidth <= 1024 && <Header.Right />}
      </Header>
      <div className="body">
        <TransactionItemCard item={transaction.Thing} />
        <Divider />
        <div className="rental-dates">
          <span className="title">Request details</span>
          <div className="date-container">
            <TransactionDates
              startDateTime={transaction.startDateTime}
              endDateTime={transaction.endDateTime}
              periodType={transaction.Thing.periodType}
            />
          </div>
        </div>
        <Divider />
        <DocumentList documents={transaction.Thing.requiredDocuments || []} />
        <PricingInfo
          deposit={transaction.Thing.deposit}
          unitPrice={transaction.Thing.pricePerPeriod}
          periods={getPeriodConversion(
            transaction.Thing.periodType,
            transaction.startDateTime,
            transaction.endDateTime
          )}
          periodType={transaction.Thing.periodType}
          rentalPrice={transaction.rentalPrice}
        />
        <Divider />
        <>
          {renderPaymentMethod()}
          <Divider />
        </>
        {/* <AgreementLink transaction={transaction} /> */}
        <ButtonText variant="outlined">Report</ButtonText>
        {renderButtons()}
      </div>
    </div>
  );
};

TransactionDetails.propTypes = {
  closeModal: PropTypes.func,
  generateExchangeCode: PropTypes.func,
  handleUpdateStatus: PropTypes.func,
  loading: PropTypes.bool,
  requestAgain: PropTypes.func,
  sendSystemMessage: PropTypes.func,
  transaction: PropTypes.object
};

TransactionDetails.defaultProps = {
  closeModal: () => {},
  generateExchangeCode: () => {},
  handleUpdateStatus: () => {},
  loading: false,
  requestAgain: () => {},
  sendSystemMessage: () => {},
  transaction: null
};

export default TransactionDetails;
