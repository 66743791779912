import PropTypes from 'prop-types';
import React from 'react';
import { ButtonIcon, FormTextArea } from '../../../../components';
import './styles.scss';

const ConversationFooter = ({
  disabled,
  onChange,
  onClick,
  onKeyPress,
  value
}) => {
  return (
    <div className="conversation-footer">
      <ButtonIcon icon="add_photo_alternate" variant="text" />
      <FormTextArea
        className="chat-box"
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder="Message"
        value={value}
      />
      <ButtonIcon
        className="send-button"
        disabled={disabled}
        icon="send"
        onClick={onClick}
        variant="contained"
      />
    </div>
  );
};

ConversationFooter.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.string
};

ConversationFooter.defaultProps = {
  disabled: false,
  onChange: () => {},
  onClick: () => {},
  onKeyPress: () => {},
  value: ''
};

export default ConversationFooter;
