import * as yup from 'yup';
import { resetPasswordFormValidation } from './';

const passwordSecurityFormValidationSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required.')
});

const passwordSecurityFormValidation = resetPasswordFormValidation.concat(
  passwordSecurityFormValidationSchema
);

export default passwordSecurityFormValidation;
