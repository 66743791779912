import PropTypes from 'prop-types';
import React from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import ButtonIcon from '../../../ButtonIcon';
import ButtonText from '../../../ButtonText';
import { FormGroup } from '../../../Forms';
import Map from '../../../Map';
import SearchField from '../../SearchField';
import NearbyLocation from './NearbyLocation';
import './styles.scss';

const Location = ({
  initializeMap,
  lat,
  locationRef,
  lon,
  onBackClick,
  onCancelClick,
  onChange,
  onCloseClick,
  onKeyPress,
  onOKClick,
  onSuggestionClick,
  value
}) => {
  const { getPlacePredictions, placePredictions, placesService } =
    usePlacesAutocompleteService({
      apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
      debounce: 500
    });

  return (
    <>
      <div className="top">
        <div className="navigation">
          <ButtonIcon icon="arrow_back" onClick={onBackClick} />
          <span className="text">Set rental location</span>
          <ButtonIcon icon="close" onClick={onCloseClick} />
        </div>
        <div className="search-field-locations-container">
          <SearchField
            icon="place"
            placeholder="Where would you like to rent?"
            value={value}
            autoFocus
            onChange={(event) => {
              getPlacePredictions({ input: event.target.value });
              onChange({ address: event.target.value });
            }}
            onKeyPress={onKeyPress}
          />
          {!!placePredictions.length && (
            <div className="locations">
              {placePredictions.map((place, index) => (
                <NearbyLocation
                  key={index}
                  label={place.description}
                  onClick={() => {
                    placesService.getDetails(
                      {
                        placeId: place.place_id,
                        fields: ['geometry.location']
                      },
                      ({ geometry }) => {
                        onSuggestionClick({
                          lat: geometry.location.lat(),
                          lon: geometry.location.lng(),
                          address: place.description
                        });
                      }
                    );
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <Map
          locationRef={locationRef}
          initializeMap={initializeMap}
          lat={lat}
          lon={lon}
        />
      </div>
      <div className="bottom">
        <FormGroup>
          <ButtonText color="primary" onClick={onOKClick}>
            Set Location
          </ButtonText>
          <ButtonText variant="outlined" onClick={onCancelClick}>
            Reset
          </ButtonText>
        </FormGroup>
      </div>
    </>
  );
};

Location.propTypes = {
  initializeMap: PropTypes.func.isRequired,
  lat: PropTypes.object.isRequired,
  locationRef: PropTypes.object.isRequired,
  lon: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onOKClick: PropTypes.func.isRequired,
  onSuggestionClick: PropTypes.func.isRequired,
  value: PropTypes.string
};

Location.defaultProps = {
  value: ''
};

export default Location;
