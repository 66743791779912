import React from 'react';
import {
  ISSUES_WITH_UKUU_LINK,
  ISSUES_WITH_USERS_ITEMS_LINK,
  PRIVACY_POLICY_LINK,
  PROVIDE_FEEDBACK_LINK,
  TERMS_OF_USE_LINK
} from '../../constants/links';
import './styles.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <nav className="top">
        <nav className="left">
          <span className="title">LET US KNOW</span>
          <a
            href={ISSUES_WITH_USERS_ITEMS_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Report Misuse
          </a>
          <a
            href={ISSUES_WITH_UKUU_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Report a Problem
          </a>
          <a
            href={PROVIDE_FEEDBACK_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Provide Feedback
          </a>
        </nav>
        <nav className="right">
          <span className="title">INFORMATION</span>
          <a
            href={PRIVACY_POLICY_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Privacy Policy
          </a>
          <a
            href={TERMS_OF_USE_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Terms and Conditions
          </a>
        </nav>
      </nav>
      <div className="bottom">
        <p className="copyright">
          {`© Copyright 2021 - ${currentYear} Ukuu Inc. All rights reserved.`}
        </p>
        <p className="version">
          Build version: {process.env.REACT_APP_COMMIT_ID.substr(0, 6)}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
