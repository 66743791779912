import { LendYours } from '../../components';
import React from 'react';
import PropTypes from 'prop-types';

const WrapperComponent = ({ handleClick }) => {
  return (
    <div className="container">
      <LendYours handleClick={handleClick} />
    </div>
  );
};

export default WrapperComponent;

WrapperComponent.propTypes = {
  handleClick: PropTypes.func
};

WrapperComponent.defaultProps = {
  handleClick: () => {}
};
