import PropTypes from 'prop-types';
import React from 'react';
import RNModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import {
  ButtonIcon,
  ButtonText,
  Header,
  FormTitle
} from '../../../../components';
import { useWindowDimensions } from '../../../../hooks';
import { URL_HELPERS } from '../../../../utils/url';
import useSignInInformation from './useSignInInformation';
import './styles.scss';
import { EmailAddressForm, PasswordForm } from './components';

const SignInInformation = ({ user }) => {
  const history = useHistory();

  const { windowWidth } = useWindowDimensions();

  const {
    emailAddressForm,
    passwordForm,
    updateEmailAddressLoading,
    updatePasswordLoading,
    onEmailAddressSubmit,
    onPasswordSubmit
  } = useSignInInformation(user);

  const handleClick = () => history.push(URL_HELPERS.profile);

  const renderComponent = () => {
    return (
      <div className="sign-in-information-subpage">
        <Header>
          <Header.Left>
            <ButtonIcon icon="arrow_back" onClick={handleClick} />
          </Header.Left>
          <Header.Center>Security and Login</Header.Center>
          <Header.Right />
        </Header>
        <div className="body">
          <FormTitle title="Email address" />
          <EmailAddressForm
            form={emailAddressForm}
            isLoading={updateEmailAddressLoading}
            onSubmit={onEmailAddressSubmit}
          />
          <FormTitle title="Password" />
          <PasswordForm
            form={passwordForm}
            isLoading={updatePasswordLoading}
            onSubmit={onPasswordSubmit}
          />
          <ButtonText variant="outlined" onClick={handleClick}>
            Cancel
          </ButtonText>
        </div>
      </div>
    );
  };

  return windowWidth <= 1024 ? (
    <RNModal overlayClassName="overlay" className="modal-content" isOpen>
      {renderComponent()}
    </RNModal>
  ) : (
    renderComponent()
  );
};

SignInInformation.propTypes = {
  user: PropTypes.object
};

SignInInformation.defaultProps = {
  user: null
};

export default SignInInformation;
