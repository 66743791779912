import { useContext, useState } from 'react';
import { API } from 'aws-amplify';
import AuthContext from '../../contexts/AuthContext';

/**
 * @param {import('@aws-amplify/api-graphql').GraphQLOptions} args
 */
const useMutation = (query, args) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const {
    state: { accessToken }
  } = useContext(AuthContext);

  const callback = async (params = {}) => {
    try {
      setLoading(true);
      const response = await API.graphql({
        query,
        authMode: accessToken ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY',
        ...params,
        ...args
      });
      setData(response.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  return [callback, { loading, data, error }];
};

export default useMutation;
