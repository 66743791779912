import React from 'react';
import './styles.scss';

const Loader = () => {
  return (
    <div className="loader" data-testid="loader">
      <i type="icon-spinner animate-spin" className="loading" />
    </div>
  );
};

export default Loader;
