import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import { getDistance } from '../../utils';
import './styles.scss';
import ItemCard from './ItemCard';

const ItemCardList = ({
  children,
  firstCoords,
  headerNode,
  history,
  label,
  things
}) => {
  const { state } = useContext(UserContext);

  const handleClick = (thing) => {
    return () =>
      history.push(
        `/${thing.User.publicID === state.publicID ? 'my-items' : 'items'}/${
          thing.publicID
        }`
      );
  };

  return (
    <div className="item-card-list">
      {headerNode}
      {label && <span className="label">{label}</span>}
      <div className="list">
        {children}
        {things?.map((thing) => (
          <ItemCard
            address={thing.address}
            distance={getDistance(firstCoords || state.currentLocation, {
              lat: thing.location.lat,
              lon: thing.location.lon
            })}
            hasToggle={thing.User?.id === state.id && true}
            listed={thing.listed}
            name={thing.name}
            numberOfRatings={thing.numberOfRatings || 0}
            onClick={handleClick(thing)}
            periodType={thing.periodType}
            pricePerPeriod={thing.pricePerPeriod || 0}
            rating={thing.rating || 5}
            s3Image={thing.images?.[0]}
            thing={thing}
            key={thing.id}
          />
        ))}
      </div>
    </div>
  );
};

ItemCardList.propTypes = {
  children: PropTypes.node,
  firstCoords: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  headerNode: PropTypes.node,
  history: PropTypes.object,
  label: PropTypes.string,
  things: PropTypes.array
};

ItemCardList.defaultProps = {
  children: null,
  firstCoords: '' || 0,
  headerNode: null,
  history: null,
  label: '',
  things: []
};

export default ItemCardList;
