/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const archiveThing = /* GraphQL */ `
  mutation ArchiveThing($input: ArchiveThingInput!) {
    archiveThing(input: $input) {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const requestThing = /* GraphQL */ `
  mutation RequestThing(
    $input: RequestThingInput!
    $condition: RequestThingConditionInput
  ) {
    requestThing(input: $input, condition: $condition) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTransactionStatus = /* GraphQL */ `
  mutation UpdateTransactionStatus($input: UpdateTransactionStatusInput!) {
    updateTransactionStatus(input: $input) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const logTransaction = /* GraphQL */ `
  mutation LogTransaction(
    $input: LogTransactionInput!
    $recipientEmail: String
  ) {
    logTransaction(input: $input, recipientEmail: $recipientEmail) {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const setDefaultPaymentMethod = /* GraphQL */ `
  mutation SetDefaultPaymentMethod($input: SetDefaultPaymentMethodInput!) {
    setDefaultPaymentMethod(input: $input) {
      items {
        id
        brand
        last4
        billing_details {
          email
          name
          phone
        }
        exp_month
        exp_year
        default
      }
    }
  }
`;
export const createStripeCustomer = /* GraphQL */ `
  mutation CreateStripeCustomer($input: CreateStripeCustomerInput!) {
    createStripeCustomer(input: $input) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const attachPaymentMethodToCustomer = /* GraphQL */ `
  mutation AttachPaymentMethodToCustomer(
    $input: AttachPaymentMethodToCustomerInput!
  ) {
    attachPaymentMethodToCustomer(input: $input) {
      items {
        id
        brand
        last4
        billing_details {
          email
          name
          phone
        }
        exp_month
        exp_year
        default
      }
    }
  }
`;
export const createSetupIntent = /* GraphQL */ `
  mutation CreateSetupIntent($input: CreateSetupIntentInput!) {
    createSetupIntent(input: $input) {
      clientSecret
    }
  }
`;
export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent($input: CreatePaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePaymentMethod = /* GraphQL */ `
  mutation DeletePaymentMethod($input: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      id
      brand
      last4
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        email
        name
        phone
      }
      exp_month
      exp_year
      default
    }
  }
`;
export const confirmPaymentIntent = /* GraphQL */ `
  mutation ConfirmPaymentIntent($input: ConfirmPaymentIntentInput!) {
    confirmPaymentIntent(input: $input)
  }
`;
export const postUserReview = /* GraphQL */ `
  mutation PostUserReview($input: PostUserReviewInput!) {
    postUserReview(input: $input) {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const postThingReview = /* GraphQL */ `
  mutation PostThingReview($input: PostThingReviewInput!) {
    postThingReview(input: $input) {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const activateExchangeCode = /* GraphQL */ `
  mutation ActivateExchangeCode($input: ActivateExchangeCodeInput!) {
    activateExchangeCode(input: $input) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStripeAccount = /* GraphQL */ `
  mutation CreateStripeAccount($input: CreateStripeAccountInput!) {
    createStripeAccount(input: $input) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStripeAccountLink = /* GraphQL */ `
  mutation CreateStripeAccountLink($input: CreateStripeAccountLinkInput!) {
    createStripeAccountLink(input: $input) {
      object
      created
      expires_at
      url
    }
  }
`;
export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      brand
      last4
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        email
        name
        phone
      }
      exp_month
      exp_year
      default
    }
  }
`;
export const generateExchangeCode = /* GraphQL */ `
  mutation GenerateExchangeCode($input: GenerateExchangeCodeInput!) {
    generateExchangeCode(input: $input) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTransactionHistory = /* GraphQL */ `
  mutation CreateTransactionHistory(
    $input: CreateTransactionHistoryInput!
    $condition: ModelTransactionHistoryConditionInput
  ) {
    createTransactionHistory(input: $input, condition: $condition) {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTransactionHistory = /* GraphQL */ `
  mutation UpdateTransactionHistory(
    $input: UpdateTransactionHistoryInput!
    $condition: ModelTransactionHistoryConditionInput
  ) {
    updateTransactionHistory(input: $input, condition: $condition) {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTransactionHistory = /* GraphQL */ `
  mutation DeleteTransactionHistory(
    $input: DeleteTransactionHistoryInput!
    $condition: ModelTransactionHistoryConditionInput
  ) {
    deleteTransactionHistory(input: $input, condition: $condition) {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      executeAt
      function
      data
      hasExecuted
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      executeAt
      function
      data
      hasExecuted
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      executeAt
      function
      data
      hasExecuted
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createThing = /* GraphQL */ `
  mutation CreateThing(
    $input: CreateThingInput!
    $condition: ModelThingConditionInput
  ) {
    createThing(input: $input, condition: $condition) {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateThing = /* GraphQL */ `
  mutation UpdateThing(
    $input: UpdateThingInput!
    $condition: ModelThingConditionInput
  ) {
    updateThing(input: $input, condition: $condition) {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteThing = /* GraphQL */ `
  mutation DeleteThing(
    $input: DeleteThingInput!
    $condition: ModelThingConditionInput
  ) {
    deleteThing(input: $input, condition: $condition) {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      publicID
      transactionID
      transaction {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedID
      lastMessageReceived {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedAt
      messages {
        items {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasRead
      ownerID
      renterID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      publicID
      transactionID
      transaction {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedID
      lastMessageReceived {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedAt
      messages {
        items {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasRead
      ownerID
      renterID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      publicID
      transactionID
      transaction {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedID
      lastMessageReceived {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedAt
      messages {
        items {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasRead
      ownerID
      renterID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSearch = /* GraphQL */ `
  mutation CreateSearch(
    $input: CreateSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    createSearch(input: $input, condition: $condition) {
      id
      keyword
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSearch = /* GraphQL */ `
  mutation UpdateSearch(
    $input: UpdateSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    updateSearch(input: $input, condition: $condition) {
      id
      keyword
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSearch = /* GraphQL */ `
  mutation DeleteSearch(
    $input: DeleteSearchInput!
    $condition: ModelSearchConditionInput
  ) {
    deleteSearch(input: $input, condition: $condition) {
      id
      keyword
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
