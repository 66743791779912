import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../../assets/icons/Logo.svg';
import { useS3Read } from '../../hooks';
import { isEmpty } from '../../utils';
import './styles.scss';

const Image = ({ className, image }) => {
  const { loading, data } = useS3Read(image);

  const classNames = clsx('image-container', className);

  return (
    <div className={classNames}>
      {isEmpty(image) || loading ? (
        <img className="placeholder-image" src={Logo} />
      ) : (
        <img className="image" src={data} />
      )}
    </div>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object
};

Image.defaultProps = {
  className: '',
  image: null
};

export default Image;
