const alertTransaction = (transaction, userId) => {
  if (!transaction.hasRead) {
    if (
      userId === transaction.ownerID &&
      transaction.transactionStatus === 'REQUESTED'
    ) {
      return true;
    } else if (
      userId === transaction.renterID &&
      transaction.transactionStatus === 'INRENT'
    ) {
      return true;
    } else if (
      userId === transaction.renterID &&
      transaction.transactionStatus === 'ACCEPTED'
    ) {
      return true;
    } else if (
      userId === transaction.ownerID &&
      transaction.transactionStatus === 'INRENT'
    ) {
      return true;
    } else if (
      userId === transaction.renterID &&
      (transaction.transactionStatus === 'CANCELLED' ||
        transaction.transactionStatus === 'COMPLETED' ||
        transaction.transactionStatus === 'DECLINED')
    ) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export default alertTransaction;
