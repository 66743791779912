import * as yup from 'yup';

const userFormValidation = yup.object().shape({
  firstName: yup.string().required('First name is empty'),
  lastName: yup.string().required('Last name is empty'),
  location: yup
    .object()
    .shape({
      lat: yup.string(),
      lon: yup.string(),
      address: yup.string()
    })
    .test('location', null, function (obj) {
      if (!obj.address) {
        return this.createError({
          message: 'Location is required'
        });
      } else if (!obj.lat || !obj.lon) {
        return this.createError({
          message: 'You must select a location from the list of suggestions'
        });
      } else {
        return true;
      }
    }),
  bio: yup.string().max(500)
});

export default userFormValidation;
