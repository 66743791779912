import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import boxImage from '../../assets/images/box.png';
import cloudImage from '../../assets/images/cloud.png';
import creditCardImage from '../../assets/images/credit-card.png';
import documentsImage from '../../assets/images/documents.png';
import hedgehogImage from '../../assets/images/hedgehog.png';
import illustrationImage from '../../assets/images/illustration.png';
import Icon from '../Icon';
import './styles.scss';

const EmptyListPlaceholder = ({
  children,
  icon,
  size,
  supportingText,
  text,
  type
}) => {
  const BOX = ['item'];
  const CLOUD = ['account', 'profile'];
  const CREDIT_CARD = ['payment method'];
  const DOCUMENTS = ['transaction', 'message', 'review'];
  const ILLUSTRATION = ['image'];

  let imageVariant = '';

  if (BOX.includes(type)) {
    imageVariant = boxImage;
  } else if (CLOUD.includes(type)) {
    imageVariant = cloudImage;
  } else if (CREDIT_CARD.includes(type)) {
    imageVariant = creditCardImage;
  } else if (DOCUMENTS.includes(type)) {
    imageVariant = documentsImage;
  } else if (ILLUSTRATION.includes(type)) {
    imageVariant = illustrationImage;
  } else {
    imageVariant = hedgehogImage;
  }

  const imageClassNames = clsx('image', `-${size}`);
  const iconContainerClassNames = clsx('icon-container', `-${size}`);
  const iconClassNames = clsx(`-${size}`);

  return (
    <div className="empty-list-placeholder">
      <div
        className={imageClassNames}
        style={{
          backgroundImage: `url(${imageVariant})`
        }}
      >
        {children}
        {type !== 'desktop' && (
          <div className={iconContainerClassNames}>
            <Icon icon={icon} className={iconClassNames} />
          </div>
        )}
      </div>
      <div className="text-container">
        <span className="text">{text}</span>
        <div className="supporting-text">{supportingText}</div>
      </div>
    </div>
  );
};

EmptyListPlaceholder.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  supportingText: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf([
    'item',
    'transaction',
    'message',
    'review',
    'payment method',
    'account',
    'profile',
    'image',
    'app'
  ])
};

EmptyListPlaceholder.defaultProps = {
  children: null,
  icon: '',
  size: 'small',
  supportingText: '',
  text: '',
  type: 'app'
};

export default EmptyListPlaceholder;
