import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  SHOW_MODAL,
  CLOSE_MODAL,
  CHANGE_MODAL_TITLE,
  UPDATE_MODAL
} from './constants';
import AuthContext from './AuthContext';
import {
  SignInForm,
  SignUpForm,
  AddPaymentMethodForm,
  EditPaymentMethodForm
} from '../components';

const initialState = {
  content: () => {},
  visible: false,
  title: ''
};

const ModalContext = createContext({ state: initialState });

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        visible: true,
        content: action.payload.content,
        title: action.payload.title
      };
    case UPDATE_MODAL:
      return {
        ...state,
        ...action.payload
      };
    case CLOSE_MODAL:
      return {
        ...state,
        visible: false,
        title: '',
        content: () => {}
      };
    case CHANGE_MODAL_TITLE:
      return {
        ...state,
        title: action.payload
      };
    default:
      return state;
  }
};

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  const {
    state: { accessToken }
  } = useContext(AuthContext);

  const showModal = (payload) => {
    dispatch({
      type: SHOW_MODAL,
      payload
    });
  };

  const changeModalTitle = (title) => {
    dispatch({
      type: CHANGE_MODAL_TITLE,
      payload: title
    });
  };

  const showSigninModal = (event) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        content: () => SignInForm({ event }),
        title: 'Sign In'
      }
    });
  };

  const showSignupModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        content: () => SignUpForm(location),
        title: 'Sign Up'
      }
    });
  };

  const updateModal = (payload) => {
    console.log('update modal', payload);
    dispatch({
      type: UPDATE_MODAL,
      payload
    });
  };

  const authorizedModal = (payload, event) => {
    if (accessToken) {
      dispatch({
        type: SHOW_MODAL,
        payload
      });
    } else {
      showSigninModal(event);
    }
  };

  const AddPaymentMethodModal = (callback) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        content: () =>
          AddPaymentMethodForm({
            callback
          }),
        title: 'Add Payment Method'
      }
    });
  };

  const EditPaymentMethodModal = (props) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        content: () =>
          EditPaymentMethodForm({
            ...props
          }),
        title: 'Edit Payment Method'
      }
    });
  };

  const paymentServiceModal = ({ hasPaymentMethods, content, title }) => {
    if (accessToken) {
      dispatch({
        type: SHOW_MODAL,
        payload: {
          content: () =>
            hasPaymentMethods
              ? content()
              : AddPaymentMethodForm({
                  modalContent: { content, title }
                }),
          title: hasPaymentMethods ? title : 'Add Payment Method'
        }
      });
    } else {
      showSigninModal();
    }
  };

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
  };

  return (
    <ModalContext.Provider
      value={{
        state,
        showModal,
        closeModal,
        changeModalTitle,
        showSigninModal,
        showSignupModal,
        authorizedModal,
        paymentServiceModal,
        updateModal,
        AddPaymentMethodModal,
        EditPaymentMethodModal
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModalContext;
