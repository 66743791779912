import React, { useContext } from 'react';
import RNModal from 'react-modal';
import ModalContext from '../../contexts/ModalContext';
import ButtonIcon from '../ButtonIcon';
import Header from '../Header';
import './styles.scss';

if (process.env.NODE_ENV !== 'test') RNModal.setAppElement('#root');

const Modal = () => {
  const { state, closeModal } = useContext(ModalContext);

  return (
    <RNModal
      overlayClassName="overlay -modal"
      className="modal-content"
      isOpen={state.visible}
      ariaHideApp={false}
    >
      <Header>
        <Header.Left>
          <ButtonIcon icon="arrow_back" onClick={closeModal} />
        </Header.Left>
        <Header.Center data-testid="title">{state.title}</Header.Center>
        <Header.Right />
      </Header>
      <div className="modal-body" data-testid="modalContent">
        {state.content()}
      </div>
    </RNModal>
  );
};

export default Modal;
