import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import './styles.scss';

const Distance = ({ className, distance }) => {
  const classNames = clsx('distance', className);

  return (
    <address className="distance-container" data-testid="distance">
      <Icon icon="place" />
      <span className={classNames}>{distance} mi</span>
    </address>
  );
};

Distance.propTypes = {
  className: PropTypes.string,
  distance: PropTypes.string
};

Distance.defaultProps = {
  className: '',
  distance: ''
};

export default Distance;
