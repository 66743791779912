import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import ModalContext from '../../contexts/ModalContext';
import { activateExchangeCode as activateExchangeCodeMutation } from '../../graphql/mutations';
import { useMutation } from '../../hooks';
import { TransactionStatus } from '../../models';
import { Badge, ButtonText, FormInput, ToastNotification } from '..';
import './styles.scss';

const AcceptExchange = ({ transaction }) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {}
  });

  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { closeModal } = useContext(ModalContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [activateExchangeCode] = useMutation(activateExchangeCodeMutation);

  const onSubmit = async (values) => {
    let renterExchangeCode = '';
    for (let key in values) {
      renterExchangeCode += values[key];
    }

    try {
      setLoading(true);
      const response = await activateExchangeCode({
        variables: {
          input: {
            transactionID: transaction.id,
            exchangeCode: renterExchangeCode
          }
        }
      });

      if (
        response.activateExchangeCode.transactionStatus ===
        TransactionStatus.INRENT
      ) {
        addToast(
          <ToastNotification
            message="Booking has begun"
            toastId="transaction-inrent"
            variant="success"
          />,
          { id: 'transaction-inrent' }
        );
      } else {
        addToast(
          <ToastNotification
            message="Booking has completed"
            toastId="transaction-completed"
            variant="success"
          />,
          { id: 'transaction-completed' }
        );
      }
      closeModal();
    } catch (error) {
      console.error('error', error);

      setErrorMessage('Invalid exchange code');
      addToast(
        <ToastNotification
          message="Invalid exchange code"
          toastId="transaction-invalid-code"
          variant="error"
        />,
        { id: 'transaction-invalid-code' }
      );
      reset();
    } finally {
      setLoading(false);
    }
  };

  const handleKeyUp = (event, index) => {
    const form = event.target.form;
    form.elements[index].focus();
  };

  return (
    <form className="accept-exchange" onSubmit={handleSubmit(onSubmit)}>
      <p className="message">
        Enter in the six-digit code provided by {transaction.owner.firstName} to
        verify this item exchange.
      </p>
      <div className="exchange-code">
        {errorMessage && (
          <Badge
            variant="contained"
            type="error"
            label={errorMessage}
            className="message"
          />
        )}
        <div className="code">
          <FormInput
            control={control}
            name="exchangeCode1"
            type="number"
            autoFocus
            tabIndex={1}
            onKeyUp={(event) => handleKeyUp(event, 1)}
            placeholder="-"
          />
          <FormInput
            control={control}
            name="exchangeCode2"
            type="number"
            tabIndex={2}
            onKeyUp={(event) => handleKeyUp(event, 2)}
            placeholder="-"
          />
          <FormInput
            control={control}
            name="exchangeCode3"
            type="number"
            tabIndex={3}
            onKeyUp={(event) => handleKeyUp(event, 3)}
            placeholder="-"
          />
          <FormInput
            control={control}
            name="exchangeCode4"
            type="number"
            tabIndex={4}
            onKeyUp={(event) => handleKeyUp(event, 4)}
            placeholder="-"
          />
          <FormInput
            control={control}
            name="exchangeCode5"
            type="number"
            tabIndex={5}
            onKeyUp={(event) => handleKeyUp(event, 5)}
            placeholder="-"
          />
          <FormInput
            control={control}
            name="exchangeCode6"
            type="number"
            tabIndex={6}
            onKeyUp={(event) => handleKeyUp(event, 6)}
            placeholder="-"
          />
        </div>
      </div>
      <p className="expiration-timer">This code will expire in 00:60 seconds</p>
      <ButtonText color="primary" loading={loading}>
        Submit
      </ButtonText>
      <ButtonText variant="outlined" type="button" onClick={closeModal}>
        Close
      </ButtonText>
    </form>
  );
};

AcceptExchange.propTypes = {
  transaction: PropTypes.object
};

AcceptExchange.defaultProps = {
  transaction: null
};

export default AcceptExchange;
