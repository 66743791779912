import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  OWNER_AGREEMENT_LINK,
  RENTER_AGREEMENT_LINK
} from '../../constants/links';
import UserContext from '../../contexts/UserContext';
import Icon from '../Icon';
import './styles.scss';

const AgreementLink = ({ link, transaction }) => {
  const { state } = useContext(UserContext);

  return (
    <a
      href={
        link || transaction.ownerID === state.id
          ? OWNER_AGREEMENT_LINK
          : RENTER_AGREEMENT_LINK
      }
      target="_blank"
      rel="noreferrer"
      className="agreement-link"
    >
      <span className="label">Agreement</span>
      <div className="icon-container">
        <Icon icon="info" />
      </div>
    </a>
  );
};

AgreementLink.propTypes = {
  link: PropTypes.oneOf([OWNER_AGREEMENT_LINK, RENTER_AGREEMENT_LINK]),
  transaction: PropTypes.object
};

AgreementLink.defaultProps = {
  link: RENTER_AGREEMENT_LINK,
  transaction: null
};

export default AgreementLink;
