import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';
import {
  SET_USER_LOCATION,
  SET_USER_DATA,
  REMOVE_USER_DATA
} from './constants';

const initialState = {
  location: {
    lat: null,
    lon: null,
    address: null
  },
  currentLocation: {
    lat: null,
    lon: null,
    address: null
  },
  email: '',
  sub: '',
  id: '',
  publicID: '',
  firstName: '',
  lastName: '',
  fullName: '',
  hasPaymentMethods: false,
  customerID: '',
  unreadConversations: [],
  avatar: null,
  loading: true,
  stripeAccountID: '',
  stripeDetailsSubmitted: false
};

const UserContext = createContext({ state: initialState });

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_USER_LOCATION:
        return {
          ...state,
          currentLocation: action.payload.location
        };
      case SET_USER_DATA:
        return {
          ...state,
          ...action.payload,
          loading: false
        };
      case REMOVE_USER_DATA:
        return initialState;
      default:
        return state;
    }
  }, initialState);

  const setUserLocation = (data) => {
    dispatch({
      type: SET_USER_LOCATION,
      payload: {
        location: {
          lat: data.lat,
          lon: data.lon,
          address: data.address
        }
      }
    });
  };

  const setUserData = (data) => {
    dispatch({
      type: SET_USER_DATA,
      payload: data
    });
  };

  const removeUserData = () => {
    dispatch({ type: REMOVE_USER_DATA });
  };

  return (
    <UserContext.Provider
      value={{ state, setUserLocation, setUserData, removeUserData }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserContext;
