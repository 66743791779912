import PropTypes from 'prop-types';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { Controller } from 'react-hook-form';
import { FormInputError, FormLabel } from '../Forms';
import './styles.scss';

const LocationInput = ({
  className,
  control,
  defaultValue,
  error,
  handleChange,
  inputClassName,
  label,
  name,
  noController,
  placeholder,
  required,
  saveLatLng,
  ...props
}) => {
  return (
    <div className={`form-input-container ${className}`}>
      {label && <FormLabel text={label} required={required} />}
      {noController ? (
        <Autocomplete
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={`input ${inputClassName}`}
          options={{
            types: []
          }}
          onPlaceSelected={({ geometry }, input) => {
            if (saveLatLng) {
              if (geometry) {
                handleChange({
                  latitude: geometry.location.lat(),
                  longitude: geometry.location.lng(),
                  address: input.value
                });
              }
            } else {
              handleChange({ address: input.value });
            }
          }}
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
          data-testid="locationInput"
          {...props}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Autocomplete
              defaultValue={value.address}
              className={`input ${inputClassName}`}
              options={{
                types: []
              }}
              onChange={(event) => {
                onChange({ address: event.target.value });
              }}
              onPlaceSelected={({ geometry, ...rest }, input) => {
                console.log(rest);
                if (saveLatLng) {
                  onChange({
                    lat: geometry.location.lat(),
                    lon: geometry.location.lng(),
                    address: input.value
                  });
                } else {
                  onChange({ address: input.value });
                }
              }}
              apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
              data-testid="locationInput"
              {...props}
              {...rest}
            />
          )}
        />
      )}
      {error && <FormInputError error={error} data-testid={`${name}Error`} />}
    </div>
  );
};

LocationInput.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  error: PropTypes.object,
  handleChange: PropTypes.func,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  noController: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  saveLatLng: PropTypes.bool
};

LocationInput.defaultProps = {
  className: '',
  control: {},
  defaultValue: '',
  error: null,
  handleChange: () => {},
  inputClassName: '',
  label: '',
  name: '',
  noController: false,
  placeholder: '',
  required: false,
  saveLatLng: false
};

export default LocationInput;
