import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import RNModal from 'react-modal';
import { useParams } from 'react-router-dom';
import { Loader, RequestItem } from '../../components';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import {
  requestThing as requestThingMutation,
  sendMessage as sendMessageMutation
} from '../../graphql/customMutations';
import {
  getUserTransaction as getUserTransactionQuery,
  getUserTransactions as getUserTransactionsQuery
} from '../../graphql/customQueries';
import {
  generateExchangeCode as generateExchangeCodeMutation,
  updateTransaction as updateTransactionMutation,
  updateTransactionStatus as updateTransactionStatusMutation
} from '../../graphql/mutations';
import {
  onOwnerUpdateTransaction as onOwnerUpdateTransactionSubscription,
  onRenterUpdateTransaction as onRenterUpdateTransactionSubscription
} from '../../graphql/subscriptions';
import { useLazyQuery, useMutation, useWindowDimensions } from '../../hooks';
import { TransactionStatus, TransactionType } from '../../models';
import { alertTransaction } from '../../utils';
import Transactions from './Transactions';
import './styles.scss';
import { TransactionDetails } from './components';

const TransactionsContainer = ({ history }) => {
  const { transactionId } = useParams();

  const [getUserTransactions, { loading: getUserTransactionsLoading }] =
    useLazyQuery(getUserTransactionsQuery);

  const [
    getUserTransaction,
    { loading: getUserTransactionLoading, data: getUserTransactionData }
  ] = useLazyQuery(getUserTransactionQuery);

  const [updateTransaction, { loading: updateTransactionLoading }] =
    useMutation(updateTransactionMutation);

  const [requestThing, { data: requestThingData }] =
    useMutation(requestThingMutation);

  const [generateExchangeCode, { loading: generateExchangeCodeLoading }] =
    useMutation(generateExchangeCodeMutation);

  const [
    updateTransactionStatus,
    {
      loading: updateTransactionStatusLoading,
      data: updateTransactionStatusData
    }
  ] = useMutation(updateTransactionStatusMutation);

  const [sendMessage] = useMutation(sendMessageMutation);

  const { windowWidth } = useWindowDimensions();

  const { state: userState } = useContext(UserContext);
  const { showModal, paymentServiceModal } = useContext(ModalContext);

  const [transactionsData, setTransactionsData] = useState([]);
  const [currentTab, setCurrentTab] = useState(TransactionType.SUPPLYING);
  const [activeTransactions, setActiveTransactions] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [transactionData, setTransactionData] = useState(null);

  const subscriptionRenterRef = useRef(null);
  const subscriptionOwnerRef = useRef(null);

  //

  useEffect(() => {
    setTransactionsData([]);
    setTransactionData(null);

    const getTransactions = async () => {
      const variables = {
        userID: userState.id,
        type: currentTab,
        limit: 10
      };

      const response = await getUserTransactions({ variables });

      setTransactionsData(response?.getUserTransactions.items);

      setNextToken(
        response?.getUserTransactions.nextToken
          ? response.getUserTransactions.nextToken
          : null
      );
    };

    getTransactions();
  }, [currentTab]);

  useEffect(() => {
    if (requestThingData?.requestThing) {
      history.push(`/transactions/${requestThingData.requestThing.publicID}`);
      setTransactionsData([requestThingData.requestThing, ...transactionsData]);
    }
  }, [requestThingData]);

  useEffect(() => {
    const subscribeToUpdatedTransactions = async ({
      variables,
      query,
      queryName,
      subscriptionRef
    }) => {
      try {
        subscriptionRef.current = await API.graphql({
          query,
          variables: {
            ...variables
          }
        }).subscribe({
          next: ({ value }) => {
            const updatedTransaction = value.data[queryName];

            const currentTransaction = transactionsData.find(
              (transaction) => transaction.id === updatedTransaction.id
            );
            const filteredTransactions = transactionsData.filter(
              (transaction) => transaction.id !== updatedTransaction.id
            );
            if (transactionId === transactionData.publicID) {
              setTransactionData({
                ...transactionData,
                ...updatedTransaction
              });
              setTransactionsData([
                {
                  ...currentTransaction,
                  ...updatedTransaction,
                  hasRead: true
                },
                ...filteredTransactions
              ]);
            } else {
              setTransactionsData([
                {
                  ...currentTransaction,
                  ...updatedTransaction
                },
                ...filteredTransactions
              ]);
            }
          },
          error: (error) => console.error('Transaction subscription: ', error)
        });
      } catch (error) {
        console.error(error);
      }
    };

    if (currentTab === 'SUPPLYING') {
      subscribeToUpdatedTransactions({
        variables: {
          ownerID: userState.id
        },
        query: onOwnerUpdateTransactionSubscription,
        queryName: 'onOwnerUpdateTransaction',
        subscriptionRef: subscriptionOwnerRef
      });
    } else {
      subscribeToUpdatedTransactions({
        variables: {
          renterID: userState.id
        },
        query: onRenterUpdateTransactionSubscription,
        queryName: 'onRenterUpdateTransaction',
        subscriptionRef: subscriptionRenterRef
      });
    }

    return () => {
      subscriptionOwnerRef?.current?.unsubscribe();
      subscriptionRenterRef?.current?.unsubscribe();
    };
  }, [transactionId, transactionData]);

  useEffect(() => {
    const getTransaction = async () => {
      setTransactionsData(
        transactionsData.map((transaction) => {
          if (transaction.publicID === transactionId) {
            return {
              ...transaction,
              hasRead: true
            };
          }
          return transaction;
        })
      );
      await getUserTransaction({
        variables: {
          publicID: transactionId,
          userID: userState.id
        }
      });
    };

    if (transactionId) {
      getTransaction();
    }
  }, [transactionId]);

  useEffect(() => {
    const updateTransactionRead = async () => {
      if (getUserTransactionData) {
        if (getUserTransactionData.getUserTransaction.items.length) {
          if (
            alertTransaction(
              getUserTransactionData.getUserTransaction.items[0],
              userState.id
            )
          ) {
            const updateTransactionResponse = await updateTransaction({
              variables: {
                input: {
                  id: getUserTransactionData.getUserTransaction.items[0].id,
                  hasRead: true,
                  _version:
                    getUserTransactionData.getUserTransaction.items[0]._version
                }
              }
            });
            setTransactionData(updateTransactionResponse.updateTransaction);
          } else {
            setTransactionData(
              getUserTransactionData.getUserTransaction.items[0]
            );
          }
        } else {
          history.push('/transactions');
        }
      }
    };
    updateTransactionRead();
  }, [getUserTransactionData]);

  useEffect(() => {
    setActiveTransactions(
      transactionsData.filter(({ transactionStatus }) => {
        if (currentTab === TransactionType.SUPPLYING) {
          return transactionStatus === TransactionStatus.INRENT;
        }
        return (
          transactionStatus === TransactionStatus.INRENT ||
          transactionStatus === TransactionStatus.ACCEPTED
        );
      })
    );
  }, [transactionsData]);

  useEffect(() => {
    if (updateTransactionStatusData) {
      setTransactionsData([
        updateTransactionStatusData.updateTransactionStatus,
        ...transactionsData.filter(
          (transaction) => transaction.id !== transactionData.id
        )
      ]);

      setTransactionData(updateTransactionStatusData.updateTransactionStatus);
    }
  }, [updateTransactionStatusData]);

  const handlePagination = async () => {
    if (nextToken) {
      const variables = {
        userID: userState.id,
        type: currentTab,
        limit: 10,
        nextToken
      };
      const response = await getUserTransactions({ variables });

      setTransactionsData([
        ...transactionsData,
        ...response.getUserTransactions.items
      ]);
      if (response.getUserTransactions.nextToken) {
        return setNextToken(response.getUserTransactions.nextToken);
      } else {
        return setNextToken(null);
      }
    }
  };

  const handleTabNavigation = (tab) => {
    history.push('/transactions');
    setCurrentTab(tab);
  };

  const handleUpdateStatus = async (transactionStatus) => {
    try {
      await updateTransactionStatus({
        variables: {
          input: {
            id: transactionData.id,
            _version: transactionData._version,
            hasRead: false,
            transactionStatus
          }
        }
      });
    } catch (error) {
      console.error('updateTransactionStatus', error);
      throw error;
    }
  };

  const sendSystemMessage = async (systemMessage, recipientID) => {
    if (recipientID) {
      // :: Send system message to each user
      await sendMessage({
        variables: {
          input: {
            conversationID: transactionData.conversation.id,
            conversationPublicID: transactionData.conversation.publicID,
            // fix systemmessage
            systemMessage,
            recipientID
          }
        }
      });
    } else {
      const userIDs = [transactionData.owner.id, transactionData.renter.id];
      // :: Send system message to each user
      userIDs.forEach(async (userID) => {
        await sendMessage({
          variables: {
            input: {
              conversationID: transactionData.conversation.id,
              conversationPublicID: transactionData.conversation.publicID,
              // fix systemmessage
              systemMessage,
              recipientID: userID
            }
          }
        });
      });
    }
  };

  const requestAgain = () => {
    if (transactionData.Thing.listed) {
      paymentServiceModal({
        hasPaymentMethods: userState.hasPaymentMethods,
        content: () => (
          <RequestItem
            thing={transactionData.Thing}
            requestThing={async (input) => {
              const response = await requestThing({
                variables: {
                  input
                }
              });
              return response;
            }}
          />
        ),
        title: 'Request item'
      });
    }
  };

  const renderTransactionDetails = () => {
    if (
      transactionData ||
      getUserTransactionLoading ||
      updateTransactionLoading
    ) {
      if (getUserTransactionLoading || updateTransactionLoading) {
        return <Loader />;
      } else {
        if (windowWidth <= 1024) {
          const closeModal = () => {
            setTransactionData(null);
            history.push('/transactions');
          };
          return (
            <RNModal
              overlayClassName="overlay"
              className="modal-content"
              isOpen
            >
              <TransactionDetails
                transaction={transactionData}
                user={
                  currentTab === TransactionType.SUPPLYING
                    ? transactionData.renter
                    : transactionData.owner
                }
                currentTab={currentTab}
                handleUpdateStatus={handleUpdateStatus}
                loading={
                  updateTransactionStatusLoading || generateExchangeCodeLoading
                }
                showModal={showModal}
                history={history}
                closeModal={closeModal}
                generateExchangeCode={generateExchangeCode}
                sendSystemMessage={sendSystemMessage}
                requestAgain={requestAgain}
              />
            </RNModal>
          );
        } else {
          return (
            <TransactionDetails
              transaction={transactionData}
              user={
                currentTab === TransactionType.SUPPLYING
                  ? transactionData.renter
                  : transactionData.owner
              }
              currentTab={currentTab}
              handleUpdateStatus={handleUpdateStatus}
              showModal={showModal}
              history={history}
              generateExchangeCode={generateExchangeCode}
              loading={
                updateTransactionStatusLoading || generateExchangeCodeLoading
              }
              sendSystemMessage={sendSystemMessage}
              requestAgain={requestAgain}
            />
          );
        }
      }
    } else {
      return (
        <h3 className="title" data-testid="selectTransactionTxt">
          Select a transaction
        </h3>
      );
    }
  };

  const renderTransactionList = () => {
    if (getUserTransactionsLoading && !transactionsData.length) {
      return <Loader />;
    } else {
      return transactionsData.map((transaction) => (
        <div className="transaction" key={transaction.id}>
          {transaction.id}
        </div>
      ));
    }
  };

  return (
    <Transactions
      handleTabNavigation={handleTabNavigation}
      transactionsData={transactionsData}
      handlePagination={handlePagination}
      nextToken={nextToken}
      renderTransactionDetails={renderTransactionDetails}
      renderTransactionList={renderTransactionList}
      activeTransactions={activeTransactions}
    />
  );
};

TransactionsContainer.propTypes = {
  history: PropTypes.object.isRequired
};

export default TransactionsContainer;
