import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { Avatar, ToastNotification } from '../../../../components';
import { useS3Read } from '../../../../hooks';
import { validateImages } from '../../../../utils';
import ButtonAvatarUpload from '../ButtonAvatarUpload';
import './styles.scss';

const AvatarMedia = () => {
  const { control, getValues } = useFormContext();
  const { addToast } = useToasts();

  const { data } = useS3Read(getValues('avatar'));

  const [image, setImage] = useState('');

  const mapPreviews = (image) => {
    try {
      return URL.createObjectURL(image);
    } catch {
      return image;
    }
  };

  const updateImages = (onChange, acceptedFiles) => {
    onChange(acceptedFiles);
    setImage(mapPreviews(acceptedFiles));
  };

  const handleChange = async (event, onChange) => {
    if (event.target.files || event.target.files.length) {
      try {
        const acceptedFiles = await validateImages(event.target.files);
        updateImages(onChange, acceptedFiles[0]);
      } catch (error) {
        addToast(
          <ToastNotification
            message={error}
            toastId="media-change-error"
            variant="error"
          />,
          { id: 'media-change-error' }
        );
      }
    }
  };

  useEffect(() => {
    setImage(data);
  }, [data]);

  return (
    <Controller
      name="avatar"
      control={control}
      render={({ field: { onChange } }) => (
        <Dropzone
          maxSize={10 * 1000000}
          accept={['image/jpeg', 'image/jpg', 'image/png']}
          onDropRejected={(rejectedFiles) => {
            const errors = rejectedFiles.map((file) => file.errors[0]);
            let errorMessage;
            switch (errors[0].code) {
              case 'file-invalid-type':
                errorMessage = 'Unsupported file format. Must be jpg or png';
                break;
              case 'file-too-large':
                errorMessage = 'File must not be greater than 10 MB';
                break;
              default:
                errorMessage = 'Unsupported file format. Must be jpg or png';
                break;
            }
            addToast(
              <ToastNotification
                message={errorMessage}
                toastId="media-drop-error"
                variant="error"
              />,
              { id: 'media-drop-error' }
            );
          }}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              className="avatar-media"
              data-testid="media"
              {...getRootProps()}
            >
              <div className="image-button-container">
                {image?.length ? (
                  <img
                    className="image"
                    data-testid="mainThumbnail"
                    src={image}
                  />
                ) : isDragActive ? (
                  <p data-testid="dragActive">Drag and drop some files here</p>
                ) : (
                  <Avatar size="160" />
                )}
                <ButtonAvatarUpload
                  onChange={(event) => handleChange(event, onChange)}
                  getInputProps={getInputProps}
                />
              </div>
            </div>
          )}
        </Dropzone>
      )}
    />
  );
};

export default AvatarMedia;
