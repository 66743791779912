import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ukuuRoomIcon from '../../assets/images/ukuu_room_icon.png';
import UserContext from '../../contexts/UserContext';
import { findThings as findThingsQuery } from '../../graphql/customQueries';
import { useDebounce, useLazyQuery, useWindowDimensions } from '../../hooks';
import { isEmpty } from '../../utils';
import './styles.scss';
import MobileSearch from './MobileSearch';
import searchLG from '../../assets/icons/search-lg.svg';

const Search = ({ history }) => {
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [date, setDate] = useState('');

  const [map, setMap] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);
  const [mapsPlacesApi, setMapsPlacesApi] = useState(null);
  const [placesDetails, setPlacesDetails] = useState(null);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const {
    state: { location }
  } = useContext(UserContext);

  const { windowWidth } = useWindowDimensions();
  const searchRef = useRef(null);
  const suggestionsRef = useRef(null);
  const inputRef = useRef(null);

  const debouncedKeyword = useDebounce(keyword, 500);

  const shrinkSearch = (event) => {
    if (windowWidth > 768) {
      const path = event?.path || (event.composedPath && event.composedPath());

      if (
        !path.includes(searchRef.current) &&
        !path.includes(suggestionsRef.current)
      ) {
      } else {
        removeSuggestions();
      }
    }
  };

  useEffect(() => {
    if (windowWidth > 768) {
      document.addEventListener('click', shrinkSearch);

      return () => document.removeEventListener('click', shrinkSearch);
    }
  }, [windowWidth]);

  useEffect(() => {
    const showSuggestions = async () => {
      const variables = {
        filter: {
          name: { fuzzy: debouncedKeyword },
          listed: {
            eq: true
          },
          archived: {
            eq: false
          }
        },
        limit: 5
      };

      const response = await findThings({ variables });
      setThingSuggestions(response?.findThings?.items);
    };

    if (debouncedKeyword.length >= 3) {
      showSuggestions();
    } else {
      removeSuggestions();
    }
  }, [debouncedKeyword]);

  const handleDateRange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (
      endDate &&
      format(endDate, 'MMM dd, yyyy') !== format(new Date(), 'MMM dd, yyyy')
    ) {
      setDate(
        `${format(startDate, 'MMM dd, yyyy')} - ${format(
          endDate,
          'MMM dd, yyyy'
        )}`
      );
    }
  }, [startDate, endDate]);

  const showStartDateValue = () => {
    return format(startDate, 'MMM dd, yyyy');
  };

  const showEndDateValue = () => {
    return endDate ? format(endDate, 'MMM dd, yyyy') : '';
  };

  const handleLocationChange = () => {
    let markers = [];
    map.addListener('bounds_changed', () => {
      mapsPlacesApi.setBounds(map.getBounds());
    });

    mapsPlacesApi.addListener('places_changed', () => {
      const places = mapsPlacesApi.getPlaces();
      if (places) {
        const bounds = new mapsApi.LatLngBounds();
        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log('location not found');
            return;
          }
          setPlacesDetails({
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng()
          });
          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
          const icon = {
            url: ukuuRoomIcon,
            size: new mapsApi.Size(71, 71),
            origin: new mapsApi.Point(0, 0),
            anchor: new mapsApi.Point(17, 34),
            scaledSize: new mapsApi.Size(50, 50)
          };

          //remove prev markers
          markers.forEach((marker) => {
            marker.setMap(null);
          });

          markers.push(
            new mapsApi.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location
            })
          );
        });
        map.fitBounds(bounds);
      }
    });
  };

  const handleMobileLocationChange = ({ ...coordinatesInput }) => {
    setPlacesDetails({
      ...coordinatesInput
    });
  };

  const handleSubmit = (coordinatesData) => {
    let search = `?keyword=${keyword}&date_range=${format(
      startDate,
      'dd/MM/yyyy'
    )}:${format(endDate || startDate, 'dd/MM/yyyy')}`;

    const coordinates = coordinatesData || placesDetails;

    if ((coordinates?.lat && coordinates?.lon) || !isEmpty(location)) {
      search = `${search}&latlng=${coordinates?.lat || location.lat},${
        coordinates?.lon || location.lon
      }`;
    }
    if (windowWidth <= 768) {
      setShowMobileSearch(false);
    }
    history.push({
      pathname: '/search',
      search
    });
  };

  const [thingSuggestions, setThingSuggestions] = useState([]);
  const [findThings] = useLazyQuery(findThingsQuery);

  const handleKeywordChange = async (event) => {
    setKeyword(event.target.value);
    setHasSelected(false);
  };

  const removeSuggestions = () => {
    setThingSuggestions([]);
  };

  // *
  const handleClearAllClick = () => {
    setKeyword('');
    setStartDate(new Date());
    setEndDate(new Date());
    setDate('');
    setPlacesDetails(null);
  };

  const handleQueryCancelClick = () => {
    setKeyword('');
  };

  const handleDateCancelClick = () => {
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const handleLocationCancelClick = () => {
    setPlacesDetails(null);
  };

  const handleCloseClick = () => {
    setShowMobileSearch(false);
  };

  const renderSearchOverlay = () => {
    return (
      <MobileSearch
        onKeywordChange={handleKeywordChange}
        onDateChange={handleDateRange}
        startDate={startDate}
        endDate={endDate}
        date={date}
        onSubmit={handleSubmit}
        isOpen={showMobileSearch}
        onLocationChange={handleMobileLocationChange}
        keyword={keyword}
        thingSuggestions={thingSuggestions}
        removeSuggestions={removeSuggestions}
        address={placesDetails?.address}
        handleLocationChange={handleLocationChange}
        locationRef={inputRef}
        initializeMap={({ map, maps }) => {
          setMap(map);
          setMapsPlacesApi(new maps.places.SearchBox(inputRef.current));
          setMapsApi(maps);
        }}
        lat={placesDetails?.lat || location?.lat}
        lon={placesDetails?.lon || location?.lon}
        startDateValue={showStartDateValue()}
        endDateValue={showEndDateValue()}
        onClearAllClick={handleClearAllClick}
        onQueryCancelClick={handleQueryCancelClick}
        onDateCancelClick={handleDateCancelClick}
        onLocationCancelClick={handleLocationCancelClick}
        onCloseClick={handleCloseClick}
      />
    );
  };

  return (
    <div className="search-bar">
      <div className="default-search" ref={searchRef}>
        <img src={searchLG} className="search" />
        <div className="default-search-container">
          <p className="subheading">What are you looking for?</p>
          <input
            className="field"
            type="text"
            name="ukuusearch"
            placeholder="Select availability or location"
            onChange={handleKeywordChange}
            onKeyPress={({ key, target }) => {
              if (key === 'Enter' && target.value.length >= 2) {
                handleSubmit();
              }
            }}
            value={keyword}
            readOnly={windowWidth <= 768}
            onClick={() => {
              setShowMobileSearch(true);
            }}
          />
        </div>
      </div>
      {renderSearchOverlay()}
    </div>
  );
};

Search.propTypes = {
  expanded: PropTypes.bool,
  history: PropTypes.object
};

Search.defaultProps = {
  expanded: false,
  history: null
};

export default Search;
