import React from 'react';
import { useFormContext } from 'react-hook-form';
import { categories } from '../../../../assets/json';
import { Dropdown, FormKeywordInput, FormTitle } from '../../../../components';
import './styles.scss';

const SearchTerms = () => {
  const { control, getValues, errors } = useFormContext();

  return (
    <div className="search-terms">
      <FormTitle title="Search terms" />
      <Dropdown
        control={control}
        data-testid="category"
        error={errors.category}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.key}
        keywordTags={() => getValues('category')}
        label="Categories"
        name="category"
        options={[...categories]}
        placeholder="Item category"
        shouldEnterKeywords
      />
      <FormKeywordInput
        control={control}
        data-testid="tags"
        error={errors.tags}
        keywordTags={() => getValues('tags')}
        label="Keywords"
        name="tags"
        shouldEnterKeywords
      />
    </div>
  );
};

export default SearchTerms;
