import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import { getDistance } from '../../utils';
import Distance from '../Distance';
import Image from '../Image';
import Price from '../Price';
import Rating from '../Rating';
import './styles.scss';

const TransactionItemCard = ({ item }) => {
  const {
    images,
    location,
    name,
    numberOfRatings,
    periodType,
    pricePerPeriod,
    rating
  } = item;

  const { state } = useContext(UserContext);

  return (
    <div className="transaction-item-card">
      <Image image={images?.[0]} className="item-image" />
      <div className="info">
        <span className="name">{name}</span>
        <Price periodType={periodType} pricePerPeriod={pricePerPeriod} />
        <Rating rating={rating || 0} numberOfRatings={numberOfRatings || 0} />
        {location && (
          <Distance
            distance={getDistance(state.currentLocation, {
              lat: location.lat,
              lon: location.lon
            })}
          />
        )}
      </div>
    </div>
  );
};

TransactionItemCard.propTypes = {
  item: PropTypes.object
};

TransactionItemCard.defaultProps = {
  item: null
};

export default TransactionItemCard;
