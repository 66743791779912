import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import { us_states } from '../../assets/json';
import ModalContext from '../../contexts/ModalContext';
import { createStripeError, isStripeError } from '../../utils';
import { editPaymentMethodValidation } from '../../validators';
import {
  ButtonText,
  Confirmation,
  Dropdown,
  EmptyListPlaceholder,
  FormInput,
  FormTitle,
  PaymentMethodCard,
  ToastNotification
} from '..';
import './styles.scss';

const MONTHS = Array.from({ length: 12 }).map((_, index) => {
  const month = index + 1;

  return {
    value: month,
    label: month
  };
});

const YEARS = Array.from({ length: 25 }).map((_, index) => {
  const year = new Date().getFullYear() + index;

  return {
    value: year,
    label: year
  };
});

const EditPaymentMethodForm = ({
  checked,
  onDelete,
  paymentMethod,
  updatePaymentMethod
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      fullName: paymentMethod.billing_details.name,
      state: us_states.find(
        (state) => state.value === paymentMethod.billing_details.address?.state
      ),
      city: paymentMethod.billing_details.address?.city,
      address: paymentMethod.billing_details.address?.line1,
      expMonth: MONTHS.find((month) => month.value === paymentMethod.exp_month),
      expYear: YEARS.find((year) => year.value === paymentMethod.exp_year),
      postalCode: paymentMethod.billing_details.address?.postal_code
    },
    resolver: yupResolver(editPaymentMethodValidation)
  });
  const { addToast } = useToasts();

  const { updateModal, closeModal } = useContext(ModalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      await updatePaymentMethod({
        variables: {
          input: {
            paymentMethodID: paymentMethod.id,
            name: data.fullName,
            state: data.state.value,
            city: data.city,
            line1: data.address,
            expMonth: data.expMonth.value,
            expYear: data.expYear.value,
            postalCode: data.postalCode
          }
        }
      });

      addToast(
        <ToastNotification
          message="Update payment method successful"
          toastId="payment-method-update-success"
          variant="success"
        />,
        { id: 'payment-method-update-success' }
      );
    } catch (error) {
      setError(
        isStripeError(error) ? createStripeError(error) : 'There was an error.'
      );

      addToast(
        <ToastNotification
          message="There was an error"
          toastId="payment-method-update-error"
          variant="error"
        />,
        { id: 'payment-method-update-error' }
      );

      console.error('edit payment method', error);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const handleDeleteClick = () => {
    updateModal({
      title: 'Delete Payment Method',
      content: () => (
        <Confirmation
          confirmLabel="Delete"
          confirmAction={() => onDelete(paymentMethod.id)}
          confirmActionButtonProps={{ color: 'error' }}
          rejectLabel="Cancel"
        >
          <EmptyListPlaceholder
            type="payment method"
            icon="delete_outline"
            text="Are you sure you want to delete?"
            supportingText={`Card number ending in ${paymentMethod.last4}`}
          />
        </Confirmation>
      )
    });
  };

  return (
    <form
      className="edit-payment-method-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <PaymentMethodCard
        checked={checked}
        brand={paymentMethod.brand}
        lastFourDigits={paymentMethod.last4}
      />
      <FormTitle title="Personal Information" />
      <FormInput
        label="Full Name"
        control={control}
        name="fullName"
        error={errors.fullName}
        required
      />
      <Dropdown
        label="State"
        options={[...us_states]}
        name="state"
        control={control}
        error={errors.state}
        required
      />
      <FormInput
        label="City"
        control={control}
        name="city"
        error={errors.city}
        required
      />
      <FormInput
        label="Address"
        control={control}
        name="address"
        error={errors.address}
        required
      />
      <FormTitle title="Card Details" />
      <div className="expiration-date">
        <Dropdown
          label="Month"
          options={MONTHS}
          name="expMonth"
          control={control}
          error={errors.expMonth}
        />
        <Dropdown
          label="Year"
          options={YEARS}
          name="expYear"
          control={control}
          error={errors.expYear}
        />
      </div>
      <FormInput
        label="Postal Code"
        control={control}
        name="postalCode"
        error={errors.postalCode}
        required
      />
      <ButtonText
        color="primary"
        loading={loading}
        disabled={error?.type === 'validation_error'}
      >
        Update
      </ButtonText>
      <ButtonText variant="outlined" onClick={closeModal}>
        Cancel
      </ButtonText>
      <ButtonText color="error" onClick={handleDeleteClick}>
        Delete
      </ButtonText>
    </form>
  );
};

EditPaymentMethodForm.propTypes = {
  checked: PropTypes.bool,
  onDelete: PropTypes.func,
  paymentMethod: PropTypes.object,
  updatePaymentMethod: PropTypes.func
};

EditPaymentMethodForm.defaultProps = {
  checked: false,
  onDelete: () => {},
  paymentMethod: null,
  updatePaymentMethod: () => {}
};

export default EditPaymentMethodForm;
