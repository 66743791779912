/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserThings = /* GraphQL */ `
  query GetUserThings($userID: ID!, $limit: Int, $nextToken: String) {
    getUserThings(userID: $userID, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const findThings = /* GraphQL */ `
  query FindThings(
    $filter: SearchableThingFilterInput
    $limit: Int
    $sort: [SearchableThingSortInput]
    $nextToken: String
    $from: Int
  ) {
    findThings(
      filter: $filter
      limit: $limit
      sort: $sort
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
    }
  }
`;
export const getUserTransactions = /* GraphQL */ `
  query GetUserTransactions(
    $userID: ID!
    $limit: Int
    $nextToken: String
    $type: TransactionType
  ) {
    getUserTransactions(
      userID: $userID
      limit: $limit
      nextToken: $nextToken
      type: $type
    ) {
      items {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($sub: String, $publicID: String, $email: String) {
    getUserData(sub: $sub, publicID: $publicID, email: $email) {
      items {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserConversations = /* GraphQL */ `
  query GetUserConversations(
    $userID: ID!
    $renterNextToken: String
    $ownerNextToken: String
  ) {
    getUserConversations(
      userID: $userID
      renterNextToken: $renterNextToken
      ownerNextToken: $ownerNextToken
    ) {
      items {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renterNextToken
      ownerNextToken
      startedAt
    }
  }
`;
export const getPaymentMethods = /* GraphQL */ `
  query GetPaymentMethods($customerID: ID!) {
    getPaymentMethods(customerID: $customerID) {
      items {
        id
        brand
        last4
        billing_details {
          email
          name
          phone
        }
        exp_month
        exp_year
        default
      }
    }
  }
`;
export const checkUserReview = /* GraphQL */ `
  query CheckUserReview($userID: ID!, $reviewerID: ID!) {
    checkUserReview(userID: $userID, reviewerID: $reviewerID) {
      items {
        reviewerID
        reviewer {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        rating
        createdAt
        thingID
        thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        userID
        user {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const checkReview = /* GraphQL */ `
  query CheckReview($userID: ID!, $thingID: ID!, $reviewerID: ID!) {
    checkReview(userID: $userID, thingID: $thingID, reviewerID: $reviewerID) {
      user
      thing
    }
  }
`;
export const getStripeAccount = /* GraphQL */ `
  query GetStripeAccount($id: ID!) {
    getStripeAccount(id: $id) {
      stripeAccountID
      email
      detailsSubmitted
    }
  }
`;
export const getUserTransaction = /* GraphQL */ `
  query GetUserTransaction($publicID: ID!, $userID: ID!) {
    getUserTransaction(publicID: $publicID, userID: $userID) {
      items {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserConversation = /* GraphQL */ `
  query GetUserConversation($publicID: ID!, $userID: ID!) {
    getUserConversation(publicID: $publicID, userID: $userID) {
      items {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTransactionHistory = /* GraphQL */ `
  query GetTransactionHistory($id: ID!) {
    getTransactionHistory(id: $id) {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTransactionHistories = /* GraphQL */ `
  query ListTransactionHistories(
    $filter: ModelTransactionHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionID
        transactionStatus
        paymentStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTransactionHistories = /* GraphQL */ `
  query SyncTransactionHistories(
    $filter: ModelTransactionHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        transactionID
        transactionStatus
        paymentStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      executeAt
      function
      data
      hasExecuted
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        executeAt
        function
        data
        hasExecuted
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchedules = /* GraphQL */ `
  query SyncSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        executeAt
        function
        data
        hasExecuted
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getThing = /* GraphQL */ `
  query GetThing($id: ID!) {
    getThing(id: $id) {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listThings = /* GraphQL */ `
  query ListThings(
    $filter: ModelThingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncThings = /* GraphQL */ `
  query SyncThings(
    $filter: ModelThingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncThings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserThing = /* GraphQL */ `
  query GetUserThing(
    $publicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelThingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserThing(
      publicID: $publicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchThings = /* GraphQL */ `
  query SearchThings(
    $filter: SearchableThingFilterInput
    $sort: [SearchableThingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableThingAggregationInput]
  ) {
    searchThings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        reviewerID
        reviewer {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        rating
        createdAt
        thingID
        thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        userID
        user {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        reviewerID
        reviewer {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        rating
        createdAt
        thingID
        thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        userID
        user {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        id
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserMessages = /* GraphQL */ `
  query GetUserMessages(
    $conversationPublicID: ID!
    $dateSent: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserMessages(
      conversationPublicID: $conversationPublicID
      dateSent: $dateSent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      publicID
      transactionID
      transaction {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedID
      lastMessageReceived {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedAt
      messages {
        items {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasRead
      ownerID
      renterID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConversations = /* GraphQL */ `
  query SyncConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOwnerConversations = /* GraphQL */ `
  query GetOwnerConversations(
    $ownerID: ID!
    $lastMessageReceivedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOwnerConversations(
      ownerID: $ownerID
      lastMessageReceivedAt: $lastMessageReceivedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRenterConversations = /* GraphQL */ `
  query GetRenterConversations(
    $renterID: ID!
    $lastMessageReceivedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRenterConversations(
      renterID: $renterID
      lastMessageReceivedAt: $lastMessageReceivedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile(
    $publicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserProfile(
      publicID: $publicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSearch = /* GraphQL */ `
  query GetSearch($id: ID!) {
    getSearch(id: $id) {
      id
      keyword
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSearches = /* GraphQL */ `
  query ListSearches(
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keyword
        location
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSearches = /* GraphQL */ `
  query SyncSearches(
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSearches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keyword
        location
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
