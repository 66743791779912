import { Storage } from 'aws-amplify';
import { useState } from 'react';

const useLazyS3Read = (args = {}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState(null);

  const callback = async (s3Files) => {
    try {
      if (!s3Files) return;

      setLoading(true);

      if (s3Files?.length) {
        let s3Data = [];
        for (let counter = 0; counter < s3Files.length; counter++) {
          const result = await Storage.get(s3Files[counter].key, {
            ...args
          });
          s3Data.push(result);
        }

        setData(s3Data);
        setLoading(false);

        return s3Data;
      } else {
        if (s3Files.key) {
          const result = await Storage.get(s3Files.key, { ...args });

          setData(result);
        } else {
          setData([]);
        }
        setLoading(false);
      }
    } catch (error) {
      setErrors(error);
      setLoading(false);

      console.error('read image', error);
    }
  };

  return [callback, { loading, errors, data }];
};

export default useLazyS3Read;
