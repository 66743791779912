import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordFormValidation } from '../../validators';
import { Badge, ButtonText } from '..';
import { FormInput } from '../Forms';
import './styles.scss';

const ResetPasswordForm = ({ closeModal, code, data, history }) => {
  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(resetPasswordFormValidation)
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    const { username } = JSON.parse(atob(data));
    try {
      await Auth.forgotPasswordSubmit(username, code, values.newPassword);
      setLoading(false);
      closeModal();
      history.push({ pathname: '/', state: { hasResetPassword: true } });
    } catch (error) {
      handleError(error);
      console.error('Forgot: ', error);
      setLoading(false);
    }
  };

  const handleError = (error) => {
    switch (error?.code) {
      case 'ExpiredCodeException':
        setError('Invalid reset password code');
        break;
      case 'LimitExceededException':
        setError(error.message);
        break;
      default:
        setError('There was an error');
        break;
    }
  };

  return (
    <form className="reset-password-form" onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Badge
          variant="contained"
          type="error"
          label={error}
          className="message"
        />
      )}
      <div className="password-guidelines">
        <p className="title">Password Guidelines/Requirements:</p>
        <p>&#8226; Must be at least 8 characters long</p>
        <p>&#8226; Must have at least 1 number</p>
        <p>&#8226; Must have at least 1 capital letter</p>
      </div>
      <FormInput
        label="New Password"
        type="password"
        name="newPassword"
        control={control}
        error={formState.errors.newPassword}
      />
      <FormInput
        label="Confirm Password"
        type="password"
        name="confirmPassword"
        control={control}
        error={formState.errors.confirmPassword}
      />
      <ButtonText color="primary" loading={loading}>
        Update
      </ButtonText>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  closeModal: PropTypes.func,
  code: PropTypes.string,
  data: PropTypes.object,
  history: PropTypes.object
};

ResetPasswordForm.defaultProps = {
  closeModal: () => {},
  code: '',
  data: null,
  history: null
};

export default ResetPasswordForm;
