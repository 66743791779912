import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import RNModal from 'react-modal';
import { ButtonIcon, Loader } from '../../../../components';
import UserContext from '../../../../contexts/UserContext';
import { createStripeAccountLink as createStripeAccountLinkMutation } from '../../../../graphql/mutations';
import { getStripeAccount as getStripeAccountQuery } from '../../../../graphql/queries';
import { useMutation, useQuery, useWindowDimensions } from '../../../../hooks';
import './styles.scss';

const Remittance = ({ closeModal }) => {
  const [{ data: createStripeAccountLinkData }] = useMutation(
    createStripeAccountLinkMutation
  );

  const [stripeAccount, setStripeAccount] = useState(null);

  const { state } = useContext(UserContext);
  const { data, loading: getStripeAccountLoading } = useQuery(
    getStripeAccountQuery,
    {
      variables: {
        id: state.id
      }
    }
  );

  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    if (createStripeAccountLinkData?.createStripeAccountLink) {
      const { url } = createStripeAccountLinkData.createStripeAccountLink;
      window.location.replace(url);
    }
  }, [createStripeAccountLinkData]);

  useEffect(() => {
    if (data?.getStripeAccount) {
      setStripeAccount(data.getStripeAccount);
      if (stripeAccount) {
        console.log(`stripeAccount available.`);
      }
    }
  }, [data]);

  const renderRemittance = () => {
    return (
      <div className="remittance-subpage">
        {getStripeAccountLoading ? (
          <Loader />
        ) : (
          <>
            <div className="header">
              <ButtonIcon icon="arrow_back" onClick={closeModal} />
            </div>
            <div className="body">{null}</div>
          </>
        )}
      </div>
    );
  };

  return windowWidth > 1024 ? (
    renderRemittance()
  ) : (
    <RNModal overlayClassName="overlay" className="modal-content" isOpen>
      {renderRemittance()}
    </RNModal>
  );
};

Remittance.propTypes = {
  closeModal: PropTypes.func
};

Remittance.defaultProps = {
  closeModal: () => {}
};

export default Remittance;
