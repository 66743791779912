import { useState } from 'react';
import { Storage } from 'aws-amplify';

const useS3Upload = (args = {}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const callback = async (data) => {
    try {
      let s3Data;

      setLoading(true);

      if (data?.length) {
        s3Data = [];
        for (let counter = 0; counter < data.length; counter++) {
          const result = await Storage.put(
            `ukuu_${new Date().getTime()}`,
            data[counter],
            {
              contentType: data[counter].type,
              ...args
            }
          );
          s3Data.push({
            key: result.key,
            bucket: process.env.REACT_APP_S3_BUCKET,
            region: process.env.REACT_APP_S3_REGION
          });
        }
      } else {
        const result = await Storage.put(`ukuu_${new Date().getTime()}`, data, {
          contentType: data.type,
          ...args
        });

        s3Data = {
          key: result.key,
          bucket: process.env.REACT_APP_S3_BUCKET,
          region: process.env.REACT_APP_S3_REGION
        };
      }

      setLoading(false);
      return s3Data;
    } catch (error) {
      console.error('upload', error);
      setErrors(error);
      setLoading(false);
      return error;
    }
  };

  return [callback, { loading, errors }];
};

export default useS3Upload;
