import * as yup from 'yup';

const addPaymentMethodValidation = yup.object().shape({
  fullName: yup.string().required('Full name is required'),
  state: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string()
    })
    .test('state', null, function (obj) {
      if (!obj.value || !obj.label) {
        return this.createError({
          message: 'State is required'
        });
      }
      return true;
    })
    .required('State is required'),
  city: yup.string().required('City is required'),
  address: yup.string().required('Address is required')
});

export default addPaymentMethodValidation;
