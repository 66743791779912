import PropTypes from 'prop-types';
import React from 'react';

// Layout for pages that don't follow the same padding
const Custom = ({ children }) => {
  return <main>{children}</main>;
};

Custom.propTypes = {
  children: PropTypes.node.isRequired
};

export default Custom;
