export const URL_HELPERS = {
  index: '/',
  add: '/my-items/add',
  confirm: '/confirm',
  dashboard: '/dashboard',
  messages: '/messages',
  myItems: '/my-items',
  profile: '/profile',
  resetPassword: '/reset-password',
  searchResults: '/search',
  signin: '/signin',
  signout: '/signout',
  transactions: '/transactions'
};

export const APP_URL_SCHEMES = {
  dashboardTransaction: '/dashboard/transaction/:transactionId',
  userProfile: '/user/:publicId',
  itemDetails: '/items/:itemId',
  editItem: '/my-items/edit/:itemId',
  myItemDetails: '/my-items/:itemId',
  transactionDetails: '/transactions/:transactionId',
  convoDetails: '/messages/:convoId',
  convoTransactionDetails: '/messages/:convoId/transaction/:transactionId',
  paymentInformation: '/profile/payment-information',
  remittance: '/profile/remittance',
  accounts: '/profile/accounts',
  loginInformation: '/profile/login-information',
  itemsAndReviews: '/profile/items-and-reviews'
};
