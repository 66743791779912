// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PeriodType = {
  "HOURS": "HOURS",
  "DAYS": "DAYS",
  "WEEKS": "WEEKS",
  "MONTHS": "MONTHS"
};

const RequiredDocument = {
  "IDENTIFICATION": "IDENTIFICATION",
  "OPERATORS_LICENSE": "OPERATORS_LICENSE",
  "INSURANCE": "INSURANCE",
  "OTHER": "OTHER"
};

const SystemMessage = {
  "ITEM_REQUEST": "ITEM_REQUEST",
  "ACCEPT_REQUEST": "ACCEPT_REQUEST",
  "GENERATE_START_EXCHANGE_CODE": "GENERATE_START_EXCHANGE_CODE",
  "GENERATE_END_EXCHANGE_CODE": "GENERATE_END_EXCHANGE_CODE",
  "LATE_DROPOFF_REMINDER": "LATE_DROPOFF_REMINDER",
  "LATE_DROPOFF_WARNING": "LATE_DROPOFF_WARNING",
  "DECLINE_REQUEST": "DECLINE_REQUEST",
  "IN_RENT": "IN_RENT",
  "COMPLETE_RENT": "COMPLETE_RENT",
  "CANCEL_RENT": "CANCEL_RENT",
  "USER_REVIEW": "USER_REVIEW",
  "ITEM_REVIEW": "ITEM_REVIEW"
};

const TransactionStatus = {
  "REQUESTED": "REQUESTED",
  "ACCEPTED": "ACCEPTED",
  "DECLINED": "DECLINED",
  "INRENT": "INRENT",
  "CANCELLED": "CANCELLED",
  "COMPLETED": "COMPLETED"
};

const PaymentStatus = {
  "PENDING": "PENDING",
  "WITHHELD": "WITHHELD",
  "RETRY_HOLD": "RETRY_HOLD",
  "CAPTURED": "CAPTURED",
  "FAILED": "FAILED",
  "COMPLETED": "COMPLETED",
  "COMPLETED_WITH_REMARKS": "COMPLETED_WITH_REMARKS",
  "CANCELLED": "CANCELLED"
};

const SearchableThingSortableFields = {
  "ID": "id",
  "NAME": "name",
  "PRICE_PER_PERIOD": "pricePerPeriod",
  "MIN_RENT_PERIOD": "minRentPeriod",
  "USER_ID": "userID",
  "LISTED": "listed",
  "TAGS": "tags",
  "DESCRIPTION": "description",
  "DEPOSIT": "deposit",
  "ITEM_VALUE": "itemValue",
  "MAX_RENT_PERIOD": "maxRentPeriod",
  "ARCHIVED": "archived",
  "ARCHIVED_AT": "archivedAt",
  "PUBLIC_ID": "publicID",
  "DAYS_AVAILABLE": "daysAvailable",
  "LOCATION": "location"
};

const SearchableSortDirection = {
  "ASC": "asc",
  "DESC": "desc"
};

const TransactionType = {
  "SUPPLYING": "SUPPLYING",
  "RENTING": "RENTING"
};

const ModelAttributeTypes = {
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet",
  "NULL": "_null"
};

const { Thing, User, Transaction, Conversation, Message, TransactionHistory, Review, Schedule, Search, ModelThingConnection, Category, Location, Image, MessageBody, SearchableThingConnection, ModelTransactionConnection, ModelUserConnection, ModelConversationsConnection, PaymentMethodConnection, PaymentMethod, PaymentMethodBillingDetails, BillingDetailsAddress, ModelReviewConnection, ReviewChecker, StripeAccount, ModelConversationConnection, SetupIntent, StripeAccountLink, PaymentIntentIDs, ModelMessageConnection, StripeCustomer, StripeRedirectToUrl, StripeNextAction } = initSchema(schema);

export {
  Thing,
  User,
  Transaction,
  Conversation,
  Message,
  TransactionHistory,
  Review,
  Schedule,
  Search,
  PeriodType,
  RequiredDocument,
  SystemMessage,
  TransactionStatus,
  PaymentStatus,
  SearchableThingSortableFields,
  SearchableSortDirection,
  TransactionType,
  ModelAttributeTypes,
  ModelThingConnection,
  Category,
  Location,
  Image,
  MessageBody,
  SearchableThingConnection,
  ModelTransactionConnection,
  ModelUserConnection,
  ModelConversationsConnection,
  PaymentMethodConnection,
  PaymentMethod,
  PaymentMethodBillingDetails,
  BillingDetailsAddress,
  ModelReviewConnection,
  ReviewChecker,
  StripeAccount,
  ModelConversationConnection,
  SetupIntent,
  StripeAccountLink,
  PaymentIntentIDs,
  ModelMessageConnection,
  StripeCustomer,
  StripeRedirectToUrl,
  StripeNextAction
};