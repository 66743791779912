/**
 * @param {("HOURS"|"DAYS"|"WEEKS"|"MONTHS")} periodType
 * @param {String} startDate
 * @param {String} endDate
 */
const getPeriodConversion = (periodType, startDate, endDate) => {
  const diff = new Date(endDate).getTime() - new Date(startDate).getTime();
  let periodConversion;
  switch (periodType) {
    case 'DAYS':
      periodConversion = diff / 86400 / 1000;
      break;
    case 'WEEKS':
      periodConversion = diff / (7 * 86400) / 1000;
      break;
    case 'MONTHS':
      periodConversion = diff / (30 * 86400) / 1000;
      break;
    default:
      periodConversion = diff / 3600 / 1000;
      break;
  }

  const periods =
    diff < 0
      ? 0
      : +periodConversion.toFixed(
          Math.round(periodConversion).toFixed(1) ===
            periodConversion.toFixed(1)
            ? 0
            : 1
        );
  return periods;
};

export default getPeriodConversion;
