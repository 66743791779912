import React from 'react';
import { ButtonText } from '../../../../components';
import PropTypes from 'prop-types';

const LendYours = ({ handleClick }) => {
  return (
    <div className="call-to-action-container">
      <div className="text-container">
        <h2>Lend yours!</h2>
        <p className="secondary-Heading">
          By listing and lending your own items, you can earn on Ukuu!
        </p>
      </div>
      <ButtonText
        color="primary"
        variant={'call-to-action'}
        onClick={handleClick}
      >
        Lend my own items
      </ButtonText>
    </div>
  );
};

export default LendYours;

LendYours.propTypes = {
  handleClick: PropTypes.func
};

LendYours.defaultProps = {
  handleClick: () => {}
};
