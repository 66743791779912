import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Address,
  Bio,
  EmptyListPlaceholder,
  Icon,
  ItemCardList,
  Loader,
  NavigationTabs,
  ProfileHeader,
  Rating,
  ReviewCardList,
  StarRating,
  ToastNotification
} from '../../components';
import useUserProfile from './useUserProfile';
import './styles.scss';

const UserProfile = ({ history }) => {
  const { publicId } = useParams();
  console.log(`ze history:`, history);
  const {
    isUserProfileLoading,
    userProfileData,
    isUserItemsLoading,
    userItemsData
  } = useUserProfile(publicId);

  const [tab, setTab] = useState('ITEMS');

  const isItemsEmpty = userItemsData.length === 0;
  const isReviewsEmpty = userProfileData?.reviews.items.length === 0;

  const classNames = clsx(
    'items-and-reviews',
    (tab === 'ITEMS' && isItemsEmpty && '-empty') ||
      (tab === 'REVIEWS' && isReviewsEmpty && '-empty')
  );

  const handleClick = (tab) => setTab(tab.toUpperCase());

  const handleShareClick = () => {
    if (navigator.share) {
      navigator.share({
        url: `${window.location.origin}/user/${userProfileData.publicID}`,
        title: 'Ukuu',
        text: 'Checkout my profile!'
      });
    } else {
      navigator.clipboard
        .writeText(`${window.location.origin}/user/${userProfileData.publicID}`)
        .then(() => {
          addToast(
            <ToastNotification
              message="Share functionality not supported. Copied to clipboard."
              toastId="clipboard-copy"
            />,
            {
              id: 'clipboard-copy'
            }
          );
        })
        .catch((error) => console.error('error', error));
    }
  };

  if (isUserProfileLoading) {
    return <Loader />;
  }

  return (
    <div className="user-profile-page">
      <main className="body">
        <ProfileHeader
          avatar={userProfileData.avatar}
          name={userProfileData.fullName}
        >
          {userProfileData.address && (
            <Address address={userProfileData.address} />
          )}
          <div className="user-rating">
            <StarRating rating={userProfileData.rating || 0} />
            <Rating
              hasIcon={false}
              numberOfRatings={userProfileData.numberOfRatings || 0}
              rating={userProfileData.rating || 0}
            />
          </div>
          <button onClick={handleShareClick} className="share-button">
            <Icon icon="share" />
            <span className="share-caption">Share</span>
          </button>
          {userProfileData.bio && <Bio bio={userProfileData.bio} />}
        </ProfileHeader>
        <section className={classNames}>
          <NavigationTabs
            activeTab={tab}
            leftTabLabel="Items"
            rightTabLabel="Reviews"
            onClick={handleClick}
          />
          {isUserItemsLoading ? (
            <Loader />
          ) : tab === 'ITEMS' ? (
            isItemsEmpty ? (
              <EmptyListPlaceholder
                type="item"
                icon="find_in_page"
                text="No listings found"
                supportingText="User hasn't added any items yet."
              />
            ) : (
              <ItemCardList
                history={history}
                things={userItemsData.filter((item) => item.listed)}
              />
            )
          ) : isReviewsEmpty ? (
            <EmptyListPlaceholder
              type="review"
              icon="find_in_page"
              text="No reviews found"
              supportingText="User hasn't received any reviews yet."
            />
          ) : (
            <ReviewCardList reviews={userProfileData.reviews.items} />
          )}
        </section>
      </main>
    </div>
  );
};

UserProfile.propTypes = {
  history: PropTypes.object
};

UserProfile.defaultProps = {
  history: null
};

export default UserProfile;
