import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Address,
  Bio,
  Divider,
  Icon,
  ProfileHeader,
  Rating,
  StarRating,
  ToastNotification
} from '../../../../components';
import UserForm from '../UserForm';
import ModalContext from '../../../../contexts/ModalContext';
import { APP_URL_SCHEMES } from '../../../../utils/url';
import './styles.scss';

const ProfileSidebar = ({
  userProfileData,
  setUserProfileData,
  setUserData,
  updateUser,
  children
}) => {
  // Destructuring userProfileData into its parts.
  const { address, avatar, bio, fullName, numberOfRatings, rating } =
    userProfileData;
  const { showModal } = useContext(ModalContext);
  const handleEditProfileClick = () => {
    showModal({
      title: 'Edit Profile',
      content: () =>
        UserForm({
          currentUser: {
            avatar: userProfileData.avatar,
            firstName: userProfileData.firstName,
            lastName: userProfileData.lastName,
            location: userProfileData.location,
            address: userProfileData.address,
            bio: userProfileData.bio,
            id: userProfileData.id,
            _version: userProfileData._version
          },
          setUserData,
          updateUser,
          setUserProfileData
        })
    });
  };

  const handleShareClick = () => {
    if (navigator.share) {
      navigator.share({
        url: `${window.location.origin}/user/${userProfileData.publicID}`,
        title: 'Ukuu',
        text: 'Checkout my profile!'
      });
    } else {
      navigator.clipboard
        .writeText(`${window.location.origin}/user/${userProfileData.publicID}`)
        .then(() => {
          addToast(
            <ToastNotification
              message="Share functionality not supported. Copied to clipboard."
              toastId="clipboard-copy"
            />,
            {
              id: 'clipboard-copy'
            }
          );
        })
        .catch((error) => console.error('error', error));
    }
  };

  return (
    <div className="profile-sidebar">
      <div className="sidebar-container">
        <ProfileHeader avatar={avatar} name={fullName}>
          <>
            {address && <Address address={address} />}
            <div className="user-rating">
              <StarRating rating={rating} />
              <Rating
                hasIcon={false}
                numberOfRatings={numberOfRatings}
                rating={rating}
              />
            </div>
            <button onClick={handleShareClick} className="share-button">
              <Icon icon="share" />
              <span className="share-caption">Share</span>
            </button>
            {bio && <Bio bio={bio} />}
          </>
          {/* )} */}
        </ProfileHeader>
        <Divider />
        <div className="links">
          <a onClick={handleEditProfileClick} className="link">
            <Icon icon="edit" />
            <span className="label">Edit Profile</span>
          </a>
          <NavLink to={APP_URL_SCHEMES.accounts} className="link">
            <Icon icon="people_outline" />
            <span className="label">Connected Accounts</span>
          </NavLink>
          <NavLink to={APP_URL_SCHEMES.loginInformation} className="link">
            <Icon icon="lock_open" />
            <span className="label">Security and Login</span>
          </NavLink>
          <NavLink to={APP_URL_SCHEMES.paymentInformation} className="link">
            <Icon icon="account_balance" />
            <span className="label">Payment Information</span>
          </NavLink>
          {/* <Divider />
          <a
            href="http://info.ukuu.io"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img src={hedgehogInactive} className="icon hedgehog" />
            <span className="label">How Ukuu works</span>
          </a>
          <a
            href="http://help.ukuu.io"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <Icon icon="help_outline" />
            <span className="label">Help and Support</span>
          </a>
          <Divider />
          <button className="button link" onClick={handleSignOutClick}>
            <Icon icon="logout" />
            <span className="label">Log out</span>
          </button> */}
        </div>
      </div>
      {children}
    </div>
  );
};

ProfileSidebar.propTypes = {
  address: PropTypes.string,
  avatar: PropTypes.object,
  bio: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  numberOfRatings: PropTypes.number,
  rating: PropTypes.number,
  userProfileData: PropTypes.object,
  setUserProfileData: PropTypes.func,
  setUserData: PropTypes.func,
  updateUser: PropTypes.func
};

ProfileSidebar.defaultProps = {
  address: '',
  avatar: {},
  bio: '',
  children: null,
  name: '',
  numberOfRatings: 0,
  rating: 0,
  userProfileData: {},
  setUserProfileData: () => {},
  setUserData: () => {},
  updateUser: () => {}
};

export default ProfileSidebar;
