import React, { useReducer, createContext } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { GET_AUTH_TOKENS, SIGN_OUT } from './constants';

const initialState = {
  accessToken: '',
  loading: true
};

const AuthContext = createContext({ state: initialState });

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case GET_AUTH_TOKENS:
        return {
          ...state,
          accessToken: action.payload.accessToken,
          loading: false
        };

      case SIGN_OUT:
        return {
          ...state,
          accessToken: ''
        };
      default:
        return state;
    }
  }, initialState);

  const getAuthTokens = async () => {
    try {
      const { authenticated } = await Auth.currentCredentials();

      if (authenticated) {
        const currSession = await Auth.currentSession();
        const accessToken = currSession.getAccessToken().getJwtToken();
        dispatch({
          type: GET_AUTH_TOKENS,
          payload: {
            accessToken
          }
        });
      } else {
        dispatch({
          type: GET_AUTH_TOKENS,
          payload: {
            accessToken: ''
          }
        });
      }
    } catch (error) {
      console.error('auth: ', error);
      dispatch({
        type: GET_AUTH_TOKENS,
        payload: {
          accessToken: ''
        }
      });
      return null;
    }
  };

  const signout = async () => {
    await Auth.signOut();
    dispatch({
      type: SIGN_OUT
    });
  };

  return (
    <AuthContext.Provider value={{ state, getAuthTokens, signout }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
