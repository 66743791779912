import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '../Avatar';
import './styles.scss';

const UserCard = ({ avatar, children, className, name, onClick, size }) => {
  const userCardClassNames = clsx('user-card', className);

  return (
    <div className={userCardClassNames} onClick={onClick}>
      <Avatar avatar={avatar} size={size} name={name} />
      {children}
    </div>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string
};

UserCard.defaultProps = {
  avatar: null,
  children: null,
  className: '',
  name: '',
  onClick: () => {},
  size: '40'
};

export default UserCard;
