import { useContext, useEffect, useState } from 'react';
import { categories } from '../../assets/json';
import ModalContext from '../../contexts/ModalContext';
import { listThings as listThingsQuery } from '../../graphql/queries';
import { CategorySearch } from './components';
import { useQuery } from '../../hooks';

const useHome = () => {
  const categoryIcons = {
    APPLICANCES: 'local_laundry_service',
    ARTS_CRAFTS_SEWING: 'brush',
    AUTOMOBILES_POWERSPORTS: 'directions_car',
    AUTOMOTIVE_PARTS_TOOLS_ACCESSORIES: 'build',
    BABY_CARE: 'child_care',
    BEAUTY_PERSONAL_CARE: 'self_improvement',
    BOOKS: 'auto_stories',
    CDS_TAPES_RECORDS: 'album',
    CELL_PHONES_ACCESSORIES: 'phone_iphone',
    CLOTHING_SHOES_JEWELRY: 'checkroom',
    COMPUTERS_COMPUTER_PARTS: 'devices',
    ELECTRONICS: 'tv',
    EXERCISE_EQUIPMENT_HOME_GYM: 'fitness_center',
    GARDEN_OUTDOOR: 'yard',
    HEALTH_HOUSEHOLD: 'home',
    HOME_KITCHEN: 'kitchen',
    INDUSTRIAL_SCIENTIFIC: 'science',
    SCIENTIFIC: 'science',
    LUGGAGE_TRAVEL_GEAR: 'luggage',
    MUSICAL_INSTRUMENTS: 'piano',
    OFFICE_PRODUCTS: 'print',
    PET_SUPPLIES: 'pets',
    SPORTS_RECREATION_OUTDOORS: 'deck',
    TOOLS_DIY_HOME_IMPROVEMENT: 'home_repair_service',
    TOYS_GAMES: 'smart_toy',
    VIDEO_GAME_SYSTEMS: 'sports_esports'
  };

  const TOP_CATEGORIES_COUNT = 5;

  let categoryThingsCount = {
    APPLICANCES: 0,
    ARTS_CRAFTS_SEWING: 0,
    AUTOMOBILES_POWERSPORTS: 0,
    AUTOMOTIVE_PARTS_TOOLS_ACCESSORIES: 0,
    BABY_CARE: 0,
    BEAUTY_PERSONAL_CARE: 0,
    BOOKS: 0,
    CDS_TAPES_RECORDS: 0,
    CELL_PHONES_ACCESSORIES: 0,
    CLOTHING_SHOES_JEWELRY: 0,
    COMPUTERS_COMPUTER_PARTS: 0,
    ELECTRONICS: 0,
    EXERCISE_EQUIPMENT_HOME_GYM: 0,
    GARDEN_OUTDOOR: 0,
    HEALTH_HOUSEHOLD: 0,
    HOME_KITCHEN: 0,
    INDUSTRIAL_SCIENTIFIC: 0,
    SCIENTIFIC: 0,
    LUGGAGE_TRAVEL_GEAR: 0,
    MUSICAL_INSTRUMENTS: 0,
    OFFICE_PRODUCTS: 0,
    PET_SUPPLIES: 0,
    SPORTS_RECREATION_OUTDOORS: 0,
    TOOLS_DIY_HOME_IMPROVEMENT: 0,
    TOYS_GAMES: 0,
    VIDEO_GAME_SYSTEMS: 0
  };

  const { loading: listThingsLoading, data: listThingsData } = useQuery(
    listThingsQuery,
    {
      variables: {
        limit: 300,
        filter: {
          archived: {
            eq: false
          },
          listed: {
            eq: true
          }
        }
      }
    }
  );

  const {
    closeModal,
    showModal,
    state: { visible },
    updateModal
  } = useContext(ModalContext);

  const [isTopCategoriesLoading, setIsTopCategoriesLoading] = useState(true);
  const [topCategories, setTopCategories] = useState([]);
  const [isCategoryLoading, setIsCategoryLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categoryList, setCategoryList] = useState(categories);

  // Get top categories and top category
  useEffect(() => {
    if (!listThingsLoading && listThingsData) {
      listThingsData.listThings.items.forEach((item) => {
        item.category?.forEach((itemCategory) => {
          categoryThingsCount[itemCategory.key]++;
        });
      });

      let topCategoriesByThingsCount = Object.entries(categoryThingsCount);
      topCategoriesByThingsCount.sort(
        (categoryA, categoryB) => categoryB[1] - categoryA[1]
      );
      topCategoriesByThingsCount = topCategoriesByThingsCount.slice(
        0,
        TOP_CATEGORIES_COUNT
      );
      topCategoriesByThingsCount = categories.filter((category) =>
        topCategoriesByThingsCount.some(
          (topCategory) => topCategory[0] === category.key
        )
      );
      topCategoriesByThingsCount.sort(
        (categoryA, categoryB) =>
          categoryThingsCount[categoryB.key] -
          categoryThingsCount[categoryA.key]
      );

      setTopCategories(topCategoriesByThingsCount);
      setIsTopCategoriesLoading(false);

      setCategory(topCategoriesByThingsCount[0]);
      setCategoryItems(
        listThingsData.listThings.items.filter((item) =>
          item?.category?.some(
            (itemCategory) =>
              itemCategory.key === topCategoriesByThingsCount[0]?.key
          )
        )
      );
      setIsCategoryLoading(false);
    }
  }, [listThingsLoading, listThingsData]);

  useEffect(() => {
    updateModal({
      title: 'Categories',
      content: () =>
        CategorySearch({
          categories: categoryList,
          icons: categoryIcons,
          onChange: handleChange,
          onClick: handleSearchedCategoryClick
        })
    });
  }, [categoryList]);

  useEffect(() => {
    if (!visible) {
      setCategoryList([...categories]);
    }
  }, [visible]);

  const categoryClick = (category) => {
    setIsCategoryLoading(true);
    setCategory(category);
    setCategoryItems(
      listThingsData?.listThings.items
        .filter((item) =>
          item?.category?.some(
            (itemCategory) => itemCategory.key === category.key
          )
        )
        .sort(
          (thingA, thingB) =>
            thingB.rating / thingB.numberOfRatings -
            thingA.rating / thingA.numberOfRatings
        )
    );
    setIsCategoryLoading(false);
  };

  const handleCategoryClick = (category) => {
    return () => {
      categoryClick(category);
    };
  };

  const handleShowAllClick = () => {
    showModal({
      title: 'Categories',
      content: () =>
        CategorySearch({
          categories: categoryList,
          icons: categoryIcons,
          onChange: handleChange,
          onClick: handleSearchedCategoryClick
        })
    });
  };

  const handleSearchedCategoryClick = (category) => {
    return () => {
      categoryClick(category);
      closeModal();
    };
  };

  const handleChange = (event) => {
    const keyword = new RegExp(event.target.value, 'i');

    setCategoryList(
      categories.filter((category) => keyword.test(category.name))
    );
  };

  return {
    categoryIcons,
    isItemsLoading: listThingsLoading || isTopCategoriesLoading,
    topCategories,
    isCategoryLoading,
    category,
    categoryItems,
    onCategoryClick: handleCategoryClick,
    onShowAllClick: handleShowAllClick,
    categoryList
  };
};

export default useHome;
