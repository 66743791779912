import clsx from 'clsx';
import { addDays } from 'date-fns';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState, useRef } from 'react';
import RDatePicker from 'react-datepicker';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ButtonIcon,
  ButtonText,
  Divider,
  EmptyListPlaceholder,
  Header,
  Icon,
  Loader,
  Search
} from '../../components';
import { useWindowDimensions } from '../../hooks';
import { URL_HELPERS } from '../../utils/url';
import useDashboard from './useDashboard';
import './styles.scss';
import { TransactionCard, TransactionDetails } from './components';

export const DashboardContext = createContext();

const Dashboard = ({ history }) => {
  const { windowWidth } = useWindowDimensions();
  // const [getUserData] = useLazyQuery(getUserDataQuery);

  const {
    userTransactionsData,
    hasMoreTransactions,
    onPaginate,
    renderTransaction,
    tab: transactionType
  } = useDashboard();

  const [tab, setTab] = useState(windowWidth <= 768 ? 'CALENDAR' : 'LIST');
  const [date, setDate] = useState(new Date());
  const [transactionsOnDate, setTransactionsOnDate] =
    useState(userTransactionsData);
  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const [ready, setReady] = useState(true);

  const calendarTabClassName = clsx(tab === 'CALENDAR' && '-active');
  const listTabClassName = clsx(tab === 'LIST' && '-active');
  const datePickerRef = useRef(null);

  useEffect(() => {
    if (userTransactionsData && userTransactionsData.length > 0) {
      setReady(true);
    }
  }, [userTransactionsData]);

  useEffect(() => {
    if (userTransactionsData && userTransactionsData.length > 0) {
      setReady(true);
    }

    if (datePickerRef.current) {
      handleChange(date);
    }
  }, [userTransactionsData, date, datePickerRef.current]);

  const handleAddClick = () => {
    history.push({
      pathname: URL_HELPERS.add,
      state: {
        thing: {
          name: ''
        }
      }
    });
  };

  const renderDesktopTransaction = (transaction) => {
    // window.alert(`${JSON.stringify(transaction)}`);
    setSelectedTransaction([transaction]);
  };

  const handleTabClick = (tab) => {
    return () => setTab(tab);
  };

  const handleChange = (date) => {
    if (ready) {
      console.log('SELECTED DATE:', date);
      const formattedDate = date.toISOString();
      console.log(formattedDate);
      setDate(date);
      setSelectedTransaction([]);
      const filteredTransactions = userTransactionsData.filter(
        (transaction) => {
          const transactionStartDate = new Date(transaction?.startDateTime);
          const transactionEndDate = new Date(transaction?.endDateTime);
          const selectedDate = new Date(formattedDate);

          // Check if selected date is between startDateTime and endDateTime
          return (
            selectedDate >= transactionStartDate &&
            selectedDate <= transactionEndDate
          );
        }
      );
      setTransactionsOnDate(filteredTransactions);
    }
  };

  const renderTransactionsOnDate = () => {
    return transactionsOnDate.length > 0 ? (
      transactionsOnDate.map((transaction) => {
        return (
          <TransactionCard
            transaction={transaction}
            history={history}
            key={transaction.id}
            type={'desktop'}
            handleDesktopClick={() => {
              renderDesktopTransaction(transaction);
            }}
          />
        );
      })
    ) : (
      <div className="container-desktop-empty">
        <EmptyListPlaceholder
          type="transaction"
          icon="close"
          text="Nothing upcoming on this date"
          supportingText="For more activity, try listing an item of your own!"
        />
      </div>
    );
  };

  const datePickerComponent = () => {
    return (
      <RDatePicker
        inline
        monthsShown={1}
        calendarStartDay={1}
        minDate={new Date()}
        maxDate={addDays(new Date(), 7)}
        selected={date}
        onChange={handleChange}
        ref={datePickerRef}
      />
    );
  };

  const renderUpcomingTransactions = () => {
    if (windowWidth <= 768) {
      if (tab === 'CALENDAR') {
        return (
          <>
            <datePickerComponent />
            {datePickerRef.current ? (
              renderTransactionsOnDate()
            ) : (
              <div>Loading...</div>
            )}
          </>
        );
      } else {
        if (userTransactionsData.length > 0) {
          return (
            <InfiniteScroll
              dataLength={userTransactionsData.length}
              next={onPaginate}
              hasMore={hasMoreTransactions}
              loader={<Loader />}
              // TODO: Update endMessage
              endMessage={
                <span className="end-message">All transactions loaded.</span>
              }
              className="transaction-list"
            >
              {userTransactionsData.map((transaction) => (
                <TransactionCard
                  transaction={transaction}
                  history={history}
                  key={transaction.id}
                />
              ))}
            </InfiniteScroll>
          );
        } else {
          <EmptyListPlaceholder
            type="transaction"
            icon="close"
            text="Nothing upcoming yet"
            supportingText="For more activity, try listing an item of your own!"
          />;
        }
      }
    } else {
      return renderTransactionsOnDate();
    }
  };

  // if (isUserProfileLoading) {
  //   return <Loader />;
  // }

  return (
    <DashboardContext.Provider value={{ tab: transactionType }}>
      <div className="dashboard-page">
        {windowWidth <= 768 && (
          <Header>
            <Header.Center>
              <Search history={history} />
            </Header.Center>
          </Header>
        )}
        <div className="body">
          <section className="add-item">
            <div className="heading">
              <h3>Lend yours!</h3>
              <p>By listing your own items, you can earn on Ukuu!</p>
            </div>
            <ButtonText color="primary" onClick={handleAddClick}>
              <Icon icon="add" />
              Add Item
            </ButtonText>
            {windowWidth >= 769 && (
              <>
                <datePickerComponent />
              </>
            )}
          </section>
          <section className="upcoming-transactions">
            <div className="heading">
              <div className="tab-section">
                <span>Upcoming</span>
                {windowWidth <= 768 && (
                  <div className="button-group">
                    <ButtonIcon
                      variant="outlined"
                      icon="calendar_today"
                      className={calendarTabClassName}
                      onClick={handleTabClick('CALENDAR')}
                    />
                    <ButtonIcon
                      variant="outlined"
                      icon="format_list_bulleted"
                      className={listTabClassName}
                      onClick={handleTabClick('LIST')}
                    />
                  </div>
                )}
              </div>
              <Divider />
              <p>
                Below are your upcoming transactions. Your soonest transaction
                is on top.
              </p>
              {renderTransaction()}
              {renderUpcomingTransactions()}
            </div>
          </section>
          {windowWidth >= 769 && (
            <section className="column-transaction-details">
              <div className="column-transaction-details-container">
                {selectedTransaction.length > 0 ? (
                  <TransactionDetails transaction={selectedTransaction[0]} />
                ) : selectedTransaction.length > 0 ||
                  transactionsOnDate.length > 0 ? (
                  <div className="container-third-column">
                    <EmptyListPlaceholder
                      type="transaction"
                      icon="close"
                      text="Select an upcoming transaction"
                      supportingText="Pick a transaction from the list to see more details"
                      size="small"
                    />
                  </div>
                ) : (
                  <div className="container-third-column">
                    <EmptyListPlaceholder type={'desktop'} />
                  </div>
                )}
              </div>
            </section>
          )}
        </div>
      </div>
    </DashboardContext.Provider>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object
};

Dashboard.defaultProps = {
  history: null
};

export default Dashboard;
