import * as yup from 'yup';

const itemFormValidation = yup.object().shape({
  listed: yup.boolean(),
  name: yup.string().required('Item name is required'),
  description: yup.string().when('listed', {
    is: true,
    then: yup.string().required('Item description is required')
  }),
  pricePerPeriod: yup.number().when('listed', {
    is: true,
    then: yup
      .number()
      .typeError('Price per period must be a number')
      .required('Price per period is required')
      .test(
        'isPositive',
        'Price per period must be positive number',
        (value) => value > 0
      )
      .min(1, 'Price per period must be greater than 0')
      .test(
        'acceptablePricePerPeriod',
        null,
        function (pricePerPeriod, { parent: { minRentPeriod } }) {
          const rentalPrice = pricePerPeriod * minRentPeriod;
          if (rentalPrice <= 5) {
            return this.createError({
              message:
                'Price per period multiplied by minimum rental must be greater than $5',
              path: 'pricePerPeriod'
            });
          }
          return true;
        }
      )
  }),
  periodType: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .test('periodType', null, function (obj) {
      if (obj.value === '') {
        return this.createError({ message: 'Period type is required' });
      }
      return true;
    }),
  location: yup
    .object()
    .shape({
      lat: yup.string(),
      lon: yup.string(),
      address: yup.string()
    })
    .test('location', null, function (obj) {
      if (!obj.address) {
        return this.createError({
          message: 'Location is required'
        });
      } else if (!obj.lat || !obj.lon) {
        return this.createError({
          message: 'You must select a location from the list of suggestions'
        });
      } else {
        return true;
      }
    }),
  daysAvailable: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        checked: yup.boolean()
      })
    )
    .test('checkBox', null, function (obj) {
      const result = obj.filter((days) => {
        if (days.checked === false) {
          return days;
        }
      });

      if (result.length === obj.length) {
        return this.createError({ message: 'Days available is required' });
      }
      return true;
    }),
  deposit: yup.number().when('listed', {
    is: true,
    then: yup
      .number()
      .typeError('Deposit must be a number')
      .required('Deposit is required')
      .test(
        'acceptableDeposit',
        null,
        function (deposit, { parent: { pricePerPeriod } }) {
          const acceptableDeposit = pricePerPeriod * 0.5;
          if (acceptableDeposit > deposit) {
            return this.createError({
              message: `Deposit must be at least $${acceptableDeposit}`,
              path: 'deposit'
            });
          }
          return true;
        }
      )
  }),
  category: yup.array().max(3, 'Maximum of 3 category is required'),
  tags: yup.array().max(15, 'Maximum of 15 keyword is required'),
  minRentPeriod: yup
    .number()
    .typeError('Minimum rental must be a number')
    .test(
      'isPositive',
      'Minimum rental must be positive number',
      (value) => value > 0
    )
    .min(1, 'Minimum rental must be greater than 0'),
  maxRentPeriod: yup
    .number()
    .typeError('Maximum rental must be a number')
    .min(0, 'Maximum rental must be a positive number')
    .transform((_, val) => {
      return val === val ? Number(val) : null;
    })
    .nullable(true)
});

export default itemFormValidation;
