/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onSendMessage = /* GraphQL */ `
  subscription OnSendMessage($recipientID: ID) {
    onSendMessage(recipientID: $recipientID) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onOwnerUpdateTransaction = /* GraphQL */ `
  subscription OnOwnerUpdateTransaction($ownerID: ID) {
    onOwnerUpdateTransaction(ownerID: $ownerID) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onRenterUpdateTransaction = /* GraphQL */ `
  subscription OnRenterUpdateTransaction($renterID: ID) {
    onRenterUpdateTransaction(renterID: $renterID) {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTransactionHistory = /* GraphQL */ `
  subscription OnCreateTransactionHistory {
    onCreateTransactionHistory {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTransactionHistory = /* GraphQL */ `
  subscription OnUpdateTransactionHistory {
    onUpdateTransactionHistory {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTransactionHistory = /* GraphQL */ `
  subscription OnDeleteTransactionHistory {
    onDeleteTransactionHistory {
      id
      transactionID
      transactionStatus
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSchedule = /* GraphQL */ `
  subscription OnCreateSchedule {
    onCreateSchedule {
      executeAt
      function
      data
      hasExecuted
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSchedule = /* GraphQL */ `
  subscription OnUpdateSchedule {
    onUpdateSchedule {
      executeAt
      function
      data
      hasExecuted
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSchedule = /* GraphQL */ `
  subscription OnDeleteSchedule {
    onDeleteSchedule {
      executeAt
      function
      data
      hasExecuted
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction {
    onCreateTransaction {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction {
    onUpdateTransaction {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction {
    onDeleteTransaction {
      id
      renterID
      ownerID
      Thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      renter {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      thingID
      publicID
      conversationID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDateTime
      endDateTime
      lastTransactionUpdate
      TransactionHistories {
        items {
          id
          transactionID
          transactionStatus
          paymentStatus
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactionStatus
      paymentStatus
      hasRead
      paymentMethodID
      paymentIntentID
      rentalPrice
      customerID
      deposit
      exchangeCode
      lateFeePaymentIntentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateThing = /* GraphQL */ `
  subscription OnCreateThing {
    onCreateThing {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateThing = /* GraphQL */ `
  subscription OnUpdateThing {
    onUpdateThing {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteThing = /* GraphQL */ `
  subscription OnDeleteThing {
    onDeleteThing {
      id
      name
      timezone
      periodType
      pricePerPeriod
      minRentPeriod
      userID
      listed
      tags
      description
      deposit
      itemValue
      maxRentPeriod
      requiredDocuments
      archived
      archivedAt
      publicID
      daysAvailable
      category {
        name
        key
      }
      location {
        lat
        lon
      }
      address
      images {
        bucket
        key
        region
      }
      User {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      numberOfRatings
      rating
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview {
    onCreateReview {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview {
    onUpdateReview {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview {
    onDeleteReview {
      reviewerID
      reviewer {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      rating
      createdAt
      thingID
      thing {
        id
        name
        timezone
        periodType
        pricePerPeriod
        minRentPeriod
        userID
        listed
        tags
        description
        deposit
        itemValue
        maxRentPeriod
        requiredDocuments
        archived
        archivedAt
        publicID
        daysAvailable
        category {
          name
          key
        }
        location {
          lat
          lon
        }
        address
        images {
          bucket
          key
          region
        }
        User {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        numberOfRatings
        rating
        reviews {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        lastName
        fullName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        publicID
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        customerID
        rating
        numberOfRatings
        reviews {
          nextToken
          startedAt
        }
        createdReviews {
          nextToken
          startedAt
        }
        stripeAccountID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      publicID
      transactionID
      transaction {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedID
      lastMessageReceived {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedAt
      messages {
        items {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasRead
      ownerID
      renterID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      publicID
      transactionID
      transaction {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedID
      lastMessageReceived {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedAt
      messages {
        items {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasRead
      ownerID
      renterID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      publicID
      transactionID
      transaction {
        id
        renterID
        ownerID
        Thing {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        renter {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        thingID
        publicID
        conversationID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDateTime
        endDateTime
        lastTransactionUpdate
        TransactionHistories {
          nextToken
          startedAt
        }
        transactionStatus
        paymentStatus
        hasRead
        paymentMethodID
        paymentIntentID
        rentalPrice
        customerID
        deposit
        exchangeCode
        lateFeePaymentIntentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedID
      lastMessageReceived {
        id
        senderID
        recipientID
        sender {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        recipient {
          id
          firstName
          lastName
          fullName
          email
          bio
          sub
          address
          publicID
          customerID
          rating
          numberOfRatings
          stripeAccountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        body {
          text
        }
        dateSent
        conversationPublicID
        conversation {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        conversationID
        systemMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageReceivedAt
      messages {
        items {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hasRead
      ownerID
      renterID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      firstName
      lastName
      fullName
      avatar {
        bucket
        key
        region
      }
      email
      bio
      Things {
        items {
          id
          name
          timezone
          periodType
          pricePerPeriod
          minRentPeriod
          userID
          listed
          tags
          description
          deposit
          itemValue
          maxRentPeriod
          requiredDocuments
          archived
          archivedAt
          publicID
          daysAvailable
          address
          numberOfRatings
          rating
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Transactions {
        items {
          id
          renterID
          ownerID
          thingID
          publicID
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          paymentStatus
          hasRead
          paymentMethodID
          paymentIntentID
          rentalPrice
          customerID
          deposit
          exchangeCode
          lateFeePaymentIntentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sub
      location {
        lat
        lon
      }
      address
      publicID
      renterConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ownerConversations {
        items {
          id
          publicID
          transactionID
          lastMessageReceivedID
          lastMessageReceivedAt
          hasRead
          ownerID
          renterID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customerID
      rating
      numberOfRatings
      reviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdReviews {
        items {
          reviewerID
          content
          rating
          createdAt
          thingID
          userID
          id
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeAccountID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSearch = /* GraphQL */ `
  subscription OnCreateSearch {
    onCreateSearch {
      id
      keyword
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSearch = /* GraphQL */ `
  subscription OnUpdateSearch {
    onUpdateSearch {
      id
      keyword
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSearch = /* GraphQL */ `
  subscription OnDeleteSearch {
    onDeleteSearch {
      id
      keyword
      location
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
