import PropTypes from 'prop-types';
import React from 'react';
import { ButtonText, FormInput } from '../../../../../../components';
import './styles.scss';

const CodeForm = ({ form, isLoading, onSubmit }) => {
  return (
    <form className="code-form" onSubmit={onSubmit}>
      <p>
        Please enter the verification code that was sent to your email address
        below.
      </p>
      <FormInput
        label="Email Verification Code"
        name="verificationCode"
        required
        control={form.control}
        error={form.formState.errors.verificationCode}
      />
      <ButtonText color="primary" loading={isLoading}>
        Update
      </ButtonText>
    </form>
  );
};

CodeForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

CodeForm.defaultProps = {
  form: null,
  isLoading: false,
  onSubmit: () => {}
};

export default CodeForm;
