import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Icon from '../Icon';
import './styles.scss';

const StarRating = ({ className, onChange, rating, readOnly }) => {
  const [hover, setHover] = useState(-1);

  const starRatingClassNames = clsx('star-rating', className);

  return (
    <div className={starRatingClassNames}>
      {readOnly ? (
        <>
          {Array.from({ length: Math.ceil(rating >= 5 ? 5 : rating) }).map(
            (_, index) => {
              if (Number.isInteger(rating)) {
                return <Icon icon="star" key={index} />;
              } else {
                return (
                  <Icon
                    icon={
                      index + 1 === Math.ceil(rating) ? 'star_half' : 'star'
                    }
                    key={index}
                  />
                );
              }
            }
          )}
          {Array.from({ length: 5 - Math.ceil(rating) }).map((_, index) => (
            <Icon icon="star_outline" key={index} />
          ))}
        </>
      ) : (
        Array.from({ length: 5 }).map((_, index) => (
          <Icon
            icon={index <= hover ? 'star' : 'star_outline'}
            className="clickable"
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating - 1)}
            onClick={() => onChange(index + 1)}
            key={index}
          />
        ))
      )}
    </div>
  );
};

StarRating.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  rating: PropTypes.number,
  readOnly: PropTypes.bool
};

StarRating.defaultProps = {
  className: '',
  onChange: () => {},
  rating: 0,
  readOnly: true
};

export default StarRating;
