import PropTypes from 'prop-types';
import React from 'react';
import { getIntervalDate } from '../../utils';
import './styles.scss';

const TransactionDates = ({ endDateTime, periodType, startDateTime }) => {
  return (
    <div className="transaction-dates">
      <span className="label">Start and End Date</span>
      <span className="dates">
        {getIntervalDate(startDateTime, endDateTime, periodType)}
      </span>
    </div>
  );
};

TransactionDates.propTypes = {
  endDateTime: PropTypes.string,
  periodType: PropTypes.oneOf(['DAYS', 'HOURS', 'MONTHS', 'WEEKS']),
  startDateTime: PropTypes.string
};

TransactionDates.defaultProps = {
  endDateTime: '',
  periodType: 'DAYS',
  startDateTime: ''
};

export default TransactionDates;
