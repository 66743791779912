import React, { useContext, useEffect, useState } from 'react';
import RNModal from 'react-modal';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, RequestItem } from '../../components';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import {
  requestThing as requestThingMutation,
  sendMessage as sendMessageMutation
} from '../../graphql/customMutations';
import {
  getUserTransaction as getUserTransactionQuery,
  getUserTransactions as getUserTransactionsQuery
} from '../../graphql/customQueries';
import {
  generateExchangeCode as generateExchangeCodeMutation,
  updateTransactionStatus as updateTransactionStatusMutation
} from '../../graphql/mutations';
import { useLazyQuery, useMutation, useWindowDimensions } from '../../hooks';
import { TransactionDetails } from './components';
import { getRenterConversations as getRenterConversationsQuery } from '../../graphql/customQueries';

const useDashboard = () => {
  const { transactionId } = useParams();
  const history = useHistory();

  const { state } = useContext(UserContext);
  const { paymentServiceModal } = useContext(ModalContext);

  const [
    getUserTransaction,
    { loading: isUserTransactionLoading, data: getUserTransactionData }
  ] = useLazyQuery(getUserTransactionQuery);

  const [requestThing, { data: requestThingData }] =
    useMutation(requestThingMutation);
  const [generateExchangeCode, { loading: generateExchangeCodeLoading }] =
    useMutation(generateExchangeCodeMutation);
  const [
    updateTransactionStatus,
    {
      loading: updateTransactionStatusLoading,
      data: updateTransactionStatusData
    }
  ] = useMutation(updateTransactionStatusMutation);

  const [getRenterConversations] = useLazyQuery(getRenterConversationsQuery);

  const [sendMessage] = useMutation(sendMessageMutation);

  const { windowWidth } = useWindowDimensions();

  const [transaction, setTransaction] = useState(null);
  const [renterTransactions] = useState([]);

  const [getUserTransactions] = useLazyQuery(getUserTransactionsQuery);

  const [userTransactions, setUserTransactions] = useState([]);
  const [nextToken, setNextToken] = useState('');

  const fetchRenterConversations = async () => {
    try {
      const conversations = await getRenterConversations({
        variables: {
          renterID: state.id,
          limit: 10,
          sortDirection: 'DESC'
        }
      });

      // console.log(
      //   'Data:',
      //   JSON.stringify(
      //     conversations.getRenterConversations.items.map(
      //       (item) => item.transaction
      //     )
      //   )
      // );

      return conversations.getRenterConversations.items.map(
        (item) => item.transaction
      );
    } catch (error) {
      console.error('Error fetching renter conversations:', error);
      return [];
    }
  };

  const getTransactions = async () => {
    const response = await getUserTransactions({
      variables: {
        userID: state.id,
        limit: 100
      }
    });

    setUserTransactions(
      response?.getUserTransactions.items
        ? response.getUserTransactions.items
        : []
    );
    setNextToken(
      response?.getUserTransactions.nextToken
        ? response.getUserTransactions.nextToken
        : ''
    );

    const renterTransactions = await fetchRenterConversations();
    setUserTransactions((prev) => [...prev, ...renterTransactions]);
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    if (transactionId) {
      const getTransaction = async () => {
        await getUserTransaction({
          variables: {
            publicID: transactionId,
            userID: state.id
          }
        });
      };

      getTransaction();
    } else {
      setTransaction(null);
    }
  }, [transactionId]);

  useEffect(() => {
    if (getUserTransactionData) {
      const lenderTransactions =
        getUserTransactionData.getUserTransaction.items[0];
      const combinedTransactions = [
        ...lenderTransactions,
        ...renterTransactions
      ];

      // console.log('combined', JSON.stringify(combinedTransactions));
      setTransaction(combinedTransactions);
    }
  }, [getUserTransactionData]);

  useEffect(() => {
    if (requestThingData?.requestThing) {
      history.push(
        `/dashboard/transaction/${requestThingData.requestThing.publicID}`
      );
    }
  }, [requestThingData]);

  useEffect(() => {
    if (updateTransactionStatusData) {
      setTransaction(updateTransactionStatusData.updateTransactionStatus);
    }
  }, [updateTransactionStatusData]);

  const requestAgain = () => {
    if (transaction.Thing.listed) {
      paymentServiceModal({
        hasPaymentMethods: state.hasPaymentMethods,
        title: 'Request item',
        content: () => (
          <RequestItem
            thing={transaction.Thing}
            requestThing={async (input) => {
              const response = await requestThing({
                variables: {
                  input
                }
              });
              return response;
            }}
          />
        )
      });
    }
  };

  const handleUpdateStatus = async (transactionStatus) => {
    try {
      await updateTransactionStatus({
        variables: {
          input: {
            id: transaction.id,
            _version: transaction._version,
            hasRead: false,
            transactionStatus
          }
        }
      });
    } catch (error) {
      console.error('updateTransactionStatus', error);
      throw error;
    }
  };

  const sendSystemMessage = async (systemMessage, recipientID) => {
    if (recipientID) {
      await sendMessage({
        variables: {
          input: {
            conversationID: transaction.conversation.id,
            conversationPublicID: transaction.conversation.publicID,
            systemMessage,
            recipientID
          }
        }
      });
    } else {
      const userIDs = [transaction.owner.id, transaction.renter.id];
      await Promise.all(
        userIDs.map((userID) =>
          sendMessage({
            variables: {
              input: {
                conversationID: transaction.conversation.id,
                conversationPublicID: transaction.conversation.publicID,
                systemMessage,
                recipientID: userID
              }
            }
          })
        )
      );
    }
  };

  const renderTransaction = () => {
    if (!transaction) {
      return null;
    }

    const closeModal = () => {
      history.push('/dashboard');
      setTransaction(null);
    };

    const renderTransactionDetails = () => {
      return isUserTransactionLoading ? (
        <Loader />
      ) : (
        <TransactionDetails
          closeModal={closeModal}
          generateExchangeCode={generateExchangeCode}
          handleUpdateStatus={handleUpdateStatus}
          loading={
            updateTransactionStatusLoading || generateExchangeCodeLoading
          }
          requestAgain={requestAgain}
          sendSystemMessage={sendSystemMessage}
          transaction={transaction}
        />
      );
    };

    return windowWidth <= 1024 ? (
      <RNModal overlayClassName="overlay" className="modal-content" isOpen>
        {renderTransactionDetails()}
      </RNModal>
    ) : (
      renderTransactionDetails()
    );
  };

  const handlePaginate = async () => {
    if (nextToken === '') return;

    const response = await getUserTransactions({
      variables: {
        userID: state.id,
        limit: 10,
        nextToken
      }
    });

    setUserTransactions((userTransactions) =>
      response?.getUserTransactions.items
        ? [...userTransactions, ...response.getUserTransactions.items]
        : []
    );
    setNextToken(
      response?.getUserTransactions.nextToken
        ? response.getUserTransactions.nextToken
        : ''
    );

    const renterTransactions = await fetchRenterConversations();
    setUserTransactions((prev) => [...prev, ...renterTransactions]);
  };

  return {
    userTransactionsData: userTransactions,
    hasMoreTransactions: !!nextToken,
    onPaginate: handlePaginate,
    transaction,
    renderTransaction,
    tab: state.id === transaction?.owner.id ? 'SUPPLYING' : 'RENTING'
  };
};

export default useDashboard;
