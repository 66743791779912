import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ModalContext from '../../contexts/ModalContext';
import { useS3Read } from '../../hooks';
import { Image } from '..';
import './styles.scss';
import ModalImageCarousel from './ModalImageCarousel';

const ImageCarousel = ({ images }) => {
  const { data, loading } = useS3Read(images);

  const { showModal } = useContext(ModalContext);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleChange = (index) => {
    setSelectedImageIndex(index);
  };

  const handleClick = (index) => {
    showModal({
      title: 'Photos',
      content: () =>
        ModalImageCarousel({
          data,
          loading,
          onChange: handleChange,
          selectedItem: index
        })
    });
  };

  return (
    <div className="image-carousel">
      {images.length > 0 ? (
        <Carousel
          autoPlay={false}
          infiniteLoop
          interval={60000}
          onClickItem={handleClick}
          selectedItem={selectedImageIndex}
          showIndicators={false}
          showThumbs={false}
          statusFormatter={(currentItem, total) => `${currentItem}/${total}`}
        >
          {images.map((image, index) => (
            <Image image={image} key={index} />
          ))}
        </Carousel>
      ) : (
        <Image />
      )}
    </div>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.array
};

ImageCarousel.defaultProps = {
  images: []
};

export default ImageCarousel;
