import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormLabel } from '../Forms';
import './styles.scss';

const RangeSlider = ({ handleSlider, label, max, min, multiSlider }) => {
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const minValRef = useRef(minVal);
  const maxValRef = useRef(maxVal);
  const range = useRef(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  return (
    <div className="range-slider">
      {label && <FormLabel text={label} />}
      {multiSlider ? (
        <>
          <Slider
            min={min}
            max={max}
            defaultValue={[min, max]}
            value={[minVal, maxVal || max]}
            range
            allowCross={false}
            onChange={([minimum, maximum]) => {
              setMinVal(minimum);
              setMaxVal(maximum);
            }}
            onAfterChange={([minimum, maximum]) => {
              handleSlider(minimum, maximum);
            }}
            className="multi-slider"
          />
          <div className="values">
            <div className="min" data-testid="minVal">{`$${
              minVal || min
            }`}</div>
            <div className="max" data-testid="maxVal">{`$${
              maxVal || max
            }`}</div>
          </div>
        </>
      ) : (
        <>
          <Slider
            min={min}
            max={max}
            value={minVal}
            onChange={(value) => setMinVal(value)}
            onAfterChange={(value) => handleSlider(value)}
            className="single-slider"
          />
          <div className="values">
            <div className="min" data-testid="minVal">{`${
              minVal || min
            } miles`}</div>
          </div>
        </>
      )}
    </div>
  );
};

RangeSlider.propTypes = {
  handleSlider: PropTypes.func,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  multiSlider: PropTypes.bool
};

RangeSlider.defaultProps = {
  handleSlider: () => {},
  label: '',
  max: 100,
  min: 0,
  multiSlider: false
};

export default RangeSlider;
