const { formatInTimeZone } = require('date-fns-tz');
import { parse } from 'date-fns';

const getLocalTime = (timezone, date) => {
  return new Date(
    formatInTimeZone(
      new Date(parse('', '', new Date(date))),
      timezone || 'Asia/Manila',
      'yyyy/MM/dd HH:mm:ss'
    )
  );
};

export default getLocalTime;
