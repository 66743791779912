import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ButtonText,
  EmptyListPlaceholder,
  FormGroup,
  FormInput,
  Header,
  Icon,
  ItemCardList,
  Loader
} from '../../components';
import useMyItems from './useMyItems';
import './styles.scss';
import { AddItemButton } from './components';

const MyItems = ({ history }) => {
  const {
    isUserItemsLoading,
    userItemsData,
    searchedItems,
    onChange,
    onClick
  } = useMyItems();

  const classNames = clsx(
    'body',
    (userItemsData.length === 0 || searchedItems.length === 0) && '-empty'
  );

  if (isUserItemsLoading) {
    return <Loader />;
  }

  return (
    <div className="my-items-page">
      <Header>
        <Header.Center>
          <FormInput
            icon="search"
            noController
            onChange={onChange}
            placeholder="Search your listings"
          />
        </Header.Center>
      </Header>
      <div className={classNames}>
        {userItemsData.length > 0 ? (
          searchedItems.length > 0 ? (
            <ItemCardList history={history} things={searchedItems}>
              <AddItemButton onClick={onClick} />
            </ItemCardList>
          ) : (
            <EmptyListPlaceholder
              type="item"
              icon="inventory_2"
              supportingText="Try searching another item."
              text="No items found"
            />
          )
        ) : (
          <div className="empty-list">
            <EmptyListPlaceholder
              type="item"
              icon="inventory_2"
              supportingText="You haven’t added any items yet. Create a listing by clicking the button below!"
              text="No items found"
            />
            <FormGroup>
              <ButtonText color="primary" onClick={onClick}>
                <Icon icon="add" />
                Add item
              </ButtonText>
            </FormGroup>
          </div>
        )}
      </div>
    </div>
  );
};

MyItems.propTypes = {
  history: PropTypes.object.isRequired
};

export default MyItems;
