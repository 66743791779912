import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Address, Distance, Price, Rating } from '../../../../components';
import UserContext from '../../../../contexts/UserContext';
import { getDistance } from '../../../../utils';
import './styles.scss';

const GeneralInformation = ({ item }) => {
  const { state } = useContext(UserContext);

  return (
    <div className="general-information">
      <div className="top">
        <span className="name">{item.name}</span>
        <Rating
          rating={item?.rating || 0}
          numberOfRatings={item.numberOfRatings || 0}
        />
      </div>
      <div className="bottom">
        <Price
          pricePerPeriod={item.pricePerPeriod}
          periodType={item.periodType}
        />
        <div className="location">
          <Distance
            distance={getDistance(state?.currentLocation, {
              lat: item.location.lat,
              lon: item.location.lon
            })}
          />
          <Address address={item.address} />
        </div>
      </div>
    </div>
  );
};

GeneralInformation.propTypes = {
  item: PropTypes.object
};

GeneralInformation.defaultProps = {
  item: null
};

export default GeneralInformation;
