import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import ButtonIcon from '../../ButtonIcon';
import { FormInputError, FormLabel } from '..';
import './styles.scss';

const FormInput = ({
  className,
  control,
  error,
  icon,
  label,
  name,
  noController,
  required,
  success,
  type,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputContainerClassNames = clsx('form-input-container', className);
  const inputClassNames = clsx(
    'input',
    icon && '-icon',
    success && 'success',
    error && 'error'
  );

  const handleShowPasswordClick = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <div className={inputContainerClassNames}>
      {label && <FormLabel text={label} required={required} />}
      <div className="form-input">
        {icon && <ButtonIcon icon={icon} />}
        {noController ? (
          type === 'password' ? (
            <div className="password">
              <input
                className={inputClassNames}
                type={showPassword ? 'text' : 'password'}
                data-testid="passwordInput"
                {...props}
              />
              <ButtonIcon
                icon={showPassword ? 'visibility_off' : 'visibility'}
                variant="text"
                className="toggler"
                onClick={handleShowPasswordClick}
                data-testid="toggler"
              />
            </div>
          ) : (
            <input
              className={inputClassNames}
              type={type}
              name={name}
              data-testid="textInput"
              {...props}
            />
          )
        ) : (
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return type === 'password' ? (
                <>
                  <div className="password">
                    <input
                      className={inputClassNames}
                      type={showPassword ? 'text' : 'password'}
                      data-testid="passwordInput"
                      {...props}
                      {...field}
                    />
                    <ButtonIcon
                      icon={showPassword ? 'visibility_off' : 'visibility'}
                      variant="text"
                      className="toggler"
                      onClick={handleShowPasswordClick}
                      data-testid="toggler"
                    />
                  </div>
                </>
              ) : (
                <>
                  <input
                    className={inputClassNames}
                    type={type}
                    name={name}
                    data-testid="textInput"
                    {...props}
                    {...field}
                  />
                </>
              );
            }}
          />
        )}
      </div>
      {error && <FormInputError error={error} data-testid={`${name}Error`} />}
    </div>
  );
};

FormInput.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object,
  error: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noController: PropTypes.bool,
  required: PropTypes.bool,
  shouldEnterKeywords: PropTypes.bool,
  success: PropTypes.object,
  type: PropTypes.string
};

FormInput.defaultProps = {
  className: '',
  control: {},
  error: null,
  icon: '',
  label: '',
  noController: false,
  required: false,
  shouldEnterKeywords: false,
  success: null,
  type: 'text'
};

export default FormInput;
