export const onSendMessage = /* GraphQL */ `
  subscription OnSendMessage($recipientID: ID) {
    onSendMessage(recipientID: $recipientID) {
      id
      senderID
      recipientID
      sender {
        id
        firstName
        fullName
        lastName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      recipient {
        id
        firstName
        fullName
        lastName
        avatar {
          bucket
          key
          region
        }
        email
        bio
        Things {
          nextToken
          startedAt
        }
        Transactions {
          nextToken
          startedAt
        }
        sub
        location {
          lat
          lon
        }
        address
        renterConversations {
          nextToken
          startedAt
        }
        ownerConversations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      body {
        text
        attachment {
          bucket
          key
          region
        }
      }
      dateSent
      conversationPublicID
      conversation {
        id
        publicID
        transactionID
        transaction {
          id
          renterID
          ownerID
          thingID
          publicID
          exchangeCode
          Thing {
            id
            name
            periodType
            pricePerPeriod
            minRentPeriod
            userID
            listed
            tags
            description
            deposit
            itemValue
            maxRentPeriod
            requiredDocuments
            archived
            archivedAt
            publicID
            daysAvailable
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          renter {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          owner {
            id
            firstName
            lastName
            fullName
            avatar {
              key
              bucket
              region
            }
            email
            bio
            sub
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          conversationID
          startDateTime
          endDateTime
          lastTransactionUpdate
          transactionStatus
          hasRead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedID
        lastMessageReceived {
          id
          senderID
          recipientID
          dateSent
          conversationPublicID
          conversationID
          systemMessage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lastMessageReceivedAt
        messages {
          nextToken
          startedAt
        }
        hasRead
        ownerID
        renterID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      conversationID
      systemMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
