import PropTypes from 'prop-types';
import React from 'react';
import { UnitPeriod } from '../../constants/enumTypes';
import './styles.scss';

const PricingInfo = ({
  deposit,
  periods,
  periodType,
  rentalPrice,
  unitPrice
}) => {
  return (
    <div className="pricing-info">
      <p className="title">Price details</p>
      <div className="items">
        <li>
          <span>Deposit</span>
          <span>${deposit.toLocaleString()}</span>
        </li>
        <li className="light-gray">
          <span>Unit Price</span>
          <span>${unitPrice.toLocaleString()}</span>
        </li>
        <li className="light-gray">
          <span>Period</span>
          <span>
            {periods}{' '}
            {periods === 1
              ? UnitPeriod[periodType].SINGULAR
              : UnitPeriod[periodType].PLURAL}
          </span>
        </li>
        <li>
          <span>Rental Price</span>
          <span>${rentalPrice.toLocaleString()}</span>
        </li>
        <li className="large">
          <span>Total Charge</span>
          <span>${(deposit + rentalPrice).toLocaleString()}</span>
        </li>
      </div>
    </div>
  );
};

PricingInfo.propTypes = {
  deposit: PropTypes.number,
  periods: PropTypes.number,
  periodType: PropTypes.oneOf(['HOURS', 'DAYS', 'WEEKS', 'MONTHS']).isRequired,
  rentalPrice: PropTypes.number,
  unitPrice: PropTypes.number
};

PricingInfo.defaultProps = {
  deposit: 0,
  periods: 0,
  periodType: 'DAYS',
  rentalPrice: 0,
  unitPrice: 0
};

export default PricingInfo;
