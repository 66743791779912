import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Tab = ({ isActive, label, onClick }) => {
  const classNames = clsx('tab', isActive && '-active');

  const handleClick = () => onClick(label);

  return (
    <button
      className={classNames}
      onClick={handleClick}
      data-testid={`${label}Button`}
    >
      {label}
      {isActive && <span className="alert" />}
    </button>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func
};

Tab.defaultProps = {
  isActive: true,
  label: 'Tab',
  onClick: () => {}
};

export default Tab;
