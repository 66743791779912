import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import ukuuRoomIcon from '../../assets/images/ukuu_room_icon.png';
import ModalContext from '../../contexts/ModalContext';
import ButtonText from '../ButtonText';
import { FormGroup } from '../Forms';
import './styles.scss';

const Map = ({
  confirmCallback,
  enableSearch,
  initializeMap,
  lat,
  lon,
  marker
}) => {
  const OFFSET = Math.random() * 0.003 - 0.001;
  const inputRef = useRef(null);
  const { closeModal } = useContext(ModalContext);

  const loadMap = (map, maps) => {
    if (enableSearch) {
      if (inputRef) {
        setMapsPlacesApi(new maps.places.SearchBox(inputRef.current));
      }
    }
    if (marker === 'circle') {
      new maps.Circle({
        strokeColor: '#00acc1',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#00acc1',
        fillOpacity: 0.35,
        map,
        center: {
          lat: lat + OFFSET,
          lng: lon + OFFSET
        },
        radius: 500
      });
    } else {
      new maps.Marker({
        position: {
          lat: lat,
          lng: lon
        },
        map,
        icon: {
          url: ukuuRoomIcon,
          scaledSize: new maps.Size(50, 50) // scaled size
        }
      });
    }
  };

  return (
    <div className="map-form">
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
            libraries: ['places']
          }}
          defaultCenter={{
            lat: lat + OFFSET,
            lng: lon + OFFSET
          }}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            if (initializeMap) {
              initializeMap({ map, maps });
            }
            loadMap(map, maps);
          }}
        ></GoogleMapReact>
      </div>
      {!!confirmCallback && (
        <div className="button">
          <FormGroup>
            <ButtonText
              color="primary"
              onClick={() => {
                closeModal();
              }}
            >
              {enableSearch ? 'Confirm' : 'Ok'}
            </ButtonText>
          </FormGroup>
        </div>
      )}
    </div>
  );
};

Map.propTypes = {
  confirmCallback: PropTypes.func,
  enableSearch: PropTypes.bool,
  handleChange: PropTypes.func,
  initializeMap: PropTypes.func,
  lat: PropTypes.number,
  locationRef: PropTypes.object,
  lon: PropTypes.number,
  map: PropTypes.object,
  mapsApi: PropTypes.object,
  mapsPlacesApi: PropTypes.object,
  marker: PropTypes.oneOf(['circle', 'pin'])
};

Map.defaultProps = {
  confirmCallback: null,
  enableSearch: false,
  handleChange: () => {},
  initializeMap: () => {},
  lat: 0,
  locationRef: null,
  lon: 0,
  map: null,
  mapsApi: null,
  mapsPlacesApi: null,
  marker: 'pin'
};

export default Map;
