import { API } from 'aws-amplify';
import React, { useContext, useEffect, useRef, useState } from 'react';
import RNModal from 'react-modal';
import { useHistory, useParams } from 'react-router-dom';
import { EmptyListPlaceholder, Loader } from '../../components';
import { TransactionStatusUI } from '../../constants/enumTypes';
import UserContext from '../../contexts/UserContext';
import { sendMessage as sendMessageMutation } from '../../graphql/customMutations';
import {
  getOwnerConversations as getOwnerConversationsQuery,
  getRenterConversations as getRenterConversationsQuery,
  getUserConversation as getUserConversationQuery,
  getUserMessages as getUserMessagesQuery
} from '../../graphql/customQueries';
import { onSendMessage as onSendMessageSubscription } from '../../graphql/customSubscriptions';
import { updateConversation as updateConversationMutation } from '../../graphql/mutations';
import { checkReview as checkReviewQuery } from '../../graphql/queries';
import { useLazyQuery, useMutation, useWindowDimensions } from '../../hooks';
import { TransactionType } from '../../models';
import { ConversationDetails } from './components';

const useMessages = () => {
  const { convoId } = useParams();
  const history = useHistory();

  const [getOwnerConversations, { loading: getOwnerConversationsLoading }] =
    useLazyQuery(getOwnerConversationsQuery);
  const [getRenterConversations, { loading: getRenterConversationsLoading }] =
    useLazyQuery(getRenterConversationsQuery);
  const [getUserConversation, { loading: getUserConversationLoading }] =
    useLazyQuery(getUserConversationQuery);
  const [getUserMessages, { loading: getUserMessagesLoading }] =
    useLazyQuery(getUserMessagesQuery);
  // checks if user has already been reviewed
  const [checkReview, { loading: checkReviewLoading }] =
    useLazyQuery(checkReviewQuery);

  const [updateConversation] = useMutation(updateConversationMutation);
  const [sendMessage] = useMutation(sendMessageMutation);

  const { state, setUserData } = useContext(UserContext);

  const { windowWidth } = useWindowDimensions();

  const [tab, setTab] = useState('ALL');
  const [userConversationsData, setUserConversationsData] = useState([]);
  const [userConversationsNextToken, setUserConversationsNextToken] =
    useState('');
  const [userConversationData, setUserConversationData] = useState(null);
  const [userMessagesData, setUserMessagesData] = useState([]);
  const [userMessagesNextToken, setUserMessagesNextToken] = useState('');
  const [hasReviewed, setHasReviewed] = useState(false);

  const subscriptionRef = useRef(null);

  const getConversations = async (nextToken) => {
    let conversations = [];

    if (tab === 'ALL') {
      const [ownerConversations, renterConversations] = await Promise.all([
        getOwnerConversations({
          variables: {
            ownerID: state.id,
            limit: 10,
            sortDirection: 'DESC',
            nextToken
          }
        }),
        getRenterConversations({
          variables: {
            renterID: state.id,
            limit: 10,
            sortDirection: 'DESC',
            nextToken
          }
        })
      ]);

      try {
        conversations = [
          ...ownerConversations.getOwnerConversations.items.map(
            (conversation) => ({
              ...conversation,
              user: conversation.transaction.renter,
              transactionType: TransactionType.SUPPLYING
            })
          ),
          ...renterConversations.getRenterConversations.items.map(
            (conversation) => ({
              ...conversation,
              user: conversation.transaction.owner,
              transactionType: TransactionType.RENTING
            })
          )
        ];
      } catch (error) {
        console.log(`There was an error:`, error);
      }
    } else {
      const queryName =
        tab === TransactionType.SUPPLYING
          ? 'getOwnerConversations'
          : 'getRenterConversations';
      const userType = tab === TransactionType.SUPPLYING ? 'renter' : 'owner';

      const result = await (tab === TransactionType.SUPPLYING
        ? getOwnerConversations
        : getRenterConversations)({
        variables: {
          [tab === TransactionType.SUPPLYING ? 'ownerID' : 'renterID']:
            state.id,
          limit: 10,
          sortDirection: 'DESC',
          nextToken
        }
      });

      conversations = result[queryName].items.map((conversation) => ({
        ...conversation,
        user: conversation.transaction[userType],
        transactionType: tab
      }));
    }

    if (nextToken) {
      setUserConversationsData([...userConversationsData, ...conversations]);
    } else {
      setUserConversationsData(conversations);
    }

    setUserConversationsNextToken(
      conversations.length > 0
        ? conversations[conversations.length - 1].nextToken
        : ''
    );
  };

  useEffect(() => {
    setUserConversationsData([]);
    setUserConversationData(null);

    getConversations();
  }, [tab]);

  useEffect(() => {
    setUserMessagesData([]);
    const getConversation = async () => {
      try {
        if (convoId && state.id) {
          const responseConversation = await getUserConversation({
            variables: {
              publicID: convoId,
              userID: state.id
            }
          });

          const conversation =
            responseConversation.getUserConversation.items[0];

          if (conversation) {
            const responseCheckReview = await checkReview({
              variables: {
                reviewerID: state.id,
                userID:
                  state.id === conversation.transaction.renterID
                    ? conversation.transaction.ownerID
                    : conversation.transaction.renterID,
                thingID: conversation.transaction.thingID
              }
            });

            setHasReviewed({
              user: responseCheckReview.checkReview.user,
              thing: responseCheckReview.checkReview.thing
            });

            const responseMessages = await getUserMessages({
              variables: {
                conversationPublicID: convoId,
                sortDirection: 'DESC',
                limit: 10
              }
            });

            setUserConversationData(conversation);
            setUserMessagesData(
              responseMessages.getUserMessages.items.reverse()
            );
            setUserMessagesNextToken(
              responseMessages.getUserMessages.nextToken || ''
            );
          } else {
            history.push('/messages');
          }
        }
      } catch (error) {
        console.error('getConversation', error);
      }
    };

    getConversation();
  }, [convoId]);

  useEffect(() => {
    const updateConversationRead = async () => {
      try {
        const variables = {
          input: {
            hasRead: true,
            id: userConversationData.id,
            _version: userConversationData._version
          }
        };

        await updateConversation({ variables });
        setUserData({
          unreadConversations: state.unreadConversations.filter(
            (conversation) => conversation.id !== userConversationData.id
          )
        });
      } catch (error) {
        console.error('update convo read: ', error);
      }
    };

    if (
      userConversationData &&
      state.id === userConversationData.lastMessageReceived?.recipientID
    ) {
      updateConversationRead();
    }

    if (convoId) {
      setUserConversationsData(
        userConversationsData.map((conversation) => {
          if (conversation.publicID === convoId) {
            return {
              ...conversation,
              hasRead: true
            };
          }
          return conversation;
        })
      );
    }
  }, [convoId, userConversationData]);

  useEffect(() => {
    const subscribeToIncomingMessages = async () => {
      subscriptionRef.current = await API.graphql({
        query: onSendMessageSubscription,
        variables: {
          recipientID: state.id
        }
      }).subscribe({
        next: ({ value }) => {
          console.log(value);
          const currentConversation = userConversationsData.find(
            (conversation) =>
              conversation.id === value.data.onSendMessage.conversation.id
          );
          const filteredConversations = userConversationsData.filter(
            (conversation) =>
              conversation.id !== value.data.onSendMessage.conversation.id
          );

          if (currentConversation) {
            if (value.data.onSendMessage.conversationPublicID === convoId) {
              setUserConversationsData([
                {
                  ...currentConversation,
                  ...value.data.onSendMessage.conversation,
                  hasRead: true
                },
                ...filteredConversations
              ]);
              setUserMessagesData([
                ...userMessagesData,
                value.data.onSendMessage
              ]);
              setUserConversationData({
                ...userConversationData,
                ...value.data.onSendMessage.conversation
              });
            } else {
              setUserConversationsData([
                {
                  ...currentConversation,
                  ...value.data.onSendMessage.conversation
                },
                ...filteredConversations
              ]);
            }
          } else {
            const newConversation = value.data.onSendMessage.conversation;

            setUserConversationsData([
              {
                ...newConversation,
                user:
                  state.id === newConversation.transaction.renterID
                    ? newConversation.transaction.owner
                    : newConversation.transaction.renter
              },
              ...userConversationsData
            ]);
          }
        },
        error: (error) => console.error('subscription', error)
      });
    };

    if (convoId && userConversationData) {
      subscribeToIncomingMessages();
    }

    return () => {
      subscriptionRef?.current?.unsubscribe();
    };
  }, [convoId, userMessagesData]);

  const send = async (messageBody) => {
    try {
      const variables = {
        input: {
          senderID: state.id,
          recipientID:
            state.id === userConversationData.transaction.renterID
              ? userConversationData.transaction.ownerID
              : userConversationData.transaction.renterID,
          body: {
            ...messageBody
          },
          conversationID: userConversationData.id,
          conversationPublicID: userConversationData.publicID
        }
      };
      const updatedMessages = [
        ...userMessagesData,
        { ...variables.input, delivered: false }
      ];

      setUserMessagesData(updatedMessages);
      const response = await sendMessage({ variables });

      if (response) {
        setUserMessagesData(
          updatedMessages.map((message) => {
            if (message.delivered === false) {
              return {
                ...response.sendMessage,
                delivered: true
              };
            }
            return message;
          })
        );
        const currentConversation = userConversationsData.find(
          (conversation) =>
            conversation.id === response.sendMessage.conversation.id
        );
        const filteredConversations = userConversationsData.filter(
          (conversation) =>
            conversation.id !== response.sendMessage.conversation.id
        );

        setUserConversationsData([
          {
            ...currentConversation,
            ...response.sendMessage.conversation
          },
          ...filteredConversations
        ]);
        setUserConversationData({
          ...userConversationData,
          ...response.sendMessage.conversation
        });
      }
    } catch (error) {
      console.error('sendMessage: ', error);
    }
  };

  const handleUpdateTransaction = (updatedTransaction) => {
    setUserConversationData({
      ...userConversationData,
      transaction: updatedTransaction
    });
  };

  const handleTabNavigation = (tab) => {
    history.push('/messages');
    setUserConversationsNextToken('');
    setTab(tab);
  };

  //

  const handleConversationsPagination = () => {
    if (userConversationsNextToken === '') return;

    getConversations(userConversationsNextToken);
  };

  const handleMessagesPagination = async () => {
    if (userMessagesNextToken === '') return;

    const response = await getUserMessages({
      variables: {
        conversationPublicID: convoId,
        sortDirection: 'DESC',
        limit: 10,
        nextToken: userMessagesNextToken
      }
    });

    setUserMessagesData([
      ...response.getUserMessages.items.reverse(),
      ...userMessagesData
    ]);

    setUserMessagesNextToken(response.getUserMessages.nextToken || '');
  };

  const renderConversation = () => {
    if (
      getUserConversationLoading ||
      getUserMessagesLoading ||
      checkReviewLoading
    ) {
      return <Loader />;
    }

    if (!userConversationData) {
      return windowWidth <= 768 ? null : (
        <div className="messages-column-container">
          <EmptyListPlaceholder
            type="message"
            icon="close"
            text="No conversation selected"
            supportingText="To view a message please select a conversation to the left"
          />
        </div>
      );
    }

    const { transaction } = userConversationData;

    const closeModal = () => {
      setUserConversationData(null);
      history.push('/messages');
    };

    const renderConversationDetails = () => {
      return (
        <ConversationDetails
          sendMessage={send}
          handlePagination={handleMessagesPagination}
          nextToken={userMessagesNextToken}
          thing={transaction.Thing}
          targetUser={
            state.id === transaction.renterID
              ? transaction.owner
              : transaction.renter
          }
          messages={userMessagesData}
          handleUpdateTransaction={handleUpdateTransaction}
          transaction={transaction}
          hasReviewed={hasReviewed}
          transactionStatusBadgeProps={{
            label:
              TransactionStatusUI[
                userConversationData.transaction.transactionStatus
              ].label,
            type: userConversationData.transaction.transactionStatus.toLowerCase(),
            className: 'transaction-status'
          }}
          closeModal={closeModal}
        />
      );
    };

    return windowWidth <= 768 ? (
      <RNModal overlayClassName="overlay" className="modal-content" isOpen>
        {renderConversationDetails()}
      </RNModal>
    ) : (
      renderConversationDetails()
    );
  };

  return {
    tab,
    isUserConversationsLoading:
      getOwnerConversationsLoading || getRenterConversationsLoading,
    userConversationsData,
    hasMoreConversations: !!userConversationsNextToken,
    handleTabNavigation,
    handlePagination: handleConversationsPagination,
    renderConversation
  };
};

export default useMessages;
