import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalContext from '../../contexts/ModalContext';
import {
  createStripeCustomer as createStripeCustomerMutation,
  createUser as createUserMutation
} from '../../graphql/customMutations';
import { useMutation } from '../../hooks';
import { getPublicId } from '../../utils';
import { signupValidation } from '../../validators';
import { Badge, ButtonSocMed, ButtonText, Confirmation, SignInForm } from '..';
import { FormGroup, FormInput } from '../Forms';
import './styles.scss';

const SignUpForm = () => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm({
    resolver: yupResolver(signupValidation),
    criteriaMode: 'all'
  });

  const [createUser] = useMutation(createUserMutation);
  const [createStripeCustomer] = useMutation(createStripeCustomerMutation);

  const { changeModalTitle } = useContext(ModalContext);

  const [content, setContent] = useState('SIGN_UP_FORM');
  const [message, setMessage] = useState('');

  const handleMessage = (error) => {
    if (error.code) {
      switch (error.code) {
        case 'UsernameExistsException':
          setMessage(error.message);
          break;
        default:
          setMessage('There was an error to your request.');
          break;
      }
    } else {
      setMessage('There was an error to your request.');
    }
  };

  const handleSignUpClick = async (data) => {
    try {
      const { userSub } = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          given_name: data.firstName,
          family_name: data.lastName
        }
      });

      const user = await createUser({
        variables: {
          input: {
            sub: userSub,
            publicID: getPublicId(`${data.firstName} ${data.lastName}`),
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName
          }
        }
      });

      if (user.createUser) {
        await createStripeCustomer({
          variables: {
            input: {
              userID: user.createUser.id,
              publicID: user.createUser.publicID,
              email: user.createUser.email,
              fullName: user.createUser.fullName,
              _version: user.createUser._version
            }
          }
        });
      }

      changeModalTitle('Sign Up');
      setContent('CONFIRMATION');
    } catch (error) {
      handleMessage(error);
      console.error('Sign Up:', error);
    }
  };

  const handleSignInClick = () => {
    changeModalTitle('Sign In');
    setContent('SIGN_IN_FORM');
  };

  const renderContent = () => {
    switch (content) {
      case 'SIGN_UP_FORM':
        return (
          <div className="sign-up-form" data-testid="signUpContent">
            <FormGroup>
              <ButtonSocMed
                socialMedia="facebook"
                label="Sign Up with Facebook"
              />
              <ButtonSocMed socialMedia="google" label="Sign Up with Google" />
              <ButtonSocMed socialMedia="amazon" label="Sign Up with Amazon" />
            </FormGroup>
            <p>or sign up with email</p>
            <form
              onSubmit={handleSubmit(handleSignUpClick)}
              data-testid="signUpForm"
            >
              <FormGroup>
                {message && (
                  <Badge
                    variant="contained"
                    type="error"
                    label={message}
                    className="message"
                    data-testid="message"
                  />
                )}
                <FormInput
                  label="First Name"
                  type="text"
                  name="firstName"
                  control={control}
                  error={errors.firstName}
                  data-testid="firstName"
                />
                <FormInput
                  label="Last Name"
                  type="text"
                  name="lastName"
                  control={control}
                  error={errors.lastName}
                  data-testid="lastName"
                />
                <FormInput
                  label="Email Address"
                  type="text"
                  name="email"
                  control={control}
                  error={errors.email}
                  data-testid="email"
                />
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  control={control}
                  error={errors.password}
                  data-testid="password"
                />
                <ButtonText
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                  data-testid="signUpBtn"
                >
                  Sign Up
                </ButtonText>
                <ButtonText
                  color="primary"
                  variant="outlined"
                  type="button"
                  onClick={handleSignInClick}
                  data-testid="signInBtn"
                >
                  Sign In
                </ButtonText>
              </FormGroup>
            </form>
          </div>
        );
      case 'SIGN_IN_FORM':
        return <SignInForm />;
      case 'CONFIRMATION':
        return (
          <Confirmation
            message="Your account was successfully created! Please check your email inbox to verify your account and get started with Ukuu."
            rejectLabel="Close"
            confirmLabel="Confirm"
          />
        );
    }
  };

  return renderContent();
};

export default SignUpForm;
