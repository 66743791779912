import { getDistance as GeoGetDistance } from 'geolib';
import { isEmpty } from '.';

/**
 * @param {Object} firstCoords
 * @param {Object} secondCoords
 * @param {("mi")} unit // :: We can add more distance units in the future
 * @returns String
 */
const getDistance = (firstCoords, secondCoords, unit = 'mi') => {
  if (!isEmpty(firstCoords) && !isEmpty(secondCoords)) {
    let conversion;

    // :: We can add more distance units in the future e.g. km
    switch (unit) {
      case 'mi':
        conversion = 0.000621371;
        break;
      default:
        conversion = 0.000621371;
        break;
    }

    const distance = GeoGetDistance(firstCoords, secondCoords) * conversion;

    return distance < 10 ? distance.toFixed(2) : distance.toFixed(1);
  }
  return '--';
};

export default getDistance;
