import PropTypes from 'prop-types';
import React from 'react';
import ButtonIcon from '../../../ButtonIcon';
import ItemSuggestion from '../../ItemSuggestion';
import SearchField from '../../SearchField';
import './styles.scss';
import { FormGroup } from '../../../Forms';
import ButtonText from '../../../ButtonText';

const TextQuery = ({
  onBackClick,
  onCancelClick,
  onCloseClick,
  onOKClick,
  onSuggestionClick,
  suggestions,
  ...props
}) => {
  return (
    <>
      <div className="top">
        <div className="navigation">
          <ButtonIcon icon="arrow_back" onClick={onBackClick} />
          <span className="text">Search</span>
          <ButtonIcon icon="close" onClick={onCloseClick} />
        </div>
        <div className="text-query">
          <SearchField
            icon="search"
            placeholder="What are you looking for?"
            autoFocus
            {...props}
          />
          {!!suggestions.length && (
            <div className="suggestions">
              {suggestions.map((suggestion) => (
                <ItemSuggestion
                  key={suggestion.id}
                  name={suggestion.name}
                  onClick={onSuggestionClick}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="bottom">
        <FormGroup>
          <ButtonText color="primary" onClick={onOKClick}>
            Search
          </ButtonText>
          <ButtonText variant="outlined" onClick={onCancelClick}>
            Reset
          </ButtonText>
        </FormGroup>
      </div>
    </>
  );
};

TextQuery.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onOKClick: PropTypes.func.isRequired,
  onSuggestionClick: PropTypes.func.isRequired,
  suggestions: PropTypes.array
};

TextQuery.defaultProps = {
  suggestions: []
};

export default TextQuery;
