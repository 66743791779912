import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouteMatch } from 'react-router-dom';
import { Loader } from '../../../../components';
import { ConversationFooter, ConversationHeader, MessageDetails } from '..';
import './styles.scss';

const ConversationDetails = ({
  closeModal,
  handlePagination,
  handleUpdateTransaction,
  hasReviewed,
  messages,
  nextToken,
  sendMessage,
  targetUser,
  thing,
  transaction,
  transactionStatusBadgeProps
}) => {
  const routeMatch = useRouteMatch();

  const [messageBody, setMessageBody] = useState(null);
  let lastDisplayedDate = null;
  const handleChange = ({ target }) => {
    if (target.value) {
      setMessageBody({
        text: target.value
      });
    } else {
      setMessageBody(null);
    }
  };

  const handleSendMessage = () => {
    sendMessage(messageBody);
    setMessageBody(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && messageBodyValid() && !event.shiftKey) {
      event.preventDefault();
      sendMessage(messageBody);
      setMessageBody(null);
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
  };

  const messageBodyValid = () => {
    if (!messageBody?.text.trim()) return false;
    if (!messageBody?.text) return false;
    if (!messageBody) return false;
    return true;
  };

  return (
    <div className="conversation-details">
      <ConversationHeader
        avatar={targetUser.avatar}
        itemName={thing.name}
        onClick={closeModal}
        to={`${routeMatch.url}/transaction/${transaction.publicID}`}
        transactionStatusBadgeProps={transactionStatusBadgeProps}
        userName={targetUser.firstName}
      />
      <div className="conversation-body" id="chat">
        <InfiniteScroll
          dataLength={messages.length}
          next={handlePagination}
          scrollThreshold={0.9}
          hasMore={!!nextToken}
          loader={<Loader />}
          inverse
          scrollableTarget="chat"
        >
          {messages
            .filter(
              (message) =>
                (message.systemMessage &&
                  message.recipientID !== targetUser.id) ||
                !message.systemMessage
            )
            .map((message) => {
              let showDate = false;
              if (message.dateSent) {
                const messageDate = format(
                  new Date(message.dateSent),
                  'MMM dd, yyyy'
                );
                showDate = messageDate !== lastDisplayedDate;
                if (showDate) {
                  lastDisplayedDate = messageDate;
                }
              }

              return (
                <MessageDetails
                  key={message.id}
                  message={message}
                  targetUser={targetUser}
                  thing={thing}
                  transaction={transaction}
                  hasReviewed={hasReviewed}
                  handleUpdateTransaction={handleUpdateTransaction}
                  showDate={showDate}
                />
              );
            })}
        </InfiniteScroll>
      </div>
      <ConversationFooter
        disabled={!messageBodyValid()}
        onChange={handleChange}
        onClick={messageBodyValid() ? handleSendMessage : () => {}}
        onKeyPress={handleKeyPress}
        value={messageBody?.text || ''}
      />
    </div>
  );
};

ConversationDetails.propTypes = {
  closeModal: PropTypes.func,
  handlePagination: PropTypes.func,
  handleUpdateTransaction: PropTypes.func,
  hasReviewed: PropTypes.bool,
  messages: PropTypes.array,
  nextToken: PropTypes.string,
  sendMessage: PropTypes.func,
  targetUser: PropTypes.string,
  thing: PropTypes.object,
  transaction: PropTypes.object,
  transactionStatusBadgeProps: PropTypes.object
};

ConversationDetails.defaultProps = {
  closeModal: () => {},
  handlePagination: () => {},
  handleUpdateTransaction: () => {},
  hasReviewed: false,
  messages: [],
  nextToken: '',
  sendMessage: () => {},
  targetUser: '',
  thing: null,
  transaction: null,
  transactionStatusBadgeProps: null
};

export default ConversationDetails;
