import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const FormGroup = ({ children }) => {
  return <div className="form-group">{children}</div>;
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired
};

export default FormGroup;
