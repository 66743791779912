import { useState, useContext } from 'react';
import { API } from 'aws-amplify';
import AuthContext from '../../contexts/AuthContext';

/**
 * @param {import('@aws-amplify/api-graphql').GraphQLOptions} args
 */
const useLazyQuery = (query, args) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState(null);
  const {
    state: { accessToken }
  } = useContext(AuthContext);

  const callback = async (variables) => {
    try {
      setData(null);
      setLoading(true);
      const response = await API.graphql({
        query,
        authMode: accessToken ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY',
        ...variables,
        ...args
      });

      setData(response.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrors(error);
    }
  };

  return [callback, { loading, errors, data }];
};

export default useLazyQuery;
