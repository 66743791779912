import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';
import './styles.scss';

const SearchField = ({ icon, innerRef, text, ...props }) => {
  return (
    <div className="search-field">
      {icon && <Icon icon={icon} />}
      <input type="text" {...props} className="field" ref={innerRef} />
      {text && <span className="text">{text}</span>}
    </div>
  );
};

SearchField.propTypes = {
  advancedSearchClick: PropTypes.func,
  icon: PropTypes.string,
  innerRef: PropTypes.object,
  searchIconClick: PropTypes.func,
  text: PropTypes.string
};

SearchField.defaultProps = {
  advancedSearchClick: () => {},
  icon: '',
  innerRef: null,
  searchIconClick: () => {},
  text: ''
};

export default SearchField;
