import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ButtonText,
  FormInput,
  FormTextArea,
  LocationInput,
  ToastNotification
} from '../../../../components';
import ModalContext from '../../../../contexts/ModalContext';
import { useS3Upload } from '../../../../hooks';
import { URL_HELPERS } from '../../../../utils/url';
import { userFormValidation } from '../../../../validators';
import AvatarMedia from '../AvatarMedia';
import './styles.scss';

const UserForm = ({
  currentUser,
  setUserData,
  setUserProfileData,
  updateUser
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    getValues
  } = useForm({
    defaultValues: {
      avatar: currentUser.avatar,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      location: {
        ...currentUser.location,
        address: currentUser.address
      },
      bio: currentUser.bio
    },
    resolver: yupResolver(userFormValidation)
  });
  const { addToast } = useToasts();
  const history = useHistory();

  const [uploadFiles] = useS3Upload();

  const { closeModal } = useContext(ModalContext);

  const [loading, setLoading] = useState(false);

  const handleSaveClick = async (values) => {
    try {
      setLoading(true);
      const data = {};

      for (const key in values) {
        if (values[key]) {
          data[key] = values[key];

          if (key === 'location') {
            data['address'] = data[key].address;
            delete data[key].address;
          }

          if (data[key] === currentUser[key]) {
            delete data[key];
          } else {
            if (key === 'avatar') {
              data[key] = await uploadFiles(values[key]);
            }
          }
        }
      }
      if (data.firstName || data.lastName) {
        data['fullName'] = `${data.firstName || currentUser.firstName} ${
          data.lastName || currentUser.lastName
        }`;
      }

      await updateUser({
        variables: {
          input: {
            ...data,
            id: currentUser.id,
            _version: currentUser._version
          }
        }
      });

      const updatedUserData = { ...currentUser, ...data };
      console.log(`updatedUserData:`, updatedUserData);
      setUserData(updatedUserData);
      setUserProfileData(updatedUserData);

      addToast(
        <ToastNotification
          message="Saved Successfully"
          toastId="profile-update-success"
          variant="success"
        />,
        { id: 'profile-update-success' }
      );
      closeModal();
      history.push(URL_HELPERS.profile);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-form">
      <FormProvider
        control={control}
        errors={errors}
        getValues={getValues}
        register={register}
      >
        <AvatarMedia />
        <FormInput
          control={control}
          error={errors.firstName}
          label="First Name"
          name="firstName"
          required
          type="text"
        />
        <FormInput
          control={control}
          error={errors.lastName}
          label="Last Name"
          name="lastName"
          required
          type="text"
        />
        <LocationInput
          className={errors.location ? 'error' : ''}
          control={control}
          error={errors.location}
          label="Location"
          name="location"
          required
          saveLatLng
        />
        <FormTextArea
          error={errors.bio}
          label="Bio (details about yourself)"
          name="bio"
          placeholder="Something about yourself..."
          register={register}
          type="text"
        />
        <ButtonText
          color="primary"
          loading={loading}
          onClick={handleSubmit(handleSaveClick)}
        >
          Save
        </ButtonText>
        <ButtonText variant="outlined" onClick={closeModal}>
          Cancel
        </ButtonText>
      </FormProvider>
    </div>
  );
};

UserForm.propTypes = {
  currentUser: PropTypes.shape({
    _version: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    avatar: PropTypes.object,
    bio: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired
  }).isRequired,
  setUserData: PropTypes.func,
  updateUser: PropTypes.func,
  setUserProfileData: PropTypes.func
};

UserForm.defaultProps = {
  setUserData: () => {},
  updateUser: () => {},
  setUserProfileData: () => {}
};

export default UserForm;
