import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import './styles.scss';

/**
 * *NOTE:
 *   Usage: Set .content positions (top, right, bottom, left)
 */

const DropdownMenu = ({ children, onOutsideClick }) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <div className="dropdown-menu-container" ref={ref}>
      {children}
    </div>
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.node,
  onOutsideClick: PropTypes.func
};

DropdownMenu.defaultProps = {
  children: null,
  onOutsideClick: () => {}
};

export default DropdownMenu;
