import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { KeywordTagList } from '..';
import { FormInputError, FormLabel } from '../Forms';
import './styles.scss';
import Select from './Select';

const Dropdown = ({
  children,
  className,
  control,
  error,
  handleChange,
  keywordTags,
  label,
  name,
  noController,
  options,
  placeholder,
  required,
  shouldEnterKeywords,
  ...props
}) => {
  const [keywords, setKeywords] = useState(keywordTags);

  const handleSelect = (option, onChange) => {
    if (shouldEnterKeywords) {
      const duplicate = keywords.find(
        (keyword) => option.name === keyword.name
      );
      if (!duplicate) {
        setKeywords([{ name: option.name, key: option.key }, ...keywords]);
        if (noController) {
          return;
        }
        onChange([{ name: option.name, key: option.key }, ...keywords]);
      }
    } else {
      if (noController) {
        handleChange(option);
        return;
      }
      onChange({ value: option.value, label: option.label });
    }
  };

  const handleRemove = (index, field) => {
    setKeywords(keywords.filter((_, i) => index !== i));
    if (noController) {
      return;
    } else {
      field.onChange(keywords.filter((_, i) => index !== i));
    }
  };

  return (
    <div className="form-input-container">
      {label && (
        <FormLabel text={label} required={required} htmlFor={name}>
          {children}
        </FormLabel>
      )}
      {noController ? (
        <>
          <Select
            {...props}
            placeholder={placeholder}
            options={options}
            className={[className, error ? 'error' : ''].join(' ')}
            name={name}
            onChange={(option) => handleSelect(option)}
          />
          <KeywordTagList
            keywords={keywords?.map(({ name }) => name) || []}
            onRemove={handleRemove}
          />
        </>
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <Select
                {...props}
                placeholder={placeholder}
                value={field.value}
                options={options}
                className={[className, error ? 'error' : ''].join(' ')}
                name={name}
                error={error}
                onChange={(option) => handleSelect(option, field.onChange)}
                field={field}
              />
              <KeywordTagList
                keywords={keywords?.map(({ name }) => name) || []}
                onRemove={(index) => {
                  handleRemove(index, field);
                }}
              />
            </>
          )}
        />
      )}
      {error && <FormInputError error={error} data-testid={`${name}Error`} />}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  control: PropTypes.object,
  error: PropTypes.object,
  handleChange: PropTypes.func,
  keywordTags: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noController: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  shouldEnterKeywords: PropTypes.bool
};

Dropdown.defaultProps = {
  children: null,
  className: '',
  control: {},
  error: null,
  handleChange: () => {},
  keywordTags: [],
  label: '',
  noController: false,
  options: [],
  placeholder: '',
  register: () => {},
  required: false,
  shouldEnterKeywords: false
};

export default Dropdown;
