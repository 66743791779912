import { useContext, useEffect, useState } from 'react';
import UserContext from '../../contexts/UserContext';
import { getUserProfile as getUserProfileQuery } from '../../graphql/customQueries';
import { updateUser as updateUserMutation } from '../../graphql/mutations';
import { getUserThings as getUserThingsQuery } from '../../graphql/queries';
import { useMutation, useQuery } from '../../hooks/api';

const useProfile = () => {
  const { state } = useContext(UserContext);

  const {
    loading: getUserProfileLoading,
    data: getUserProfileData,
    refetchQueries: refetchUserProfile
  } = useQuery(getUserProfileQuery, {
    variables: {
      publicID: state.publicID
    }
  });

  const { loading: getUserThingsLoading, data: getUserThingsData } = useQuery(
    getUserThingsQuery,
    {
      variables: {
        userID: state.id
      }
    }
  );

  const [updateUser, { data: updateUserData }] =
    useMutation(updateUserMutation);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (updateUserData) {
      refetchUserProfile();
    }
  }, [updateUserData]);

  // *NOTE: Sorts the items starting from the most recently updated
  useEffect(() => {
    if (getUserThingsData) {
      setItems(
        [...getUserThingsData.getUserThings.items].sort((itemA, itemB) =>
          itemA.updatedAt >= itemB.updatedAt ? -1 : 1
        )
      );
    }
  }, [getUserThingsData]);

  return {
    isUserProfileLoading: getUserProfileLoading,
    userProfileData: getUserProfileData?.getUserProfile.items[0] || null,
    isUserItemsLoading: getUserThingsLoading,
    items,
    updateUser
  };
};

export default useProfile;
