import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AcceptExchange, ButtonText } from '../../../../components';
import ModalContext from '../../../../contexts/ModalContext';
import './styles.scss';

const EarlyDropOff = ({ transaction }) => {
  const { showModal, closeModal } = useContext(ModalContext);

  const handleConfirmClick = () => {
    showModal({
      title: 'Verify Exchange Code',
      content: () => AcceptExchange({ transaction })
    });
  };

  const handleCancelClick = () => closeModal();

  return (
    <div className="early-drop-off">
      <p className="message">
        Dropping this item off early means you are returning the item to its
        owner and completing this rental immediately. You will be charged the
        full rental rate for the amount of time you had this item and 50% of the
        rental rate remaining for the transaction.
      </p>
      <p className="message">Are you sure you want to end this rental early?</p>
      <ButtonText color="primary" onClick={handleConfirmClick}>
        Confirm
      </ButtonText>
      <ButtonText variant="outlined" onClick={handleCancelClick}>
        Cancel
      </ButtonText>
    </div>
  );
};

EarlyDropOff.propTypes = {
  transaction: PropTypes.object
};

EarlyDropOff.defaultProps = {
  transaction: null
};

export default EarlyDropOff;
