import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import Loader from '../Loader';
import './styles.scss';

const ButtonIcon = ({
  children,
  className,
  disabled,
  icon,
  loading,
  type,
  variant,
  ...props
}) => {
  const classNames = clsx('button-icon', `-${variant}`, className);

  return (
    <button
      className={classNames}
      disabled={disabled || loading}
      type={type}
      {...props}
    >
      {loading ? <Loader /> : children || <Icon icon={icon} />}
    </button>
  );
};

ButtonIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text'])
};

ButtonIcon.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  icon: '',
  loading: false,
  type: 'button',
  variant: 'text'
};

export default ButtonIcon;
